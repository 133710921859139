import Api from '../api/api';
import { Permission } from '../api/model';
import Constants from '../constants';
import { isAndroid, isBrowser, isIOS, isMobile, isTablet } from 'react-device-detect';

export default class SoarHelper {
    public static isSoar(): boolean {
        return Api.getSubdomain() === Constants.DOMAIN;
    }

    public static apiPermissionToEnum(permission: string): Permission {
        switch (permission) {
            case 'READ':
                return Permission.Read;
            case 'WRITE':
                return Permission.Write;
            case 'REVIEW':
                return Permission.Review;
            case 'ADMIN':
                return Permission.Admin;
            case 'OWNER':
                return Permission.Owner;
            default:
                return Permission.Read;
        }
    }

    public static permissionEnumToString(permission: Permission): string {
        switch (permission) {
            case Permission.Read:
                return 'READ';
            case Permission.Write:
                return 'WRITE';
            case Permission.Review:
                return 'REVIEW';
            case Permission.Admin:
                return 'ADMIN';
            case Permission.Owner:
                return 'OWNER';
            default:
                return 'VIEW';
        }
    }

    public static permissionsEnumToUIText(permission: Permission): string {
        switch (permission) {
            case Permission.Read:
                return 'VIEW';
            case Permission.Write:
                return 'WRITE';
            case Permission.Review:
                return 'WRITE';
            case Permission.Admin:
                return 'ADMIN';
            case Permission.Owner:
                return 'OWNER';
            default:
                return 'VIEW';
        }
    }

    public static formatCurrencyFromCents(amount?: number, currency?: string) {
        if (amount) {
            const currencyLabel = 'USD' === currency ? 'US' : currency ? currency : '';
            return `${currencyLabel}$${(amount / 100).toFixed(2)}`;
        }
        return '';
    }

    public static isValidDomainCharacters = (name: string) => {
        /**
         * \w starts with word char
         * [\w\-] any character or -
         * {0, 61} up to 61 times
         * \w)? end with a word char
         */
        return name.length > 0 && !name.includes(' ') && name.match(/^\w(?:[\w\-]{0,61}\w)?$/); // eslint-disable-line no-useless-escape
    };

    public static isLocalHost() {
        return window.location.hostname?.includes('localhost') || window.location.hostname?.includes('127.0.0.1');
    }

    public static isSoarIOS(): boolean {
        return window.navigator.userAgent.includes('Soar iOS');
    }

    public static isSoarAndroid(): boolean {
        return window.navigator.userAgent.includes('Soar Android');
    }

    public static isMobile(): boolean {
        return isMobile || isTablet;
    }

    public static shouldSessionBeRecorded() {
        return !(
            !this.isSoar() ||
            this.isLocalHost() ||
            window.navigator.userAgent.includes('Prerender') ||
            window.navigator.userAgent.includes('HeadlessChrome') ||
            window.navigator.userAgent.includes('bot')
        );
    }

    public static extractIdFromMapSlug(tileLayerSlug: string): number | undefined {
        let tileLayerId = Number.NaN;
        //split the tileLayerId by dash and get the last segment
        if (tileLayerSlug.includes('-')) {
            const splitTileLayerId = tileLayerSlug.split('-');
            tileLayerId = Number(splitTileLayerId[splitTileLayerId.length - 1]);
        } else {
            tileLayerId = Number(tileLayerSlug);
        }
        return Number.isNaN(tileLayerId) ? undefined : tileLayerId;
    }
}

/*
    Unified method to determine when mobile version should be displayed
    Ideally probably this should be moved into root-configured and include useWidth as well and
    then try avoid using media queries in the code and use only this one single source of truth 
    as I believe at the moment lots weird things are happening with mobile version due to combination
    of both methods. There might be a better solution than this but at something for discussion.
*/
export const isMobileVersion = !isBrowser || isAndroid || isIOS || isMobile || isTablet || SoarHelper.isSoarIOS();
