// One of the only places push is accepted otherwise will display an error and message
// eslint-disable-next-line no-restricted-imports
import { push } from 'connected-react-router';
import { LatLng } from 'leaflet';
import { useLocation } from 'react-router-dom';
import { DashboardMode } from '../store/Dashboard/model';
import { SentinelFeature } from '../store/Map/Sentinel/model';
import { SideDrawerMode } from '../store/SideDrawer/model';
import store from '../store/store';
import ShareService from './share-service';

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function usePositionQuery(): [position?: LatLng, zoom?: number] {
    const pos = useQuery().get('pos');
    if (pos) {
        const parts = pos.split(',');
        const lat = parseFloat(parts[0]);
        const lng = parseFloat(parts[1]);
        const zoom = Number(parseFloat(parts[2]).toFixed(2));
        return [new LatLng(lat, lng), zoom];
    }
    return [undefined, undefined];
}

export function useSentinelQuery(): SentinelFeature | undefined {
    const uri = window.location.href;
    return ShareService.sentinelFeatureFromUri(uri);
}

export function useAnnotationQuery(): string | null {
    return useQuery().get('annotations');
}

// TODO: Remove all URI mutations from this class and all related code paths.
//       In fact we should probably remove this utility class altogether
export default class UriHelper {
    public static POSITION_URI_KEY = 'pos';
    public static TILELAYER_URI_KEY = 'tileLayer';
    public static TASK_URI_KEY = 'task';
    public static BASEMAP_URI_KEY = 'basemap';
    public static ANNOTATION_URI_KEY = 'annotations';
    public static OLD_SENTINEL_URI_KEY = 'service'; // Backward compatibility
    public static EDIT_MODE = 'edit';

    public static hasEditModeParameter() {
        return this.tryGetParam(UriHelper.EDIT_MODE) ? true : false;
    }

    public static setEditModeParameter(editMode: boolean) {
        if (editMode) {
            UriHelper.addParameterToUri(UriHelper.EDIT_MODE, 'true', true);
        } else {
            UriHelper.removeParameterFromUri(UriHelper.EDIT_MODE, true);
        }
    }

    public static addParameterToUri(key, value, notify?: boolean) {
        const params = new URLSearchParams(window.location.search);
        params.set(key, encodeURI(value));
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params}`;

        this.updateHistoryState(newUrl, notify);
    }

    public static addParametersToUri(obj) {
        const params = new URLSearchParams(window.location.search);
        for (const key in obj) {
            const value: string = obj[key];
            if (value && value.length > 0) {
                params.set(key, value);
            } else {
                params.delete(key);
            }
        }
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${
            params.toString().length > 0 ? '?' + params : ''
        }`;
        this.updateHistoryState(newUrl);
    }

    public static removeParameterFromUri(key, notify?: boolean) {
        const params = new URLSearchParams(window.location.search);
        params.delete(key);

        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params}`;

        this.updateHistoryState(newUrl, notify);
    }

    public static tryGetParam(key: string): string | null {
        return this.getParamOrNull(key, new URLSearchParams(window.location.search));
    }

    private static getParamOrNull(key: string, params: URLSearchParams): string | null {
        if (params.has(key)) {
            return params.get(key);
        }
        return null;
    }

    private static updateHistoryState(newPath, notify?: boolean) {
        if (window.history.replaceState) {
            window.history.replaceState({ path: newPath }, '', newPath);
            if (notify) {
                dispatchEvent(new PopStateEvent('popstate', { state: { path: newPath } }));
            }
        }
    }

    public static removeAllSentinelParametersFromURI = () => {
        UriHelper.removeParameterFromUri('service');
        UriHelper.removeParameterFromUri('request');
        UriHelper.removeParameterFromUri('bbox');
        UriHelper.removeParameterFromUri('description');
        UriHelper.removeParameterFromUri('time');
        UriHelper.removeParameterFromUri('layers');
        UriHelper.removeParameterFromUri('layerKey');
        UriHelper.removeParameterFromUri('title');
        UriHelper.removeParameterFromUri('image_data');
    };

    public static addPositionToPath(path) {
        const pos = UriHelper.tryGetParam('pos');
        if (pos) {
            store.dispatch(push({ pathname: path, search: pos ? `pos=${pos}` : '' }));
            return;
        }
        store.dispatch(push(path));
    }

    public static navigateToPath(path: string) {
        store.dispatch(push(path));
    }

    public static navigateToDrawer(mode: SideDrawerMode) {
        let path = '/';
        switch (mode) {
            case SideDrawerMode.NONE:
                path = '/';
                break;
            case SideDrawerMode.YOUR_MAPS:
                path = '/my-maps';
                break;
            case SideDrawerMode.MY_BOOKMARKS:
                path = '/my-bookmarks';
                break;
            case SideDrawerMode.MAPS:
                path = '/maps';
                break;
            case SideDrawerMode.SATELLITE:
                path = '/satellites';
                break;
            case SideDrawerMode.SATELLITE_SENTINEL:
                path = '/satellites/sentinel';
                break;
            case SideDrawerMode.SATELLITE_LANDSAT:
                path = '/satellites/landsat';
                break;
            case SideDrawerMode.SOAR_DRAW_NEW:
                path = '/draw/new';
                break;
            case SideDrawerMode.SOAR_DRAW_EDIT:
                path = '/draw/edit';
                break;
            case SideDrawerMode.SOAR_DRAW_VIEW:
                path = '/draw';
                break;

            case SideDrawerMode.SATELLITE_SKYMAP50:
            case SideDrawerMode.SATELLITE_SKYMAP50_CUSTOM_JOB:
                path = '/satellites/skymap50';
                break;
            case SideDrawerMode.SATELLITE_SKYMAP50_IMAGE_THE_FUTURE:
                path = '/satellites/skymap50/new-collect';
                break;
            case SideDrawerMode.SATELLITE_SKYMAP50_SEARCH_ARCHIVE:
                path = '/satellites/skymap50/archive';
                break;
            case SideDrawerMode.SATELLITE_CGSTL:
                path = '/satellites/cgstl';
                break;
            case SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_50:
                path = '/satellites/cgstl/archive50';
                break;
            case SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_75:
                path = '/satellites/cgstl/archive75';
                break;
            case SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_100:
                path = '/satellites/cgstl/archive100';
                break;
            case SideDrawerMode.SHARE_MAP:
                path = '/upload';
                break;
            default:
                // TODO: Throw an error instead of console logging.
                console.error('SideDrawerMode is not supported by this method.');
                return;
        }
        UriHelper.addPositionToPath(path);
    }

    public static navigateToDashboard(mode?: DashboardMode) {
        let path = '/';
        switch (mode) {
            case DashboardMode.DASHBOARD:
                path = '/dashboard/';
                break;
            case DashboardMode.ACCOUNT_SETTINGS:
                path = '/dashboard/account-settings';
                break;

            case DashboardMode.PROFILE:
                path = '/dashboard/profile';
                break;
            case DashboardMode.MY_IMAGERY:
                path = '/dashboard/my-imagery';
                break;
            case DashboardMode.MY_DRAW_PROJECTS:
                path = '/dashboard/my-draw-projects';
                break;
            case DashboardMode.MY_BOOKMARKS:
                path = '/dashboard/my-bookmarks';
                break;
            case DashboardMode.SATELLITE_QUEUE:
                path = '/dashboard/satellite-queue';
                break;
            case DashboardMode.PAYOUT:
                path = '/dashboard/payout';
                break;
            case DashboardMode.PURCHASE_HISTORY:
                path = '/dashboard/purchase-history';
                break;
            case DashboardMode.SALES_HISTORY:
                path = '/dashboard/sales-history';
                break;
            case DashboardMode.TERMS_AND_CONDITION:
                path = '/dashboard/terms-and-condition';
                break;
            case DashboardMode.SUBDOMAIN_OVERVIEW:
                path = '/dashboard/overview';
                break;
            case DashboardMode.USER_MANAGEMENT:
                path = '/dashboard/users';
                break;
            case DashboardMode.DATA_MANAGEMENT:
                path = '/dashboard/data';
                break;
            case DashboardMode.ACCOUNT_MANAGEMENT:
                path = '/dashboard/account';
                break;
            default:
                path = '/dashboard';
                return;
        }
        store.dispatch(push(path));
    }

    public static navigateToMap(listingId: number) {
        this.navigateToPath(`/maps/${listingId}`);
    }

    public static navigateToEditDrawProject(projectId: string) {
        this.navigateToPath(`/draw/edit/${projectId}`);
    }

    public static navigateToDrawProject(projectId: string) {
        this.navigateToPath(`/draw/${projectId}`);
    }

    public static navigateToProfile(profileId: string) {
        this.navigateToPath(`/profile/${profileId}`);
    }

    public static exitToMap(map?: number) {
        if (!/\/maps/.test(window.location.pathname)) {
            const params = new URLSearchParams(window.location.search);

            // this needs updating if the pinned maps are implemented
            let mapPath = '/maps';
            if (map) {
                mapPath = `${mapPath}/${map}`;
            }

            this.navigateToPath(`${mapPath}${params.toString().length > 0 ? '?' + params : ''}`);
        }
    }

    public static isDrawProject() {
        return this.isPathMatchingPattern(`/draw/`);
    }

    public static isPathMatchingPattern(pattern: string) {
        const pathname = window.location.pathname;
        return pathname.indexOf(pattern) !== -1;
    }

    public static replacePath(newPath: string) {
        if (window.history.replaceState) {
            const params = new URLSearchParams(window.location.search);
            const newUrl = `${window.location.protocol}//${window.location.host}${newPath}?${params}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
        }
    }
}
