import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchInput from '../Search/search-input';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetSelectedSearchTerm, actionClearSelectedSearch } from '../../store/Map/MapSelection/actions';
import { selectSelectedSearchTerm } from '../../store/Map/MapSelection/selectors';
import SearchResults from '../Search/search-results';
import Analytics from '../../lib/user-analytics';

interface SearchModalContainerProps {
    onSelectListing: (id: number) => void;
    toggle: () => void;
    children?: React.ReactElement;
}

const SearchModalContainer = (props: SearchModalContainerProps) => {
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [resultsClassName, setResultsClassName] = useState<'open' | 'closed'>('closed');
    const [childrenClassName, setChildrenClassName] = useState<'open' | 'closed'>('closed');

    const dispatch = useDispatch();

    const searchTerm = useSelector(selectSelectedSearchTerm);
    const setSearchTerm = (searchTerm: string | undefined) => dispatch(actionSetSelectedSearchTerm(searchTerm));
    const clearSelectedSearch = useCallback(() => dispatch(actionClearSelectedSearch()), [dispatch]);

    const handleSelectedListing = (id: number) => {
        props.onSelectListing(id);
        clearSelectedSearch();
    };

    useEffect(() => {
        if (searchTerm && searchTerm.length > 0) {
            Analytics.Event('Draw Tools - Project', `Search from add map - ${searchTerm}`);
            setResultsClassName('open');
            setChildrenClassName('closed');
        } else {
            setResultsClassName('closed');
            setChildrenClassName('open');
        }
    }, [searchTerm, props.children]);

    return (
        <React.Fragment>
            <CloseIcon src="/assets/close.png" onClick={() => props.toggle()} />

            <SearchInput
                handleOnSearchInputHasFocus={() => null}
                handleOnSearchLoading={(isLoading) => setIsSearchLoading(isLoading)}
                handleAutocompleteResults={() => null}
                handleSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
                handleEnterKeyPress={() => null}
                isLoading={isSearchLoading}
            />

            {searchTerm && searchTerm?.length > 0 ? (
                <SearchResultsContainer className={resultsClassName}>
                    <SearchResults
                        handleSelectListing={handleSelectedListing}
                        handleSelectAutocomplete={() => null}
                        autoCompleteResults={[]}
                        disableFilters
                    />
                </SearchResultsContainer>
            ) : (
                <ChildrenContainer className={childrenClassName}>{props.children}</ChildrenContainer>
            )}
        </React.Fragment>
    );
};

export default SearchModalContainer;

const CloseIcon = styled.img`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin: 10px 8px 0 0;
    cursor: pointer;
    float: right;
`;

const transitionStyles = `
    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    &.open {
        opacity: 1;
    }

    &.closed {
        opacity: 0;
    }
`;

const SearchResultsContainer = styled.div`
    ${transitionStyles}
    margin: 60px 3vw 0px;
    padding: 16px 0px;
    background: rgb(24, 25, 25);
    border-radius: 10px;
`;

const ChildrenContainer = styled.div`
    ${transitionStyles}
`;
