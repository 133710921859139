import React from 'react';
import UriHelper from '../../../lib/uri-helper';
import Analytics, { ConversionEvent } from '../../../lib/user-analytics';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import store from '../../../store/store';
import SatelliteItem, { CostType } from './satellite-item';
import PFSSatelliteItem from './pfs-satellite-item';

const Satellites = () => {
    const isOpen = true;
    return (
        <React.Fragment>
            <PFSSatelliteItem
                disabled={false}
                imageUrl="/assets/floating-drawer-satellite-icons/pics-from-space-logo.png"
                title="Pics from Space"
                hoverText="Buying satellite imagery has never been easier"
                onClick={() => {
                    Analytics.Event('Sidedraw - Satellite', 'Clicked PicsFromSpace');

                    const pos = store.getState().appDomain.mapBounds
                        ? `pos=${encodeURI(store.getState().appDomain.mapBounds.toBBoxString())}`
                        : null;

                    window.open(`https://picsfromspace.com/satellite${pos ? `?${pos}` : ''}`, '_blank');
                }}
            />

            <SatelliteItem
                disabled={false}
                imageUrl="/assets/floating-drawer-satellite-icons/satellite-skymap50-logo.png"
                cost={CostType.Paid}
                title="SkyMap50"
                hoverText="High resolution satellite imagery at 50cm per pixel"
                onClick={() => {
                    UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SKYMAP50);
                    Analytics.Event('Sidedraw - Satellite', 'Clicked SkyMap50');
                    Analytics.Conversion(ConversionEvent.SELECT_SATELLITE_TYPE);
                }}
                isOpen={isOpen}
            />

            <SatelliteItem
                disabled={false}
                cost={CostType.Paid}
                imageUrl="/assets/floating-drawer-satellite-icons/satellite-cgstl-logo.png"
                title="CG Satellite"
                hoverText="High resolution satellite imagery from 50cm per pixel"
                onClick={() => {
                    UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_CGSTL);
                    Analytics.Event('Sidedraw - Satellite', 'Clicked CGSTL');
                    Analytics.Conversion(ConversionEvent.SELECT_SATELLITE_TYPE);
                }}
                isOpen={isOpen}
            />

            <SatelliteItem
                disabled={false}
                imageUrl="/assets/floating-drawer-satellite-icons/satellite-sentinel-logo-alternative.png"
                cost={CostType.Free}
                title="Sentinel"
                hoverText="Medium resolution satellite images from 10m per pixel"
                onClick={() => {
                    UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SENTINEL);
                    Analytics.Event('Sidedraw - Satellite', 'Clicked Sentinel');
                    Analytics.Conversion(ConversionEvent.SELECT_SATELLITE_TYPE);
                }}
                isOpen={isOpen}
            />

            <SatelliteItem
                disabled={false}
                cost={CostType.Free}
                imageUrl="/assets/floating-drawer-satellite-icons/satellite-nasa-logo.png"
                title="Landsat"
                hoverText="Low resolution satellite imagery at 30m per pixel."
                onClick={() => {
                    UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_LANDSAT);
                    Analytics.Event('Sidedraw - Satellite', 'Clicked Landsat');
                    Analytics.Conversion(ConversionEvent.SELECT_SATELLITE_TYPE);
                }}
                isOpen={isOpen}
            />

            <SatelliteItem
                disabled={false}
                cost={CostType.Soon}
                imageUrl="/assets/floating-drawer-satellite-icons/satellite-21at-logo.png"
                hoverText="Coming soon."
                title="21AT"
                onClick={() => console.log('clicked')}
                isOpen={isOpen}
            />
        </React.Fragment>
    );
};

export default Satellites;
