import React from 'react';
import styled from 'styled-components';

interface DrawerContainerProps {
    handleBack: () => void;
    handleCloseDraw?: () => void;
    closeDraw?: boolean;
    hideBack?: boolean;
    children?: React.ReactNode;
}

const DrawerContainerHeader = (props: DrawerContainerProps) => {
    return (
        <Header closeDraw={props.closeDraw}>
            {!props.hideBack ? (
                <React.Fragment>
                    <HeaderButton onClick={() => props.handleBack()} data-testid="satellite-drawer-button-back">
                        <BackArrow />
                        <BackText>Back</BackText>
                    </HeaderButton>
                    <HeaderContent>{props.children}</HeaderContent>
                    {props.handleCloseDraw ? (
                        <ChevronIconContainer>
                            <ChevronIcon
                                data-testid="satellite-drawer-button-toggle"
                                onClick={props.handleCloseDraw}
                                className={props.closeDraw ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
                            />
                        </ChevronIconContainer>
                    ) : null}
                </React.Fragment>
            ) : (
                <React.Fragment>{props.children}</React.Fragment>
            )}
        </Header>
    );
};

export default DrawerContainerHeader;

interface CloseDrawProps {
    closeDraw?: boolean;
}

const Header = styled.div<CloseDrawProps>`
    border-bottom: ${(props) => (props.closeDraw ? '' : '1px solid rgba(255, 255, 255, 0.3)')};
    height: 49px;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const HeaderContent = styled.div`
    overflow: hidden;

    div {
        display: flex;
        flex-direction: row;
    }
`;

const HeaderButton = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    cursor: pointer;
`;

const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin: 8px;
    float: left;
`;

const BackText = styled.div`
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    height: 32px;
    margin: 9px;
    margin-left: 0px;
    user-select: none;
`;

const ChevronIconContainer = styled.div`
    width: 100%;
    user-select: none;
`;

const ChevronIcon = styled.i`
    float: right;
    padding: 15px 12px 0px 0px;
    color: rgb(255 255 255 / 80%);
    font-size: 18px;
    transition: all 1s;
    -webkit-transition: all 1s;
    cursor: pointer;
`;
