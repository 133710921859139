import { toast } from 'react-toastify';
import { actionTypes as at } from './constants';
import {
    actionSentinelSelectAOI,
    actionSentinelSelectFeature,
    actionSentinelSelectFeatureOpacity,
} from '../Sentinel/actions';
import {
    actionShowLoginDialog,
    actionSetLoginDialogCloseRedirect,
    actionResetLoginDialogCloseRedirect,
} from '../../App/actions';
import { actionLogoutUser } from '../../Account/actions';
import { actionAppendMap, actionSetActiveMap } from '../SuperMap/actions';
import { actionSetTileLayerOpacity, actionPinTileLayer } from '../TileLayer/actions';
import ApiListings from '../../../api/api-listings';
import ApiDraw, { CreateProjectRequest, UpdateProjectRequest, UpdateProjectDataRequest } from '../../../api/api-draw';
import { LatLngBounds, LatLng } from 'leaflet';
import UriHelper from '../../../lib/uri-helper';
import store from '../../store';

export const actionFetchDrawProjectById = (projectId: string, isMobile?: boolean) => {
    const parseMap = async (mapLayer, getState) => {
        const mapId = mapLayer?.mapId ?? mapLayer?.activeMap;

        if (mapId && !getState().mapTileLayerDomain.pinnedTileLayers.find((tileLayer) => tileLayer.id === mapId)) {
            const mapListing = await ApiListings.getListing(mapId);

            return { ...mapListing, transparency: mapLayer.transparency, pinned: mapLayer?.pinned };
        }
        return null;
    };

    const dispatchMap = (parsedMap, dispatch, getState) => {
        dispatch(actionAppendMap(parsedMap));
        dispatch(actionSetTileLayerOpacity(parsedMap.id, parsedMap.transparency));

        if (!getState().mapTileLayerDomain.pinnedTileLayers.find((tileLayer) => tileLayer.id === parsedMap.id)) {
            if (parsedMap?.pinned && parsedMap) {
                dispatch(actionPinTileLayer(parsedMap));
            } else {
                dispatch(actionSetActiveMap(parsedMap));
            }
        }
    };

    const parseSatellite = (mapLayer) => {
        const createLatLngBounds = (bounds: { _northEast; _southWest }): LatLngBounds => {
            const { _northEast, _southWest } = bounds;

            const northEast = new LatLng(_northEast.lat, _northEast.lng);
            const southWest = new LatLng(_southWest.lat, _southWest.lng);

            return new LatLngBounds(southWest, northEast);
        };

        const bbox = createLatLngBounds(mapLayer?.satelliteFeature?.bbox);

        const satelliteFeature = {
            ...mapLayer?.satelliteFeature,
            bbox: bbox,
            transparency: mapLayer.transparency,
        };

        return satelliteFeature;
    };

    const dispatchSatellite = (parsedSatellite, dispatch) => {
        dispatch(actionSentinelSelectAOI(parsedSatellite.bbox));
        dispatch(actionSentinelSelectFeature(parsedSatellite));
        dispatch(actionSentinelSelectFeatureOpacity(parsedSatellite.transparency));
    };

    return async (dispatch, getState) => {
        dispatch({
            type: at.DRAW_PROJECT_FETCHING,
            payload: true,
        });
        try {
            const drawProject = await ApiDraw.getDrawProject(parseInt(projectId));
            if (drawProject?.data?.project?.mapLayers) {
                await Promise.all(
                    drawProject?.data?.project?.mapLayers.map(async (mapLayer) => {
                        if (mapLayer?.mapId) {
                            return await parseMap(mapLayer, getState);
                        } else if (mapLayer?.satelliteFeature) {
                            return parseSatellite(mapLayer);
                        }
                    })
                ).then((results) =>
                    results.forEach((result) => {
                        if (result) {
                            if (result.listing) {
                                dispatchMap(result, dispatch, getState);
                            } else if (result.satellite) {
                                dispatchSatellite(result, dispatch);
                            }
                        }
                    })
                );
            }

            // Support old draw projects with satelliteFeature
            if (drawProject?.data?.project?.satelliteFeature) {
                const parsedSatellite = parseSatellite(drawProject?.data?.project);
                if (parsedSatellite) {
                    dispatchSatellite(parsedSatellite, dispatch);
                }
            }

            // Support old draw projects with activeMap
            if (drawProject?.data?.project?.activeMap) {
                const parsedMap = await parseMap(drawProject?.data?.project, getState);
                if (parsedMap) {
                    dispatchMap(parsedMap, dispatch, getState);
                }
            }

            dispatch({
                type: at.DRAW_PROJECT_FETCH_SUCCESS,
                payload: drawProject,
            });
        } catch (err) {
            if (err.message.includes('404')) {
                UriHelper.navigateToPath('/404-page-not-found');
            } else if (err.message.includes('403')) {
                if (isMobile) {
                    UriHelper.navigateToPath('/permission-denied');
                    return;
                }

                const isLoggedIn = store.getState().accountDomain.loggedIn;
                if (isLoggedIn) {
                    dispatch(actionShowLoginDialog(false));
                    dispatch(actionResetLoginDialogCloseRedirect());

                    dispatch({
                        type: at.DRAW_PROJECT_FETCH_ERROR,
                        payload: err.message,
                    });
                } else {
                    dispatch(actionShowLoginDialog(true));
                    dispatch(actionSetLoginDialogCloseRedirect('/maps'));
                }
            } else {
                toast.error(err.message);
            }
        } finally {
            dispatch({
                type: at.DRAW_PROJECT_FETCHING,
                payload: false,
            });
        }
    };
};

export const actionFetchDrawStoryProjectsById = (projectId: string) => {
    const parseMap = async (mapLayer, dispatch, getState) => {
        const mapId = mapLayer?.mapId ?? mapLayer?.activeMap;

        if (mapId && !getState().mapTileLayerDomain.pinnedTileLayers.find((tileLayer) => tileLayer.id === mapId)) {
            const mapListing = await ApiListings.getListing(mapId);

            dispatch(actionAppendMap(mapListing));

            dispatch(actionSetTileLayerOpacity(mapId, mapLayer.transparency));

            if (!getState().mapTileLayerDomain.pinnedTileLayers.find((tileLayer) => tileLayer.id === mapId)) {
                if (mapLayer?.pinned && mapListing) {
                    dispatch(actionPinTileLayer(mapListing));
                } else {
                    dispatch(actionSetActiveMap(mapListing));
                }
            }
        }
    };

    const parseSatellite = (mapLayer, dispatch) => {
        const createLatLngBounds = (bounds: { _northEast; _southWest }): LatLngBounds => {
            const { _northEast, _southWest } = bounds;

            const northEast = new LatLng(_northEast.lat, _northEast.lng);
            const southWest = new LatLng(_southWest.lat, _southWest.lng);

            return new LatLngBounds(southWest, northEast);
        };

        const bbox = createLatLngBounds(mapLayer?.satelliteFeature?.bbox);

        const satelliteFeature = {
            ...mapLayer?.satelliteFeature,
            bbox: bbox,
        };
        dispatch(actionSentinelSelectAOI(bbox));
        dispatch(actionSentinelSelectFeature(satelliteFeature));
        dispatch(actionSentinelSelectFeatureOpacity(mapLayer.transparency));
    };

    return async (dispatch, getState) => {
        dispatch({
            type: at.DRAW_PROJECT_STORY_FETCHING,
            payload: true,
        });
        try {
            const projectIds = projectId.split(',').map((id) => parseInt(id));

            const drawProjects = await Promise.all(
                projectIds.map(async (id: number) => {
                    try {
                        const drawProject = await ApiDraw.getDrawProject(id);
                        return drawProject;
                    } catch (error) {
                        return undefined;
                    }
                })
            );

            // If the user has tried to use an unknown id or the project was deleted so not include in the results
            const filterDrawProjects = drawProjects.filter((project) => project !== undefined);
            if (filterDrawProjects[0]?.data?.project?.mapLayers) {
                await Promise.all(
                    filterDrawProjects[0]?.data?.project?.mapLayers.map(async (mapLayer) => {
                        if (mapLayer?.mapId) {
                            await parseMap(mapLayer, dispatch, getState);
                        } else if (mapLayer?.satelliteFeature) {
                            parseSatellite(mapLayer, dispatch);
                        }
                    })
                );
            }
            dispatch({
                type: at.DRAW_PROJECT_STORY_FETCH_SUCCESS,
                payload: filterDrawProjects,
            });
        } catch (err) {
            if (err.message.includes('404')) {
                UriHelper.navigateToPath('/404-page-not-found');
            } else {
                toast.error(err.message);
            }
        } finally {
            dispatch({
                type: at.DRAW_PROJECT_STORY_FETCHING,
                payload: false,
            });
        }
    };
};

export const actionResetDrawStoryProject = () => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_STORY_RESET,
            payload: [],
        });
    };
};

export const actionResetDrawProject = () => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_RESET,
            payload: null,
        });
    };
};

export const actionFetchUserDrawProjects = () => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECTS_FETCHING,
            payload: true,
        });
        try {
            const drawProjects = await ApiDraw.getUserProjects();

            dispatch({
                type: at.DRAW_PROJECTS_FETCH_SUCCESS,
                payload: drawProjects,
            });
        } catch (err) {
            toast.error(err.message);
            dispatch({
                type: at.DRAW_PROJECTS_FETCH_ERROR,
                payload: err.message,
            });
        } finally {
            dispatch({
                type: at.DRAW_PROJECTS_FETCHING,
                payload: false,
            });
        }
    };
};

export const actionFetchSharedDrawProjects = () => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECTS_SHARED_FETCHING,
            payload: true,
        });
        try {
            const drawProjects = await ApiDraw.getSharedProjects();

            dispatch({
                type: at.DRAW_PROJECTS_SHARED_FETCH_SUCCESS,
                payload: drawProjects,
            });
        } catch (err) {
            toast.error(err.message);
            dispatch({
                type: at.DRAW_PROJECTS_SHARED_FETCH_ERROR,
                payload: err.message,
            });
        } finally {
            dispatch({
                type: at.DRAW_PROJECTS_SHARED_FETCHING,
                payload: false,
            });
        }
    };
};

export const actionResetUserDrawProjects = () => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECTS_RESET,
            payload: [],
        });
    };
};

export const actionCreateDrawProject = (project: CreateProjectRequest) => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_CREATE_ERROR,
            payload: null,
        });
        dispatch({
            type: at.DRAW_PROJECT_CREATING,
            payload: true,
        });
        try {
            await ApiDraw.createDrawProject(project)
                .then((res) => {
                    dispatch({
                        type: at.DRAW_PROJECT_CREATE,
                        payload: { ...res, data: project.data },
                    });
                })
                .catch((err) => {
                    throw new Error(err);
                });
        } catch (err) {
            if (err.message.includes('401')) {
                toast.error('Oops! Your session has expired. Can you sign back in, please? And try again.', {
                    autoClose: 10000,
                });
                dispatch(actionLogoutUser());
                dispatch(actionShowLoginDialog(true));
            } else {
                toast.error(err.message);
                dispatch({
                    type: at.DRAW_PROJECT_CREATE_ERROR,
                    payload: err.message,
                });
            }
        } finally {
            dispatch({
                type: at.DRAW_PROJECT_CREATING,
                payload: false,
            });
        }
    };
};

export const actionUpdateDrawProject = (projectId: number, project: UpdateProjectRequest) => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_UPDATING,
            payload: true,
        });
        try {
            const drawProject = await ApiDraw.updateDrawProject(projectId, project);

            let updatedPayload = drawProject;
            if ((project as UpdateProjectDataRequest)?.data) {
                updatedPayload = { ...updatedPayload, data: (project as UpdateProjectDataRequest).data };
            }

            dispatch({
                type: at.DRAW_PROJECT_UPDATE,
                payload: updatedPayload,
            });
        } catch (err) {
            toast.error(err.message);
        } finally {
            dispatch({
                type: at.DRAW_PROJECT_UPDATING,
                payload: false,
            });
        }
    };
};

export const actionDeleteDrawProject = (projectId: number) => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_DELETING,
            payload: true,
        });
        try {
            await ApiDraw.deleteDrawProject(projectId);
            toast.dark('Stoary deleted successfully!');
            dispatch({
                type: at.DRAW_PROJECT_DELETE,
                payload: projectId,
            });
        } catch (err) {
            toast.error(err.message);
        } finally {
            dispatch({
                type: at.DRAW_PROJECT_DELETING,
                payload: false,
            });
        }
    };
};

export const actionSetStoryProjectNumber = (projectNumber: number) => {
    return (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_STORY_NUMBER,
            payload: projectNumber,
        });
    };
};
