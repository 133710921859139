import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RRNavLink, useLocation } from 'react-router-dom';
import {
    NavItem as BootstrapNavItem,
    NavLink as BootstrapNavLink,
    Navbar as BootstrapNavbar,
    NavbarBrand as BootstrapNavbarBrand,
} from 'reactstrap';
import styled from 'styled-components';
import SoarHelper from '../../lib/soar-helper';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { selectLoggedIn, selectMyProfile } from '../../store/Account/selectors';
import { setSelectedAnnotationAction } from '../../store/Annotations/actions';
import { actionShowLoginDialog } from '../../store/App/actions';
import { selectConfig, selectShowNavbar } from '../../store/App/selectors';
import { actionGetNotifications } from '../../store/Notifications/actions';
import { StyledButton } from '../Shared/styled-button';
import { Notifications } from '../SocialMapping/Notifications/notifications';
import NavbarProfile from './navbar-profile';
import { NavbarSubdomainList } from './navbar-subdomain-list';

const NavigationBar = () => {
    const config = useSelector(selectConfig);
    const userLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);
    const showNavbar = useSelector(selectShowNavbar);

    const dispatch = useDispatch();
    const url = useLocation();

    // Check for new notifications
    const checkNotifications = () => {
        if (userLoggedIn && myProfile) {
            dispatch(actionGetNotifications());
        }
    };

    const onClickLogo = () => {
        Analytics.Event('Navigation', 'Clicked Logo');
        checkNotifications();
        UriHelper.navigateToPath('/');
    };

    const onClickMaps = () => {
        Analytics.Event('Navigation', 'Clicked Maps');
        checkNotifications();
    };

    const onClickLogin = () => {
        Analytics.Event('Navigation', 'Clicked Login');
        dispatch(actionShowLoginDialog(true));
    };

    const onClickSatellites = () => {
        Analytics.Event('Navigation', 'Clicked Satellites');
    };

    const onClickUpload = () => {
        dispatch(setSelectedAnnotationAction(undefined));
        if (UriHelper.hasEditModeParameter()) {
            UriHelper.setEditModeParameter(false);
        }
        Analytics.Event('Navigation', 'Clicked Upload');
    };

    const onClickMyMaps = () => {
        Analytics.Event('Navigation', 'Clicked My Maps');
    };

    const onClickBookmarks = () => {
        Analytics.Event('Navigation', 'Clicked Bookmarks');
    };

    const isSoar = SoarHelper.isSoar();
    const isMaps = url.pathname.includes('/maps');
    const isSatellite = url.pathname.includes('/satellites');
    const isUpload = url.pathname.includes('/upload');
    const isMyMaps = url.pathname.includes('/my-maps');
    const isBookmarks = url.pathname.includes('/my-bookmarks');
    const isDrawEdit = url.pathname.includes('/draw/edit') || url.pathname.includes('/draw/new');

    // Some interesting naming going on from the config soar subdomain logo is Soar+-logo_FINAL_white_crop which is from the backend?
    const logoUrl = config.LOGO || '/assets/soar_logo.png';

    if (!showNavbar) {
        return <React.Fragment />;
    }

    return (
        <Navbar fixed="top">
            <NavBarBrandingContent>
                <SoarNavbarBrand onClick={onClickLogo}>
                    {isSoar ? (
                        <SoarLogo src={logoUrl} alt="Soar" data-testid="navbar-logo" />
                    ) : (
                        <SubdomainImg src={logoUrl} alt="Soar" data-testid="navbar-logo-plus" />
                    )}
                </SoarNavbarBrand>
                {myProfile?.domains && <NavbarSubdomainList subdomains={myProfile.domains} />}
            </NavBarBrandingContent>

            <Nav>
                {!isDrawEdit ? (
                    <React.Fragment>
                        <NavItem selected={isMaps} id={!isMaps ? 'maps-section' : undefined}>
                            <NavLink onClick={onClickMaps} to="/maps" tag={RRNavLinkStyled} id="navbar-menu-maps">
                                Maps
                            </NavLink>
                        </NavItem>
                        <NavItem selected={isSatellite} id={!isSatellite ? 'satellites-section' : undefined}>
                            <NavLink
                                onClick={onClickSatellites}
                                to="/satellites"
                                tag={RRNavLinkStyled}
                                id="navbar-menu-satellites"
                            >
                                Satellites
                            </NavLink>
                        </NavItem>

                        {myProfile && isSoar ? (
                            <React.Fragment>
                                <NavItem selected={isMyMaps}>
                                    <NavLink
                                        onClick={onClickMyMaps}
                                        to="/my-maps"
                                        tag={RRNavLinkStyled}
                                        id="navbar-menu-my-maps"
                                    >
                                        Your Maps
                                    </NavLink>
                                </NavItem>

                                <NavItem selected={isBookmarks}>
                                    <NavLink
                                        onClick={onClickBookmarks}
                                        to="/my-bookmarks"
                                        tag={RRNavLinkStyled}
                                        id="navbar-menu-bookmarks"
                                    >
                                        Bookmarks
                                    </NavLink>
                                </NavItem>
                            </React.Fragment>
                        ) : null}

                        {isSoar || UserHelper.hasWritePermissionsForSubdomain(myProfile) ? (
                            <NavAddItem selected={isUpload} id={'upload-section'}>
                                <NavLink onClick={onClickUpload} to="/upload" tag={RRNavLinkStyled}>
                                    <ShareButton>
                                        <UploadIcon className="fa fa-plus" />
                                        Add your maps
                                    </ShareButton>
                                </NavLink>
                            </NavAddItem>
                        ) : null}
                    </React.Fragment>
                ) : null}

                <NavItem>
                    <BellContainer>
                        <Notifications />
                    </BellContainer>
                </NavItem>

                {userLoggedIn && myProfile ? (
                    <NavbarProfile userProfile={myProfile} isSoar={isSoar} />
                ) : (
                    <SignInNavItem>
                        <NavLink onClick={onClickLogin} data-cy={'Sign in'} data-testid="navbar-sign-in">
                            Sign in
                        </NavLink>
                    </SignInNavItem>
                )}
            </Nav>
        </Navbar>
    );
};

export default NavigationBar;

interface NavItemProps {
    selected?: boolean;
}

export const Navbar = styled(BootstrapNavbar)`
    background: ${(props) => props.theme.color.transparentDark};
    height: 78px;
    margin-left: 0;
    z-index: 10025;
    color: white;
`;

const NavBarBrandingContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Nav = styled(BootstrapNavbar)`
    padding: 0.5rem 0 0.5rem 1rem;
    flex-wrap: nowrap;
`;

const NavItem = styled(BootstrapNavItem)<NavItemProps>`
    list-style: none;
    a {
        color: ${(props) => (props.selected ? '#EED926 !important' : 'white')};
    }

    &:hover {
        color: none;
    }
`;

const NavAddItem = styled(NavItem)`
    // If the user is on a tablet the add map button will not display but will
    // make everything else functional and aesthetically pleasing
    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const BellContainer = styled.div`
    margin-top: -8px;
`;
const SignInNavItem = styled(BootstrapNavItem)`
    color: white;
    list-style: none;
    min-width: 71px;

    &:hover {
        color: #eed926;
    }
`;

interface NavLinkProps {
    to?: string;
}

const NavLink = styled(BootstrapNavLink)<NavLinkProps>`
    color: white !important;
    cursor: pointer;
    &:hover {
        color: #eed926 !important;
    }
`;

const RRNavLinkStyled = styled(RRNavLink)`
    color: white;
    text-align: center;
    &:hover {
        color: #eed926;
    }
`;

const ShareButton = styled(StyledButton)`
    margin: -6px 0 -6px 0;
    padding: 6px 12px;
    min-width: 154px;
`;

const SoarNavbarBrand = styled(BootstrapNavbarBrand)`
    cursor: pointer;
`;

const SoarLogo = styled.img`
    height: 48px;
`;

const SubdomainImg = styled.img`
    width: 134px;
    height: 48px;
    padding-top: 2px;
`;

const UploadIcon = styled.i`
    color: white;
    width: 17px;
    margin: -2px 4px 0 0;
`;
