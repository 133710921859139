import React, { useEffect, useState } from 'react';
import { LatLngBoundsExpression } from 'leaflet';
import { SentinelFeature } from '../../../store/Map/Sentinel/model';
import { ImageOverlay } from 'react-leaflet';
import { useSentinelQuery } from '../../../lib/uri-helper';
import { useSelector } from 'react-redux';
import { selectSentinelSelectedFeatureOpacity } from '../../../store/Map/Sentinel/selectors';

interface SentinelEditLayerProps {
    editMode: boolean;
}

const SentinelEditLayer = ({ editMode }: SentinelEditLayerProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [sentinelFeature, setSentinelFeature] = useState<SentinelFeature | undefined>(undefined);

    const sentinelShareFeature = useSentinelQuery();

    const sentinelOverlayOpacity = useSelector(selectSentinelSelectedFeatureOpacity);

    useEffect(() => {
        if (!editMode && !sentinelShareFeature && sentinelFeature) {
            setSentinelFeature(undefined);
        }

        if (editMode && sentinelShareFeature && !sentinelFeature) {
            setSentinelFeature(sentinelShareFeature);
        }

        return () => {
            if (sentinelFeature) {
                setSentinelFeature(undefined);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, sentinelShareFeature]);

    if (!sentinelFeature) {
        return null;
    }

    const sentinelEditCSS = `
        pointer-events: none;
        user-select: none;
    `;

    return (
        <React.Fragment>
            {isLoading && (
                <React.Fragment>
                    <ImageOverlay
                        url="/assets/loading.gif"
                        zIndex={20}
                        interactive={false}
                        bounds={sentinelFeature.bbox as LatLngBoundsExpression}
                    />
                    <ImageOverlay
                        url={sentinelFeature.previewUrl}
                        zIndex={2}
                        bounds={sentinelFeature.bbox as LatLngBoundsExpression}
                        interactive={false}
                        className={sentinelEditCSS}
                        opacity={sentinelOverlayOpacity ?? 1}
                    />
                </React.Fragment>
            )}
            <ImageOverlay
                url={sentinelFeature.highResolutionPreviewUrl}
                zIndex={2}
                bounds={sentinelFeature.bbox as LatLngBoundsExpression}
                interactive={false}
                className={sentinelEditCSS}
                opacity={sentinelOverlayOpacity ?? 1}
                eventHandlers={{
                    load: () => {
                        setIsLoading(false);
                    },
                    unload: () => {
                        setIsLoading(true);
                    },
                }}
            />
        </React.Fragment>
    );
};

export default SentinelEditLayer;
