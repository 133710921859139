import React, { useState } from 'react';
import styled from 'styled-components';

import SoarModal, { SoarModalProps, StyledModalBody } from '../../../Shared/soar-modal';
import { inputMaxValues } from '../../../../lib/limitation-constants';
import { StyledButton } from '../../../Shared/styled-button';

import { CreateProjectMetaRequest } from '../../../../api/api-draw';
import { PulseLoader } from '../../../Shared/pulse-loader';
import ProjectPermissionsSelect, { ProjectPermission } from './Permissions/project-permissions-select';

interface ProjectMetadataDialogProps extends SoarModalProps {
    toggle: () => void;
    onSubmit: (project: CreateProjectMetaRequest) => void;
    isCreatingProject?: boolean;
}

const ProjectMetadataDialog = (props: ProjectMetadataDialogProps) => {
    const [title, setTitle] = useState<string>('');
    const [titleLength, setTitleLength] = useState<number>(0);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [projectPermission, setProjectPermission] = useState<ProjectPermission>('public');
    const [inviteOnlyEmails, setInviteOnlyEmails] = useState<string[]>([]);

    const checkForm = () => {
        if (!title) {
            return false;
        } else {
            // TODO: set project permissions
            const createProjectRequest: CreateProjectMetaRequest = {
                title: title,
                public: projectPermission === 'public',
                sharePermissions: inviteOnlyEmails.map((email) => ({ email })),
            };

            props.onSubmit(createProjectRequest);
            return true;
        }
    };

    const setProjectTitle = (title: string) => {
        if (title.length < inputMaxValues.MAX_TITLE_CHARACTERS) {
            setTitle(title);
            setTitleLength(title.length);
        }
    };

    const handleClosingProjectDetails = () => {
        props.toggle();
        setTitle('');
        setTitleLength(0);
        setFormValid(true);
    };

    return (
        <ProjectMetadataModal title="Save your Stoary" isOpen={props.isOpen} toggle={handleClosingProjectDetails}>
            <ProjectMetadataModalBody>
                <Content>
                    <Label>Title</Label>
                    <div className="d-flex" style={{ margin: '0px 1px' }}>
                        <TextField
                            type="text"
                            value={title}
                            onChange={(e) => setProjectTitle(e.target.value)}
                            placeholder="Give your stoary a catchy title"
                            disabled={titleLength === inputMaxValues.MAX_TITLE_CHARACTERS ? true : false}
                        />
                    </div>

                    <CharacterCount characters={titleLength} maxCharacters={inputMaxValues.MAX_TITLE_CHARACTERS - 1}>
                        Characters: {titleLength}/{inputMaxValues.MAX_TITLE_CHARACTERS - 1}
                    </CharacterCount>
                    {!formValid && !title && <Error>Please enter a title for your stoary</Error>}

                    <ProjectPermissionsSelect
                        projectPermission={projectPermission}
                        onPermissionChange={(permission) => setProjectPermission(permission)}
                        invitedUserEmails={inviteOnlyEmails}
                        onInviteUsers={(emails) => {
                            setInviteOnlyEmails([...emails, ...inviteOnlyEmails]);
                        }}
                        onInviteUser={(email) => {
                            setInviteOnlyEmails([email, ...inviteOnlyEmails]);
                        }}
                        onRemoveUser={(email) => {
                            setInviteOnlyEmails(inviteOnlyEmails.filter((e) => e !== email));
                        }}
                    />

                    <div className="d-flex mt-4 justify-content-between">
                        {!props.isCreatingProject ? (
                            <ProjectMetadataButtonControl>
                                <StyledButton
                                    className="mr-0 pl-4 pr-4"
                                    onClick={() => {
                                        setFormValid(checkForm());
                                    }}
                                >
                                    SAVE
                                    <i className="fa fa-save ml-2"></i>
                                </StyledButton>
                            </ProjectMetadataButtonControl>
                        ) : (
                            <PulseLoader />
                        )}
                    </div>
                </Content>
            </ProjectMetadataModalBody>
        </ProjectMetadataModal>
    );
};

export default ProjectMetadataDialog;

const ProjectMetadataModal = styled(SoarModal)`
    .modal-header {
        .modal-title {
            text-align: center;
        }

        & + div {
            margin-bottom: 5px;
            max-height: calc(100vh - 200px);
            overflow: hidden auto;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #343a40;
            }

            &::-webkit-scrollbar {
                width: 8px;
                background-color: #343a40;
            }

            &::-webkit-scrollbar-thumb {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #eed926;
                border-radius: 4px;
            }
        }
    }
`;

const ProjectMetadataModalBody = styled(StyledModalBody)`
    padding: 1rem;
`;

const Content = styled.div`
    flex: 1 1 auto;
    text-align: left;
    font-weight: 400;
    color: rgb(179, 179, 179);
`;

const TextField = styled.input`
    display: block;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    padding: 4px;
    color: white;
    border-radius: 0.25rem;
    margin: 0px 1px;
`;

const Label = styled.p`
    display: block;
    color: white;
    font-size: 16px;
    margin: 4px 0px;
`;

const Error = styled.p`
    color: red !important;
    top: -10px;
`;

const ProjectMetadataButtonControl = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

interface CharacterCountProps {
    characters: number;
    maxCharacters: number;
}

const CharacterCount = styled.span<CharacterCountProps>`
    color: ${(props) =>
        props.characters >= props.maxCharacters ? 'rgba(255, 0, 0, 0.7);' : 'rgba(255, 255, 255, 0.5);'};
    font-size: 10px;
    display: block;
    float: right;
    margin-right: 2px;
`;
