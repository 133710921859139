import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import { v4 as uuidv4 } from 'uuid';
import Rectangle, { defaultRectangleOptions } from './rectangle';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';

import { handlePattternIdFromPatternFillUrl, handleDuplicatePattern } from '../FillPattern/pattern-util';

interface RectangleAnnotationBuilderProps {
    onCreateRectangle: (rectangle: Rectangle) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const createRectangleBuilderElement = (props: RectangleAnnotationBuilderProps, context: LeafletContextInterface) => {
    const MINIMUM_SIZE_FOR_SINGLE_CLICK_BUILD = 10; //px
    const SINGLE_CLICK_RECTANGLE_WIDTH = 60; //px
    const SINGLE_CLICK_RECTANGLE_HEIGHT = 50; //px

    const lastUsedRectangleOptions = store.getState().annotationDomain.present.rectangleReducer.rectangleOptions;
    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);

    const emptyLatLngBounds = new L.LatLngBounds(new L.LatLng(0, 0), new L.LatLng(0, 0));
    const rectangle = new L.Rectangle(emptyLatLngBounds, {
        ...defaultRectangleOptions,
        ...(lastUsedRectangleOptions ?? {}),
        opacity: 0,
        fillOpacity: 0,
        pane: builderPaneId,
    });
    const rectangleElement = createElementObject<L.Rectangle, RectangleAnnotationBuilderProps>(rectangle, context);

    const isRectangleBelowMinimumSize = (start: L.LatLng, end: L.LatLng): boolean => {
        const origin = new L.LatLng(0, 0);
        if (start.equals(origin) || end.equals(origin) || start.equals(end)) {
            return true;
        }
        const startScreenPosition = context.map.latLngToContainerPoint(start);
        const endScreenPosition = context.map.latLngToContainerPoint(end);
        const width = startScreenPosition.distanceTo(new L.Point(endScreenPosition.x, startScreenPosition.y));
        const height = startScreenPosition.distanceTo(new L.Point(startScreenPosition.x, endScreenPosition.y));
        return width < MINIMUM_SIZE_FOR_SINGLE_CLICK_BUILD || height < MINIMUM_SIZE_FOR_SINGLE_CLICK_BUILD;
    };

    rectangleElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        let startLatLng: L.LatLng;

        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                props.onCancelBuild();
                context.map.off('mousemove', onMouseMove);
                context.map.off('mousedown', onMouseDown);
                context.map.off('mouseup', onMouseUp);
                context.map.dragging.enable();
                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
                document.removeEventListener('keydown', onKeyDown);
            }
        };

        document.addEventListener('keydown', onKeyDown);

        const onMouseMove = (e: L.LeafletMouseEvent) => {
            rectangleElement.instance.setBounds(new L.LatLngBounds(startLatLng, e.latlng));
        };

        const onMouseUp = (e: L.LeafletMouseEvent) => {
            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            context.map.off('mouseup', onMouseUp);
            context.map.dragging.enable();
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
            document.removeEventListener('keydown', onKeyDown);

            const rectangleId = uuidv4();
            const rectangleOptions = {
                ...rectangleElement.instance.options,
                ...lastUsedRectangleOptions,
            };

            if (rectangleOptions.fillColor) {
                const pattern = handlePattternIdFromPatternFillUrl(rectangleOptions.fillColor);

                if (pattern !== 'none') {
                    rectangleOptions.fillColor = handleDuplicatePattern(
                        pattern,
                        rectangleId,
                        rectangleOptions.color || '#eed926'
                    );
                }
            }

            if (isRectangleBelowMinimumSize(startLatLng, e.latlng)) {
                const startScreenPosition = context.map.latLngToContainerPoint(e.latlng);
                const endScreenPosition = new L.Point(
                    startScreenPosition.x + SINGLE_CLICK_RECTANGLE_WIDTH,
                    startScreenPosition.y + SINGLE_CLICK_RECTANGLE_HEIGHT
                );
                const endLatLng = context.map.containerPointToLatLng(endScreenPosition);
                const bounds = new L.LatLngBounds(e.latlng, endLatLng);
                const rectangle: Rectangle = {
                    annotationType: 'Rectangle',
                    zIndex: props.zIndex || defaultZIndex,
                    id: rectangleId,
                    bounds: bounds,
                    options: rectangleOptions,
                    showArea: false,
                    showLength: false,
                    units: 'metric',
                    labelColor: 'black',
                };
                props.onCreateRectangle(rectangle);
            } else {
                const rectangle: Rectangle = {
                    annotationType: 'Rectangle',
                    zIndex: props.zIndex || defaultZIndex,
                    id: rectangleId,
                    bounds: rectangleElement.instance.getBounds(),
                    options: rectangleOptions,
                    showArea: false,
                    showLength: false,
                    units: 'metric',
                    labelColor: 'black',
                };
                props.onCreateRectangle(rectangle);
            }
        };

        const onMouseDown = (e: L.LeafletMouseEvent) => {
            rectangleElement.instance.setStyle({
                ...defaultRectangleOptions,
                ...(lastUsedRectangleOptions ?? {}),
            });
            startLatLng = e.latlng;
            rectangleElement.instance.setBounds(new L.LatLngBounds(e.latlng, e.latlng));
            context.map.on('mousemove', onMouseMove);
            context.map.on('mouseup', onMouseUp);
        };

        context.map.on('mousedown', onMouseDown);
    });

    return rectangleElement;
};

const useRectangleBuilder = createElementHook<L.Rectangle, RectangleAnnotationBuilderProps, LeafletContextInterface>(
    createRectangleBuilderElement
);

const RectangleAnnotationBuilder = (props: RectangleAnnotationBuilderProps) => {
    const context = useLeafletContext();
    const rectangleBuilder = useRectangleBuilder(props, context);
    useLayerLifecycle(rectangleBuilder.current, context);
    return null;
};

export default RectangleAnnotationBuilder;
