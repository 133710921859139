import styled from 'styled-components';
import { ListingDTO } from '../../../../../api/model';
import StoryMapOpacitySlider from './story-map-opacity-slider';

interface StoryMapAttributionProps {
    listing: ListingDTO;
}

const StoryMapAttribution = (props: StoryMapAttributionProps) => {
    return (
        <Container>
            <Reference>Original Map:</Reference>
            <CardContainer>
                <ContentContainer>
                    <Link href={`/maps/${props.listing.id}`} title="Click to open this map">
                        <Title>{props.listing.title}</Title>
                    </Link>
                    <SubText>by {props.listing.userName || 'Soar User'}</SubText>
                    <StoryMapOpacitySlider tileLayer={props.listing} />
                </ContentContainer>
            </CardContainer>
        </Container>
    );
};

export default StoryMapAttribution;

const Container = styled.div`
    margin: 12px;
`;

const Link = styled.a`
    text-decoration: none;
    color: white;
    cursor: pointer;
    &:hover {
        color: #eed926;
        text-decoration: underline;
    }
`;

const Reference = styled.span`
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.normal};
    color: white;
    margin: 0 0 2px 2px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: ${(props) => props.theme.borderRadius};
    padding: 3px;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 2px;
`;

const Title = styled.p`
    color: #eed926 !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    margin: 0;
`;
