import L, { LeafletMouseEvent } from 'leaflet';
import {
    LeafletContextInterface,
    createElementHook,
    createElementObject,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import { v4 as uuidv4 } from 'uuid';

import Circle, { defaultCircleOptions } from './circle';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';

import { handlePattternIdFromPatternFillUrl, handleDuplicatePattern } from '../FillPattern/pattern-util';

interface CircleBuilderProps {
    onCreateCircle: (circle: Circle) => void;
    onCancel: () => void;
    zIndex?: number;
}

const createCircleBuilder = (props: CircleBuilderProps, context: LeafletContextInterface) => {
    let startLatLng = new L.LatLng(0, 0);
    const MINIMUM_SCREEN_SIZE_RADIUS = 10; //px
    const SINGLE_CLICK_RADIUS_SIZE = 60; //px

    const lastUsedCircleOptions = store.getState().annotationDomain.present.circleReducer.circleOptions;
    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);

    const circle = new L.Circle(startLatLng, 0, {
        ...defaultCircleOptions,
        ...(lastUsedCircleOptions ?? {}),
        opacity: 0,
        fillOpacity: 0,
        pane: builderPaneId,
    });
    const circleElement = createElementObject<L.Circle, CircleBuilderProps>(circle, context);

    const isCircleBelowMinimumSize = (start: L.LatLng, end: L.LatLng): boolean => {
        const startScreenPosition = context.map.latLngToContainerPoint(start);
        const endScreenPosition = context.map.latLngToContainerPoint(end);
        const radius = startScreenPosition.distanceTo(endScreenPosition);
        return radius < MINIMUM_SCREEN_SIZE_RADIUS;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancel();
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

            context.map.off('mousemove', mouseMove);
            context.map.off('mousedown', mouseDown);
            context.map.off('mouseup', mouseUp);
            context.map.dragging.enable();
        }
    };

    const mouseUp = (e: L.LeafletMouseEvent) => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.off('mousemove', mouseMove);
        context.map.off('mousedown', mouseDown);
        context.map.off('mouseup', mouseUp);
        context.map.dragging.enable();

        const circleId = uuidv4();
        const circleOptions: L.CircleOptions = circleElement.instance.options as L.CircleOptions;

        if (circleOptions.fillColor) {
            const pattern = handlePattternIdFromPatternFillUrl(circleOptions.fillColor);

            if (pattern !== 'none') {
                circleOptions.fillColor = handleDuplicatePattern(pattern, circleId, circleOptions.color || '#eed926');
            }
        }

        if (isCircleBelowMinimumSize(startLatLng, e.latlng)) {
            // The user either single clicked, or tried to make an extremely small circle
            const centerContainerPoint = context.map.latLngToContainerPoint(startLatLng);
            const radiusContainerPoint = L.point(
                centerContainerPoint.x + SINGLE_CLICK_RADIUS_SIZE,
                centerContainerPoint.y
            );
            const radiusLatLng = context.map.containerPointToLatLng(radiusContainerPoint);
            const radius = context.map.distance(startLatLng, radiusLatLng);
            const circle: Circle = {
                annotationType: 'Circle',
                id: circleId,
                center: startLatLng,
                radius: radius,
                options: circleOptions,
                zIndex: props.zIndex || defaultZIndex,
                showRadius: false,
                units: 'metric',
                labelColor: 'black',
            };
            props.onCreateCircle(circle);
        } else {
            // The user dragged to make a circle
            const circle: Circle = {
                annotationType: 'Circle',
                id: circleId,
                center: circleElement.instance.getLatLng(),
                radius: circleElement.instance.getRadius(),
                options: circleOptions,
                zIndex: props.zIndex || defaultZIndex,
                showRadius: false,
                units: 'metric',
                labelColor: 'black',
            };

            props.onCreateCircle(circle);
        }
    };
    const mouseMove = (e: LeafletMouseEvent) => {
        const radius = context.map.distance(startLatLng, e.latlng);
        circleElement.instance.setRadius(radius);
    };

    const mouseDown = (e: LeafletMouseEvent) => {
        circleElement.instance.setStyle({
            ...defaultCircleOptions,
            ...(lastUsedCircleOptions ?? {}),
        });
        startLatLng = e.latlng;
        circleElement.instance.setLatLng(e.latlng);
        context.map.on('mousemove', mouseMove);
        context.map.on('mouseup', mouseUp);
    };

    circleElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        document.addEventListener('keydown', handleKeyDown);

        context.map.on('mousedown', mouseDown);
    });

    circleElement.instance.on('remove', () => {
        context.map.dragging.enable();
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        document.removeEventListener('keydown', handleKeyDown);

        context.map.off('mousedown', mouseDown);
    });

    return circleElement;
};

const useCircleBuilder = createElementHook<L.Circle, CircleBuilderProps, LeafletContextInterface>(createCircleBuilder);

const CircleBuilder = (props: CircleBuilderProps) => {
    const context = useLeafletContext();
    const circleBuilder = useCircleBuilder(props, context);
    useLayerLifecycle(circleBuilder.current, context);
    return null;
};

export default CircleBuilder;
