import styled from 'styled-components';
import SoarModal from '../../../../Shared/soar-modal';
import ProjectPermissionsSelect, { ProjectPermission } from './project-permissions-select';
import ProjectPermissionsUserTable from './project-permissions-user-table';
import { StyledButton } from '../../../../Shared/styled-button';
import { useState } from 'react';
import ApiDraw, { ProjectResponse } from '../../../../../api/api-draw';
import Analytics from '../../../../../lib/user-analytics';

interface ProjectPermissionsModalProps {
    isOpen: boolean;
    toggle: () => void;
    onInvalidateProjects: () => void;
    drawProject: ProjectResponse;
    invitedUserEmails: string[];
}

const ProjectPermissionsModal = (props: ProjectPermissionsModalProps) => {
    const permissionsForProject = (project: ProjectResponse): ProjectPermission => {
        if (project.public) {
            return 'public';
        }
        if (project.sharePermissions && project.sharePermissions.length > 0) {
            return 'inviteOnly';
        }
        return 'private';
    };

    const [projectPermission, setProjectPermission] = useState<ProjectPermission>(
        permissionsForProject(props.drawProject)
    );
    const [invitedUserEmails, setInvitedUserEmails] = useState<string[]>(props.invitedUserEmails);
    const [isUpdatingProject, setIsUpdatingProject] = useState(false);

    const handleUpdateProjectPermissions = () => {
        setIsUpdatingProject(true);

        // If the project is 'private', we need to remove all invited users
        const invitedUsers =
            projectPermission === 'private' ? [] : invitedUserEmails.map((email) => ({ email: email }));

        ApiDraw.updateDrawProject(props.drawProject.id, {
            ...props.drawProject,
            public: projectPermission === 'public',
            sharePermissions: invitedUsers,
        })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                Analytics.Event(
                    'Draw Tools - Project',
                    `Clicked to udpate project permissions - ${projectPermission} - ${props.drawProject.id}`
                );
                setIsUpdatingProject(false);
                props.onInvalidateProjects();
                props.toggle();
            });
    };

    return (
        <SoarModal isOpen={props.isOpen} toggle={props.toggle} title="Manage Permissions">
            <ProjectPermissionsSelect
                projectPermission={projectPermission}
                onPermissionChange={(permission) => setProjectPermission(permission)}
                invitedUserEmails={[]}
                onInviteUser={(email) => setInvitedUserEmails((current) => [email, ...current])}
                onInviteUsers={(emails) => setInvitedUserEmails((current) => [...emails, ...current])}
                onRemoveUser={(email) => setInvitedUserEmails((current) => current.filter((e) => e !== email))}
            />
            {projectPermission === 'inviteOnly' ? (
                <ProjectPermissionsUserTable
                    invitedUserEmails={invitedUserEmails}
                    onRemoveUser={(email) => setInvitedUserEmails((current) => current.filter((e) => e !== email))}
                />
            ) : null}

            <UpdateButton
                disabled={isUpdatingProject}
                onClick={() => {
                    handleUpdateProjectPermissions();
                }}
            >
                Save changes
            </UpdateButton>
        </SoarModal>
    );
};

export default ProjectPermissionsModal;

const UpdateButton = styled(StyledButton)`
    display: block;
    margin: 24px auto;
`;
