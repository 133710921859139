import React, { useEffect, useState } from 'react';
import ListingHelper from '../../lib/listing-helper';
import Analytics from '../../lib/user-analytics';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-container';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import { ListingDTO } from '../../api/model';
import ApiListings from '../../api/api-listings';

interface SearchContentNearbyMapsProps {
    handleSelectListing: (id: number) => void;
}

const SearchContentNearbyMaps = (props: SearchContentNearbyMapsProps) => {
    const [nearbyListings, setNearbyListings] = useState<ListingDTO[] | undefined>(undefined);

    useEffect(() => {
        ApiListings.getListingsNearby().then(setNearbyListings);
    }, []);

    if (!nearbyListings || nearbyListings.length === 0) return <React.Fragment />;

    const carouselItems: CarouselItem[] = nearbyListings.map((t) => {
        return {
            id: t.id,
            author: t.userName,
            previewUrl: ListingHelper.getPreviewUrlForListing(t.id) as string,
            title: t.title,
        };
    });

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-recent-map.svg" />
                Maps near me
            </SegmentTitle>
            <SearchContentCarousel
                handleSelectListing={(id) => {
                    props.handleSelectListing(id);
                    Analytics.Event('Search Bar', 'Clicked Map From Most Nearby Maps', id);
                }}
                height="180px"
                items={carouselItems}
            />
        </SegmentContainer>
    );
};

export default SearchContentNearbyMaps;
