import React from 'react';
import TermsAndConditions from './terms-and-conditions';
import PrivacyAgreement from './privacy-agreement';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';
import { isMobileVersion } from '../../lib/soar-helper';

const Terms = () => {
    const handleClose = () => {
        UriHelper.navigateToPath(`/`);
    };

    return (
        <TermsContainer>
            {isMobileVersion ? <CloseIcon src="/assets/close.png" onClick={() => handleClose()} /> : null}
            <TermsAndConditions />
            <PrivacyContainer>
                <PrivacyAgreement />
            </PrivacyContainer>
        </TermsContainer>
    );
};

export default Terms;

const TermsContainer = styled.div`
    margin: 2vh 4vw 2vh 4vw;
    overflow: auto;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        height: 100vh;
        margin-left: 4vw;
    }
    @media all and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
        margin: 20px;
    }

    @media only screen and (max-width: 767px) {
        margin: 20px;
        padding-bottom: 20px;
        height: 100vh;
        overflow: auto;
    }
`;

const CloseIcon = styled.img`
    margin: 15px;
    width: 22px;
    height: 22px;
    pointer-events: all;
    float: right;
`;

const PrivacyContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        height: 100vh;
    }
`;
