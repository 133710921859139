import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export interface DropOptionType {
    value: string;
    label?: string;
    key?: string;
}

interface Icons {
    dark: string;
    light: string;
}

interface DropDownProps {
    name: string;
    title: string;
    options: DropOptionType[];
    icons?: Icons;
    initial?: DropOptionType[];
    handleSelectedOptions: (value: DropOptionType[]) => void;
    isOpen?: boolean;
    handleOpen?: () => void;
    isRadio?: boolean;
    width?: number;
    categoryFilter?: boolean;
    dataTestId?: string;
}

const defaultIcon: Icons = {
    light: '/assets/category/category-icon-black.svg',
    dark: '/assets/category/category-icon-white.svg',
};

const DropDown = (props: DropDownProps) => {
    const { icons = defaultIcon, handleSelectedOptions } = props;
    const [selectedOptions, setSelectedOptions] = useState<DropOptionType[]>(props.initial ?? []);
    const [changeIcon, setChangeIcon] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [isDropdownOpen, setDropDownOpen] = useState<boolean>(!!props.isOpen);

    const handleOptionClicked = (option: DropOptionType) => () => {
        const addedItem = selectedOptions.indexOf(option);
        if (props.isRadio) {
            setSelectedOptions([option]);
            setDropDownOpen(false);
        } else if (addedItem === -1) {
            setSelectedOptions([...selectedOptions, option]);
        } else {
            const filteredArray = selectedOptions.filter((_option) => _option !== option);
            setSelectedOptions(filteredArray);
        }
    };

    const handleClearOptions = () => {
        if (selectedOptions.length !== 0) {
            setSelectedOptions([]);
        }
    };

    useEffect(() => {
        handleSelectedOptions(selectedOptions);
        setIsSelected(selectedOptions.length > 0 && selectedOptions[0].value !== '');
    }, [handleSelectedOptions, selectedOptions]);

    useEffect(() => {
        setDropDownOpen(!!props.isOpen);
    }, [props.isOpen]);

    return (
        <DropDownContainer title="Sort by" data-testid={props.dataTestId}>
            <DropDownHeader
                id={props.name}
                onClick={() => {
                    props.handleOpen?.();
                    setDropDownOpen(!isDropdownOpen);
                }}
                isOpen={isDropdownOpen}
                isSelected={isSelected}
                onMouseEnter={() => setChangeIcon(true)}
                onMouseLeave={() => setChangeIcon(false)}
            >
                <DropdownHeaderTitle>
                    {isDropdownOpen || isSelected || changeIcon ? (
                        <DropDownHeaderIcon src={icons.light} />
                    ) : (
                        <DropDownHeaderIcon src={icons.dark} />
                    )}

                    <DropDownHeaderText isOpen={isDropdownOpen} isSelected={isSelected} isColorChange={changeIcon}>
                        {isSelected
                            ? `${selectedOptions.map((o) => o.label ?? o.value)[0]} ${
                                  selectedOptions.length > 1 ? ' +' : ''
                              }`
                            : props.title}
                    </DropDownHeaderText>
                </DropdownHeaderTitle>

                <ChevronIcon className={isDropdownOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
            </DropDownHeader>
            {isDropdownOpen && (
                <DropDownListContainer>
                    <DropDownList>
                        {props.options.map((option, index) => (
                            <ListItem key={index} onClick={handleOptionClicked(option)}>
                                <CheckBoxItem
                                    type={props.isRadio ? 'radio' : 'checkbox'}
                                    checked={selectedOptions.indexOf(option) > -1}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleOptionClicked(option);
                                    }}
                                />
                                {option.label ? option.label : option.value}
                            </ListItem>
                        ))}
                        {(!props.isRadio || props.categoryFilter) && (
                            <ListItem key={'clear-All'} onClick={handleClearOptions}>
                                <ClearListItem>Clear All</ClearListItem>
                            </ListItem>
                        )}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    );
};

export default DropDown;

const DropDownContainer = styled.div`
    width: 100%;
    max-width: 500px;
    position: relative;
    pointer-events: auto;
`;

interface DropdownHeaderProps {
    isOpen: boolean;
    isSelected: boolean;
    isColorChange?: boolean;
}

const DropDownHeader = styled.div<DropdownHeaderProps>`
    padding: 3px 10px;
    font-size: 16px;
    border: 1px solid rgb(255 255 255 / 0.3);
    color: ${(props) => (props.isOpen || props.isSelected ? '#191A1A' : 'rgba(255, 255, 255, 0.6)')};
    background: ${(props) => (props.isOpen || props.isSelected ? '#EED926' : 'rgba(0, 0, 0, 0.2)')};
    border-radius: ${(props) => (props.isOpen ? '6px 6px 0px 0px' : '6px')};
    min-height: 35px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 600px) {
        min-height: 30px;
        height: 30px;
        font-size: 14px;
    }

    &:hover {
        background: #eed926;
        color: #191a1a;
    }
`;

const DropdownHeaderTitle = styled.div`
    display: flex;
    width: 100%;
`;

const DropDownHeaderText = styled.div<DropdownHeaderProps>`
    width: 100%;
    color: ${(props) =>
        props.isOpen || props.isSelected || props.isColorChange ? '#191A1A' : 'rgba(255, 255, 255, 0.6)'};
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-left: 5px;
    font-size: 14px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const DropDownHeaderIcon = styled.img`
    padding-right: 5px;
    max-width: 27px;
    opacity: 0.5;
`;

const DropDownListContainer = styled.div`
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    z-index: 106;
    width: 100%;
    min-width: 180px;

    @media only screen and (max-width: 600px) {
        min-width: max-content;
        right: 0px;
        margin-top: 1px;
    }
`;

const DropDownList = styled.ul`
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0px 0px 6px 6px;

    @media only screen and (max-width: 600px) {
        min-width: max-content;
        border-radius: 6px 0px 6px 6px;
    }
`;

const ListItem = styled.li`
    cursor: pointer;
    list-style: none;
    color: rgba(255, 255, 255, 0.6);
    padding: 8px 20px;
    border-bottom: 1px solid rgb(255 255 255 / 30%);
    background: rgb(0 0 0 / 0.8);
    z-index: 200;

    @media only screen and (max-width: 600px) {
        padding: 5px 5px 5px 10px;
        font-size: 16px;

        :first-child {
            border-top-left-radius: 6px;
        }
    }

    &:hover {
        background-color: rgba(237, 222, 0, 0.3);
    }

    &:last-child {
        border-bottom: none;
        border-radius: 0px 0px 6px 6px;
    }
`;

const ClearListItem = styled.p`
    padding-left: 18px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0px;

    @media only screen and (max-width: 600px) {
        padding-left: 12px;
    }
`;

const ChevronIcon = styled.i`
    width: 20px;
    padding: 4px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const CheckBoxItem = styled.input`
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-left: -7px;

    @media only screen and (max-width: 600px) {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        margin-left: -5px;
    }
`;
