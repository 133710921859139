export const actionTypes = {
    DRAW_PROJECTS_FETCHING: 'DRAW_PROJECTS/FETCHING',
    DRAW_PROJECTS_FETCH_SUCCESS: 'DRAW_PROJECTS/FETCH_SUCCESS',
    DRAW_PROJECTS_FETCH_ERROR: 'DRAW_PROJECTS/FETCH_ERROR',
    DRAW_PROJECTS_RESET: 'DRAW_PROJECTS/RESET',

    DRAW_PROJECTS_SHARED_FETCHING: 'DRAW_PROJECTS_SHARED/FETCHING',
    DRAW_PROJECTS_SHARED_FETCH_SUCCESS: 'DRAW_PROJECTS_SHARED/FETCH_SUCCESS',
    DRAW_PROJECTS_SHARED_FETCH_ERROR: 'DRAW_PROJECTS_SHARED/FETCH_ERROR',
    DRAW_PROJECTS_SHARED_RESET: 'DRAW_PROJECTS_SHARED/RESET',

    DRAW_PROJECT_FETCHING: 'DRAW_PROJECT/FETCHING',
    DRAW_PROJECT_FETCH_SUCCESS: 'DRAW_PROJECT/FETCH_SUCCESS',
    DRAW_PROJECT_FETCH_ERROR: 'DRAW_PROJECT/FETCH_ERROR',
    DRAW_PROJECT_RESET: 'DRAW_PROJECT/RESET',

    DRAW_PROJECT_CREATE: 'DRAW_PROJECT/CREATE_PROJECT',
    DRAW_PROJECT_CREATING: 'DRAW_PROJECT/CREATING_PROJECT',
    DRAW_PROJECT_CREATE_ERROR: 'DRAW_PROJECT/CREATE_PROJECT_ERROR',

    DRAW_PROJECT_UPDATE: 'DRAW_PROJECT/UPDATE_PROJECT',
    DRAW_PROJECT_UPDATING: 'DRAW_PROJECT/UPDATING_PROJECT',

    DRAW_PROJECT_DELETE: 'DRAW_PROJECT/DELETE_PROJECT',
    DRAW_PROJECT_DELETING: 'DRAW_PROJECT/DELETING_PROJECT',

    DRAW_PROJECT_STORY_FETCHING: 'DRAW_PROJECT_STORY/FETCHING',
    DRAW_PROJECT_STORY_FETCH_SUCCESS: 'DRAW_PROJECT_STORY/FETCH_SUCCESS',
    DRAW_PROJECT_STORY_FETCH_ERROR: 'DRAW_PROJECT_STORY/FETCH_ERROR',
    DRAW_PROJECT_STORY_RESET: 'DRAW_PROJECT_STORY/RESET',
    DRAW_PROJECT_STORY_NUMBER: 'DRAW_PROJECT_STORY/STORY_NUMBER',
};
