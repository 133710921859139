import styled from 'styled-components';

export interface PreviewTileProps {
    previewUrl?: string;
    title?: string;
    subText?: string;
}

const PreviewTile = (props: PreviewTileProps) => {
    const handleImgError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = '/assets/image-unavailable/preview_unavailable.png';
        event.currentTarget.style.width = '50px';
        event.currentTarget.style.marginLeft = '6px';
    };
    return (
        <Container>
            <PreviewImageContainer>
                <PreviewImage
                    src={props.previewUrl || '/assets/image-unavailable/preview_unavailable.png'}
                    onError={handleImgError}
                />
            </PreviewImageContainer>
            <ContentContainer>
                <Title>{props.title}</Title>
                <SubText>{props.subText}</SubText>
            </ContentContainer>
        </Container>
    );
};

export default PreviewTile;

const Container = styled.div`
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
`;

const PreviewImageContainer = styled.div`
    height: 100%;
    width: 25%;
    max-width: 64px;
`;

const PreviewImage = styled.img`
    height: 100%;
    width: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin: 0;
    padding: 0;
    object-fit: cover;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 2px 0 0 4px;
    width: 75%;
`;

const Title = styled.p`
    color: #eed926 !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
`;
