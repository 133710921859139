import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ProjectResponse } from '../../../api/api-draw';
import UriHelper from '../../../lib/uri-helper';

interface DrawProjectsItemProps {
    drawProject: ProjectResponse;
    onShare?: () => void;
    onViewProject?: () => void;
    onEdit?: () => void;
    onEditDetails?: () => void;
    onDelete?: () => void;
    onOpenPermissions?: () => void;
    dataTestId?: string;
}

const ScreenWidthToggle = 1366;

const DrawProjectsItem = (props: DrawProjectsItemProps) => {
    const { drawProject: project } = props;

    const isShared = project.sharedAt ? true : false;

    const renderTitle = () => {
        const ownerTag = isShared && (
            <OwnerTag
                data-tooltip-id="dashboard-tooltip"
                data-tooltip-content={`Shared at ${
                    project.sharedAt && moment(project.sharedAt * 1000).format('hh:mm A, MMM Do YYYY')
                }`}
            >
                (Shared by
                <OptionLink
                    onClick={handleViewProjectOwner}
                    data-tooltip-id="dashboard-tooltip"
                    data-tooltip-content={`View Profile`}
                >
                    <OptionText title={project.ownerName}>{project.ownerName}</OptionText>
                </OptionLink>
                )
            </OwnerTag>
        );

        return (
            <TitleContent className={`${isShared ? 'shared' : ''}`}>
                <OptionLink
                    onClick={isShared ? props.onViewProject : props.onEditDetails}
                    data-tooltip-id="dashboard-tooltip"
                    data-tooltip-content={isShared ? 'Open Stoary' : 'Edit Stoary Details'}
                >
                    <OptionText>{project.title || <em>Untitled Project</em>}</OptionText>
                </OptionLink>
                {ownerTag}
            </TitleContent>
        );
    };

    const handleViewProjectOwner = () => {
        UriHelper.navigateToProfile(project.ownerId);
    };

    return (
        <DrawProjectTr data-testid={props.dataTestId}>
            <DrawProjectTd className="title" data-testid={props.dataTestId && `${props.dataTestId}-title`}>
                {renderTitle()}
            </DrawProjectTd>
            <DrawProjectTd className={'date'} data-testid={props.dataTestId && `${props.dataTestId}-date-modified`}>
                <span
                    data-tooltip-id="dashboard-tooltip"
                    data-tooltip-content={moment(project.updatedAt * 1000).format('hh:mm A, MMM Do YYYY')}
                >
                    {moment(project.updatedAt * 1000).fromNow()}
                </span>
            </DrawProjectTd>
            <DrawProjectTd className={'date'} data-testid={props.dataTestId && `${props.dataTestId}-data-created`}>
                <span
                    data-tooltip-id="dashboard-tooltip"
                    data-tooltip-content={moment(project.createdAt * 1000).format('hh:mm A, MMM Do YYYY')}
                >
                    {moment(project.createdAt * 1000).fromNow()}
                </span>
            </DrawProjectTd>

            <DrawProjectTd className={'options'} data-testid={props.dataTestId && `${props.dataTestId}-options`}>
                {props.onViewProject && (
                    <OptionLink
                        data-testid={props.dataTestId && `${props.dataTestId}-option-view`}
                        onClick={props.onViewProject}
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-content="Open Stoary"
                    >
                        <OptionIcon src="/assets/annotations/project-dashboard/open_project.svg" />
                        <OptionText>Open</OptionText>
                    </OptionLink>
                )}
                {props.onEdit && !isShared && (
                    <OptionLink
                        data-testid={props.dataTestId && `${props.dataTestId}-option-edit`}
                        onClick={props.onEdit}
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-content="Edit Stoary"
                    >
                        <OptionIcon src="/assets/annotations/project-dashboard/edit_project.svg" />
                        <OptionText>Edit</OptionText>
                    </OptionLink>
                )}

                {props.onOpenPermissions && !isShared && (
                    <OptionLink
                        data-testid={props.dataTestId && `${props.dataTestId}-option-permissions`}
                        onClick={props.onOpenPermissions}
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-content="Manage Stoary Permissions"
                    >
                        <OptionIcon src="/assets/annotations/project-dashboard/permissions.svg" />
                        <OptionText>Permissions</OptionText>
                    </OptionLink>
                )}

                {props.onShare && (
                    <OptionLink
                        data-testid={props.dataTestId && `${props.dataTestId}-option-share`}
                        onClick={props.onShare}
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-content="Share Stoary"
                    >
                        <OptionIcon src="/assets/annotations/project-dashboard/share.svg" />
                        <OptionText>Share</OptionText>
                    </OptionLink>
                )}

                {props.onDelete && !isShared && (
                    <OptionLink
                        data-testid={props.dataTestId && `${props.dataTestId}-option-delete`}
                        onClick={props.onDelete}
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-content="Delete Stoary"
                    >
                        <OptionIcon src="/assets/annotations/project-dashboard/delete.svg" />
                        <OptionText>Delete</OptionText>
                    </OptionLink>
                )}
            </DrawProjectTd>
        </DrawProjectTr>
    );
};

export default DrawProjectsItem;

const OptionText = styled.span`
    color: white;
    font-size: 13px;
    word-break: break-word;

    &:hover {
        color: #eed926;
    }
`;

const OptionIcon = styled.img`
    width: 18px;
    height: 18px;
    vertical-align: top;
    cursor: pointer;

    & + ${OptionText} {
        margin-left: 10px;
    }
`;

const OptionLink = styled.a`
    font-size: 12px;
    display: inline-flex;
    cursor: pointer;

    ${OptionText} {
        &:hover {
            color: #eed926;
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: ${ScreenWidthToggle}px) {
        span {
            display: none;
        }
    }
`;

const DrawProjectTr = styled.tr`
    color: white !important;

    td {
        color: white !important;
        vertical-align: inherit !important;
    }

    td > span {
        color: white !important;
    }
`;

const DrawProjectTd = styled.td`
    padding: 10px;
    width: 50px !important;

    &.title {
        width: 25% !important;
        max-width: 250px;

        ${OptionLink} {
            @media only screen and (max-width: ${ScreenWidthToggle}px) {
                span {
                    display: inline-block;
                }
            }
        }
    }

    &.description {
        width: auto !important;
        > div {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            color: white;
            word-break: break-word;
            overflow-wrap: break-word;
            line-break: auto;
            text-wrap: balance;
        }
    }

    &.date {
        width: 8% !important;
        cursor: help;
    }

    &.options {
        width: 35% !important;
        white-space: nowrap;

        ${OptionLink} {
            margin: 5px;
            padding: 5px 2px;
            text-transform: uppercase;

            &:first-child {
                margin-left: 0;
                padding-left: 0;
            }
        }
    }
`;

const OwnerTag = styled.span`
    color: rgba(255, 255, 255, 0.75);
    font-size: 13px;
    margin-left: 4px;
    cursor: help;
    white-space: nowrap;
    position: relative;

    ${OptionLink} {
        max-width: 75%;
        position: relative;
        margin-left: 4px;
        color: rgba(255, 255, 255, 0.75);

        ${OptionText} {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const TitleContent = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    &.shared {
        & > ${OptionLink} {
            max-width: 100%;
            ${OptionText} {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    ${OwnerTag} {
        max-width: 95%;
        position: relative;
    }

    @media only screen and (max-width: ${ScreenWidthToggle}px) {
        flex-direction: column;
        align-items: start;

        ${OwnerTag} {
            margin-left: 0 !important;
        }
    }
`;
