import Rectangle from '../../../components/MapView/Annotations/Rectangle/rectangle';
import { actionTypes as at } from './constants';
import { Action } from '../../root-reducer';

interface RectangleState {
    rectangles: Rectangle[];
    rectangleOptions?: L.PolylineOptions;
    projectChanged?: boolean;
}

const initialState: RectangleState = {
    rectangles: [],
    rectangleOptions: undefined,
    projectChanged: false,
};

export default (state: RectangleState = initialState, action: Action): RectangleState => {
    switch (action.type) {
        case at.SET_RECTANGLES:
            return { ...state, rectangles: action.payload.rectangles, projectChanged: true };

        case at.SET_RECTANGLE_OPTIONS:
            return { ...state, rectangleOptions: action.payload };

        case at.SET_RECTANGLE_ADD:
            return {
                ...state,
                rectangles: [...state.rectangles, action.payload],
                projectChanged: true,
            };

        case at.SET_RECTANGLE_UPDATE: {
            const newRectangles = state.rectangles.map((rectangle) => {
                if (rectangle.id === action.payload.id) {
                    return { ...rectangle, ...action.payload };
                }
                return rectangle;
            });
            return { ...state, rectangles: newRectangles, projectChanged: true };
        }

        case at.SET_RECTANGLE_DELETE: {
            const newRectangles = state.rectangles.filter((rectangle) => rectangle.id !== action.payload.id);
            return { ...state, rectangles: newRectangles, projectChanged: true };
        }
        default:
            return state;
    }
};
