import Coordinate from '../../../components/MapView/Annotations/Coordinate/coordinate';
import { CoordinateSystem } from '../../../components/MapView/Annotations/Coordinate/coordinate-util';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface CoordinateState {
    coordinates: Coordinate[];
    projectChanged?: boolean;
    coordinateSystem?: CoordinateSystem;
}

const initialState: CoordinateState = {
    coordinates: [],
    projectChanged: false,
    coordinateSystem: 'WGS84 DD',
};

export default (state = initialState, action: Action): CoordinateState => {
    switch (action.type) {
        case at.SET_COORDINATES:
            return { ...state, coordinates: action.payload.coordinates, projectChanged: true };

        case at.SET_COORDINATE_ADD:
            return {
                ...state,
                coordinates: [...state.coordinates, action.payload],
                projectChanged: true,
            };

        case at.SET_COORDINATE_UPDATE: {
            const coordinates = state.coordinates.map((coordinate) => {
                if (coordinate.id === action.payload.id) {
                    return { ...coordinate, ...action.payload };
                }
                return coordinate;
            });
            return { ...state, coordinates, projectChanged: true };
        }

        case at.SET_COORDINATE_DELETE: {
            const newCoordinates = state.coordinates.filter((coordinate) => coordinate.id !== action.payload.id);
            return { ...state, coordinates: newCoordinates, projectChanged: true };
        }

        case at.SET_COORDINATE_SYSTEM:
            return { ...state, coordinateSystem: action.payload, projectChanged: true };

        default:
            return state;
    }
};
