import {
    LeafletContextInterface,
    PathProps,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
} from '@react-leaflet/core';
import L from 'leaflet';
import { createTextBoxResizeElement } from './text-annotation-resize';
import TextBox, { hiddenTextBoxRectangleOptions, highlightedTextBoxRectangleOptions } from './textbox';
import LayersUtil from '../layers-util';
import textCalculateSize from './text-calculate-size';
import { createTextAnnotationSVGLabel } from './text-annotation-svg-label';

// The line height is a multiplier of the font size
const FONT_LINE_HEIGHT = 1.5;

// Text wrapping constants
const TEXT_WRAP = true;
const TEXT_WRAP_CHARACTER_COUNT = 80;

interface TextAnnotationProps extends PathProps {
    onSelect: () => void;
    onDeselect: () => void;
    isSelected: boolean;
    children?: React.ReactNode;
    textBox: TextBox;
    onUpdate: (textBox: TextBox) => void;
}

//
// This is a composite object that is used to display a textbox, including all functionality to move, resize, and edit the text
//
export const createTextBox = (props: TextAnnotationProps, context: LeafletContextInterface) => {
    const pane = LayersUtil.getPaneId(context.map, props.textBox);

    // The rectangle can be thought of as the base of this object
    const rectangle = new L.Rectangle(props.textBox.bounds, {
        ...hiddenTextBoxRectangleOptions,
        pane: pane,
    });

    const rectangleElement = createElementObject<L.Rectangle, TextAnnotationProps>(rectangle, context);

    // The resizeElement is composite and contains the move and resize functionality
    const resizeElement = createTextBoxResizeElement({ rectangleElement: rectangleElement, context }, context);

    const calculateTextAreaSize = (): L.Point => {
        const northEastPoint = context.map.latLngToContainerPoint(props.textBox.bounds.getNorthEast());
        const southWestPoint = context.map.latLngToContainerPoint(props.textBox.bounds.getSouthWest());
        const width = Math.abs(northEastPoint.x - southWestPoint.x);
        const height = Math.abs(southWestPoint.y - northEastPoint.y);
        return new L.Point(width, height);
    };

    // calculates the size of the actual text inside the textarea
    const calculateTextSize = (textArea: HTMLTextAreaElement): L.Point => {
        const span = document.createElement('span');

        span.style.display = 'inline-block';
        span.style.whiteSpace = 'pre';
        span.style.fontSize = textArea.style.fontSize;
        span.style.fontFamily = textArea.style.fontFamily;
        span.style.lineHeight = textArea.style.lineHeight;
        span.innerHTML = /\n$/.test(textArea.value) || textArea.value === '' ? `${textArea.value} ` : textArea.value;

        document.body.appendChild(span);

        const width = span.offsetWidth;
        const height = span.offsetHeight;

        document.body.removeChild(span);

        return new L.Point(width, height);
    };

    const adjustTextBoxSize = (textArea: HTMLTextAreaElement, caretPosition?: number) => {
        const size = calculateTextAreaSize();
        const textSize = calculateTextSize(textArea);

        let textAreaBounds = props.textBox.bounds;
        if (textArea.scrollWidth > size.x) {
            const widthSizeChange = textArea.scrollWidth - size.x;

            const southEastPoint = context.map.latLngToContainerPoint(props.textBox.bounds.getSouthEast());
            const southEast = context.map.containerPointToLatLng(
                new L.Point(southEastPoint.x + widthSizeChange, southEastPoint.y)
            );
            const newBounds = new L.LatLngBounds(props.textBox.bounds.getNorthWest(), southEast);
            rectangleElement.instance.setBounds(newBounds);
            textArea.style.width = `${size.x + widthSizeChange}px`;
            resizeElement.instance.fireEvent('textbox-size-change', { bounds: newBounds });

            textAreaBounds = newBounds;
        }

        if (size.y !== textSize.y) {
            const northWestPoint = context.map.latLngToContainerPoint(props.textBox.bounds.getNorthWest());
            const southEastPoint = context.map.latLngToContainerPoint(props.textBox.bounds.getSouthEast());

            const southEast = context.map.containerPointToLatLng(
                new L.Point(southEastPoint.x, northWestPoint.y + textSize.y)
            );
            const newBounds = new L.LatLngBounds(props.textBox.bounds.getNorthWest(), southEast);
            rectangleElement.instance.setBounds(newBounds);
            textArea.style.height = `${textSize.y}px`;
            resizeElement.instance.fireEvent('textbox-size-change', { bounds: newBounds });

            textAreaBounds = newBounds;
        }

        requestAnimationFrame(() => {
            props.onUpdate({
                ...props.textBox,
                text: textArea.value,
                bounds: textAreaBounds,
                caretPosition: caretPosition,
            });
        });
    };

    const onTextAreaInput = (textArea: HTMLTextAreaElement, e: KeyboardEvent) => {
        const isValidKey = (key: KeyboardEvent['key']): boolean => {
            return (
                ![
                    'Shift',
                    'Control',
                    'Meta',
                    'Alt',
                    'Insert',
                    'PageUp',
                    'PageDown',
                    'NumLock',
                    'ScrollLock',
                    'Pause',
                    'Delete',
                    'Backspace',
                    'Enter',
                ].includes(key) && !/^F([2-9]|1[0-2]?)$/.test(key)
            );
        };

        // prevent Ctrl+V (pasting is processed in onpaste event) and allow only necessary key strokes
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (isValidKey(e.key) && !(e.ctrlKey === true && e.key === 'v')) {
            adjustTextBoxSize(textArea);
        }
    };

    const buildTextAreaIcon = (disabled: boolean): L.DivIcon => {
        const buildTextAreaHTMLElement = (disabled: boolean): HTMLElement => {
            const size = calculateTextAreaSize();
            const textArea = document.createElement('textarea');

            const setOpacity = (hex, alpha) =>
                `${hex}${Math.floor(alpha * 255)
                    .toString(16)
                    .padStart(2, '0')}`;

            // The DivIcon doesn't respect map zoom, so we need to manually calculate the font size.
            // It should be 32px at the zoom level it was created at, and the same apparent size under zoom changes.
            const fontSizeBase = props.textBox.fontSizeBase || 32;

            const fontSize = fontSizeBase * Math.pow(2, context.map.getZoom() - props.textBox.zoomBase);

            textArea.style.cursor = disabled ? 'pointer' : 'text';
            textArea.style.width = `${size.x}px`;
            textArea.style.height = `${size.y}px`;
            textArea.style.resize = 'none';
            textArea.style.border = 'none';
            textArea.style.fontSize = `${fontSize}px`;
            textArea.style.lineHeight = `${FONT_LINE_HEIGHT}`;
            textArea.style.fontFamily = props.textBox.fontFamily ? props.textBox.fontFamily : 'Manrope';
            textArea.style.textAlign = 'center';
            textArea.style.margin = '0';
            textArea.style.padding = '0';
            textArea.style.background = setOpacity(props.textBox.fillColor, props.textBox.fillOpacity);
            textArea.style.color = props.textBox.textColor;
            textArea.style.overflow = 'hidden';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.spellcheck = false;
            textArea.wrap = 'off';
            textArea.value = props.textBox.text;
            textArea.contentEditable = 'true';
            textArea.placeholder = 'Enter text...';

            // if no caretPosition is defined, use the text length to immediately force the cursor to the end of the text
            textArea.setSelectionRange(
                props.textBox?.caretPosition || props.textBox.text.length,
                props.textBox?.caretPosition || props.textBox.text.length
            );

            // Prevent clicking the textbox triggering the map.on('click') event
            textArea.onclick = (e: Event) => {
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();
                if (!props.isSelected) {
                    props.onSelect();
                }
            };

            requestAnimationFrame(() => {
                adjustTextBoxSize(textArea, props.textBox?.caretPosition);
            });

            textArea.addEventListener('keydown', (e: KeyboardEvent) => {
                onTextAreaInput(textArea, e);
            });

            textArea.addEventListener('input', (e: InputEvent) => {
                const inputType = e.inputType;

                switch (inputType) {
                    case 'insertText':
                    case 'insertLineBreak':
                    case 'deleteContentForward':
                    case 'deleteContentBackward':
                    case 'insertCompositionText': {
                        const textAreaElement = e.target as HTMLTextAreaElement;
                        const caretLastPosition = textAreaElement.selectionEnd;

                        const textAreaCaretPosition = caretLastPosition;

                        textArea.setSelectionRange(textAreaCaretPosition, textAreaCaretPosition);
                        textArea.focus();

                        adjustTextBoxSize(textArea, textAreaCaretPosition);
                        break;
                    }
                    default:
                        break;
                }
            });

            textArea.onpaste = (e: ClipboardEvent) => {
                const textAreaElement = e.target as HTMLTextAreaElement;
                const textAreaValue = textAreaElement.value;
                const caretFirstPosition = textAreaElement.selectionStart;
                const caretLastPosition = textAreaElement.selectionEnd;
                const clipboardData = e.clipboardData;
                const clipboardText = clipboardData?.getData('text');

                let textAreaCaretPosition = caretLastPosition;

                if (clipboardText) {
                    let updatedText =
                        textAreaValue.substring(0, caretFirstPosition) +
                        clipboardText +
                        textAreaValue.substring(caretLastPosition);

                    if (TEXT_WRAP) {
                        const wrappedText = updatedText.split('\n').map((lineOfText) => {
                            if (lineOfText.length <= TEXT_WRAP_CHARACTER_COUNT) {
                                return lineOfText;
                            }

                            const brokenLines: string[] = [];
                            let forBreakingText = lineOfText;
                            while (forBreakingText) {
                                if (forBreakingText.length < TEXT_WRAP_CHARACTER_COUNT) {
                                    brokenLines.push(forBreakingText);
                                    forBreakingText = '';
                                } else {
                                    const nearestSpaceOfLine = forBreakingText.lastIndexOf(
                                        ' ',
                                        TEXT_WRAP_CHARACTER_COUNT
                                    );
                                    if (nearestSpaceOfLine >= 0) {
                                        brokenLines.push(forBreakingText.substr(0, nearestSpaceOfLine));
                                        forBreakingText = forBreakingText.substr(nearestSpaceOfLine + 1);
                                    } else {
                                        brokenLines.push(
                                            `${forBreakingText.substr(0, TEXT_WRAP_CHARACTER_COUNT - 1)}-`
                                        );
                                        forBreakingText = forBreakingText.substr(TEXT_WRAP_CHARACTER_COUNT - 1);
                                    }
                                }
                            }
                            return brokenLines.join('\n');
                        });

                        updatedText = wrappedText?.join('\n') as string;
                    }

                    textArea.value = updatedText;

                    const selectedTextSize = caretLastPosition - caretFirstPosition;
                    textAreaCaretPosition =
                        caretLastPosition + clipboardText.replace(/\r\n/, '\n').length - selectedTextSize;

                    textArea.setSelectionRange(textAreaCaretPosition, textAreaCaretPosition);
                    textArea.focus();
                }

                requestAnimationFrame(() => {
                    adjustTextBoxSize(textArea, textAreaCaretPosition);
                });
                return false;
            };
            return textArea;
        };

        const size = calculateTextAreaSize();
        const textAreaDivIcon = new L.DivIcon({
            html: buildTextAreaHTMLElement(disabled),
            iconSize: [size.x, size.y],
            iconAnchor: [0, 0],
            className: 'draw-text-area',
        });
        return textAreaDivIcon;
    };

    // The textarea used to edit the text is actually a DivIcon with a textarea inside it
    const textAreaMarker = new L.Marker(props.textBox.bounds.getNorthWest(), {
        icon: buildTextAreaIcon(true),
        bubblingMouseEvents: false,
        pane: pane,
    });

    const calculateTextHeight = (bounds: L.LatLngBounds): number => {
        const northEastPoint = context.map.latLngToContainerPoint(bounds.getNorthEast());
        const southEastPoint = context.map.latLngToContainerPoint(bounds.getSouthEast());
        const height = Math.abs(southEastPoint.y - northEastPoint.y);
        return height / FONT_LINE_HEIGHT;
    };

    const calculateDesiredTextBoxSize = (fontSize: number) => {
        const size = textCalculateSize(props.textBox.text.split('\n')[0], {
            font: props.textBox.fontFamily,
            fontSize: `${fontSize}px`,
            lineHeight: '0',
        });
        const numberOfLines = props.textBox.text.split('\n').length;
        const numberOfLineBreaks = numberOfLines + 1 * Math.floor(numberOfLines / 2);
        return new L.Point(size.width, size.height * (numberOfLineBreaks * FONT_LINE_HEIGHT));
    };

    const calculateBoundsSize = (bounds: L.LatLngBounds): L.Point => {
        const northEastPoint = context.map.latLngToContainerPoint(bounds.getNorthEast());
        const southWestPoint = context.map.latLngToContainerPoint(bounds.getSouthWest());
        const width = Math.abs(northEastPoint.x - southWestPoint.x);
        const height = Math.abs(southWestPoint.y - northEastPoint.y);
        return new L.Point(width, height);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rectangleElement.instance.on('resize', (e: any) => {
        const bounds = e.bounds;
        if (bounds) {
            const numberOfLines = props.textBox.text.split('\n').length;

            // Calculate size of font based on height
            let fontSize = calculateTextHeight(bounds) / numberOfLines;
            let desiredSize = calculateDesiredTextBoxSize(fontSize);
            const boundsSize = calculateBoundsSize(bounds);

            // Reduce size of font until it fits the width
            if (boundsSize.x < desiredSize.x) {
                while (boundsSize.x < desiredSize.x) {
                    fontSize = fontSize - 0.5;
                    desiredSize = calculateDesiredTextBoxSize(fontSize);
                }
            }

            const icon = textAreaMarker.getIcon() as L.DivIcon;
            const textArea = icon.options.html as HTMLTextAreaElement;
            textArea.style.fontSize = `${fontSize}px`;

            props.onUpdate({
                ...props.textBox,
                fontSizeBase: fontSize,
                zoomBase: context.map.getZoom(),
                text: textArea.value,
                bounds: bounds,
            });
        }
    });

    // When the text box is not selected, it is an SVG overlay
    let textBoxLabel:
        | Readonly<{
              instance: L.SVGOverlay;
              context: LeafletContextInterface;
          }>
        | undefined = undefined;

    // #7019 - It is possible to trigger a select and zoom at the same frame. This forces a deselect if that event occurs.
    const onZoomEnd = () => {
        props.onDeselect();
    };

    rectangleElement.instance.on('add', () => {
        if (props.isSelected) {
            context.map.on('zoomend', onZoomEnd);
            context.map.addLayer(textAreaMarker);
            textAreaMarker.setIcon(buildTextAreaIcon(false));
            if (textBoxLabel && textBoxLabel.instance) {
                context.map.removeLayer(textBoxLabel.instance);
            }

            context.map.addLayer(rectangleElement.instance);
            context.map.addLayer(resizeElement.instance);
            rectangleElement.instance.setStyle(highlightedTextBoxRectangleOptions);

            const icon = textAreaMarker.getIcon() as L.DivIcon;
            const textArea = icon.options.html as HTMLTextAreaElement;
            textArea.focus();

            context.map.dragging.disable();
            context.map.dragging.disable();
            context.map.touchZoom.disable();
            context.map.scrollWheelZoom.disable();
            context.map.boxZoom.disable();
            if (context.map.tap) context.map.tap.disable();
        } else {
            context.map.off('zoomend', onZoomEnd);
            textBoxLabel = createTextAnnotationSVGLabel({ textBox: props.textBox }, context);
            context.map.addLayer(textBoxLabel.instance);
            rectangleElement.instance.setStyle(hiddenTextBoxRectangleOptions);
        }
    });

    rectangleElement.instance.on('remove', () => {
        context.map.removeLayer(rectangleElement.instance);
        context.map.removeLayer(textAreaMarker);
        context.map.removeLayer(resizeElement.instance);

        if (textBoxLabel && textBoxLabel.instance) context.map.removeLayer(textBoxLabel.instance);

        context.map.dragging.enable();
        context.map.touchZoom.enable();
        context.map.scrollWheelZoom.enable();
        context.map.boxZoom.enable();
        if (context.map.tap) context.map.tap.enable();
    });

    return rectangleElement;
};

const useTextBoxAnnotation = createElementHook<L.Rectangle, TextAnnotationProps, LeafletContextInterface>(
    createTextBox
);

const useTextBox = createPathHook(useTextBoxAnnotation);
const TextAnnotation = createContainerComponent(useTextBox);

export default TextAnnotation;
