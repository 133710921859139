import Select, { components, InputProps, OptionProps, DropdownIndicatorProps } from 'react-select';

interface MultiSelectOption {
    value: string;
    label: string;
}

interface MultiselectProps {
    options: MultiSelectOption[];
    onChange: (values: MultiSelectOption[]) => void;
    defaultValue?: MultiSelectOption[];
    dataTestId?: string;
}

const customStyles = {
    container: (provided) => ({
        ...provided,
        '& :hover': {
            borderColor: '#eed926',
        },
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: 'none',
        borderColor: 'rgba(255, 255, 255, 0.6)',
        boxShadow: 'none',
        color: 'rgba(255, 255, 255, 0.6)',
        '& :hover': {
            borderColor: '#eed926',
        },
    }),
    menuList: (provided) => ({
        ...provided,
        height: '160px',
    }),
    input: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.6)',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

export const Multiselect = (props: MultiselectProps) => {
    const Input = (inputProps: InputProps) => {
        return <components.Input {...inputProps} data-testid={props.dataTestId && `${props.dataTestId}-input`} />;
    };
    const Option = (optionProps: OptionProps) => {
        return (
            <components.Option
                {...optionProps}
                innerProps={Object.assign({}, optionProps.innerProps, {
                    'data-testid': props.dataTestId && `${props.dataTestId}-option`,
                })}
            />
        );
    };

    const DropdownIndicator = (dropdownProps: DropdownIndicatorProps) => {
        return (
            <components.DropdownIndicator
                {...dropdownProps}
                innerProps={Object.assign({}, dropdownProps.innerProps, {
                    'data-testid': props.dataTestId && `${props.dataTestId}-indicator`,
                })}
            />
        );
    };

    return (
        <Select
            components={{ Input, Option, DropdownIndicator }}
            options={props.options}
            isMulti
            styles={customStyles}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
        />
    );
};
