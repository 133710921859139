import styled from 'styled-components';
import { StyledButton } from '../../../Shared/styled-button';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';

const AppLinkAndroid = 'https://play.google.com/store/apps/details?id=earth.soar.wrapper';
const AppLinkiOS = 'https://apps.apple.com/us/app/soar-the-new-atlas/id6452726556';

const MobileUseDesktopContent = () => {
    const handleClickedSearch = () => {
        Analytics.Event('Mobile', 'Mobile Suggest Desktop', 'Clicked Search Imagery');
        UriHelper.navigateToPath('/');
    };

    const handleClickAndroid = () => {
        Analytics.Event('Mobile', 'Mobile Suggest Desktop', 'Clicked Google Play Store');
        window.open(AppLinkAndroid);
    };

    const handleClickiOS = () => {
        Analytics.Event('Mobile', 'Mobile Suggest Desktop', 'Clicked Apple App Store');
        window.open(AppLinkiOS);
    };

    return (
        <Container>
            <HeaderImage src="/assets/mobile/soar-suggest-notice.png" />

            <QuoteText>"The most coolest mapping website you've never heard of"</QuoteText>
            <QuoteAuthor>- Popular Science</QuoteAuthor>
            <PlayStoreButton src="/assets/mobile/play-store.svg" onClick={handleClickAndroid} />
            <AppStoreButton src="/assets/mobile/app-store.svg" onClick={handleClickiOS} />

            <Hr>or</Hr>

            <MainText>
                For the best viewing experience, please use our app or a desktop based web browser.
                <br />
            </MainText>

            <PageButton onClick={handleClickedSearch}>Continue with browser</PageButton>
        </Container>
    );
};

export default MobileUseDesktopContent;

const Container = styled.div`
    border-radius: 4px;
    border: 1px solid #eed926;
    padding: 5px 15px;
    min-height: 100%;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);

    @media only screen and (max-height: 650px) {
        margin-top: 50px; // for ios the margin top needs to be set..
    }
`;

const HeaderImage = styled.img`
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;
`;

const QuoteText = styled.p`
    color: white;
    font-size: 18px;
`;

const QuoteAuthor = styled.p`
    color: white;
    font-size: 12px;
    margin: 10px 0px;
    font-style: italic;
`;

const MainText = styled.div`
    margin: 10px auto 20px;
    color: inherit;
    font-size: inherit;
    text-align: left;
    line-height: 1.4;
    font-size: 14px;
    padding: 0 10px;
`;

const PageButton = styled(StyledButton)`
    margin: 15px auto 15px;
    font-size: 14px;
    text-transform: initial;
`;

const PlayStoreButton = styled.img`
    margin: 0 auto 16px;
    display: block;
    cursor: pointer;
    width: 160px;
    height: 55px;
    object-fit: cover;
`;

const AppStoreButton = styled.img`
    margin: 5px auto 10px;
    display: block;
    cursor: pointer;
    width: 160px;
    height: auto;
`;

const Hr = styled.div`
    text-align: center;
    color: white;
    font-size: 75%;
    padding: 5px 0px;
    display: flex;
    flex-direction: horizontal;
    align-items: center;

    &:before,
    &:after {
        flex: 1;
        margin: 0 2.5%;
        width: auto;
        content: '';
        background-color: white;
        height: 1px;
        overflow: hidden;
    }
`;
