import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import '../Polygon/node-marker-handles.css';
import Polyline, { defaultPolylineOptions } from './polyline';
import { v4 as uuidv4 } from 'uuid';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';
import { createSnapPointElement } from '../SnapPoints/annotation-snap-points';

interface PolylineBuilderProps {
    onCreatePolyline: (polyline: Polyline) => void;
    onCancelBuild: () => void;
    snapPoints: L.LatLng[];
    zIndex?: number;
}

const createPolylineBuilder = (props: PolylineBuilderProps, context: LeafletContextInterface) => {
    const lastUsedPathOptions = store.getState().annotationDomain.present.pathReducer.polylineOptions;

    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);
    const polyline = new L.Polyline([], {
        ...defaultPolylineOptions,
        ...(lastUsedPathOptions ?? {}),
        pane: builderPaneId,
    });
    const polylineElement = createElementObject<L.Polyline, PolylineBuilderProps>(polyline, context);

    const hologramPath = new L.Polyline([], {
        dashArray: '5, 5',
        interactive: false,
        color: polylineElement.instance.options.color ?? '#eed926',
        pane: builderPaneId,
    });
    const hologramPathElement = createElementObject<L.Polyline, PolylineBuilderProps>(hologramPath, context);
    context.map.addLayer(hologramPathElement.instance);

    const snapPolygonElement = createSnapPointElement(
        { instance: polylineElement.instance, pane: builderPaneId },
        context
    );
    const nodeMarkers: L.Marker[] = [];
    const tooltip = new L.Tooltip(new L.LatLng(0, 0), { direction: 'top' });

    polylineElement.instance.on('add', () => {
        context.map.on('mousemove', onMouseMove);
        context.map.on('click', onMouseClick);
        context.map.addLayer(snapPolygonElement.instance);

        document.addEventListener('keydown', onKeyDown);
    });

    polylineElement.instance.on('remove', () => {
        nodeMarkers.forEach((marker) => {
            context.map.removeLayer(marker);
        });
        snapPolygonElement.instance.fireEvent('remove');
        hologramPathElement.instance.remove();
        context.map.off('mousemove', onMouseMove);
        context.map.off('click', onMouseClick);
        context.map.dragging.enable();
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        document.removeEventListener('keydown', onKeyDown);

        if (tooltip.isOpen()) {
            tooltip.removeFrom(context.map);
        }
    });

    const updateHologramPath = (position: L.LatLng) => {
        const lastPosition = polylineElement.instance.getLatLngs()[
            polylineElement.instance.getLatLngs().length - 1
        ] as L.LatLng;
        if (lastPosition) {
            const positions = [lastPosition, position];
            hologramPathElement.instance.setLatLngs(positions);
        }
    };

    const updateNodeMarkers = () => {
        const positions = polylineElement.instance.getLatLngs() as L.LatLng[];
        const position = positions.length === 1 ? positions[0] : positions[positions.length - 1];

        const dotMarkerIcon = new L.DivIcon({
            iconSize: new L.Point(12, 12),
            iconAnchor: new L.Point(6, 6),
            className: 'visible-snap-point-node-marker',
        });

        const marker = new L.Marker(position, { interactive: true, icon: dotMarkerIcon, pane: builderPaneId });

        marker.on('mouseover', (e: L.LeafletMouseEvent) => {
            const index = nodeMarkers.findIndex((m) => m.getLatLng() === e.latlng);

            if (index === nodeMarkers.length - 1) {
                if (!tooltip.isOpen()) {
                    tooltip.setLatLng(e.latlng);
                    tooltip.setContent('Click to close the Line');
                    tooltip.openOn(context.map);
                }

                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
                L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
            }
        });

        marker.on('mouseout', (_: L.LeafletMouseEvent) => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
            if (tooltip.isOpen()) {
                tooltip.removeFrom(context.map);
            }
        });

        marker.on('click', (e: L.LeafletMouseEvent) => {
            const index = nodeMarkers.findIndex((m) => m.getLatLng() === e.latlng);

            // If there are less than 2 nodes, cancel the build
            // We need to do it here before the creation of the last node and before the mouseClick event
            if (nodeMarkers.length < 2) {
                props.onCancelBuild();
                return;
            }

            if (index === nodeMarkers.length - 1) {
                const polyline: Polyline = {
                    annotationType: 'Polyline',
                    zIndex: props.zIndex || defaultZIndex,
                    id: uuidv4(),
                    positions: polylineElement.instance.getLatLngs() as L.LatLng[],
                    options: polylineElement.instance.options,
                    showLength: false,
                    units: 'metric',
                    labelColor: 'black',
                };

                props.onCreatePolyline(polyline);
            } else {
                onMouseClick(e);
            }
        });

        nodeMarkers.push(marker);
        context.map.addLayer(marker);
    };

    context.map.dragging.disable();
    L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

    const onMouseMove = (e: L.LeafletMouseEvent) => {
        updateHologramPath(e.latlng);
    };

    const onMouseClick = (e: L.LeafletMouseEvent) => {
        snapPolygonElement.instance.fireEvent('update', e);
        updateNodeMarkers();
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            if (tooltip.isOpen()) {
                tooltip.closeTooltip();
            }

            const nodeCount = nodeMarkers.length;
            if (nodeCount < 2) {
                props.onCancelBuild();
            } else {
                const polyline: Polyline = {
                    annotationType: 'Polyline',
                    id: uuidv4(),
                    zIndex: props.zIndex || defaultZIndex,
                    positions: polylineElement.instance.getLatLngs() as L.LatLng[],
                    options: polylineElement.instance.options,
                    showLength: false,
                    units: 'metric',
                    labelColor: 'black',
                };
                props.onCreatePolyline(polyline);
            }
            snapPolygonElement.instance.fireEvent('remove');
        }
    };

    return polylineElement;
};

const usePathBuilder = createElementHook<L.Polyline, PolylineBuilderProps, LeafletContextInterface>(
    createPolylineBuilder
);

const PathBuilder = (props: PolylineBuilderProps) => {
    const context = useLeafletContext();
    const polylineBuilder = usePathBuilder(props, context);
    useLayerLifecycle(polylineBuilder.current, context);
    return null;
};

export default PathBuilder;
