import React, { useState, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useProjectAnnotationHandler } from '../../../MapView/Annotations/Project/use-project-annotation-handler';
import StoryMapsControl from '../../../MapView/Annotations/StoryMaps/StoryView/story-maps-control';
import { DrawPage } from '../../../MapView/Annotations/StoryMaps/draw-story';
import { useBasemap } from '../../../../store/Map/Basemap/use-basemap';

interface StoryProps {
    selectedPage: DrawPage;
    numberOfPages: number;
    currentPageIndex: number;

    onNextPage: () => void;
    onPreviousPage: () => void;
    onMapAutoZoomFinished: () => void;
}

export const MobileStoryControl = (props: StoryProps) => {
    const { handleSetProjectAnnotations } = useProjectAnnotationHandler();
    const { setBasemap } = useBasemap();
    const map = useMap();

    const [hasMapAutoZoomFinished, setHasMapAutoZoomFinished] = useState(false);

    useEffect(() => {
        if (props.selectedPage && props.selectedPage.project) {
            handleSetProjectAnnotations(
                props.selectedPage.project,
                props.selectedPage?.viewportLocked,
                props.selectedPage?.allViewportsLocked
            );
        }
        if (props.selectedPage?.activeBaseMap) {
            setBasemap(props.selectedPage.activeBaseMap);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedPage]);

    useEffect(() => {
        const onZoomEnd = () => {
            setHasMapAutoZoomFinished(true);
            map.off('zoomend', onZoomEnd);
        };

        const timer = setTimeout(() => {
            setHasMapAutoZoomFinished(true);
        }, 4000);

        map.on('zoomend', onZoomEnd);

        return () => {
            clearTimeout(timer);
            map.off('zoomend', onZoomEnd);
        };
    }, [map]);

    useEffect(() => {
        if (hasMapAutoZoomFinished) {
            props.onMapAutoZoomFinished();
        }
    }, [hasMapAutoZoomFinished, props]);

    return (
        <React.Fragment>
            {props.numberOfPages > 1 ? (
                <StoryMapsControl
                    currentPageIndex={props.currentPageIndex}
                    totalPages={props.numberOfPages}
                    onNextPage={() => {
                        if (props.currentPageIndex < props.numberOfPages) {
                            props.onNextPage();
                        }
                    }}
                    onPreviousPage={() => {
                        if (props.currentPageIndex >= 0) {
                            props.onPreviousPage();
                        }
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};
