import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiListings from '../../../api/api-listings';
import { ListingCategories, ListingDTO, ListingFile, ListingMetadata } from '../../../api/model';
import { inputMaxValues } from '../../../lib/limitation-constants';
import ListingHelper from '../../../lib/listing-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { actionClearMapById } from '../../../store/Map/SuperMap/actions';
import { MarkdownTextArea } from '../../Shared/markdown-textarea';
import { Multiselect } from '../../Shared/multi-select';
import { PulseLoader } from '../../Shared/pulse-loader';
import SoarModal, { StyledModalBody } from '../../Shared/soar-modal';
import { StyledButton, StyledDarkButton, TransparentButton } from '../../Shared/styled-button';
import TagInputField from '../../Shared/tag-input-field';
import { ModalFooter } from 'reactstrap';

interface ProfileImageryEditUpdateProps {
    listingId: number;
    listing: ListingDTO;
    handleSave: () => void;
    editDetailsOpen: boolean;
    setEditDetailsOpen: (editOpen: boolean) => void;
    reSubmit?: boolean;
}

const ImageryEditModal = (props: ProfileImageryEditUpdateProps) => {
    const { listingId } = props;
    const [listing, setListing] = useState<ListingDTO | undefined>(undefined);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [listingFiles, setListingFiles] = useState<ListingFile[] | undefined>(undefined);

    const [file, setFile] = useState<File | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);
    const [formValid, setFormValid] = useState<boolean>(true);

    const dispatch = useDispatch();

    useEffect(() => {
        ApiListings.getListing(listingId).then((dto) => {
            setListing(dto);
            setTitle(dto.title);
            setDescription(dto.description);
            setTags(dto.tags);
            setCategories(dto.categories);
            setListingFiles(dto.files);
        });
    }, [listingId]);

    const getMetadata = (): ListingMetadata | undefined => {
        if (title.trim() && description.trim() && categories.length > 0 && categories.length <= 3) {
            return {
                title,
                description,
                tags,
                categories,
            } as ListingMetadata;
        }
        return undefined;
    };

    const handleOnSave = () => {
        const metadata = getMetadata();
        if (!metadata) {
            setFormValid(false);
            return;
        }

        setFormValid(true);

        ApiListings.updateListing(listingId, metadata)
            .then(() => {
                props.handleSave();
                toast.dark('Map details successfully updated');
                Analytics.Event('My Dashboard', 'Update Listing', listingId);
            })
            .catch((err) => {
                toast.error('Error updating map information:  ' + err);
            });

        //remove attachment
        if (listing?.files && !listingFiles) {
            ApiListings.removeFile(listingId, listing.files[0].id);
        }

        //add attachment: check if file exists
        if (file) {
            ApiListings.addFile(listingId, file);
        }

        //clear map details for it to get the latest from server
        dispatch(actionClearMapById(listingId));
    };

    const handleOnResubmit = () => {
        const metadata = getMetadata();
        if (!metadata) {
            setFormValid(false);
            return;
        }

        setFormValid(true);

        ApiListings.resubmitListing(listingId, metadata)
            .then(() => {
                props.handleSave();
                toast.dark('Map details successfully updated');
                Analytics.Event('My Dashboard', 'Resubmit Listing', listingId);
            })
            .catch((err) => {
                toast.error('Error updating map information:  ' + err);
            });

        //remove attachment
        if (listing?.files && !listingFiles) {
            ApiListings.removeFile(listingId, listing.files[0].id);
        }

        //add attachment: check if file exists
        if (file) {
            ApiListings.addFile(listingId, file);
        }
    };

    const setImageTitle = (title: string) => {
        if (title.length < inputMaxValues.MAX_TITLE_CHARACTERS) {
            setTitle(title);
        }
    };

    const setImageDescription = (description: string) => {
        const wordCount = UserHelper.getWordCount(description);
        if (wordCount <= inputMaxValues.MAX_WORD_COUNT) {
            setDescription(description);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            e.target.value = '';
        }
    };

    const options = Object.keys(ListingCategories).map((c) => {
        return { value: c, label: ListingCategories[c].title };
    });

    return (
        <SoarModal
            isOpen={props.editDetailsOpen}
            toggle={() => props.setEditDetailsOpen(false)}
            title={props.reSubmit ? `Resubmit Details` : `Edit Details`}
            width="600px"
        >
            {listing === undefined ? (
                <StyledModalBody>
                    <PulseLoader />
                </StyledModalBody>
            ) : (
                <StyledModalBody>
                    <ModalText>
                        Display Name (different to filename) <span>*</span>
                    </ModalText>
                    <TextField
                        placeholder="Map Name"
                        value={title}
                        onChange={(e) => setImageTitle(e.currentTarget.value)}
                    />
                    <SharedCharacterLimit>
                        <SharedCharacterLimitDescriptor className="mr-1">Characters </SharedCharacterLimitDescriptor>
                        <SharedCharacterLimitCount
                            hasHitWordCount={title.length === inputMaxValues.MAX_TITLE_CHARACTERS - 1 ? true : false}
                        >
                            {title.length}/{inputMaxValues.MAX_TITLE_CHARACTERS - 1}
                        </SharedCharacterLimitCount>
                    </SharedCharacterLimit>

                    {!formValid && !title.trim() && <ErrorMessage>Please enter a display name</ErrorMessage>}

                    <ModalText>
                        Categories <span>*</span>
                    </ModalText>
                    <DivContainer>
                        <Multiselect
                            options={options}
                            onChange={(values) => setCategories(values.map((c) => c.value))}
                            defaultValue={options.filter((o) => categories.includes(o.value))}
                        />
                        {!formValid && categories.length === 0 ? (
                            <ErrorMessage>Please choose at least one category</ErrorMessage>
                        ) : !formValid && categories.length > 3 ? (
                            <ErrorMessage>Please choose no more than 3 categories</ErrorMessage>
                        ) : null}
                    </DivContainer>

                    <ModalTagsTitle>
                        <ModalText>Tags (press Enter to being next tag) (optional)</ModalText>
                    </ModalTagsTitle>
                    <DivContainer>
                        <TagInputField onTagInput={(tags) => setTags(tags)} initTags={tags} />
                    </DivContainer>
                    <div>
                        <ModalText>
                            {`Description (tell us about your map, max ${inputMaxValues.MAX_WORD_COUNT} words) `}
                            <span>*</span>
                        </ModalText>
                        <MarkdownTextArea value={description} setInputValue={(value) => setImageDescription(value)} />
                        <SharedCharacterLimit>
                            <SharedCharacterLimitDescriptor className="mr-1">Word Count</SharedCharacterLimitDescriptor>
                            <SharedCharacterLimitCount
                                hasHitWordCount={
                                    UserHelper.getWordCount(description) === inputMaxValues.MAX_WORD_COUNT
                                        ? true
                                        : false
                                }
                            >
                                {UserHelper.getWordCount(description)}/{inputMaxValues.MAX_WORD_COUNT}
                            </SharedCharacterLimitCount>
                        </SharedCharacterLimit>

                        {!formValid && !description.trim() && <ErrorMessage>Please provide a description</ErrorMessage>}
                    </div>

                    <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        accept=".jpg,.png"
                        onChange={handleFileChange}
                    />
                    <div className="d-flex">
                        <Label>Legend:</Label>
                        {listingFiles?.length ? (
                            <LegendContainer>
                                <img
                                    src={ListingHelper.getUrlForListingFile(listingId, listingFiles[0].id)}
                                    alt="Legend"
                                />
                                <TrashButton onClick={() => setListingFiles(undefined)} title="Remove attachment">
                                    <i className="fa fa-trash"></i>
                                </TrashButton>
                            </LegendContainer>
                        ) : file ? (
                            <LegendContainer>
                                <Text>
                                    <i className="fa fa-paperclip mr-2"></i>
                                    {file.name}
                                </Text>
                                <TrashButton onClick={() => setFile(undefined)} title="Remove attachment">
                                    <i className="fa fa-trash"></i>
                                </TrashButton>
                            </LegendContainer>
                        ) : (
                            <Button className="ml-2" onClick={() => inputRef.current?.click()} title="Add">
                                <i className="fa fa-paperclip mr-1"></i>Attach image
                            </Button>
                        )}
                    </div>
                </StyledModalBody>
            )}
            {listing !== undefined && (
                <EditModalFooter>
                    <StyledDarkButton
                        onClick={() => {
                            props.setEditDetailsOpen(false);
                        }}
                    >
                        CANCEL
                    </StyledDarkButton>

                    {props.reSubmit ? (
                        <StyledButton onClick={handleOnResubmit}>SAVE AND RESUBMIT</StyledButton>
                    ) : (
                        <StyledButton onClick={handleOnSave}>SAVE CHANGES</StyledButton>
                    )}
                </EditModalFooter>
            )}
        </SoarModal>
    );
};

export default ImageryEditModal;

const ErrorMessage = styled.div`
    padding-top: 5px;
    color: red !important;
    font-size: 12px;
    margin-bottom: 0px;
`;

interface CategoryProps {
    active?: boolean;
    hasHitWordCount?: boolean;
}

const EditModalFooter = styled(ModalFooter)`
    justify-content: right;
    padding-bottom: 20px;
    @media only screen and (max-width: 600px) {
        justify-content: center;
    }
`;

const ModalTagsTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ModalText = styled.p`
    color: white;
    margin: 12px 0px 4px 0px;
    strong {
        color: white;
    }
    span {
        color: red !important;
    }
`;

const DivContainer = styled.div`
    width: 100%;
    margin-bottom: 12px;
`;

const TextField = styled.input`
    display: block;
    width: 100%;
    background: transparent;
    padding: 4px;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid rgb(255 255 255 / 0.3) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;

    :active,
    :focus,
    :focus-visible {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6) !important;
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3) !important;
        color: rgba(255, 255, 255, 0.6) !important;
        outline: none;
    }
`;

const SharedCharacterLimit = styled.div`
    padding-top: 2px;
    padding-bottom: 7px;
    font-size: 10px;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
`;

const SharedCharacterLimitCount = styled.div<CategoryProps>`
    display: inline-block;
    -webkit-text-fill-color: ${(props) => (props.hasHitWordCount ? 'red' : 'rgba(255, 255, 255, 0.8)')};
    color: ${(props) => (props.hasHitWordCount ? 'red' : 'rgba(255, 255, 255, 0.8)')};
`;
const SharedCharacterLimitDescriptor = styled.div`
    display: inline-block;
`;

const Label = styled.div`
    color: white;
`;
const Text = styled.div`
    color: rgba(255, 255, 255, 0.6);
`;

const LegendContainer = styled.div`
    position: relative;
    margin-left: 10px;
    padding-right: 30px;

    img {
        max-width: 400px;
    }

    button {
        position: absolute;
        right: 0px;
        top: 0px;
        display: none;
        margin-right: 10px;
    }

    :hover button {
        display: block;
    }

    @media only screen and (max-width: 600px) {
        img {
            max-width: 100%;
        }
        word-break: break-all;
        font-size: 14px;
        padding-right: 0px;
    }
`;

const Button = styled(TransparentButton)`
    display: inline-block;
    border: none !important;
    padding: 0px;
    text-transform: none;
`;

const TrashButton = styled(Button)`
    color: red !important;
    -webkit-text-fill-color: red !important;
`;
