import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer as LeafletMapContainer } from 'react-leaflet';
import styled from 'styled-components';

import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import Download from '../Drawer/Satellites/Sentinel/download';
import Drawer from '../Drawer/drawer-control';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import SearchBar from '../Search/search-bar';
import UploadDialog from '../Upload/upload-dialog';
import ZoomControl from './Annotations/Zoom/zoom-control';
import Annotations from './Annotations/annotations';
import SatelliteProviders from './SatelliteProviders/satellite-providers';
import SatelliteLayers from './SatelliteTracker/satellite-layers';
import SearchResultMarker from './SearchResultMarker/search-result-marker';
import SentinelLayers from './Sentinel/sentinel-layers';
import TileLayers from './TileLayers/tilelayers';
import DroneUploadManualMarker from './UploadPreview/drone-upload-manual-marker';
import DroneUploadPreview from './UploadPreview/drone-upload-preview';
import Basemaps from './basemaps';
import MapScaleControl from './map-scale-control';
import MapViewDispatcher from './map-view-dispatcher';
import PinnedTileLayers from './pinned-tilelayers';
import TileLayerLoadingIndicator from './tilelayer-loading-indicator';
import { actionSetSideDrawerModeAction } from '../../store/SideDrawer/actions';
import { selectShowUploadDialog } from '../../store/App/selectors';
import SoarHelper from '../../lib/soar-helper';
import { actionDismissTutorial } from '../../store/App/actions';
import Tutorial from '../Tutorials/tutorial-starter';
import GlobeMiniMapControl from './MiniMap/globe-minimap-control';
import CoordinatesControl from './Coordinates/coordinates-control';
import SentinelEditLayer from './Sentinel/sentinel-edit-layer';
import SentinelShareModal from '../Drawer/Share/sentinel-share-modal';
import AnnotationToolbar from './Annotations/annotation-toolbar';
import AnnotationPatterns from './annotation-patterns';
import { selectIsDrawStoryBuilderActive } from '../../store/Map/DrawStory/selectors';

interface MapContainerProps {
    loginMode?: LoginModalMode;
    drawerMode?: SideDrawerMode;
}

// Values that give the map an aesthetically pleasing start position
const WORLD_ZOOM = 2.5;
const WORLD_CENTER: [number, number] = [34.5, 0];
export const MAX_ZOOM_LEVEL = 28;

const MapContainer = (props: MapContainerProps) => {
    const dispatch = useDispatch();
    const uploadDialogActive = useSelector(selectShowUploadDialog);
    const creatingStoryProject = useSelector(selectIsDrawStoryBuilderActive);

    const [loginMode, setLoginMode] = useState<LoginModalMode | undefined>(props.loginMode);
    const [editMode, setEditMode] = useState(false);

    const onLoginDialogClosed = () => {
        setLoginMode(undefined);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
        Analytics.Event('Main View', 'Closed login dialog');
    };

    useEffect(() => {
        if (props.drawerMode) {
            dispatch(actionSetSideDrawerModeAction(props.drawerMode));
        }
    }, [props.drawerMode, dispatch]);

    const isSoarDrawActive =
        props.drawerMode === SideDrawerMode.SOAR_DRAW_EDIT ||
        props.drawerMode === SideDrawerMode.SOAR_DRAW_NEW ||
        editMode;

    return (
        <React.Fragment>
            {!editMode || creatingStoryProject ? <Drawer /> : null}
            <Download />
            <SentinelShareModal />
            {!creatingStoryProject && <SearchBar editMode={editMode && !uploadDialogActive} />}
            <AnnotationToolbar />
            <Tutorial
                onSkip={() => {
                    dispatch(actionDismissTutorial());
                    if (SoarHelper.isSoar()) {
                        dispatch(
                            actionSetSideDrawerModeAction(props.drawerMode ? props.drawerMode : SideDrawerMode.MAPS)
                        );
                    } else {
                        dispatch(
                            actionSetSideDrawerModeAction(
                                props.drawerMode ? props.drawerMode : SideDrawerMode.SUBDOMAIN_MAPS
                            )
                        );
                    }
                }}
            />
            <Container>
                <LeafletMapContainer
                    zoomSnap={0.01}
                    maxBoundsViscosity={1.0}
                    maxZoom={MAX_ZOOM_LEVEL}
                    zoom={WORLD_ZOOM}
                    center={WORLD_CENTER}
                    minZoom={WORLD_ZOOM}
                    className="leaflet-map"
                    id="leaflet-map"
                    zoomControl={false}
                >
                    <React.Fragment>
                        <AnnotationPatterns />
                        <MapViewDispatcher />
                        {!isSoarDrawActive && <CoordinatesControl />}
                        {!isSoarDrawActive && <GlobeMiniMapControl />}
                        <ZoomControl />

                        <MapScaleControl />
                        <PinnedTileLayers editMode={editMode} />
                        <Basemaps />
                        {!isSoarDrawActive && <SatelliteLayers />}
                        <SatelliteProviders />

                        <TileLayerLoadingIndicator />
                        <TileLayers editMode={editMode} />
                        <SentinelLayers />
                        <SentinelEditLayer editMode={editMode} />
                        <SearchResultMarker />
                        <DroneUploadPreview />
                        <DroneUploadManualMarker />

                        {!uploadDialogActive && (
                            <Annotations
                                editMode={isSoarDrawActive}
                                setEditMode={(isEditMode) => setEditMode(isEditMode)}
                            />
                        )}
                    </React.Fragment>
                </LeafletMapContainer>
            </Container>
            {loginMode && <LoginRegisterDialog isOpen={true} initialMode={loginMode} onClose={onLoginDialogClosed} />}
            <UploadDialog />
        </React.Fragment>
    );
};

export default MapContainer;

const Container = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    padding: 0;
    margin: 0;

    @media (max-width: 500px) {
        position: absolute;
        top: 80px;
        bottom: 0px;
        width: 100%;
    }

    .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle {
        vertical-align: bottom;
        display: table-cell;
    }

    .leaflet-right .leaflet-control {
        margin: 0;
        padding: 0;
    }

    .leaflet-bottom.leaflet-right .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle {
        position: fixed;
        bottom: 30px;
        right: 16px;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.8) !important;
        padding: 0px !important;
        width: 45px;
        height: 45px;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        border: 1px solid #515151;
        border-radius: 6px;
    }
    .leaflet-bottom.leaflet-right .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle:hover {
        background-color: rgb(52, 58, 64) !important;
    }

    .leaflet-bottom.leaflet-right
        .leaflet-control-layers.leaflet-control:nth-of-type(2)
        a.leaflet-control-layers-toggle {
        right: 72px;
    }

    .leaflet-bottom.leaflet-right
        .leaflet-control-layers.leaflet-control:nth-of-type(3)
        a.leaflet-control-layers-toggle {
        right: 128px;
    }

    .annotation-mode-edit
        .leaflet-bottom.leaflet-right
        .leaflet-control-layers.leaflet-control:not(:first-child)
        a.leaflet-control-layers-toggle {
        display: none;
    }

    .annotation-mode-edit .leaflet-bottom.leaflet-right .leaflet-control-better-scale.leaflet-control {
        display: none;
    }

    .leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
        margin-bottom: 56px;
        z-index: 1000;
        padding: 12px;
        background: rgba(0, 0, 0, 0.8);

        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        border: 1px solid #515151 !important;
        border-radius: 6px;
    }

    .leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded:first-of-type {
        margin-right: 16px;
        min-width: 126px;
    }

    .leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded:nth-of-type(2) {
        margin-right: 71px;
        min-width: 126px;
    }

    .leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded:nth-of-type(3) {
        margin-right: 128px;
        padding: 6px 6px 2px 6px;
    }
`;
