import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import ApiDraw from '../../../../api/api-draw';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { setImageAddAction, setImageUpdateAction } from '../../../../store/Annotations/Images/actions';
import { selectImages } from '../../../../store/Annotations/Images/selectors';
import { incrementZIndexAction, setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectCurrentZIndex, selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import DrawerHint from '../../../Drawer/drawer-hint';
import { LoginModalMode } from '../../../Registration/login-enum';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';
import { PulseLoader } from '../../../Shared/pulse-loader';
import SoarModal from '../../../Shared/soar-modal';
import { StyledButton } from '../../../Shared/styled-button';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import { defaultZIndex } from '../layers-util';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import { useDrawToolUpdated } from '../use-draw-tool-updated';
import { default as ImageObject } from './image';
import ImageAnnotation from './image-annotation';
import { calculateInitialBounds } from './image-control-util';

const ImageControl = (props: AnnotationControlProps) => {
    const [showOpenImageDialog, setShowOpenImageDialog] = useState(false);
    const [userSelectedUpload, setUserSelectedUpload] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [showLogin, setShowLogin] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [imageLoadingMessage, setImageLoadingMessage] = useState('');
    const [fileTypeError, setFileTypeError] = useState<string | undefined>(undefined);

    const map = useMap();
    const dispatch = useDispatch();
    const imageAnnotations = useSelector(selectImages);

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { unlockAnnotationsEvent } = useAnnotationBuildLock();

    const onUpdateImage = useDrawToolUpdated((image: ImageObject) => {
        dispatch(setImageUpdateAction(image));
    });

    const isLoggedIn = useSelector(selectLoggedIn);
    const isInteractive = !props.locked && props.visible;

    const handleDisplayImageUpload = () => {
        if (!isLoggedIn) {
            setShowLogin(true);
            setUserSelectedUpload(true);
            setFileTypeError(undefined);
            return;
        }

        setUserSelectedUpload(false);
        setShowOpenImageDialog(true);
    };

    const handleCloseModal = () => {
        setUserSelectedUpload(false);
        setShowOpenImageDialog(false);
        setFileTypeError(undefined);
        unlockAnnotationsEvent();
    };

    useEffect(() => {
        if (!imageLoading) {
            setShowOpenImageDialog(false);
        }
    }, [imageLoading]);

    useEffect(() => {
        if (isLoggedIn && userSelectedUpload) {
            setShowOpenImageDialog(true);
        }
    }, [userSelectedUpload, isLoggedIn]);

    useEffect(() => {
        const tryAndLoadWithImageEvents = (filePath: string) => {
            const loadImageStartTime = performance.now();
            const img = new Image();
            img.onload = () => {
                const loadImageEndTime = performance.now();
                console.log('Image loaded in ' + (loadImageEndTime - loadImageStartTime) + 'ms');
                const initialBounds = calculateInitialBounds(map, img);
                const annotation: ImageObject = {
                    annotationType: 'Image',
                    id: uuidv4(),
                    imageSrc: filePath,
                    bounds: initialBounds,
                    opacity: 1,
                    zIndex: currentZIndex || defaultZIndex,
                };
                DrawToolAnalytics.add('image');

                dispatch(setImageAddAction(annotation));
                dispatch(incrementZIndexAction());
                setImageLoading(false);
                setFile(null);
                requestAnimationFrame(() => {
                    dispatch(setSelectedAnnotationAction(annotation));
                });
            };

            img.onerror = () => {
                setImageLoading(false);
                alert('There was a problem uploading the image. Please try again later');
                console.log('Image timed out while loading');
                setFile(null);
            };

            img.src = filePath;
        };

        if (file) {
            setImageLoading(true);
            ApiDraw.uploadImage(file)
                .then((filePath) => {
                    setImageLoadingMessage('Uploading');
                    const img = new Image();
                    img.src = filePath;
                    tryAndLoadWithImageEvents(filePath);
                })
                .catch((error) => {
                    setImageLoading(false);
                    alert('There was a problem uploading the image. Please try again later');
                    console.log('Error saving image');
                    console.log(error);
                })
                .finally(() => {
                    setImageLoading(false);
                    setImageLoadingMessage('Processing');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return (
        <React.Fragment>
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={(isUserLoggedIn) => {
                        setShowLogin(false);
                        if (!isUserLoggedIn) {
                            handleCloseModal();
                        }
                    }}
                />
            )}

            <AnnotationControl
                active={showOpenImageDialog}
                disabled={props.disabled}
                layout="end"
                visible={props.visible}
                iconDark="/assets/annotations/black-annotations/icon-image-black.svg"
                iconLight="/assets/annotations/icon-image.svg"
                name="Photo"
                onClick={() => {
                    handleDisplayImageUpload();
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
                dataTestId="annotation-control-image"
            />

            <SoarModal
                isOpen={showOpenImageDialog}
                title="Select photo"
                toggle={handleCloseModal}
                disabledClose={imageLoading}
            >
                <ModalContent>
                    {fileTypeError ? <DrawerHint error>{fileTypeError}</DrawerHint> : null}
                    {imageLoading ? (
                        <LoadingContainer>
                            <LoadingMessage>{imageLoadingMessage}</LoadingMessage>
                            <PulseLoader />
                        </LoadingContainer>
                    ) : (
                        <React.Fragment>
                            <Dropzone
                                accept="image/jpeg, image/png, image/gif"
                                style={{
                                    margin: '8px',
                                    border: '1px dashed rgba(255, 255, 255, 0.6)',
                                    borderRadius: '6px',
                                    padding: '12px',
                                }}
                                activeStyle={{
                                    border: '1px dashed #EED926',
                                }}
                                onDrop={(acceptedFiles: File[]) => {
                                    if (acceptedFiles.length === 0) {
                                        setFileTypeError('The file type is not supported');
                                        setFile(null);
                                    } else {
                                        setFile(acceptedFiles[0]);
                                        setFileTypeError(undefined);
                                    }
                                }}
                            >
                                <UploadIcon />
                                <UploadText>Drag and drop your photo here, or</UploadText>
                                <UploadButton>
                                    <StyledButton>CHOOSE FILE</StyledButton>
                                </UploadButton>
                            </Dropzone>
                            <DropzoneInstructionText>
                                Supported files: JPG / PNG / GIF (Soar even supports animated GIFs!)
                            </DropzoneInstructionText>
                        </React.Fragment>
                    )}
                </ModalContent>
            </SoarModal>

            {!isInteractive &&
                imageAnnotations.map((imageAnnotation) => {
                    return (
                        <ImageAnnotation
                            key={`
                                ${imageAnnotation.id}-locked-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${imageAnnotation?.bounds ? JSON.stringify(imageAnnotation.bounds) : ''}-
                                ${imageAnnotation.opacity}-
                                ${imageAnnotation.zIndex}
                            `}
                            isSelected={false}
                            imageAnnotation={imageAnnotation}
                            onUpdateImage={() => ''}
                            url={imageAnnotation.imageSrc}
                            bounds={imageAnnotation.bounds}
                            opacity={imageAnnotation.opacity}
                            isDisabled={true}
                        />
                    );
                })}

            {isInteractive &&
                imageAnnotations.map((imageAnnotation) => {
                    return (
                        <ImageAnnotation
                            key={`
                                ${imageAnnotation.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${imageAnnotation?.bounds ? JSON.stringify(imageAnnotation.bounds) : ''}-
                                ${imageAnnotation.opacity}-
                                ${imageAnnotation.zIndex}
                            `}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === imageAnnotation.id : false}
                            imageAnnotation={imageAnnotation}
                            onUpdateImage={onUpdateImage}
                            url={imageAnnotation.imageSrc}
                            bounds={imageAnnotation.bounds}
                            opacity={imageAnnotation.opacity}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default ImageControl;

const ModalContent = styled.div`
    flex: 1 1 auto;
    margin: 0px 2px 0px 2px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
`;

const UploadIcon = styled.div`
    margin: 5px auto;
    background-position: center;
    background-repeat: no-repeat;
    width: 75px;
    height: 50px;
    cursor: pointer;
    background-image: url('/assets/annotations/upload-modal-icon.svg');
`;

const UploadText = styled.div`
    color: white;
    text-align: center;
    font-size: 16px;

    &.italic {
        font-style: italic;
    }
`;

const UploadButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
`;

const DropzoneInstructionText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    margin: 12px 8px 8px 8px;
    text-align: left;
`;

const LoadingContainer = styled.div`
    min-height: 128px;
`;

const LoadingMessage = styled.span`
    color: #eed923;
    margin-top: 60px;
    text-align: center;
    font-size: 16px;
    display: block;
`;
