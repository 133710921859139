import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingType } from '../../../../api/model';
import SoarHelper from '../../../../lib/soar-helper';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import EmbeddedMapDialog from '../EmbedMap/embedded-map-dialog';
import ActiveMapBookmark from '../active-map-bookmark';
import PinMap from './pin-map';
import ShareCopy from './share-copy-link';
import ShareLike from './share-like';
import ShareLinks from './social-links';
import ShareMobileAppButton from './share-mobile-app-button';

interface ShareComponentProps {
    listingPage?: boolean;
}

const MapOptions = (props: ShareComponentProps) => {
    const selectedMap = useSelector(selectActiveAllMap);

    if (!selectedMap) {
        return <React.Fragment />;
    }

    // Do not show share links for satellite orders, as they are private
    if (selectedMap.listingType === ListingType.ORDER) {
        return <React.Fragment />;
    }

    // Do not show share links if this is Soar+ subdomain
    if (!SoarHelper.isSoar()) {
        return <React.Fragment />;
    }

    if (SoarHelper.isSoarAndroid()) {
        return (
            <ShareContainer>
                <ShareIcons>
                    <ShareMobileAppButton />
                    <ShareCopy />
                </ShareIcons>

                <ShareIcons>
                    <ShareLike selectedMap={selectedMap} />
                    {!props.listingPage && <PinMap />}
                    <ActiveMapBookmark mapId={selectedMap.id} />
                    {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                </ShareIcons>
            </ShareContainer>
        );
    }

    if (SoarHelper.isSoarIOS()) {
        return (
            <ShareContainer>
                <ShareIcons>
                    {/* TODO: Re-active when iOS wrapper updated to handle share link */}
                    {/* <ShareMobileAppButton /> */}
                    <ShareCopy />
                </ShareIcons>

                <ShareIcons>
                    <ShareLike selectedMap={selectedMap} />
                    {!props.listingPage && <PinMap />}
                    <ActiveMapBookmark mapId={selectedMap.id} />
                    {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                </ShareIcons>
            </ShareContainer>
        );
    }

    if (SoarHelper.isMobile()) {
        return (
            <ShareContainer>
                <ShareIcons>
                    <ShareLinks />
                    <ShareCopy />
                </ShareIcons>

                <ShareIcons>
                    <ShareLike selectedMap={selectedMap} />
                    {!props.listingPage && <PinMap />}
                    <ActiveMapBookmark mapId={selectedMap.id} />
                    {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                </ShareIcons>
            </ShareContainer>
        );
    }

    return (
        <ShareContainer>
            <ShareIcons>
                <ShareLinks />
                <ShareCopy />
            </ShareIcons>
            <ShareIcons>
                <ShareLike selectedMap={selectedMap} />
                {!props.listingPage && <PinMap />}
                <ActiveMapBookmark mapId={selectedMap.id} />
                {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
            </ShareIcons>
        </ShareContainer>
    );
};

export default MapOptions;

const ShareContainer = styled.div`
    display: flex;
    margin: 13px 16px 3px 10px;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
        margin: 15px 15px 15px 10px;
    }
`;

const ShareIcons = styled.div`
    display: flex;
`;
