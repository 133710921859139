import { AppState } from '../../root-reducer';
export const selectDrawProjects = (state: AppState) => state.drawProjectDomain.drawProjects;
export const selectDrawProjectsIsFetching = (state: AppState) => state.drawProjectDomain.isFetchingDrawProjects;
export const selectDrawProjectsIsFetched = (state: AppState) => state.drawProjectDomain.isFetchedDrawProjects;
export const selectDrawProjectsError = (state: AppState) => state.drawProjectDomain.drawProjectsError;

export const selectSharedDrawProjects = (state: AppState) => state.drawProjectDomain.sharedDrawProjects;
export const selectSharedDrawProjectsIsFetching = (state: AppState) =>
    state.drawProjectDomain.isFetchingSharedDrawProjects;
export const selectSharedDrawProjectsIsFetched = (state: AppState) =>
    state.drawProjectDomain.isFetchedSharedDrawProjects;
export const selectSharedDrawProjectsError = (state: AppState) => state.drawProjectDomain.sharedDrawProjectsError;

export const selectActiveDrawProject = (state: AppState) => state.drawProjectDomain.drawProject;
export const selectActiveDrawProjectIsFetching = (state: AppState) => state.drawProjectDomain.isFetchingDrawProject;
export const selectActiveDrawProjectIsFetched = (state: AppState) => state.drawProjectDomain.isFetchedDrawProject;
export const selectActiveDrawProjectError = (state: AppState) => state.drawProjectDomain.drawProjectError;

export const selectIsCreatingDrawProject = (state: AppState) => state.drawProjectDomain.isCreatingDrawProject;
export const selectDrawProjectCreatingError = (state: AppState) => state.drawProjectDomain.drawProjectCreatingError;

export const selectIsUpdatingDrawProject = (state: AppState) => state.drawProjectDomain.isUpdatingDrawProject;
export const selectIsDeletingDrawProject = (state: AppState) => state.drawProjectDomain.isDeletingDrawProject;

export const selectStoryProjectNumber = (state: AppState) => state.drawProjectDomain.drawProjectStoryNumber;
export const selectDrawStoryProjects = (state: AppState) => state.drawProjectDomain.drawStoryProjects;
export const selectDrawStoryProjectsIsFetching = (state: AppState) =>
    state.drawProjectDomain.isFetchingDrawStoryProjects;
export const selectDrawStoryProjectsIsFetched = (state: AppState) => state.drawProjectDomain.isFetchedDrawStoryProjects;
export const selectDrawStoryProjectsError = (state: AppState) => state.drawProjectDomain.drawStoryProjectsError;
