import styled from 'styled-components';
import { StyledButton } from '../../Shared/styled-button';

interface ActiveMapProps {
    closeDraw?: boolean;
}

export const Container = styled.div<ActiveMapProps>`
    display: flex;
    flex-flow: column;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    width: 450px;
    position: absolute;
    min-height: ${(props) => (props.closeDraw ? '0px' : '350px')};
    max-height: ${(props) => (props.closeDraw ? '50px' : 'calc(100vh - 215px)')};
    left: 30px;
    top: 98px;
    z-index: 1000;
    overflow: ${(props) => (props.closeDraw ? 'hidden' : '')};
    transition: all 1s;
    -webkit-transition: all 1s;
`;

export const Header = styled.div<ActiveMapProps>`
    user-select: none;
    border-bottom: ${(props) => (props.closeDraw ? '1px solid black' : '1px solid rgba(255, 255, 255, 0.3)')};
    height: 50px;
    transition: all 1s;
    -webkit-transition: all 1s;
`;

export const LogoContainer = styled.div`
    position: absolute;
    left: 50%;
`;

export const Logo = styled.img`
    height: 27px;
    margin-top: 11px;
    display: block;
    position: relative;
    left: -50%;
`;

export const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin: 8px;
    cursor: pointer;
    float: left;
`;

export const ChevronIcon = styled.i`
    float: right;
    padding: 14px 12px 0px 0px;
    color: rgb(255 255 255 / 80%);
    font-size: 18px;
    transition: all 1s;
    -webkit-transition: all 1s;
    cursor: pointer;
`;

export const Content = styled.div<ActiveMapProps>`
    overflow-y: auto;
    user-select: none;
    margin-bottom: 12px;
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

export const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    text-transform: uppercase;
`;

export const Divider = styled.div`
    border-top: 1px solid rgb(255, 255, 255, 0.3);
`;

export const TeaserText = styled.p`
    margin-top: 40px;
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
`;

export const TeaserTitle = styled.h5`
    text-align: center;
    margin-top: 30px;
    color: white;
    text-transform: uppercase;
`;

export const EmptyTeaserTitle = styled.div`
    height: 50px;
`;

export const TeaserIcon = styled.img`
    margin: 20px auto 20px auto;
    display: block;
`;

interface ButtonProps {
    disabled?: boolean;
}

export const Button = styled(StyledButton)<ButtonProps>`
    margin: 30px auto 60px auto;
    background-color: ${(props) => (props.disabled ? 'grey !important' : '#eed926')};
    text-transform: uppercase;

    button:disabled {
        display: none;
    }
    i.fa {
        margin-right: 5px;
    }
`;

export const BackText = styled.div`
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    height: 32px;
    margin: 9px 0px 0px 0px;
    user-select: none;
    cursor: pointer;
    float: left;
`;

export const ShowMoreContainer = styled.div`
    margin-bottom: 17px;
    margin-top: 12px;
`;

export const ShowMoreButton = styled.button`
    background: none !important;
    border: none;
    padding: 0 !important;
    color: white;
    margin: 0 auto;
    display: block;
    cursor: pointer;
    outline: 0 !important;
`;
