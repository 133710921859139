import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiDraw, {
    CreateProjectMetaRequest,
    CreateProjectRequest,
    UpdateProjectRequest,
} from '../../../../../api/api-draw';
import { selectLoggedIn } from '../../../../../store/Account/selectors';
import { LoginModalMode } from '../../../../Registration/login-enum';
import LoginRegisterDialog from '../../../../Registration/login-register-dialog';
import { StyledButton } from '../../../../Shared/styled-button';
import GeoJSONProject, { Project } from '../../Project/geojson-project';
import ProjectMetadataDialog from '../../Project/project-metadata-dialog';
import { DrawPage } from '../draw-story';
import StoryBuilderShareLinkModal from './story-builder-share-link-modal';
import UriHelper from '../../../../../lib/uri-helper';
import { selectMapBounds } from '../../../../../store/App/selectors';
import { setSelectedAnnotationAction } from '../../../../../store/Annotations/actions';
import { PulseLoader } from '../../../../Shared/pulse-loader';

interface StoryBuilderSaveButtonProps {
    drawPages: DrawPage[];
    currentDrawProjectId: string | undefined;
}

const StoryBuilderSaveButton = (props: StoryBuilderSaveButtonProps) => {
    const [showLogin, setShowLogin] = useState(false);
    const [showProjectDialog, setShowProjectDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showShareLinkModal, setShowShareLinkModal] = useState<number | undefined>(undefined);

    const isLoggedIn = useSelector(selectLoggedIn);
    const mapBounds = useSelector(selectMapBounds);
    const dispatch = useDispatch();

    const handleClickSave = () => {
        dispatch(setSelectedAnnotationAction(undefined));
        if (!isLoggedIn) {
            setShowLogin(true);
        } else if (props.currentDrawProjectId) {
            handleUpdateProject();
        } else {
            setShowProjectDialog(true);
        }
    };

    const handleProjectGeoJSONData = (drawPages: DrawPage[]) => {
        const pagesGeoJSONRaw = drawPages
            .map((page) => setPagesViewport(page))
            .map((page) => {
                if (page.activeMap && page.project) {
                    page.project = {
                        ...page.project,
                        activeMap: page.activeMap,
                    };
                }
                return {
                    ...page,
                    ...JSON.parse(GeoJSONProject.export(page.project as Project)),
                };
            });

        return { pages: pagesGeoJSONRaw };
    };

    const handleUpdateProject = () => {
        const pagesGeoJSON = handleProjectGeoJSONData(props.drawPages);

        const drawProject: UpdateProjectRequest = {
            data: pagesGeoJSON,
        };

        if (props.currentDrawProjectId) {
            setLoading(true);
            ApiDraw.updateDrawProject(Number(props.currentDrawProjectId), drawProject)
                .then((res) => {
                    setShowShareLinkModal(res.id);
                    UriHelper.navigateToEditDrawProject(res.id.toString());
                })
                .catch(() => {
                    setShowShareLinkModal(undefined);
                })
                .finally(() => {
                    setLoading(false);
                    setShowProjectDialog(false);
                });
        }
    };

    const setPagesViewport = (page: DrawPage): DrawPage => {
        if (!page.project) throw new Error('Project is missing');
        return {
            ...page,
            project: {
                ...page.project,
                viewportBounds:
                    page.viewportLocked || page.allViewportsLocked ? page.project.viewportBounds : mapBounds,
            },
        };
    };

    const handleSaveProject = (projectMetadata: CreateProjectMetaRequest) => {
        if (!isLoggedIn) {
            setShowLogin(true);
            return;
        }

        const pagesGeoJSON = handleProjectGeoJSONData(props.drawPages);

        const drawProject: CreateProjectRequest = {
            ...projectMetadata,
            data: pagesGeoJSON,
        };

        if (props.currentDrawProjectId) {
            setLoading(true);
            ApiDraw.updateDrawProject(Number(props.currentDrawProjectId), drawProject)
                .then((res) => {
                    setShowShareLinkModal(res.id);
                    UriHelper.navigateToEditDrawProject(res.id.toString());
                })
                .catch(() => {
                    setShowShareLinkModal(undefined);
                })
                .finally(() => {
                    setLoading(false);
                    setShowProjectDialog(false);
                });
        } else {
            setLoading(true);
            ApiDraw.createDrawProject(drawProject)
                .then((res) => {
                    setShowShareLinkModal(res.id);
                    UriHelper.navigateToEditDrawProject(res.id.toString());
                })
                .catch(() => {
                    setShowShareLinkModal(undefined);
                })
                .finally(() => {
                    setLoading(false);
                    setShowProjectDialog(false);
                });
        }
    };

    return (
        <React.Fragment>
            <SaveButton
                disabled={loading}
                onClick={() => {
                    handleClickSave();
                }}
            >
                {loading ? <PulseLoader /> : <React.Fragment>Save</React.Fragment>}
            </SaveButton>

            <LoginRegisterDialog
                isOpen={showLogin}
                onClose={() => setShowLogin(false)}
                initialMode={LoginModalMode.LOGIN}
                onLoginSuccess={() => setShowProjectDialog(true)}
                onRegisterSuccess={() => setShowProjectDialog(true)}
            />

            <ProjectMetadataDialog
                isOpen={!showLogin && showProjectDialog}
                toggle={() => setShowProjectDialog(false)}
                onSubmit={(projectMetadata: CreateProjectMetaRequest) => {
                    handleSaveProject(projectMetadata);
                }}
                isCreatingProject={loading}
            />

            {showShareLinkModal !== undefined && (
                <StoryBuilderShareLinkModal
                    isOpen={showShareLinkModal !== undefined}
                    toggle={() => setShowShareLinkModal(undefined)}
                    savedProjectId={showShareLinkModal}
                />
            )}
        </React.Fragment>
    );
};

export default StoryBuilderSaveButton;

const SaveButton = styled(StyledButton)`
    display: block;
    margin: 8px auto;
    min-width: 152px;
`;
