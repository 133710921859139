import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import turfDistance from '@turf/distance';
import L from 'leaflet';
import { bearing, formatImperialLengthString, formatMetricLengthString } from './measurement-util';

const lengthSVGLabel = (text: string, labelColor: 'white' | 'black' | 'yellow', angle: number): SVGSVGElement => {
    const backgroundColor = 'transparent';
    const textColor = labelColor;
    const strokeColor = labelColor === 'black' ? 'white' : 'black';
    const fontSize = `1.6rem`;

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('fill', backgroundColor);
    svg.style.backgroundColor = backgroundColor;

    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.setAttribute('x', '50%');
    textElement.setAttribute('y', '50%');
    textElement.setAttribute('fill', textColor);
    textElement.setAttribute('font-size', fontSize);
    textElement.setAttribute('font-family', 'Manrope');
    textElement.setAttribute('font-weight', 'bold');
    textElement.setAttribute('text-anchor', 'middle');
    textElement.setAttribute('dominant-baseline', 'text-after-edge');
    textElement.setAttribute('stroke', strokeColor);
    textElement.setAttribute('stroke-width', '0.25px');
    textElement.setAttribute('transform-origin', `50% 50%`);

    textElement.textContent = text;
    textElement.style.transform = `rotate(${angle}deg)`;
    svg.appendChild(textElement);

    return svg;
};

export const createLengthLabel = (
    startPosition: L.LatLng,
    endPosition: L.LatLng,
    allPositions: L.LatLng[],
    units: 'imperial' | 'metric',
    labelColor: 'white' | 'black' | 'yellow',
    paneId: string,
    context: LeafletContextInterface
) => {
    const center = new L.LatLngBounds([
        [startPosition.lat, startPosition.lng],
        [endPosition.lat, endPosition.lng],
    ]).getCenter();

    // The angle using Great Circle bearing
    const angle = bearing(startPosition, endPosition) - 90;

    // The angle using Pythagoras
    //const dY = endPosition.lat - startPosition.lat;
    //const dX = endPosition.lng - startPosition.lng;
    //const angle = -1 * Math.atan2(dY, dX) * (180 / Math.PI);

    // Correct the cardinality so the label is always facing upwards
    const displayAngle = angle > 90 || angle < -90 ? angle + 180 : angle;

    let distanceString: string;
    const distanceInMeters = turfDistance([startPosition.lng, startPosition.lat], [endPosition.lng, endPosition.lat], {
        units: 'meters',
    });

    if (units === 'imperial') {
        const distanceInFeet = distanceInMeters * 3.28;
        distanceString = formatImperialLengthString(distanceInFeet);
    } else {
        distanceString = formatMetricLengthString(distanceInMeters);
    }

    const bounds = center.toBounds(distanceInMeters);
    const svg = lengthSVGLabel(distanceString, labelColor, displayAngle);

    const svgElement = createElementObject<L.SVGOverlay, L.SVGOverlayStyleOptions>(
        new L.SVGOverlay(svg, bounds, { interactive: false, pane: paneId, className: 'leaflet-svg-area-label' }),
        context
    );

    const updateLabelVisibility = () => {
        const textLength = (svg.childNodes[0] as SVGTextElement).getComputedTextLength();
        svgElement.instance.getBounds();
        const startScreenPosition = context.map.latLngToLayerPoint(startPosition);
        const endScreenPosition = context.map.latLngToLayerPoint(endPosition);
        const screenDistancePadding = 10; //px
        const screenDistance = startScreenPosition.distanceTo(endScreenPosition) - screenDistancePadding;

        const svgNode = svgElement.instance.getElement()?.childNodes[0] as SVGTextElement;
        if (textLength > 0 && textLength > screenDistance) {
            svgNode.setAttribute('opacity', '0');
            svgElement.instance.fireEvent('hide');
        } else {
            svgNode.setAttribute('opacity', '1');
            svgElement.instance.fireEvent('show');
        }
    };

    const onZoomEnd = () => {
        updateLabelVisibility();
    };

    svgElement.instance.on('add', () => {
        updateLabelVisibility();
        context.map.on('zoomend', onZoomEnd);
    });

    svgElement.instance.on('remove', () => {
        context.map.off('zoomend', onZoomEnd);
    });

    return svgElement;
};
