import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import SoarHelper, { isMobileVersion } from '../../lib/soar-helper';
import UriHelper from '../../lib/uri-helper';
import { actionShowLoginDialog, actionShowUploadDialog } from '../../store/App/actions';
import { actionGetMyListings, actionReloadProfile } from '../../store/Account/actions';
import { selectMyProfile } from '../../store/Account/selectors';
import { selectShowLoginDialog } from '../../store/App/selectors';
import {
    actionGetDomainUsers,
    actionGetSubdomain,
    actionGetSubdomainListings,
    actionGetSales,
} from '../../store/Dashboard/actions';
import { actionUpdatePurchasedListings } from '../../store/Purchases/actions';
import { DashboardMode } from '../../store/Dashboard/model';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { PulseLoader } from '../Shared/pulse-loader';
import AccountManagement from './Account/account-management';
import AccountSettings from './AccountSettings/account-settings';
import DashboardHome from './Dashboard/home';
import SubdomainOverview from './Dashboard/subdomain-overview';
import DataManagement from './Imagery/data-management';
import MyImagery from './Imagery/my-imagery';
import MyDrawProjects from './DrawProjects/my-draw-projects';
import MenuSoar from './Menu/menu-soar';
import SubdomainNavigation from './Menu/menu-subdomain';
import MyBookmarks from './MyBookmarks/my-bookmarks';
import Payout from './Payout/payout';
import ProfileEdit from './Profile/profile-edit';
import SatelliteOrders from './SatelliteOrders/satellite-orders';
import PurchaseHistory from './TransactionHistory/purchase-history';
import SalesHistory from './TransactionHistory/sales-history';
import UserManagement from './Users/manage-users';
import DashboardTermsAndCondition from './dashboard-terms-and-condition';

interface MyProfileProps {
    dashboardMode: DashboardMode;
}

const Dashboard: React.FC<MyProfileProps> = ({ dashboardMode }: MyProfileProps) => {
    const dispatch = useDispatch();
    const myProfile = useSelector(selectMyProfile);
    const showLoginDialog = useSelector(selectShowLoginDialog);
    const [dashboardReady, setDashboardReady] = useState(false);

    const isSoar = SoarHelper.isSoar();

    useEffect(() => {
        (async () => {
            await dispatch(actionReloadProfile());

            if (isSoar) {
                dispatch(actionGetMyListings());
                dispatch(actionGetSales());
                dispatch(actionUpdatePurchasedListings());
                dispatch(actionShowUploadDialog(false));
            } else {
                dispatch(actionGetSubdomainListings());
                dispatch(actionGetDomainUsers());
                dispatch(actionGetSubdomain());
            }
        })().then(() => setDashboardReady(true));
    }, [dispatch, isSoar]);

    const renderContent = () => {
        if (!dashboardReady || !myProfile) return <PulseLoader />;

        switch (dashboardMode) {
            case DashboardMode.DASHBOARD:
                return <DashboardHome myProfile={myProfile} />;
            case DashboardMode.ACCOUNT_SETTINGS:
                return <AccountSettings />;
            case DashboardMode.SUBDOMAIN_OVERVIEW:
                return <SubdomainOverview />;
            case DashboardMode.USER_MANAGEMENT:
                return <UserManagement />;
            case DashboardMode.ACCOUNT_MANAGEMENT:
                return <AccountManagement />;
            case DashboardMode.PROFILE:
                return <ProfileEdit myProfile={myProfile} />;
            case DashboardMode.MY_IMAGERY:
                return <MyImagery />;
            case DashboardMode.MY_DRAW_PROJECTS:
                return <MyDrawProjects />;
            case DashboardMode.MY_BOOKMARKS:
                return <MyBookmarks />;
            case DashboardMode.DATA_MANAGEMENT:
                return <DataManagement />;
            case DashboardMode.PAYOUT:
                return <Payout />;
            case DashboardMode.PURCHASE_HISTORY:
                return <PurchaseHistory />;
            case DashboardMode.SALES_HISTORY:
                return <SalesHistory />;
            case DashboardMode.SATELLITE_QUEUE:
                return <SatelliteOrders />;
            case DashboardMode.TERMS_AND_CONDITION:
                return <DashboardTermsAndCondition />;

            default:
                return <React.Fragment />;
        }
    };

    if (showLoginDialog) {
        return (
            <LoginRegisterDialog
                isOpen={true}
                initialMode={LoginModalMode.LOGIN}
                onLoginSuccess={() => {
                    dispatch(actionShowLoginDialog(false));
                    UriHelper.navigateToDashboard(DashboardMode.DASHBOARD);
                }}
            />
        );
    }

    const showMenu = !isMobileVersion && dashboardMode !== DashboardMode.DASHBOARD;

    return (
        <Container>
            {showMenu &&
                (isSoar ? (
                    <MenuSoar dashboardMode={dashboardMode} />
                ) : (
                    <SubdomainNavigation dashboardMode={dashboardMode} />
                ))}

            <DashboardPageContainer>
                {renderContent()}
                <Tooltip
                    id="dashboard-tooltip"
                    delayShow={2000}
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.85)',
                        color: '#ffffff',
                        fontFamily: "'Manrope', sans-serif !important",
                    }}
                />
            </DashboardPageContainer>
        </Container>
    );
};

export default Dashboard;

const DashboardPageContainer = styled.div`
    width: 100%;
    height: calc(100vh - 100px);
    color: white !important;
    position: relative;
    overflow-y: auto;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
        width: 12px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-track {
        background-color: #343a40;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #eed926;
        border-radius: 20px;
        border: 1px solid #eed926;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
`;

const Container = styled.div`
    background-color: #191a1a;
    color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    position: relative;

    & > div {
        margin-top: 100px;

        &:first-child + ${DashboardPageContainer} {
            padding-right: 25px;
        }
    }
`;
