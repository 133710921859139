import { useInfiniteLoader, usePositioner, useResizeObserver } from 'masonic';
import { useScroller, useSize } from 'mini-virtual-list';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ApiListings from '../../api/api-listings';
import { ListingDTO } from '../../api/model';
import SoarHelper from '../../lib/soar-helper';
import useIntersectionObserver from '../../lib/use-intersection-observer';
import Analytics from '../../lib/user-analytics';
import { PulseLoader } from '../Shared/pulse-loader';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-container';
import SearchResultsContinentFilter from './search-results-continent-filter';
import SearchResultsEmptyView from './search-results-empty-view';
import SearchResultsMasonary, { SelectableListing } from './search-results-masonary';

const LIMIT = 40;
const THRESHOLD = 40;

interface SearchContentExploreProps {
    handleSelectListing: (id: number) => void;
}

const SearchContentExplore = (props: SearchContentExploreProps) => {
    const containerRef = useRef(null);
    const [masonryOverflow, setMasonryOverflow] = useState<'hidden' | 'auto'>('hidden');
    const [items, setItems] = useState<ListingDTO[]>([]);
    const { width, height } = useSize(containerRef);
    const { scrollTop, isScrolling } = useScroller(containerRef);
    const [shouldFetch, setShouldFetch] = useState(true);
    const [fetchStartIndex, setFetchStartIndex] = useState(0);
    const [isLoading, setIsloading] = useState(false);

    const positioner = usePositioner({
        width: width,
        columnGutter: 16,
        columnWidth: 260,
    });

    const resizeObserver = useResizeObserver(positioner);
    const intersectionObserver = useIntersectionObserver(containerRef, { threshold: 1 });

    // The masonry scrolls separately from the container.
    // We deactivate the masonry overflow until the user scrolls all the way down which
    // gives a smooth transition from content scrolling to mosaic scrolling
    useEffect(() => {
        if (intersectionObserver && intersectionObserver.intersectionRatio === 1) {
            setMasonryOverflow('auto');
        } else {
            setMasonryOverflow('hidden');
        }
    }, [intersectionObserver]);

    useEffect(() => {
        if (shouldFetch) {
            setIsloading(true);
            ApiListings.searchListings(LIMIT, fetchStartIndex)
                .then((res) => {
                    setItems((current) => [...current, ...res]);
                    setShouldFetch(res.length === LIMIT);
                })
                .finally(() => {
                    setIsloading(false);
                });
        }
    }, [fetchStartIndex, shouldFetch]);

    const selectableListings: SelectableListing[] = items.map((t) => {
        return {
            id: t.id,
            authorName: t.userName,
            title: t.title,
            handleSelectListing: (id) => {
                props.handleSelectListing(id);
                Analytics.Event('Search Bar', 'Clicked Map From Most Recent', id);
            },
            createdAt: t.createdAt,
            popularity: t.totalLikes,
            listingType: t.listingType,
            categories: t.categories,
        };
    });

    const maybeLoadMore = useInfiniteLoader(
        async (startIndex) => {
            if (masonryOverflow === 'auto') {
                setFetchStartIndex(startIndex);
            }
        },
        {
            isItemLoaded: (index, items) => !!items[index],
            minimumBatchSize: LIMIT,
            threshold: THRESHOLD,
        }
    );

    return (
        <Container>
            {selectableListings.length !== 0 && (
                <React.Fragment>
                    <SegmentTitle>
                        <SegmentTitleIcon src="/assets/search-content/emoji-explore.svg" />
                        Explore the <span>most recent</span> maps below
                    </SegmentTitle>
                    {SoarHelper.isSoar() && <SearchResultsContinentFilter />}
                </React.Fragment>
            )}

            <MasonryContainer>
                <Masonic ref={containerRef}>
                    {selectableListings.length > 0 ? (
                        <SearchResultsMasonary
                            positioner={positioner}
                            resizeObserver={resizeObserver}
                            items={selectableListings}
                            height={height}
                            scrollTop={scrollTop}
                            isScrolling={isScrolling}
                            maybeLoadMore={maybeLoadMore}
                            handleSelectListing={props.handleSelectListing}
                        />
                    ) : !isLoading ? (
                        <SearchResultsEmptyView isLoading={false} />
                    ) : null}
                    {isLoading && <PulseLoader />}
                </Masonic>
            </MasonryContainer>
        </Container>
    );
};

export default SearchContentExplore;

const Container = styled(SegmentContainer)`
    margin-bottom: 16px;
`;

const MasonryContainer = styled.main`
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    width: 100%;
    overflow: hidden;
`;

const Masonic = styled.div`
    height: calc(70vh - 116px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px;
    width: 100%;

    &::-webkit-scrollbar-track {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        margin: 3px 0;
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: rgba(125, 125, 125, 0.2);
        border-radius: 4px;
    }
`;
