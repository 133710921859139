import styled, { keyframes } from 'styled-components';
import { NotificationDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';

interface NotificationBellProps {
    showNotifications: boolean;
    toggleNotifications: () => void;
    notificationList: NotificationDTO[];
}

export const NotificationBell = ({
    showNotifications,
    toggleNotifications,
    notificationList,
}: NotificationBellProps): JSX.Element => {
    const notificationCount = notificationList.filter((notification) => notification.viewedAt === undefined).length;

    return (
        <NotificationBellContainer>
            {notificationCount > 0 && (
                <NotificationCount
                    onClick={(e) => {
                        e.preventDefault();
                        toggleNotifications();
                        Analytics.Event('Notifications', 'Clicked notifications');
                    }}
                >
                    {notificationCount < 100 ? notificationCount : '99+'}
                </NotificationCount>
            )}
            <NotificationIcon
                data-testid="navbar-notification-bell"
                onClick={(e) => {
                    e.preventDefault();
                    toggleNotifications();
                    Analytics.Event('Notifications', 'Clicked notifications');
                }}
                src={
                    notificationCount > 0
                        ? '/assets/notifications/active-bell.png'
                        : '/assets/notifications/inactive-bell.png'
                }
                isHighlighted={!showNotifications && notificationCount > 0}
            />
            {showNotifications && <NotificationArrow />}
        </NotificationBellContainer>
    );
};

interface NotificationStyleProps {
    isHighlighted: boolean;
}

const NotificationBellContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const NotificationCount = styled.div`
    position: absolute;
    background-color: #eed926;
    padding: 1px 6px 1px 6px;
    margin-left: 17px;
    border-radius: 120px;
    font-size: 10px;
    z-index: 1;
    font-weight: 600;
    border: 1px solid rgb(0 0 0 / 60%);
    cursor: pointer;
`;

const NotificationIcon = styled.img<NotificationStyleProps>`
    padding: 8px 10px 0px 10px;
    animation: ${(props) => (props.isHighlighted ? Ring : 'none')} 4s 0.7s ease-in-out infinite;
    cursor: pointer;
    width: 37px;
    height: 28px;
`;

const Ring = keyframes`
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
`;

const NotificationArrow = styled.div`
    position: absolute;
    margin-left: 10px;
    top: 50px;
    border-width: 18px 0px 18px 18px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
    transform: rotate(270deg);
`;
