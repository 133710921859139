import { LatLng, LatLngBounds } from 'leaflet';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../store/App/actions';
import {
    actionSetHighlightedListingId,
    actionSetHighlightedListingGroup,
} from '../../../store/Map/MapSelection/actions';
import { selectHighlightedListingGroup } from '../../../store/Map/MapSelection/selectors';
import { selectUserListings } from '../../../store/Map/UserProfile/selectors';
import { MapImage } from './profile-featured-maps';

const ProfileAllMaps = () => {
    const userListings = useSelector(selectUserListings);
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const dispatch = useDispatch();
    const flyTo = (position: LatLng | LatLngBounds) => dispatch(actionFlyToOnMap(position));

    const [searchTerm, setSearchTerm] = useState('');

    const [listingSearch, setListingSearch] = useState<ListingDTO[]>([]);

    const searchFilter = (title: string, tags: string[], searchTerm: string): boolean => {
        if (searchTerm?.length > 0) {
            const searchTermLowerCase = searchTerm.toLocaleLowerCase();
            return `${title} ${tags?.join(' ')}`.toLocaleLowerCase().includes(searchTermLowerCase);
        }
        return true;
    };

    useEffect(() => {
        setListingSearch(userListings.filter((tl) => searchFilter(tl.title, tl.tags, searchTerm.trim())));
    }, [userListings, searchTerm]);

    const onMouseEnter = (mapId: number) => {
        dispatch(actionSetHighlightedListingId(mapId));
        dispatch(actionSetHighlightedListingGroup(undefined));
    };

    const onMouseLeave = () => {
        dispatch(actionSetHighlightedListingId(undefined));
        dispatch(actionSetHighlightedListingGroup(undefined));
    };

    return (
        <Container>
            <SearchContainer>
                <SearchIcon className="fa fa-search" />
                <SearchInput
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
            </SearchContainer>
            <MapsContainer>
                {listingSearch.length > 0 ? (
                    <MapGrid>
                        {listingSearch.map((l, key) => {
                            const isHighlighted = highlightedTileLayerGroup?.tileLayers[0].id === l.id;
                            return (
                                <MapsGrid
                                    key={key}
                                    onMouseEnter={() => onMouseEnter(l.id)}
                                    onMouseLeave={() => onMouseLeave()}
                                    onClick={() => {
                                        UriHelper.navigateToMap(l.id);
                                        flyTo(l.boundingBox);
                                        Analytics.Event('Profile sidedraw', 'Clicked to view', l.id);
                                        ApiAnalytics.postAnalyticsListing(
                                            AnalyticsAction.VIEW,
                                            AnalyticsNote.USER_PROFILE,
                                            l.id
                                        );
                                    }}
                                >
                                    <MapImage
                                        isHighlighted={isHighlighted}
                                        src={ListingHelper.getPreviewUrlForListing(l.id, 'small')}
                                        title={l.title || ''}
                                    />
                                </MapsGrid>
                            );
                        })}
                    </MapGrid>
                ) : (
                    <ErrorLabel>No Maps were found</ErrorLabel>
                )}
            </MapsContainer>
        </Container>
    );
};

export default ProfileAllMaps;

const Container = styled.div`
    color: white;
    padding: 15px;
`;

const MapsContainer = styled.div`
    margin-top: 20px;
`;

const MapGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-auto-rows: 1fr;
`;

const MapsGrid = styled.div`
    padding: 3px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
`;

const SearchContainer = styled.div`
    width: 100%;
    flex-direction: row;
    margin-top: -35px;
`;

const SearchIcon = styled.i`
    size: 20px;
    padding: 10px;
    position: relative;
    color: #eed926;
    top: 35px;
`;

const SearchInput = styled.input`
    background: transparent;
    color: #ffffff;
    padding: 5px;
    width: 100%;
    border: 1px solid rgb(255 255 255 / 0.3);
    border-radius: 6px;
    padding-left: 30px;
    min-height: 35px;

    &:focus {
        background: transparent;
        outline: #eed926;
        border: 1px solid #eed926;
        color: #ffffff;
    }
`;

const ErrorLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    height: 25px;
    color: #ffffff;

    opacity: 0.3;
    margin-left: 5px;
`;
