import styled from 'styled-components';

const StoryMapsDrawerTitle = () => {
    return (
        <StoryMapsDrawerLogo>
            <StoryBookIcon src="/assets/stoaries-icons/book.svg" />
            <StoryTextIcon src="/assets/stoaries-icons/title.svg" />
        </StoryMapsDrawerLogo>
    );
};

export default StoryMapsDrawerTitle;

const StoryMapsDrawerLogo = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const StoryBookIcon = styled.img`
    width: 38px;
`;

const StoryTextIcon = styled.img`
    width: 120px;
    margin-left: 8px;
`;
