import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';
import { selectMapZoom } from '../../store/App/selectors';
import { selectActiveAnimatedLayer } from '../../store/Map/AnimatedLayer/selector';
import {
    selectActiveDrawProject,
    selectActiveDrawProjectIsFetching,
    selectDrawStoryProjects,
    selectDrawStoryProjectsIsFetching,
} from '../../store/Map/DrawProject/selectors';
import { selectBestFittingListingGroups } from '../../store/Map/MapSelection/selectors';
import { selectActiveSubdomainTileLayer } from '../../store/Map/Subdomain/selectors';
import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../store/Map/SuperMap/selectors';
import { selectActiveOrder } from '../../store/Map/Tasks/selectors';
import { SIDE_DRAWER_PROFILE_MODES, SideDrawerMode } from '../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../store/SideDrawer/selectors';
import { actionSetEditingComment, actionSetReplyingToComment } from '../../store/SocialMapping/actions';
import StoryBuilderSidedrawer from '../MapView/Annotations/StoryMaps/StoryBuilder/story-builder-sidedrawer';
import StoryMapsDrawerTitle from '../MapView/Annotations/StoryMaps/StoryView/story-maps-drawer-title';
import { useHistoryNavigation } from '../Shared/use-history-navigation';
import SelectContinent from './Continents/select-continent';
import ActiveAnimatedLayerMenu from './Maps/active-animated-layer';
import ActiveMap from './Maps/active-map';
import ActiveMapLoading from './Maps/active-map-loading';
import BookmarkedMaps from './Maps/bookmarked-maps';
import VisibleMaps from './Maps/visible-maps';
import ProfileSidebar from './Profile/profile-sidebar';
import CGSTLArchive100cm from './Satellites/CGSTL/cgstl-archive-100cm';
import CGSTLArchive50cm from './Satellites/CGSTL/cgstl-archive-50cm';
import CGSTLArchive75cm from './Satellites/CGSTL/cgstl-archive-75cm';
import CGSTLDrawer from './Satellites/CGSTL/cgstl-drawer';
import LandsatDrawer from './Satellites/Landsat/landsat-drawer';
import SentinelDrawer from './Satellites/Sentinel/sentinel-drawer';
import Skymap50NewCollect from './Satellites/Skymap50/NewCollect/skymap50-new-collect';
import Skymap50Archive from './Satellites/Skymap50/skymap50-archive';
import Skymap50Drawer from './Satellites/Skymap50/skymap50-drawer';
import Satellites from './Satellites/satellites';
import Drawer from './Shared/Drawer/drawer';
import DrawerContent from './Shared/Drawer/drawer-content';
import DrawerHeader, { HeaderContent } from './Shared/Drawer/drawer-header';
import ThinDrawer from './Shared/Drawer/thin-drawer';
import ThinDrawerContent from './Shared/Drawer/thin-drawer-content';
import ThinDrawerHeader from './Shared/Drawer/thin-drawer-header';
import ActiveProjectDrawer from './SoarDraw/active-project-drawer';
import ActiveStoryMapProjectDrawer from './SoarDraw/active-story-map-project-drawer';
import OrderSidebar from './Task/task-sidebar';

const DrawerControl = () => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);
    const activeMap = useSelector(selectActiveAllMap);
    const activeSubdomainMap = useSelector(selectActiveSubdomainTileLayer);
    const activeAnimatedLayer = useSelector(selectActiveAnimatedLayer);
    const mapZoom = useSelector(selectMapZoom);
    const mapsInViewport = useSelector(selectBestFittingListingGroups);
    const activeOrder = useSelector(selectActiveOrder);

    const activeDrawProject = useSelector(selectActiveDrawProject);
    const activeDrawProjectIsFetching = useSelector(selectActiveDrawProjectIsFetching);

    const activeDrawStoryProjects = useSelector(selectDrawStoryProjects);
    const activeDrawStoryProjectsIsFetching = useSelector(selectDrawStoryProjectsIsFetching);

    const dispatch = useDispatch();

    // TODO we have multiple types of navigation that should be looked at
    const { setNavigateToPath } = useHistoryNavigation();
    const reset = () => {
        dispatch(actionSetReplyingToComment(false));
        dispatch(actionSetEditingComment(false));
    };

    const getMapsInViewPort = () => {
        const mapCount = mapsInViewport.flatMap((t) => t.tileLayers).length;

        if (mapCount === 0) {
            return 'There are no maps in this area';
        } else if (mapCount === 1) {
            return 'There is 1 map in this area';
        } else {
            return `There are ${mapCount?.toLocaleString()} maps in this area`;
        }
    };

    if (sideDrawerMode === SideDrawerMode.HIDDEN) {
        return <React.Fragment />;
    }

    if (sideDrawerMode === SideDrawerMode.SOAR_DRAW_EDIT || sideDrawerMode === SideDrawerMode.SOAR_DRAW_NEW) {
        return <StoryBuilderSidedrawer />;
    }

    if (activeDrawProject && sideDrawerMode === SideDrawerMode.SOAR_PROJECT) {
        return (
            <ThinDrawer>
                <DrawerHeader
                    backButtonTooltip="Close Stoary"
                    collapsible
                    handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                />
                <DrawProjectDrawerContent>
                    <ActiveProjectDrawer
                        drawProject={activeDrawProject}
                        handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    />
                </DrawProjectDrawerContent>
            </ThinDrawer>
        );
    }

    if (activeDrawStoryProjects?.length && sideDrawerMode === SideDrawerMode.SOAR_PROJECT) {
        return (
            <ThinDrawer>
                <DrawerHeader
                    collapsible
                    backButtonTooltip="Close Draw Stoary"
                    handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    title={<StoryMapsDrawerTitle />}
                />
                <DrawProjectDrawerContent>
                    <ActiveStoryMapProjectDrawer
                        drawProjectArray={activeDrawStoryProjects}
                        handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    />
                </DrawProjectDrawerContent>
            </ThinDrawer>
        );
    }

    if (!activeMap && activeOrder) {
        return (
            <Drawer>
                <DrawerHeader
                    backButtonTooltip="Close Map"
                    handleBack={() => setNavigateToPath(SideDrawerMode.YOUR_MAPS)}
                />
                <DrawerContent>
                    <OrderSidebar order={activeOrder} />
                </DrawerContent>
            </Drawer>
        );
    }

    if (activeMapIsFetching || activeDrawProjectIsFetching || activeDrawStoryProjectsIsFetching) {
        return (
            <Drawer>
                <DrawerHeader
                    backButtonTooltip="Close Map"
                    handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    collapsible
                />
                <DrawerContent>
                    <ActiveMapLoading />
                </DrawerContent>
            </Drawer>
        );
    }

    if (SIDE_DRAWER_PROFILE_MODES.includes(sideDrawerMode)) {
        return (
            <Drawer>
                <DrawerHeader
                    backButtonTooltip="Close Profile"
                    handleBack={() => setNavigateToPath(SideDrawerMode.MAPS)}
                />
                <DrawerContent>
                    <ProfileSidebar />
                </DrawerContent>
            </Drawer>
        );
    }

    // NOTE ActiveMap is slightly different and has the DrawerContent in the component
    // to handle the comments and sharing functionality
    if (
        (activeMap && sideDrawerMode === SideDrawerMode.MAPS) ||
        (activeMap && SIDE_DRAWER_PROFILE_MODES.includes(sideDrawerMode)) ||
        (activeMap && sideDrawerMode === SideDrawerMode.YOUR_MAPS) ||
        (activeMap && sideDrawerMode === SideDrawerMode.MY_BOOKMARKS) ||
        (activeMap && sideDrawerMode === SideDrawerMode.SUBDOMAIN_MAPS)
    ) {
        reset();
        return (
            <Drawer>
                <DrawerHeader
                    backButtonTooltip="Close Map"
                    handleBack={() => {
                        reset();
                        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
                    }}
                    collapsible
                />
                <ActiveMap listing={activeMap} />
            </Drawer>
        );
    }

    // NOTE ActiveMap is slightly different and has the DrawerContent in the component
    // to handle the comments and sharing functionality
    if (activeSubdomainMap && sideDrawerMode === SideDrawerMode.MAPS) {
        return (
            <Drawer>
                <DrawerHeader
                    backButtonTooltip="Close Map"
                    handleBack={() => {
                        reset();
                        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
                    }}
                    collapsible
                />
                <ActiveMap listing={activeSubdomainMap} />
            </Drawer>
        );
    }

    if (activeAnimatedLayer && sideDrawerMode === SideDrawerMode.MAPS) {
        return (
            <Drawer>
                <DrawerHeader
                    backButtonTooltip="Close Map"
                    handleBack={() => UriHelper.navigateToDrawer(SideDrawerMode.MAPS)}
                    collapsible
                />
                <DrawerContent>
                    <ActiveAnimatedLayerMenu animatedLayer={activeAnimatedLayer} />
                </DrawerContent>
            </Drawer>
        );
    }

    // TODO did not apply changes as nadine is working on this section
    if (sideDrawerMode === SideDrawerMode.SATELLITE_SENTINEL) {
        return <SentinelDrawer />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_LANDSAT) {
        return <LandsatDrawer />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_SKYMAP50) {
        return <Skymap50Drawer />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_SKYMAP50_IMAGE_THE_FUTURE) {
        return <Skymap50NewCollect />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_SKYMAP50_SEARCH_ARCHIVE) {
        return <Skymap50Archive />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_CGSTL) {
        return <CGSTLDrawer />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_50) {
        return <CGSTLArchive50cm />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_75) {
        return <CGSTLArchive75cm />;
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_100) {
        return <CGSTLArchive100cm />;
    }

    if (sideDrawerMode === SideDrawerMode.MY_BOOKMARKS && !activeMap) {
        return (
            <ThinDrawer>
                <ThinDrawerHeader
                    handleBack={() => setNavigateToPath(SideDrawerMode.MAPS)}
                    title="Your Bookmarks"
                    subTitle={'Maps you have bookmarked'}
                />
                <ThinDrawerContent>
                    <BookmarkedMaps />
                </ThinDrawerContent>
            </ThinDrawer>
        );
    }

    if (sideDrawerMode === SideDrawerMode.YOUR_MAPS && !activeMap) {
        return (
            <ThinDrawer>
                <ThinDrawerHeader
                    handleBack={() => setNavigateToPath(SideDrawerMode.MAPS)}
                    title="Your Maps"
                    subTitle={getMapsInViewPort()}
                />
                <ThinDrawerContent>
                    <VisibleMaps />
                </ThinDrawerContent>
            </ThinDrawer>
        );
    }

    if (sideDrawerMode === SideDrawerMode.SUBDOMAIN_MAPS && !activeMap) {
        return (
            <ThinDrawer id="maps-section">
                <ThinDrawerHeader title="Maps" subTitle={getMapsInViewPort()} />
                <ThinDrawerContent>
                    <VisibleMaps />
                </ThinDrawerContent>
            </ThinDrawer>
        );
    }

    if (sideDrawerMode === SideDrawerMode.MAPS && mapZoom && mapZoom <= 4) {
        return (
            <ThinDrawer id="maps-section">
                <ThinDrawerHeader title="Explore the Digital Atlas" subTitle="Zoom in for more maps" />
                <ThinDrawerContent usePaddingTop>
                    <SelectContinent />
                </ThinDrawerContent>
            </ThinDrawer>
        );
    }

    if (sideDrawerMode === SideDrawerMode.MAPS && mapZoom && mapZoom >= 4) {
        return (
            <ThinDrawer id="maps-section">
                <ThinDrawerHeader
                    title="Explore the Digital Atlas"
                    subTitle={mapsInViewport.length === 0 ? `Here are some recommended maps` : getMapsInViewPort()}
                />
                <ThinDrawerContent>
                    <VisibleMaps showGlobalMaps={true} />
                </ThinDrawerContent>
            </ThinDrawer>
        );
    }

    if (sideDrawerMode === SideDrawerMode.SATELLITE) {
        return (
            <ThinDrawer id="satellites-section">
                <ThinDrawerHeader title={'Satellite Search'} subTitle={'Choose a Search'} />
                <ThinDrawerContent>
                    <Satellites />
                </ThinDrawerContent>
            </ThinDrawer>
        );
    }

    return <React.Fragment></React.Fragment>;
};

export default DrawerControl;

export const DrawProjectDrawerHeader = styled(DrawerHeader)`
    ${HeaderContent} {
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: ' ';
            display: inline-block;
            width: 42px;
            height: 42px;
            background: url(/assets/stoaries-icons/book.svg) no-repeat center;
            margin-right: 8px;
        }

        &:after {
            content: ' ';
            display: inline-block;
            width: 150px;
            height: 20px;
            background: url(/assets/stoaries-icons/title.svg) no-repeat center;
        }
    }
`;

export const DrawProjectDrawerContent = styled(DrawerContent)`
    min-height: 0px;
`;
