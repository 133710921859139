import { ProjectResponse } from '../../../api/api-draw';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface StoreDrawProject {
    drawProjects: ProjectResponse[];
    isFetchingDrawProjects: boolean;
    isFetchedDrawProjects: boolean;
    drawProjectsError: string | null;

    sharedDrawProjects: ProjectResponse[];
    isFetchingSharedDrawProjects: boolean;
    isFetchedSharedDrawProjects: boolean;
    sharedDrawProjectsError: string | null;

    drawProject: ProjectResponse | undefined;
    isFetchingDrawProject: boolean;
    isFetchedDrawProject: boolean;
    drawProjectError: string | null;

    isCreatingDrawProject?: boolean;
    drawProjectCreatingError?: string | null;

    isUpdatingDrawProject?: boolean;
    isDeletingDrawProject?: boolean;

    drawStoryProjects: ProjectResponse[] | [];
    isFetchingDrawStoryProjects: boolean;
    isFetchedDrawStoryProjects: boolean;
    drawStoryProjectsError: string | null;
    drawProjectStoryNumber: number;
}

const initialState: StoreDrawProject = {
    drawProjects: [],
    isFetchingDrawProjects: false,
    isFetchedDrawProjects: false,
    drawProjectsError: null,

    sharedDrawProjects: [],
    isFetchingSharedDrawProjects: false,
    isFetchedSharedDrawProjects: false,
    sharedDrawProjectsError: null,

    drawProject: undefined,
    isFetchingDrawProject: false,
    isFetchedDrawProject: false,
    drawProjectError: null,

    isCreatingDrawProject: false,
    drawProjectCreatingError: null,

    isUpdatingDrawProject: false,
    isDeletingDrawProject: false,

    drawStoryProjects: [],
    isFetchingDrawStoryProjects: false,
    isFetchedDrawStoryProjects: false,
    drawStoryProjectsError: null,
    drawProjectStoryNumber: 0,
};

export default (state: StoreDrawProject = initialState, action: Action): StoreDrawProject => {
    switch (action.type) {
        case at.DRAW_PROJECTS_FETCHING:
            return {
                ...state,
                isFetchingDrawProject: action.payload,
            };
        case at.DRAW_PROJECTS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchedDrawProjects: true,
                drawProjects: action.payload,
            };
        case at.DRAW_PROJECTS_FETCH_ERROR:
            return {
                ...state,
                isFetchedDrawProjects: false,
                drawProjectError: action.payload,
            };
        case at.DRAW_PROJECTS_RESET:
            return {
                ...state,
                drawProjects: [],
            };

        case at.DRAW_PROJECTS_SHARED_FETCHING:
            return {
                ...state,
                isFetchingSharedDrawProjects: action.payload,
            };
        case at.DRAW_PROJECTS_SHARED_FETCH_SUCCESS:
            return {
                ...state,
                isFetchedSharedDrawProjects: true,
                sharedDrawProjects: action.payload,
            };
        case at.DRAW_PROJECTS_SHARED_FETCH_ERROR:
            return {
                ...state,
                isFetchedSharedDrawProjects: false,
                sharedDrawProjectsError: action.payload,
            };
        case at.DRAW_PROJECTS_SHARED_RESET:
            return {
                ...state,
                sharedDrawProjects: [],
            };

        case at.DRAW_PROJECT_FETCHING:
            return {
                ...state,
                isFetchingDrawProject: action.payload,
            };
        case at.DRAW_PROJECT_FETCH_SUCCESS:
            return {
                ...state,
                drawProject: action.payload,
                drawProjectError: null,
                isFetchedDrawProject: true,
            };
        case at.DRAW_PROJECT_FETCH_ERROR:
            return {
                ...state,
                drawProjectError: action.payload,
                isFetchedDrawProject: false,
            };
        case at.DRAW_PROJECT_RESET:
            return {
                ...state,
                drawProject: undefined,
                drawProjectError: null,
                isFetchedDrawProject: false,
            };
        case at.DRAW_PROJECT_CREATE:
            return {
                ...state,
                drawProject: action.payload,
                drawProjects: [...state.drawProjects, action.payload],
            };
        case at.DRAW_PROJECT_CREATING:
            return {
                ...state,
                isCreatingDrawProject: action.payload,
            };
        case at.DRAW_PROJECT_CREATE_ERROR:
            return {
                ...state,
                drawProjectCreatingError: action.payload,
                isCreatingDrawProject: false,
            };
        case at.DRAW_PROJECT_UPDATE:
            return {
                ...state,
                drawProjects: state.drawProjects.map((project) => {
                    if (project.id === action.payload.id) {
                        return action.payload;
                    }
                    return project;
                }),
                drawProject: action.payload,
            };
        case at.DRAW_PROJECT_UPDATING:
            return {
                ...state,
                isUpdatingDrawProject: action.payload,
            };
        case at.DRAW_PROJECT_DELETE:
            return {
                ...state,
                drawProjects: state.drawProjects.filter((project) => project.id !== action.payload),
            };
        case at.DRAW_PROJECT_DELETING:
            return {
                ...state,
                isDeletingDrawProject: action.payload,
            };

        case at.DRAW_PROJECT_STORY_NUMBER:
            return {
                ...state,
                drawProjectStoryNumber: action.payload,
            };
        case at.DRAW_PROJECT_STORY_FETCHING:
            return {
                ...state,
                isFetchingDrawStoryProjects: action.payload,
            };
        case at.DRAW_PROJECT_STORY_FETCH_SUCCESS:
            return {
                ...state,
                isFetchedDrawStoryProjects: true,
                drawStoryProjects: action.payload,
                drawStoryProjectsError: null,
            };
        case at.DRAW_PROJECT_STORY_FETCH_ERROR:
            return {
                ...state,
                isFetchedDrawStoryProjects: false,
                drawStoryProjectsError: action.payload,
            };
        case at.DRAW_PROJECT_STORY_RESET:
            return {
                ...state,
                drawStoryProjects: [],
            };

        default:
            return state;
    }
};
