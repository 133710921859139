import { ListingDTO, ListingType } from '../../../../api/model';
import { SelectableListing } from '../../../Search/search-results-masonary';
import TemporalCategoryPill from './temporal-category-pill';

export interface TemporalCategory {
    value: string;
    label: string;
    color: string;
    title?: string;
    fontColor?: string;
}

export const temporalCategoryOptions: TemporalCategory[] = [
    { value: '', label: 'Not Applicable', color: 'transparent' },
    {
        value: 'near-real-time',
        label: 'Near Real Time',
        color: '#F7D96A',
        title: 'Maps that are updated less than 24 hours apart. Usually less than 6-12 hours.',
    },
    { value: 'daily', label: 'Daily', color: '#8CE4A3', title: 'Maps that are updated once every 24 hours.' },
    {
        value: 'weekly',
        label: 'Weekly',
        color: '#8FAFFF',
        title: 'Maps that are updated once every few days or once a week.',
    },
    {
        value: 'within-monthly',
        label: 'Within A Month',
        color: '#D1A5E6',
        title: 'Maps that are updated once every few weeks but less than a month.',
    },
    { value: 'monthly', label: 'Monthly', color: '#FF9F9F', title: 'Maps that are updated once every month.' },
    {
        value: 'seasonal',
        label: 'Seasonal',
        color: '#FFAD8F',
        title: 'Maps that are updated seasonally. Usually between 3 to 6 months.',
    },
    { value: 'yearly', label: 'Yearly', color: '#FFFFFF', title: 'Maps that are updated once a year.' },
];

interface TemporalCategoryControlProps {
    listing: ListingDTO | SelectableListing;
    inverted?: boolean;
    margin?: string;
}

const TemporalCategoryControl = ({ listing, inverted, margin }: TemporalCategoryControlProps) => {
    if (listing?.listingType !== ListingType.WMS) return null;

    const getTemporalCategory = () => {
        const selectedTemporalCategory = temporalCategoryOptions.find((temporalCategory) =>
            listing.categories.includes(temporalCategory.value)
        );

        if (selectedTemporalCategory) {
            return <TemporalCategoryPill category={selectedTemporalCategory} inverted={inverted} margin={margin} />;
        }

        return null;
    };

    return getTemporalCategory();
};

export default TemporalCategoryControl;
