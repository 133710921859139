import React from 'react';
import Analytics from '../../../lib/user-analytics';
import UriHelper from '../../../lib/uri-helper';
import { SitemapLink } from './footer';
import { SideDrawerMode } from '../../../store/SideDrawer/model';

const ProductLinks = () => {
    const onClickViewDigitalAtlas = () => {
        Analytics.Event('LandingPage', 'Clicked footer view digital atlas');
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    const onClickRegisterNow = () => {
        Analytics.Event('LandingPage', 'Clicked footer register now');
        UriHelper.navigateToPath('/register');
    };

    const onClickUploadImagery = () => {
        Analytics.Event('LandingPage', 'Clicked footer upload imagery');
        UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
    };

    return (
        <React.Fragment>
            <SitemapLink title="View the New Atlas" onClick={onClickViewDigitalAtlas}>
                View the New Atlas
            </SitemapLink>
            <SitemapLink title="Upload imagery" onClick={onClickUploadImagery}>
                Add your maps
            </SitemapLink>
            <SitemapLink title="Register now" onClick={onClickRegisterNow}>
                Register
            </SitemapLink>
        </React.Fragment>
    );
};

export default ProductLinks;
