import React from 'react';
import { useSelector } from 'react-redux';
import SoarHelper from '../../../lib/soar-helper';
import { selectActiveDrawProject } from '../../../store/Map/DrawProject/selectors';
import styled from 'styled-components';

import { shareProjectToClipboard, shareProjectToSocials } from '../../MapView/Annotations/Share/share-util';
import Analytics from '../../../lib/user-analytics';

const X_URL = 'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=';
const FACEBOOK_URL = 'https://www.facebook.com/sharer/sharer.php?u=';
const LINKEDIN_URL = 'https://www.linkedin.com/shareArticle?mini=true&url=';

const DrawProjectShare = () => {
    const isSoar = SoarHelper.isSoar();
    const drawProject = useSelector(selectActiveDrawProject);

    const handleCopyToClipboard = () => {
        if (drawProject) {
            Analytics.Event('Draw Tools - Project', 'Clicked share while viewing project', {
                ownerId: drawProject.ownerId,
                projectId: drawProject.id,
            });
            shareProjectToClipboard(drawProject.id);
        }
    };

    const handleShareToX = () => {
        if (drawProject) {
            shareProjectToSocials(drawProject.id, X_URL);
        }
    };

    const handleShareToFB = () => {
        if (drawProject) {
            shareProjectToSocials(drawProject.id, FACEBOOK_URL);
        }
    };

    const handleShareToLI = () => {
        if (drawProject) {
            shareProjectToSocials(drawProject.id, LINKEDIN_URL);
        }
    };

    if (!drawProject) {
        return <React.Fragment />;
    }

    // TODO - Remove disabled when we have a way to share projects
    // #6312 Implement Share link function after saving of Draw Project in map
    const disabled = true;
    return (
        <React.Fragment>
            {isSoar && !disabled ? (
                <React.Fragment>
                    <XShareLinkIcon title="Share on X" onClick={handleShareToX} />
                    <ShareIcon title="Share on Facebook" className="fa fa-facebook" onClick={handleShareToFB} />
                    <ShareIcon title="Share on LinkedIn" className="fa fa-linkedin" onClick={handleShareToLI} />
                </React.Fragment>
            ) : null}
            <ShareIcon title="Copy share link" className="fa fa-share" onClick={handleCopyToClipboard} />
        </React.Fragment>
    );
};

export default DrawProjectShare;

const XShareLinkIcon = styled.div`
    height: 16px;
    width: 16px;
    margin-top: 1px;
    pointer-events: all;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m12.59.79h2.45l-5.34,6.1,6.28,8.31h-4.92l-3.86-5.04-4.41,5.04H.35l5.71-6.53L.03.79h5.04l3.48,4.6L12.59.79Zm-.86,12.95h1.35L4.34,2.18h-1.46l8.85,11.57Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;

    &:hover {
        color: #eed926;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23EED926;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m12.59.79h2.45l-5.34,6.1,6.28,8.31h-4.92l-3.86-5.04-4.41,5.04H.35l5.71-6.53L.03.79h5.04l3.48,4.6L12.59.79Zm-.86,12.95h1.35L4.34,2.18h-1.46l8.85,11.57Z'/%3E%3C/svg%3E");
    }
`;

const ShareIcon = styled.i`
    color: white;
    font-size: 18px;
    margin-left: 0px;
    pointer-events: all;
    cursor: pointer;

    &:hover {
        color: #eed926;
    }
`;
