import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveSubdomainTileLayer } from '../../store/Map/Subdomain/selectors';
import { selectActiveDrawProject } from '../../store/Map/DrawProject/selectors';
import { selectActiveAllMap } from '../../store/Map/SuperMap/selectors';
import { selectUserProfile } from '../../store/Map/UserProfile/selectors';
import { SideDrawerMode, SIDE_DRAWER_PROFILE_MODES } from '../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../store/SideDrawer/selectors';
import SeoHelmetTemplate from './seo-helmet-template';
import { createSeoListing, createSeoProfile, SEO_DEFAULT, SEO_MAPS, SEO_SATELLITES } from './seo-values';

export interface SeoInfo {
    title: string;
    description: string;
    imageUrl: string;
    canonicalPath?: string;
}

const SEO = () => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const activeDrawProject = useSelector(selectActiveDrawProject);
    const activeMap = useSelector(selectActiveAllMap);
    const activeSubdomainMap = useSelector(selectActiveSubdomainTileLayer);
    const userProfile = useSelector(selectUserProfile);
    const [info, setInfo] = useState<SeoInfo>(SEO_DEFAULT);

    useEffect(() => {
        if (activeMap && !activeDrawProject) {
            setInfo(createSeoListing(activeMap));
        } else if (SIDE_DRAWER_PROFILE_MODES.includes(sideDrawerMode) && userProfile) {
            setInfo(createSeoProfile(userProfile));
        } else if (sideDrawerMode === SideDrawerMode.MAPS) {
            setInfo(SEO_MAPS);
        } else if (sideDrawerMode === SideDrawerMode.SATELLITE) {
            setInfo(SEO_SATELLITES);
        } else {
            setInfo(SEO_DEFAULT);
        }
    }, [sideDrawerMode, activeMap, activeSubdomainMap, activeDrawProject, userProfile]);

    return (
        <SeoHelmetTemplate
            title={info.title}
            description={info.description}
            imageUrl={info.imageUrl}
            canonicalPath={info.canonicalPath}
        />
    );
};

export default SEO;
