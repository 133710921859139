import React from 'react';
import styled from 'styled-components';

const StoryBuilderIntroduction = () => {
    return (
        <Container>
            <p>Stoaries allows you to create an interactive slideshow of maps and annotations.</p>
        </Container>
    );
};

export default StoryBuilderIntroduction;

const Container = styled.div`
    margin: 8px;
    padding: 8px;

    p {
        color: white;
        margin: 0;
        padding: 0;
    }
`;
