import React from 'react';
import styled from 'styled-components';
import { GlobalStyleDrawerContainer } from './drawer-styles';

interface ThinDrawerProps {
    id?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

/**
 * ThinDrawer
 * Wrapper that applies a standardized thin width to the drawer
 * @required children React.ReactNode
 */

const ThinDrawer = (props: ThinDrawerProps) => {
    return (
        <ThinDrawerContainer id={props.id} style={props.style}>
            {props.children}
        </ThinDrawerContainer>
    );
};

export default ThinDrawer;

const ThinDrawerContainer = styled(GlobalStyleDrawerContainer)`
    max-width: 286px;
    min-width: 200px;
`;
