import { setSelectedFillPatternAction } from '../../../../store/Annotations/actions';
import store from '../../../../store/store';
import Circle from '../Circle/circle';
import PolygonPath from '../Polygon/polygon';
import { Project } from '../Project/geojson-project';
import Rectangle from '../Rectangle/rectangle';
import { Pattern, availablePatterns, findAndUpdateAvailablePattern } from './availablePatterns';
import { v4 as uuidv4 } from 'uuid';

export const handlePatternPreview = (pattern: Pattern) => {
    // Create a new SVG element
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', '48');
    svg.setAttribute('height', '48');
    svg.setAttribute('viewBox', '0 0 48 48');
    svg.setAttribute('version', '1.1');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');

    // Append the original pattern to the new SVG
    svg.innerHTML = pattern.pattern;

    // Create a shape element (e.g., a rectangle) and add it to the SVG
    const shape = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    shape.setAttribute('x', '0');
    shape.setAttribute('y', '0');
    shape.setAttribute('width', '100%');
    shape.setAttribute('height', '100%');

    const fillColorId = handlePatternFillUrl(pattern);
    shape.setAttribute('fill', fillColorId);

    // Append the shape to the new SVG
    svg.appendChild(shape);

    // Serialize the new SVG to a string
    const stringSVG = new XMLSerializer().serializeToString(svg);
    const encodedSVG = encodeURIComponent(stringSVG);
    const dataURL = `data:image/svg+xml;utf8,${encodedSVG}`;

    return dataURL;
};

export const handlePatternFillUrl = (pattern: Pattern) => {
    // Guarentees the fillColor matches the pattern id
    const idMatch = pattern.pattern.match(/id="([^"]+)"/);
    const id = idMatch ? idMatch[1] : pattern.id;
    const fillColorId = `url('#${id}')`;
    return fillColorId;
};

export const handlePattternIdFromPatternFillUrl = (patternFillUrl: string) => {
    const regex = /url\('#([^'-]+)/;
    const idMatch = patternFillUrl.match(regex);
    const id = idMatch ? idMatch[1] : 'none';
    return id;
};

export const handleUpdatePattern = (shapeId: string, pattern: Pattern) => {
    const fillPatterns = store.getState().annotationDomain.present.annotationReducer.fillPatterns;

    const filteredFillPatterns = fillPatterns.filter((fillPattern) => fillPattern.shapeId !== shapeId);

    store.dispatch(setSelectedFillPatternAction([...filteredFillPatterns, { ...pattern, shapeId }]));
};

export const handleDuplicatePattern = (sourcePattern: string, id: string, color: string) => {
    const duplicatedPattern = availablePatterns(id, color).find((p) => p.id === sourcePattern);

    const newPatternFromDuplicate = {
        ...duplicatedPattern,
        shapeId: id,
    };

    const fillPatterns = store.getState().annotationDomain.present.annotationReducer.fillPatterns;

    store.dispatch(setSelectedFillPatternAction([...fillPatterns, newPatternFromDuplicate]));

    return handlePatternFillUrl(duplicatedPattern as Pattern);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleDeserializePatternFill = (json) => {
    const id = json.properties.id;
    const color = json.properties.options?.color || '#eed926';
    const patternName = handlePattternIdFromPatternFillUrl(json.properties.options?.fillColor);
    const fillPattern = findAndUpdateAvailablePattern(id, patternName, color);
    const newPattern = { ...fillPattern, shapeId: id };
    const fillPatterns = store.getState().annotationDomain.present.annotationReducer.fillPatterns;
    store.dispatch(setSelectedFillPatternAction([...fillPatterns, newPattern]));
};

const extractPatternPrefix = (url: string): string | null => {
    if (!url) return null;
    const hashIndex = url.indexOf('#');
    const dashIndex = url.indexOf('-', hashIndex);

    if (hashIndex === -1 || dashIndex === -1) {
        return null;
    }

    return url.substring(hashIndex + 1, dashIndex);
};

const createUniqueCopiedAnnotation = (annotation: Circle | PolygonPath | Rectangle) => {
    const pattern = annotation?.fillPattern || annotation?.options?.fillColor || undefined;
    if (!pattern) {
        return { ...annotation, id: uuidv4() };
    }

    const newId = uuidv4();
    const updatedPatternName = extractPatternPrefix(pattern);
    const updatedPatternUrl = `url('#${updatedPatternName}-${newId}')`;

    const updatedPattern = availablePatterns(newId, annotation.options.color || '#eed926').find(
        (pattern) => pattern.id === updatedPatternName
    );

    if (!updatedPattern) {
        return annotation;
    }

    const newPatternFromDuplicate = {
        ...updatedPattern,
        shapeId: newId,
    };

    const fillPatterns = store.getState().annotationDomain.present.annotationReducer.fillPatterns;
    const updatedPatterns = [...fillPatterns, newPatternFromDuplicate];
    store.dispatch(setSelectedFillPatternAction(updatedPatterns));

    return {
        ...annotation,
        id: newId,
        fillPattern: updatedPatternUrl,
        options: {
            ...annotation.options,
            fillColor: updatedPatternUrl,
        },
    };
};

export const handleCopyingProjectAnnotations = (project: Project | undefined): Project | undefined => {
    if (!project) return;
    const copiedProject = {
        ...project,
        circles: project?.circles.map((circle: Circle) => {
            return createUniqueCopiedAnnotation(circle);
        }),
        polygons: project?.polygons.map((polygon: PolygonPath) => {
            return createUniqueCopiedAnnotation(polygon);
        }),
        rectangles: project?.rectangles.map((rectangle: Rectangle) => {
            return createUniqueCopiedAnnotation(rectangle);
        }),
        textBoxes: project?.textBoxes.map((textBox) => {
            return {
                ...textBox,
                id: uuidv4(),
            };
        }),
    };
    return copiedProject as Project;
};
