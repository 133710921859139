import { actionTypes as at } from './constants';
import { CircleOptions } from 'leaflet';
import Circle from '../../../components/MapView/Annotations/Circle/circle';
import { Action } from '../../root-reducer';

interface CircleState {
    circles: Circle[];
    circleOptions?: CircleOptions;
    projectChanged?: boolean;
}

const initialState: CircleState = {
    circles: [],
    circleOptions: undefined,
    projectChanged: false,
};

export default (state = initialState, action: Action): CircleState => {
    switch (action.type) {
        case at.SET_CIRCLES:
            return { ...state, circles: action.payload.circles, projectChanged: true };

        case at.SET_CIRCLE_OPTIONS:
            return { ...state, circleOptions: action.payload };

        case at.SET_CIRCLE_ADD:
            return {
                ...state,
                circles: [...state.circles, action.payload],
                projectChanged: true,
            };

        case at.SET_CIRCLE_UPDATE: {
            const newCircles = state.circles.map((circle) => {
                if (circle.id === action.payload.id) {
                    return { ...circle, ...action.payload };
                }
                return circle;
            });
            return { ...state, circles: newCircles, projectChanged: true };
        }

        case at.SET_CIRCLE_DELETE: {
            const newCircles = state.circles.filter((circle) => circle.id !== action.payload.id);
            return { ...state, circles: newCircles, projectChanged: true };
        }

        default:
            return state;
    }
};
