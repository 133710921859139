import styled from 'styled-components';
import SoarModal, { StyledModalBody } from '../../../../Shared/soar-modal';
import { StyledButton } from '../../../../Shared/styled-button';
import { shareProjectToClipboard } from '../../Share/share-util';
import Analytics from '../../../../../lib/user-analytics';

interface StoryBuilderShareLinkModalProps {
    isOpen: boolean;
    toggle: () => void;
    savedProjectId: number;
}

const StoryBuilderShareLinkModal = (props: StoryBuilderShareLinkModalProps) => {
    const handleShareProject = () => {
        Analytics.Event('Draw Tools - Project', `Clicked to share project after save - ${props.savedProjectId}`);
        shareProjectToClipboard(props.savedProjectId);
        props.toggle();
    };

    const handleViewProject = () => {
        Analytics.Event('Draw Tools - Project', `Clicked to view project after save - ${props.savedProjectId}`);
        window.open(`/draw/${props.savedProjectId}`, '_blank');
        props.toggle();
    };

    return (
        <SoarModal title="Stoary saved!" isOpen={props.isOpen} toggle={props.toggle} width="500px">
            <ProjectUpdateModalBody>
                <ProjectModalButtons>
                    <ShareButton onClick={handleViewProject} title="View project in a new tab">
                        <ViewProjectImage src="/assets/annotations/open_project_dark.svg" />
                        View this Stoary
                    </ShareButton>
                    <ShareButton onClick={handleShareProject} title="Copy share link">
                        <ShareImage src="/assets/annotations/copy-share.svg" />
                        Copy share link
                    </ShareButton>
                </ProjectModalButtons>
            </ProjectUpdateModalBody>
        </SoarModal>
    );
};

export default StoryBuilderShareLinkModal;

const ProjectUpdateModalBody = styled(StyledModalBody)`
    display: flex;
    flex-direction: column;
    padding-right: 0px !important;
`;

const ProjectModalButtons = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const ShareButton = styled(StyledButton)`
    align-self: center;
    margin: 6px 0;

    :first-child {
        margin-right: 10px;
    }
`;

const ViewProjectImage = styled.img`
    height: 16px;
    width: 16px;
    margin: -2px 8px 0px 0;
    cursor: pointer;
`;

const ShareImage = styled.img`
    width: 20px;
    height: 20px;
    margin: -2px 8px 0px 0;
    cursor: pointer;
`;
