import React from 'react';
import { useSelector } from 'react-redux';
import ShareService from '../../../../lib/share-service';
import Analytics from '../../../../lib/user-analytics';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import ShareLink from './share-link';
import styled from 'styled-components';

// Soar specific social media share links
const SocialLinks = () => {
    const selectedMap = useSelector(selectActiveAllMap);

    const handleShareLink = (
        seoTitle: string,
        mapId: number,
        href: string,
        analyticsEvent: string,
        analyticsEventType: string
    ) => {
        Analytics.Event(analyticsEvent, analyticsEventType, mapId);
        const url = ShareService.shareMapUrl(mapId);
        const shareURL = `${href}${url}`;
        window.open(shareURL, '_blank');
    };

    if (!selectedMap) {
        return <React.Fragment />;
    }

    // We do not have access to the FA X icon currently, so we use a dataurl instead for X
    return (
        <React.Fragment>
            <a
                title="Share to X"
                target="_blank"
                onClick={() =>
                    handleShareLink(
                        selectedMap.seoTitle || '',
                        selectedMap.id,
                        'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=',
                        'Social Media - Opened To Share',
                        'Twitter'
                    )
                }
            >
                <XShareLinkIcon />
            </a>
            <ShareLink
                title={'Share to Facebook'}
                handleOnClick={() =>
                    handleShareLink(
                        selectedMap.seoTitle || '',
                        selectedMap.id,
                        'https://www.facebook.com/sharer.php?u=',
                        'Social Media - Opened To Share',
                        'Facebook'
                    )
                }
                icon={'fa fa-facebook'}
            />
            <ShareLink
                title={'Share to LinkedIn'}
                handleOnClick={() =>
                    handleShareLink(
                        selectedMap.seoTitle || '',
                        selectedMap.id,
                        'https://www.linkedin.com/shareArticle?mini=true&url=',
                        'Social Media - Opened To Share',
                        'LinkedIn'
                    )
                }
                icon={'fa fa-linkedin'}
            />
        </React.Fragment>
    );
};

export default SocialLinks;

const XShareLinkIcon = styled.div`
    height: 17px;
    width: 17px;
    margin: 2px -4px 0px 0px;
    pointer-events: all;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m12.59.79h2.45l-5.34,6.1,6.28,8.31h-4.92l-3.86-5.04-4.41,5.04H.35l5.71-6.53L.03.79h5.04l3.48,4.6L12.59.79Zm-.86,12.95h1.35L4.34,2.18h-1.46l8.85,11.57Z'/%3E%3C/svg%3E");

    &:hover {
        color: #eed926;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23EED926;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m12.59.79h2.45l-5.34,6.1,6.28,8.31h-4.92l-3.86-5.04-4.41,5.04H.35l5.71-6.53L.03.79h5.04l3.48,4.6L12.59.79Zm-.86,12.95h1.35L4.34,2.18h-1.46l8.85,11.57Z'/%3E%3C/svg%3E");
    }
`;
