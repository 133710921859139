import React, { useCallback, useState } from 'react';
import { LatLng } from 'leaflet';
import { useMapEvent } from 'react-leaflet';
import styled from 'styled-components';
import './coordinates-control.css';
import {
    convertDDToDMS,
    convertDDtoGDA2020,
    convertDDtoMGRS,
    convertDDtoUTM,
    displayDMS,
    wrapLongitudeCoordinates,
} from './coordinates-util';

const CoordinatesControl = () => {
    const [latlng, setLatLng] = useState(new LatLng(0, 0));
    const [display, setDisplay] = useState(0);

    const onMouseMove = useCallback((e) => {
        setLatLng(e.latlng);
    }, []);

    useMapEvent('mousemove', onMouseMove);

    const clickHandler = () => {
        setDisplay((display + 1) % 5);
    };

    const displayView = {
        0: (
            <React.Fragment>
                <div>
                    <span>Latitude:</span>
                    <span>{latlng.lat.toLocaleString('en-US', { minimumFractionDigits: 8, useGrouping: false })}</span>
                </div>
                <div>
                    <span>Longitude:</span>
                    <span>
                        {wrapLongitudeCoordinates(latlng.lng).toLocaleString('en-US', {
                            minimumFractionDigits: 8,
                            useGrouping: false,
                        })}
                    </span>
                </div>
                <Title>WGS84 DD</Title>
            </React.Fragment>
        ),
        1: (
            <React.Fragment>
                <div>
                    <span>Latitude:</span>
                    <span>{displayDMS(convertDDToDMS(latlng.lat, false))}</span>
                </div>
                <div>
                    <span>Longitude:</span>
                    <span>{displayDMS(convertDDToDMS(wrapLongitudeCoordinates(latlng.lng), true))}</span>
                </div>
                <Title>WGS84 MD</Title>
            </React.Fragment>
        ),
        2: (
            <React.Fragment>
                <div>
                    <span>MGRS:</span>
                    <span>{convertDDtoMGRS(wrapLongitudeCoordinates(latlng.lng), latlng.lat)}</span>
                </div>
                <Title>MGRS (Military)</Title>
            </React.Fragment>
        ),
        3: (
            <React.Fragment>
                <div>
                    <span>Easting:</span>
                    <span>
                        {convertDDtoUTM(latlng.lat, wrapLongitudeCoordinates(latlng.lng)).Easting.toLocaleString(
                            'en-US',
                            { useGrouping: false }
                        )}
                    </span>
                </div>
                <div>
                    <span>Northing:</span>
                    <span>
                        {convertDDtoUTM(latlng.lat, wrapLongitudeCoordinates(latlng.lng)).Northing.toLocaleString(
                            'en-US',
                            { useGrouping: false }
                        )}
                    </span>
                </div>
                <div>
                    <span>Zone:</span>
                    <span>
                        {convertDDtoUTM(latlng.lat, wrapLongitudeCoordinates(latlng.lng)).ZoneNumber}
                        {convertDDtoUTM(latlng.lat, wrapLongitudeCoordinates(latlng.lng)).ZoneLetter}
                    </span>
                </div>
                <Title>WGS84 UTM</Title>
            </React.Fragment>
        ),
        4: (
            <React.Fragment>
                <div>
                    <span>X:</span>
                    <span>
                        {convertDDtoGDA2020(latlng.lat, wrapLongitudeCoordinates(latlng.lng)).x.toLocaleString(
                            'en-US',
                            { minimumFractionDigits: 8, useGrouping: false }
                        )}
                    </span>
                </div>
                <div>
                    <span>Y:</span>
                    <span>
                        {convertDDtoGDA2020(latlng.lat, wrapLongitudeCoordinates(latlng.lng)).y.toLocaleString(
                            'en-US',
                            { minimumFractionDigits: 8, useGrouping: false }
                        )}
                    </span>
                </div>
                <Title>GDA94 MGA</Title>
            </React.Fragment>
        ),
    }[display];

    return (
        <div
            className="leaflet-bottom leaflet-left leaflet-control-coordinates-container leaflet-snapshot-ignore"
            id="coordinate-control-container"
        >
            <div className="leaflet-control leaflet-control-coordinates" onClick={clickHandler}>
                {displayView}
            </div>
        </div>
    );
};

export default CoordinatesControl;

const Title = styled.h2`
    text-align: right;
    font-style: italic;
    color: white;
    font-size: 13px;
    margin: 0;
`;
