import { actionTypes as at } from './constants';
import { PurchasedInfo } from '../../api/model';
import ApiUser from '../../api/api-user';

export const actionUpdatePurchasedListings = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.UPDATE_PURCHASED_LISTINGS,
        });
        try {
            const userLoggedIn = getState().accountDomain.loggedIn;
            if (userLoggedIn) {
                const purchases: PurchasedInfo[] = await ApiUser.getMyPurchases();
                dispatch(actionUpdatePurchasedListingsSuccess(purchases));
            } else {
                dispatch(actionUpdatePurchasedListingsError());
            }
        } catch (err) {
            //todo
        }
    };
};

const actionUpdatePurchasedListingsError = () => {
    return {
        type: at.UPDATE_PURCHASED_LISTINGS_ERROR,
        payload: {},
    };
};

const actionUpdatePurchasedListingsSuccess = (purchases: PurchasedInfo[]) => {
    return {
        type: at.UPDATE_PURCHASED_LISTINGS_SUCCESS,
        payload: purchases,
    };
};
