import { UploadCredentialsDTO } from '../../api/model';

export enum DashboardMode {
    ACCOUNT_SETTINGS,
    DASHBOARD,
    SUBDOMAIN_OVERVIEW,
    PROFILE,
    MY_IMAGERY,
    MY_DRAW_PROJECTS,
    MY_BOOKMARKS,
    PAYOUT,
    SATELLITE_QUEUE,
    PURCHASE_HISTORY,
    SALES_HISTORY,
    TERMS_AND_CONDITION,
    USER_MANAGEMENT,
    DATA_MANAGEMENT,
    ACCOUNT_MANAGEMENT,
}

export interface TileLayerUpload {
    key: string;
    file: File;
    uploaded?: number;
    filehash?: string;
    uploadDate?: Date;
    error?: string;
}

export interface StatusReport {
    key: string;
    status: number;
    file?: File;
    credentials?: UploadCredentialsDTO;
    error?: string;
}
