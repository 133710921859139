import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { NavLink as BootstrapNavLink, NavbarBrand as BootstrapNavbarBrand } from 'reactstrap';

import { actionLogoutUser } from '../../../store/Account/actions';
import { actionShowNavbar } from '../../../store/App/actions';
//import { selectConfig } from '../../../store/App/selectors';
import { DashboardMode } from '../../../store/Dashboard/model';
import { UserDTO } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';

import profileIconUrl from '../profile-icon-url';
import DashboardProfile from './home-profile';
import DashboardTile from './home-tile';
import { NavbarSubdomainList } from '../../NavigationBar/navbar-subdomain-list';
import Footer from '../../LandingPage/Footer/footer';
import SignOutModal from '../../Shared/Modal/sign-out';

interface ProfileEditProps {
    myProfile: UserDTO;
}

const DashboardHome = (props: ProfileEditProps) => {
    //const config = useSelector(selectConfig);
    const [isUserLogout, setIsUserLogout] = useState(false);

    const dispatch = useDispatch();

    const onClickLogo = () => {
        dispatch(actionShowNavbar(true));
        UriHelper.navigateToPath('/');
    };

    // TODO Disabled for now until the subdomain logo is sorted or concensus is reached...
    //const logoUrl = config.LOGO || '/assets/logos/soar_logo.png';
    const logoUrl = '/assets/logos/soar_logo.png';

    const handleTileClick = (dashboardMode: DashboardMode) => {
        dispatch(actionShowNavbar(true));
        UriHelper.navigateToDashboard(dashboardMode);
    };

    const onLogout = () => {
        dispatch(actionShowNavbar(true));
        dispatch(actionLogoutUser());

        UriHelper.navigateToPath('/');
    };

    useEffect(() => {
        return () => {
            dispatch(actionShowNavbar(true));
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <DashboardHomeContainer>
                <DashboardHomeHeader>
                    <DashboardBrand>
                        <DashboardNavBrand onClick={onClickLogo}>
                            {/* {isSoar ? <SoarLogo src={logoUrl} alt="Soar" /> : <SubdomainImg src={logoUrl} alt="Soar" />} */}
                            <SoarLogo src={logoUrl} alt="Soar" />
                        </DashboardNavBrand>
                        {props.myProfile.domains && <NavbarSubdomainList subdomains={props.myProfile.domains} />}
                    </DashboardBrand>
                    <DashboardNavSignout icon={profileIconUrl.logoutLightUrl} onClick={() => setIsUserLogout(true)}>
                        Sign Out
                    </DashboardNavSignout>
                </DashboardHomeHeader>
                <DashboardProfileContainer>
                    <DashboardProfile profile={props.myProfile} />
                </DashboardProfileContainer>
                <DashboardTiles>
                    <DashboardTile
                        onClick={() => handleTileClick(DashboardMode.MY_IMAGERY)}
                        icon={'/assets/dashboard/my-maps_white.svg'}
                        iconDark={'/assets/dashboard/my-maps_yellow.svg'}
                        title="My Maps"
                    >
                        My Maps
                    </DashboardTile>
                    <DashboardTile
                        onClick={() => handleTileClick(DashboardMode.MY_DRAW_PROJECTS)}
                        icon={'/assets/dashboard/draw_white.svg'}
                        iconDark={'/assets/dashboard/draw_yellow.svg'}
                        title="My Stoaries"
                    >
                        My Stoaries
                    </DashboardTile>
                    <DashboardTile
                        onClick={() => handleTileClick(DashboardMode.MY_BOOKMARKS)}
                        icon={'/assets/dashboard/bookmark_white.svg'}
                        iconDark={'/assets/dashboard/bookmark_yellow.svg'}
                        title="My Bookmarks"
                    >
                        My Bookmarks
                    </DashboardTile>
                    <DashboardTile
                        onClick={() => handleTileClick(DashboardMode.SATELLITE_QUEUE)}
                        icon={'/assets/dashboard/satellite_white.svg'}
                        iconDark={'/assets/dashboard/satellite_yellow.svg'}
                        title="My Satellite Orders"
                    >
                        My Satellite Orders
                    </DashboardTile>
                    <DashboardTile
                        onClick={() => handleTileClick(DashboardMode.PROFILE)}
                        icon={'/assets/dashboard/my-profile_white.svg'}
                        iconDark={'/assets/dashboard/my-profile_yellow.svg'}
                        title="Edit My Profile"
                    >
                        Edit My Profile
                    </DashboardTile>
                    <DashboardTile
                        onClick={() => handleTileClick(DashboardMode.ACCOUNT_SETTINGS)}
                        icon={'/assets/dashboard/my-account_white.svg'}
                        iconDark={'/assets/dashboard/my-account_yellow.svg'}
                        title="My Account Settings"
                    >
                        My Account Settings
                    </DashboardTile>
                </DashboardTiles>
                <DashboardTagLine>"Be a part of the world's new atlas"</DashboardTagLine>
            </DashboardHomeContainer>
            <Footer />
            <SignOutModal isOpen={isUserLogout} onToggle={() => setIsUserLogout(false)} onSignOut={() => onLogout()} />
        </React.Fragment>
    );
};

export default DashboardHome;

const DashboardHomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 900px;
    height: auto;
    min-height: 590px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 0 15px;
    border: 1px solid #eed926;
    border-radius: 8px;
`;

const DashboardHomeHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 78px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DashboardBrand = styled.div`
    display: flex;
    align-items: center;
`;

const DashboardNavBrand = styled(BootstrapNavbarBrand)`
    cursor: pointer;

    & + div {
        & > div {
            margin-top: -35px !important;
        }
    }
`;

const SoarLogo = styled.img`
    height: 48px;
`;

// const SubdomainImg = styled.img`
//     width: 152px;
//     height: 30px;
// `;

interface DashboardNavSignoutProps {
    icon: string;
}

const DashboardNavSignout = styled(BootstrapNavLink)<DashboardNavSignoutProps>`
    color: #fff !important;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
        content: ' ';

        float: left;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 5px;

        background: url('data:image/svg+xml;base64,${(props) =>
                btoa(decodeURI(props.icon.replace(/^data:image\/svg\+xml,/, '')))}')
            no-repeat center;
    }
`;

const DashboardProfileContainer = styled.section``;

const DashboardTiles = styled.section`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-evenly;
    position: relative;
    margin: 0 45px;
`;

const DashboardTagLine = styled.p`
    padding: 0 0 15px;
    margin: 0;

    color: ${(props) => props.theme.color.yellow};
    font-size: 18px;
    font-style: italic;
    text-align: center;
`;
