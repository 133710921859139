import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ShareService from '../../../../lib/share-service';
import SoarHelper from '../../../../lib/soar-helper';
import Analytics from '../../../../lib/user-analytics';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import ShareLink from './share-link';

const ShareCopyLink = () => {
    const selectedMap = useSelector(selectActiveAllMap);

    const generateCopyLink = async (mapId: number | string) => {
        const short = ShareService.shareMapUrl(mapId);
        const currentDomain = SoarHelper.isSoar() && short ? short : `${window.location.href}`;
        navigator.clipboard.writeText(currentDomain);
        toast.dark('Copied to clipboard');
        Analytics.Event(`Social Media - Copied To Share - ${currentDomain}`, 'Link', mapId);
    };

    if (!selectedMap) {
        return <React.Fragment />;
    }

    return (
        <ShareLink
            title={'Copy share link'}
            handleOnClick={() => generateCopyLink(selectedMap.seoTitle || selectedMap.id)}
            icon={'fa fa-share'}
        />
    );
};

export default ShareCopyLink;
