import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import { actionSetHighlightedContinent, actionSetSelectedContinent } from '../../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../../store/Map/MapSelection/model';
import { selectContinentListings } from '../../../store/Map/MapSelection/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';

const SelectContinent = () => {
    const dispatch = useDispatch();
    const setHighlightedContinent = (tileLayer: ListingGroup | undefined) =>
        dispatch(actionSetHighlightedContinent(tileLayer));
    const continentsDictionary = useSelector(selectContinentListings);

    const handleSelectContinent = (continentGroup: ListingGroup) => {
        if (continentGroup.continent) {
            Analytics.Event('Side Drawer', `Selected ${continentGroup.continent.name}`);
            dispatch(actionSetSelectedContinent(continentGroup));
        }
    };

    if (!continentsDictionary) {
        return (
            <PulseLoaderSkeleton>
                <PulseLoader iconSize="2rem" />
            </PulseLoaderSkeleton>
        );
    }

    return (
        <React.Fragment>
            <UnorderedList>
                {Array.from(continentsDictionary.values()).map((t) => {
                    if (!t.continent) return <React.Fragment />;

                    return (
                        <List
                            onMouseOver={() => setHighlightedContinent(t)}
                            onMouseOut={() => setHighlightedContinent(undefined)}
                            title={`Click to explore the ${t.tileLayers.length?.toLocaleString()} maps in ${
                                t.continent.name
                            }`}
                            key={t.continent.name}
                            onClick={() => handleSelectContinent(t)}
                        >
                            <Icon
                                src={t.continent.iconUrl}
                                alt={`Click to explore the ${t.tileLayers.length?.toLocaleString()} maps in ${
                                    t.continent.name
                                }`}
                            />
                            <Link>{t.continent.name}</Link>
                        </List>
                    );
                })}
            </UnorderedList>
        </React.Fragment>
    );
};

export default SelectContinent;

const Link = styled.a`
    color: #fff;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #fff;
        text-decoration: none;
    }
`;

const Icon = styled.img`
    width: 30px;
    height: 30px;
    margin: 0 12px 0 10px;
`;

const UnorderedList = styled.ul`
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    color: white;
    list-style-type: none;
    width: 100%;
`;

const List = styled.li`
    color: #fff;
    border: none;
    font-size: 16px;
    background-color: rgb(0, 0, 0, 0) !important;
    width: 100%;
    margin: 7px 0px;
    padding: 7px;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        border-radius: 6px;
        -webkit-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        -moz-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        img {
            transform: scale(0.90909);
        }
    }
`;

const PulseLoaderSkeleton = styled.div`
    min-height: 452px;
`;
