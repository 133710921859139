import React from 'react';
import AnnotationControl from './annotation-control';

interface OpenDrawToolsButtonProps {
    visible: boolean;
    onClick: () => void;
}

const OpenDrawToolsButton = (props: OpenDrawToolsButtonProps) => {
    if (!props.visible) return <React.Fragment />;

    return (
        <React.Fragment>
            <AnnotationControl
                id="draw-tool-button"
                active={true}
                disabled={false}
                visible={true}
                onClick={() => {
                    props.onClick();
                }}
                iconDark="/assets/annotations/icon-brush.svg"
                iconLight="/assets/annotations/icon-brush.svg"
                name="Open Stoaries"
                layout="isolated"
                width="60px"
                height="60px"
                iconPadding="0px"
                border="1.5px solid rgba(81, 81, 81)"
                dataTestId="annotation-control-open"
            />
        </React.Fragment>
    );
};

export default OpenDrawToolsButton;
