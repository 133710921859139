import L from 'leaflet';

import { defaultPolylineOptions } from '../Polyline/polyline';
import { defaultZIndex } from '../layers-util';
import { handleDeserializePatternFill, handlePattternIdFromPatternFillUrl } from '../FillPattern/pattern-util';

export type PolygonOtherOptions = {
    showArea: boolean;
    showSegmentsLength: boolean;
};

export default class PolygonPath {
    annotationType: string;
    id: string;
    zIndex: number;
    positions: L.LatLng[];
    options: L.PolylineOptions;
    fillPattern?: string;
    showArea: boolean;
    showLength: boolean;
    labelColor: 'white' | 'black' | 'yellow';
    units: 'metric' | 'imperial';

    constructor(
        id: string,
        positions: L.LatLng[],
        options: L.PolylineOptions,
        showArea: boolean,
        showLength: boolean,
        units: 'metric' | 'imperial',
        labelColor: 'white' | 'black' | 'yellow',
        fillPattern?: string
    ) {
        this.id = id;
        this.positions = positions;
        this.options = options;
        this.annotationType = 'Polygon';
        this.fillPattern = fillPattern;

        this.showArea = showArea;
        this.showLength = showLength;
        this.labelColor = labelColor;
        this.units = units;
    }

    static serialize = (polygon: PolygonPath): GeoJSON.Feature => {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [polygon.positions.map((position) => [position.lng, position.lat])],
            },
            properties: {
                annotationType: 'Polygon',
                id: polygon.id,
                options: polygon.options,
                zIndex: polygon.zIndex || defaultZIndex,
                showArea: polygon.showArea,
                showLength: polygon.showLength,
                units: polygon.units,
                labelColor: polygon.labelColor,
                fillPattern: polygon.fillPattern ? polygon.fillPattern : undefined,
            },
        };
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize = (json: any): PolygonPath => {
        const positions = (json.geometry.coordinates[0] as number[]).map((coordinate) => {
            return new L.LatLng(coordinate[1], coordinate[0]);
        });
        const { id, zIndex, showArea, showLength, units, labelColor } = json.properties;

        if (
            json.properties.options?.fillColor &&
            handlePattternIdFromPatternFillUrl(json.properties.options?.fillColor) !== 'none'
        ) {
            handleDeserializePatternFill(json);
        }

        return {
            annotationType: 'Polygon',
            positions: positions,
            id: id,
            zIndex: zIndex || defaultZIndex,
            options: {
                ...defaultPolygonOptions,
                ...json.properties.options,
            },
            ...defaultPolygonSupplement,
            showArea: showArea ? showArea : false,
            showLength: showLength ? showLength : false,
            labelColor: labelColor ? labelColor : 'black',
            units: units ? units : 'metric',
            fillPattern: json.properties.fillPattern ? json.properties.fillPattern : undefined,
        };
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserializeMultiPolygon = (json: any): PolygonPath[] => {
        const polygons: PolygonPath[] = [];
        const coordinates = json.geometry.coordinates;
        coordinates.forEach((coordinate) => {
            const positions = (coordinate[0] as number[]).map((coordinate) => {
                return new L.LatLng(coordinate[1], coordinate[0]);
            });
            const { id, zIndex, showArea, showLength, units, labelColor, ...polygonOptions } = json.properties;

            polygons.push({
                annotationType: 'Polygon',
                positions: positions,
                zIndex: zIndex || defaultZIndex,
                id: id,
                options: {
                    ...defaultPolygonOptions,
                    ...polygonOptions,
                },
                ...defaultPolygonSupplement,
                showArea: showArea ? showArea : false,
                showLength: showLength ? showLength : false,
                labelColor: labelColor ? labelColor : 'black',
                units: units ? units : 'metric',
                fillPattern: json.properties.fillPattern ? json.properties.fillPattern : undefined,
            });
        });
        return polygons;
    };
}

export const defaultPolygonOptions: L.PathOptions = {
    stroke: true,
    color: '#eed926',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: '#eed926',
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};

export const defaultPolygonSupplement: PolygonOtherOptions = {
    showArea: false,
    showSegmentsLength: false,
};

export const ghostPathOptions: L.PathOptions = {
    ...defaultPolylineOptions,
    interactive: true,
    color: 'transparent',
    weight: 30,
    opacity: 1.0,
    fill: false,
    fillColor: 'transparent',
};

export const selectedPolygonOutlineOptions: L.PathOptions = {
    interactive: false,
    color: 'rgba(255, 255, 255, 1.0)',
    weight: 1,
    fill: false,
};
