import { LatLng, LatLngBounds } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Polygon, useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { sampleTime } from 'rxjs/operators';
import ApiAnalytics from '../../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote } from '../../../../api/model';
import GeoUtil from '../../../../lib/geo-util';
import Analytics from '../../../../lib/user-analytics';
import {
    actionSetHighlightedListingGroup,
    actionSetBestFittingListingGroups,
    actionSetVisibleListingGroups,
} from '../../../../store/Map/MapSelection/actions';
import { ListingDictionary, ListingGroup } from '../../../../store/Map/MapSelection/model';
import {
    selectHighlightedListingGroup,
    selectHighlightedListingId,
} from '../../../../store/Map/MapSelection/selectors';
import { selectActiveAllMapIsFetching, selectActiveAllMapIsLoading } from '../../../../store/Map/SuperMap/selectors';
import HighlightedPolygonCluster from './highlighted-polygon-cluster';
import PolygonClusterUtil from './polygon-cluster-util';

/**
 * How often we will listen to the mouseMove event and re-calculate the highlighted tile layer.
 * Too large and highlighting a tile layer with your mouse creates a lagging effect
 * Too small and we might overload the browsers animation frame
 */
const MOUSE_EVENT_SAMPLE_TIME = 100; //ms;

/**
 * The number of polygons we render on the screen at any one time
 */
const MAX_NUMBER_OF_POLYGONS_IN_VIEWPORT = 10;

interface ViewportGroupedPolygonProps {
    listingDictionary: ListingDictionary | undefined;
    handleSelectListing: (id: number, listingGroup?: ListingGroup) => void;
}

const PolygonCluster = (props: ViewportGroupedPolygonProps) => {
    const map = useMap();
    const dispatch = useDispatch();

    const setVisibleListingGroups = (tileLayers: ListingGroup[]) => dispatch(actionSetVisibleListingGroups(tileLayers));
    const setBestFittingListingGroups = (tileLayers: ListingGroup[]) =>
        dispatch(actionSetBestFittingListingGroups(tileLayers));
    const setHighlightedListingGroup = (tileLayer: ListingGroup | undefined) =>
        dispatch(actionSetHighlightedListingGroup(tileLayer));
    const highlightedListingGroup = useSelector(selectHighlightedListingGroup);
    const highlightedListingId = useSelector(selectHighlightedListingId);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);
    const activeMapIsLoading = useSelector(selectActiveAllMapIsLoading);

    const [mousePosition] = useState(() => new Subject<LatLng>());
    const [sortedTileLayerDictionary, setSortedTileLayerDictionary] = useState<ListingGroup[]>([]);
    const [lastMousePosition, setLastMousePosition] = useState<LatLng | undefined>(undefined);

    const listingDictionaryRef = useRef(props.listingDictionary);

    // Hold a ref for the dictionary so it can update when the category changes without causing infinite loops in the useEffect
    // Word of caution - the useEffect dependencies are fickle.
    useEffect(() => {
        listingDictionaryRef.current = props.listingDictionary;
        filterAndSort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listingDictionary]);

    // Don't attempt to attach the event handlers until the map has loaded
    useEffect(() => {
        filterAndSort();

        map.on('moveend', onMoveEnd);
        map.on('mousemove', onMouseMove);

        return () => {
            map.off('moveend', onMoveEnd);
            map.off('mousemove', onMouseMove);
        };
        // Effect dependencies are disabled due to leaflet internal events (mouseMove, etc)
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    // Throttle the stream of mouse move events and check if the mouse is inside a polygon.
    useEffect(() => {
        const subscription = mousePosition.pipe(sampleTime(MOUSE_EVENT_SAMPLE_TIME)).subscribe((next) => {
            let highlightedTileLayerDictionary: ListingGroup | undefined = undefined;
            sortedTileLayerDictionary.slice(0, MAX_NUMBER_OF_POLYGONS_IN_VIEWPORT).forEach((t) => {
                if (t.latlngBounds.contains(next)) {
                    highlightedTileLayerDictionary = t;
                    return;
                }
            });

            // Because the polygons are already ordered from largest to smallest this will finish
            // on the smallest polygon that contains the mouse cursor
            setHighlightedListingGroup(highlightedTileLayerDictionary);
        });

        return () => {
            subscription.unsubscribe();
        };
        // Effect dependencies are disabled due to leaflet internal properties (bounds, etc)
    }, [sortedTileLayerDictionary]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (highlightedListingId) {
            const highlightedGroup = sortedTileLayerDictionary.find((groups) =>
                groups.tileLayers.find((t) => t.id === highlightedListingId)
            );
            setHighlightedListingGroup(highlightedGroup);
        }
        // Effect dependencies are disabled due to hoisted prop function (setHighlightedTileLayerGroup)
    }, [highlightedListingId]); //eslint-disable-line react-hooks/exhaustive-deps

    const tileLayersThatFitInsideBounds = (bounds: LatLngBounds): ListingGroup[] => {
        if (listingDictionaryRef.current) {
            const result = Array.from(listingDictionaryRef.current.values())
                .filter((t) => GeoUtil.contains(bounds, t.latlngBounds))
                .sort((a, b) => GeoUtil.quickArea(b.latlngBounds) - GeoUtil.quickArea(a.latlngBounds));
            return result;
        } else {
            return [];
        }
    };

    const filterAndSort = () => {
        if (listingDictionaryRef.current) {
            const bounds = map.getBounds();
            const listings = Array.from(listingDictionaryRef.current.values());

            // We hold on to the list groups that are completely inside the viewport to allow hovering
            const tileLayersInBounds = tileLayersThatFitInsideBounds(bounds);

            // These are the maps that are displayed in the sidedrawer or mobile bottom sheet
            const bestTileLayersForBounds = PolygonClusterUtil.sortListingGroupsByBestFit(listings, bounds);

            setSortedTileLayerDictionary(tileLayersInBounds);
            setVisibleListingGroups(tileLayersInBounds);
            setBestFittingListingGroups(bestTileLayersForBounds);
            setHighlightedListingGroup(undefined);
        }

        if (lastMousePosition) {
            mousePosition.next(lastMousePosition);
        }
    };

    // We cannot rely on the leaflet mouseOver events for highlighting a selected map as leaflets
    // event system doesn't respect z-index.  Instead we check on every mouse move tick for the
    // best match. Since the tile layers are already sorted from largest to smallest in the current
    // viewport, we can select the last one that contains the cursors position
    const onMouseMove = (e) => {
        mousePosition.next(e.latlng);
        setLastMousePosition(e.latlng);
    };

    // Force the filter and sort to occur off the animation thread just incase either the
    // re-render or the filter take a noticeably long time.
    const onMoveEnd = () => {
        Promise.resolve().then(filterAndSort);
    };

    const onSelectListing = (id: number, listingGroup?: ListingGroup) => {
        Analytics.Event('Map', 'Selected map', id);
        Analytics.Event('Main View', 'Clicked to view map', id);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.CLUSTER, id);
        // Invalidate the listing dictionary so they don't show while the ListingDTO is being fetched
        setVisibleListingGroups([]);
        props.handleSelectListing(id, listingGroup);
    };

    const weightForPolygon = (tileLayerGroup: ListingGroup): number => {
        if (!tileLayerGroup.tileLayers) return 1;
        if (tileLayerGroup.tileLayers.length === 1) return 2;
        if (tileLayerGroup.tileLayers.length < 5) return 3;
        if (tileLayerGroup.tileLayers.length < 15) return 4;
        return 3;
    };

    const colorForPolygon = (tileLayerGroup: ListingGroup): string => {
        if (!tileLayerGroup.tileLayers) return '#3388ff';
        if (tileLayerGroup.tileLayers.length < 3) return '#3388ff';
        if (tileLayerGroup.tileLayers.length < 5) return '#3388ff';
        return 'rgb(255, 255, 255)';
    };

    if (!props.listingDictionary) return <React.Fragment />;
    if (activeMapIsFetching || activeMapIsLoading) return <React.Fragment />;

    return (
        <React.Fragment>
            {highlightedListingGroup ? (
                <HighlightedPolygonCluster
                    highlightedListingGroup={highlightedListingGroup}
                    onNextMousePosition={(pos) => mousePosition.next(pos)}
                    onSelectListing={(listingId) => onSelectListing(listingId, highlightedListingGroup)}
                />
            ) : null}

            {sortedTileLayerDictionary.slice(0, MAX_NUMBER_OF_POLYGONS_IN_VIEWPORT).map((t) => {
                return (
                    <Polygon
                        key={t.tileLayers[0].id}
                        positions={GeoUtil.polygonForBounds(t.latlngBounds)}
                        weight={weightForPolygon(t)}
                        color={colorForPolygon(t)}
                        fillColor="transparent"
                        eventHandlers={{
                            click: () => {
                                if (isMobile) {
                                    if (t.tileLayers.length === 1) {
                                        onSelectListing(t.tileLayers[0].id);
                                    } else {
                                        setHighlightedListingGroup(t);
                                    }
                                }
                            },
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default PolygonCluster;
