import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Analytics from '../../../../../lib/user-analytics';
import useMapInteractionControl from '../../use-map-interaction-control';

const STORYMAP_PLAY_TIME = 3000;

interface StoryMapsControlProps {
    onNextPage: () => void;
    onPreviousPage: () => void;
    currentPageIndex: number;
    totalPages: number;
}

const StoryMapsControl = (props: StoryMapsControlProps) => {
    const [continuousPlay, setContinuousPlay] = useState<boolean>(false);

    // The StoryMapsSidedrawer is a child of the mapcontainer so we
    // disable the map events or it can not be interacted with it..
    const containerRef = useRef<HTMLDivElement | null>(null);
    useMapInteractionControl(containerRef);

    useEffect(() => {
        let timer: NodeJS.Timer;

        if (continuousPlay) {
            // eslint-disable-next-line prefer-const
            timer = setInterval(() => {
                if (props.currentPageIndex < props.totalPages - 1) {
                    props.onNextPage();
                } else {
                    setContinuousPlay(false);
                }
            }, STORYMAP_PLAY_TIME);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [continuousPlay, props]);

    return (
        <StoryMapsControlBar ref={containerRef}>
            <StoryMapsControlContainer>
                <StoryMapsPageIcon
                    rotate={180}
                    disabled={props.currentPageIndex === 0}
                    src="/assets/stoaries-icons/play.svg"
                    onClick={() => {
                        Analytics.Event('Draw Tools - Project', `Clicked on previous page`);
                        props.onPreviousPage();
                    }}
                    title="Previous"
                />
                <StoryMapsTitle>{`${props.currentPageIndex + 1} of ${props.totalPages}`}</StoryMapsTitle>
                <StoryMapsPageIcon
                    rotate={0}
                    disabled={props.currentPageIndex === props.totalPages - 1}
                    src="/assets/stoaries-icons/play.svg"
                    onClick={() => {
                        Analytics.Event('Draw Tools - Project', `Clicked on next page`);
                        props.onNextPage();
                    }}
                    title="Next"
                />
            </StoryMapsControlContainer>
        </StoryMapsControlBar>
    );
};

export default StoryMapsControl;

const StoryMapsControlBar = styled.div`
    display: flex;
    flex-direction: row;
    user-select: none;
    pointer-events: none;
    height: 45px;
    z-index: 99999 !important;
    position: absolute;
    bottom: 32px;
    left: calc(50vw - 75px);

    @media only screen and (max-width: 720px) {
        top: 10px;
        bottom: unset;
        left: calc(50vw - 75px);
    }
`;

const StoryMapsControlContainer = styled.div<{ margin?: string }>`
    margin: ${(props) => `${props.margin}` || '0'};
    padding: 4px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 11px 14px -7px, rgba(0, 0, 0, 0.24) 0px 23px 36px 3px,
        rgba(0, 0, 0, 0.22) 0px 9px 44px 8px;
    border: 1px solid rgb(81, 81, 81);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.8) !important;
    z-index: 9999;

    @media only screen and (max-width: 720px) {
        height: 39px;
        margin: 0px 0px 0px 5px;
    }
`;

const StoryMapsPageIcon = styled.img<{ rotate: number; disabled: boolean }>`
    width: 26px;
    margin: 4px 6px 4px 8px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    user-select: ${(props) => (props.disabled ? 'none' : 'auto')};
    transform: rotate(${(props) => props.rotate}deg);
    opacity: ${(props) => (props.disabled ? 0 : 1)};
`;

const StoryMapsTitle = styled.div`
    font-size: 22px;
    color: white;
    align-content: space-around;
    margin: 0px 4px;
`;
