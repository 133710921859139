import { defaultCircleOptions } from '../../components/MapView/Annotations/Circle/circle';
import { defaultPolygonOptions } from '../../components/MapView/Annotations/Polygon/polygon';
import { defaultRectangleOptions } from '../../components/MapView/Annotations/Rectangle/rectangle';
import { Pattern } from '../../components/MapView/Annotations/FillPattern/availablePatterns';
import store from '../store';
import { setArrowsAction } from './Arrow/actions';
import { setCirclesAction } from './Circle/actions';
import { setCoordinatesAction } from './Coordinate/actions';
import { setFreehandDrawsAction } from './Freehand/actions';
import { setImagesAction } from './Images/actions';
import { setMarkersAction } from './Marker/actions';
import { setPolylinesAction } from './Path/actions';
import { setPolygonsAction } from './Polygon/actions';
import { setRectanglesAction } from './Rectangle/actions';
import { setTextBoxesAction } from './TextBox/actions';
import { actionTypes as at } from './constants';
import { Annotation } from './reducer';
import { setMilitaryMarkersAction } from './MilitaryMarker/actions';

export const setProjectIdAction = (projectId: string) => {
    return {
        type: at.SET_PROJECT_ID,
        payload: { projectId: projectId },
    };
};

export const setProjectNameAction = (name: string) => {
    return {
        type: at.SET_PROJECT_NAME,
        payload: { name: name },
    };
};

export const deleteAllAnnotationsAction = () => {
    return (dispatch) => {
        // Dispatch the action to clear fill patterns
        dispatch(setDeleteAllFillPatternsAction());

        // Dispatch the action to clear all annotations
        dispatch({
            type: at.CLEAR_ALL,
            payload: {},
        });
        // TODO: This could be done better but calling an action never fires the clear in the present...
        store.getState().annotationDomain.present.freehandReducer.freehandDraws = [];
        store.getState().annotationDomain.present.markerReducer.markers = [];
        store.getState().annotationDomain.present.militaryMarkerReducer.militaryMarkers = [];
        store.getState().annotationDomain.present.pathReducer.polylines = [];
        store.getState().annotationDomain.present.polygonReducer.polygons = [];
        store.getState().annotationDomain.present.arrowReducer.arrows = [];
        store.getState().annotationDomain.present.circleReducer.circles = [];
        store.getState().annotationDomain.present.rectangleReducer.rectangles = [];
        store.getState().annotationDomain.present.textboxReducer.textBoxes = [];
        store.getState().annotationDomain.present.imageReducer.images = [];
        store.getState().annotationDomain.present.coordinateReducer.coordinates = [];

        store.getState().annotationDomain.present.polygonReducer.polygonOptions = defaultPolygonOptions;
        store.getState().annotationDomain.present.circleReducer.circleOptions = defaultCircleOptions;
        store.getState().annotationDomain.present.rectangleReducer.rectangleOptions = defaultRectangleOptions;

        return {
            type: at.CLEAR_ALL,
            payload: {},
        };
    };
};

export const setSelectedAnnotationAction = (annotation: Annotation) => {
    return {
        type: at.SET_SELECTED_ANNOTATION,
        payload: { selectedAnnotation: annotation },
    };
};

export const setZIndexAction = (zIndex: number) => {
    return {
        type: at.SET_CURRENT_Z_INDEX,
        payload: { zIndex: zIndex },
    };
};

export const incrementZIndexAction = () => {
    return {
        type: at.INCREMENT_CURRENT_Z_INDEX,
        payload: {},
    };
};

export const shuffleAllZIndexUpAction = () => {
    // Move every annotations zIndex up by 1
    // Useful if you need to set an annotation at the bottom layer
    const markers = store.getState().annotationDomain.present.markerReducer.markers;
    markers.forEach((marker) => {
        marker.zIndex = marker.zIndex + 1;
    });
    setMarkersAction(markers);

    const militaryMarkers = store.getState().annotationDomain.present.militaryMarkerReducer.militaryMarkers;
    militaryMarkers.forEach((milMarker) => {
        milMarker.zIndex = milMarker.zIndex + 1;
    });
    setMilitaryMarkersAction(militaryMarkers);

    const polylines = store.getState().annotationDomain.present.pathReducer.polylines;
    polylines.forEach((polyline) => {
        polyline.zIndex = polyline.zIndex + 1;
    });
    setPolylinesAction(polylines);

    const polygons = store.getState().annotationDomain.present.polygonReducer.polygons;
    polygons.forEach((polygon) => {
        polygon.zIndex = polygon.zIndex + 1;
    });
    setPolygonsAction(polygons);

    const arrows = store.getState().annotationDomain.present.arrowReducer.arrows;
    arrows.forEach((arrow) => {
        arrow.zIndex = arrow.zIndex + 1;
    });
    setArrowsAction(arrows);

    const circles = store.getState().annotationDomain.present.circleReducer.circles;
    circles.forEach((circle) => {
        circle.zIndex = circle.zIndex + 1;
    });
    setCirclesAction(circles);

    const rectangles = store.getState().annotationDomain.present.rectangleReducer.rectangles;
    rectangles.forEach((rectangle) => {
        rectangle.zIndex = rectangle.zIndex + 1;
    });
    setRectanglesAction(rectangles);

    const textBoxes = store.getState().annotationDomain.present.textboxReducer.textBoxes;
    textBoxes.forEach((textBox) => {
        textBox.zIndex = textBox.zIndex + 1;
    });
    setTextBoxesAction(textBoxes);

    const images = store.getState().annotationDomain.present.imageReducer.images;
    images.forEach((image) => {
        image.zIndex = image.zIndex + 1;
    });
    setImagesAction(images);

    const coordinates = store.getState().annotationDomain.present.coordinateReducer.coordinates;
    coordinates.forEach((coordinate) => {
        coordinate.zIndex = coordinate.zIndex + 1;
    });
    setCoordinatesAction(coordinates);

    const freehandDraws = store.getState().annotationDomain.present.freehandReducer.freehandDraws;
    freehandDraws.forEach((freehandDraw) => {
        freehandDraw.zIndex = freehandDraw.zIndex + 1;
    });
    setFreehandDrawsAction(freehandDraws);
};

export const setSelectedFillPatternAction = (patterns: Pattern[]) => {
    return {
        type: at.SET_ANNOTATION_FILL_PATTERNS,
        payload: { fillPatterns: patterns },
    };
};

export const setDeleteAllFillPatternsAction = () => {
    return {
        type: at.SET_ANNOTATION_FILL_PATTERNS,
        payload: { fillPatterns: [] },
    };
};

export const setDeleteFillPatternAction = (shapeId: string) => {
    const patterns = store.getState().annotationDomain.present.annotationReducer.fillPatterns;
    if (!patterns)
        return {
            type: at.SET_ANNOTATION_FILL_PATTERNS,
            payload: { fillPatterns: [] },
        };
    const newPatterns = patterns.filter((pattern) => pattern.shapeId !== shapeId);
    return {
        type: at.SET_ANNOTATION_FILL_PATTERNS,
        payload: { fillPatterns: newPatterns },
    };
};
