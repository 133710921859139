import Jazzicon, { jsNumberForAddress } from './Jazzicon';
import { CommentResultsDTO, UserDTO } from '../../../api/model';
import styled from 'styled-components';

interface UserAvatarProps {
    user: UserDTO | CommentResultsDTO | undefined;
    margin: string;
    diameter: number;
    onClick?: () => void;
}

const UserAvatar = ({ user, margin, diameter, onClick }: UserAvatarProps) => {
    const sharedStyles = {
        borderRadius: '50%',
        border: '2px solid #eed926',
        margin: margin,
    } as React.CSSProperties;

    const avatar = (user as UserDTO)?.avatarUrl || (user as CommentResultsDTO)?.userAvatar || undefined;
    const name = (user as UserDTO)?.name || (user as CommentResultsDTO)?.userName || 'Soar User';

    if (!user) {
        const dateAsSeed = new Date().getTime().toString();
        return (
            <UserIconContainer onClick={onClick}>
                <Jazzicon
                    paperStyles={sharedStyles}
                    diameter={diameter}
                    seed={jsNumberForAddress(dateAsSeed)}
                    data-cy="profile-avatar"
                />
            </UserIconContainer>
        );
    }

    if (!avatar) {
        return (
            <UserIconContainer onClick={onClick}>
                <Jazzicon
                    paperStyles={sharedStyles}
                    diameter={diameter}
                    seed={jsNumberForAddress(user.userId)}
                    data-cy="profile-avatar"
                />
            </UserIconContainer>
        );
    }

    return (
        <img
            src={avatar}
            alt={name}
            style={{
                width: diameter,
                height: diameter,
                ...sharedStyles,
            }}
            data-cy="profile-avatar"
            onClick={onClick}
        />
    );
};

export default UserAvatar;

const UserIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
