import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../../../../Shared/styled-button';

interface ProjectPermissionsUserEmailInputProps {
    onAddUser: (email: string) => void;
    onAddUsers: (emails: string[]) => void;
    invitedUserEmails: string[];
}

const ProjectPermissionsUserEmailInput = (props: ProjectPermissionsUserEmailInputProps) => {
    const [email, setEmail] = useState<string>('');
    const [emailFormatError, setEmailFormatError] = useState<'Invalid Email' | 'User is already invited' | undefined>(
        undefined
    );

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setEmailFormatError(undefined);
        if (e.key === 'Enter') {
            handleUserEmail(email);
        }
        if (e.key === ',') {
            e.preventDefault();
            handleUserEmail(email);
        }
    };

    const handlePasteInput = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const emails: string[] = e.clipboardData
            .getData('Text')
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email.length > 3 && email.includes('@') && email.includes('.'))
            .filter((email) => !props.invitedUserEmails.includes(email));

        props.onAddUsers(emails);
        e.preventDefault();
        setEmail('');
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value);
    };

    const handleUserEmail = (email: string) => {
        if (props.invitedUserEmails.includes(email)) {
            setEmailFormatError('User is already invited');
            setEmail('');
        } else if (email.length > 3 && email.includes('@') && email.includes('.')) {
            props.onAddUser(email);
            setEmail('');
            setEmailFormatError(undefined);
        } else {
            setEmailFormatError('Invalid Email');
        }
    };

    return (
        <React.Fragment>
            <Text>Add user</Text>
            <EmailContainer>
                <TextField
                    value={email}
                    onChange={handleInput}
                    onKeyDown={handleKeyPress}
                    onPaste={handlePasteInput}
                    type="text"
                    placeholder="Enter email"
                />
                <Button onClick={() => handleUserEmail(email)}>Invite</Button>
            </EmailContainer>
            {emailFormatError ? <ErrorText>{emailFormatError}</ErrorText> : null}
        </React.Fragment>
    );
};

export default ProjectPermissionsUserEmailInput;

const EmailContainer = styled.div`
    display: flex;
    margin: 0 0 0 12px;
`;

const Text = styled.p`
    color: white;
    margin: 12px 0 4px 4px;
`;

const ErrorText = styled.p`
    color: #ff0000 !important;
    margin: 0px 0 0px 12px;
`;

const TextField = styled.input`
    display: block;
    width: 275px;
    height: 32px;
    font-size: 1rem;
    background: transparent;
    padding: 4px;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid rgb(255 255 255 / 0.3) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    margin-right: 8px;

    :active,
    :focus,
    :focus-visible {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6) !important;
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3) !important;
        color: rgba(255, 255, 255, 0.6) !important;
        outline: none;
    }
`;

const Button = styled(StyledButton)`
    height: 32px;
    padding: 5px 25px;
`;
