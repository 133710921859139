import PolygonPath from '../../../components/MapView/Annotations/Polygon/polygon';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface PolygonState {
    polygons: PolygonPath[];
    polygonOptions?: L.PolylineOptions;
    projectChanged?: boolean;
}

const initialState: PolygonState = {
    polygons: [],
    polygonOptions: undefined,
    projectChanged: false,
};

export default (state: PolygonState = initialState, action: Action): PolygonState => {
    switch (action.type) {
        case at.SET_POLYGONS:
            return { ...state, polygons: action.payload.polygons, projectChanged: true };

        case at.SET_POLYGON_OPTIONS:
            return { ...state, polygonOptions: action.payload };

        case at.SET_POLYGON_ADD:
            return {
                ...state,
                polygons: [...state.polygons, action.payload],
                projectChanged: true,
            };

        case at.SET_POLYGON_UPDATE: {
            const newPolygons = state.polygons.map((polygon) => {
                if (polygon.id === action.payload.id) {
                    return { ...polygon, ...action.payload };
                }
                return polygon;
            });
            return { ...state, polygons: newPolygons, projectChanged: true };
        }

        case at.SET_POLYGON_DELETE: {
            const newPolygons = state.polygons.filter((polygon) => polygon.id !== action.payload.id);
            return { ...state, polygons: newPolygons, projectChanged: true };
        }

        default:
            return state;
    }
};
