import { ReactNode } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';

export interface SoarModalProps {
    title?: string | JSX.Element;
    isOpen: boolean;
    toggle?: () => void;
    width?: string;
    marginTop?: string;
    onOpen?: () => void;
    onClose?: () => void;
    children?: ReactNode;
    // backdrop: boolean | 'static';
    disabledClose?: boolean;
    className?: string;
}

const SoarModal = (props: SoarModalProps) => {
    const {
        title,
        isOpen,
        toggle,
        width,
        marginTop,
        onOpen,
        onClose,
        children,
        disabledClose = false,
        className,
    } = props;
    return (
        <StyledModal
            isOpen={isOpen}
            toggle={toggle}
            zIndex={100000}
            width={width}
            margin={marginTop}
            backdrop={disabledClose ? 'static' : true}
            className={className}
            onOpened={onOpen}
        >
            <StyledHeader>
                {(toggle || onClose) && (
                    <StyledCloseIcon
                        className={`${disabledClose && 'disabled'}`}
                        onClick={(e) => {
                            const closeIcon = e.target as HTMLDivElement;
                            if (!closeIcon.classList.contains('disabled')) {
                                if (toggle) {
                                    toggle();
                                }
                                if (onClose) {
                                    onClose();
                                }
                            }
                        }}
                    />
                )}
                {title}
            </StyledHeader>
            <StyledBody>{children}</StyledBody>
        </StyledModal>
    );
};

export default SoarModal;

// eslint-disable-next-line
const StyledModal = styled<any>(Modal)`
    width: ${(props) => (props.width ? props.width : '')};
    max-width: ${(props) => (props.width ? props.width : '500px')};
    margin-top: ${(props) =>
        props.margin ? props.margin : '100px'}; // this is set but possibly needs to be percent for mobile?
    border: 1px solid rgb(255 255 255 / 0.3);
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    margin-bottom: 20px;

    div.modal-content {
        background-color: transparent !important;
        margin: 0 auto;
    }

    @media (max-width: 650px) {
        width: 100% !important;
        margin-top: 50px; // this is set but possibly needs to be percent for mobile?
    }
`;

const StyledHeader = styled(ModalHeader)`
    color: rgba(255, 255, 255, 0.6) !important;
    border-bottom: 1px solid rgb(255 255 255 / 0.3);

    h5 {
        color: white;
        font-size: 25px;
        width: 100%;
        text-align: left;

        span {
            color: white;
        }
        span.small {
            font-size: 20px;
        }
    }

    span {
        color: inherit;
        font-size: 25px;
    }
`;

const StyledBody = styled.div`
    color: inherit !important;

    span {
        color: #eed926;
    }
`;

const StyledCloseIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-top: 10px;
    cursor: pointer;
    float: right;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export const StyledModalBody = styled(ModalBody)`
    color: white;
    font-size: 16px;

    p {
        color: white;
    }
`;

export const StyledModalFooter = styled(ModalFooter)<{ borderTop?: boolean }>`
    border-top: ${(props) => (props.borderTop ? '1px solid rgb(255 255 255 / 0.3)' : 'none')};
    justify-content: right;
    padding-bottom: 20px;

    p {
        color: white;
    }
`;
