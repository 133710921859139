import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import ShareService from '../../../../lib/share-service';

const ShareMobileAppButton = () => {
    const selectedMap = useSelector(selectActiveAllMap);

    if (!selectedMap) return <React.Fragment />;

    const handleClickShareButton = () => {
        const url = ShareService.shareMobileAppUrl(selectedMap);
        window.open(url);
    };

    return <ShareIcon onClick={handleClickShareButton} src="/assets/sharing/icon-share-white.svg" />;
};

export default ShareMobileAppButton;

const ShareIcon = styled.img`
    color: white;
    font-size: 18px;
    margin: 0px 0px 0px 8px;
    pointer-events: all;
    cursor: pointer;
    width: 18px;
`;
