import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../Shared/soar-modal';
import { StyledButton } from '../../../Shared/styled-button';
import UriHelper from '../../../../lib/uri-helper';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';
import { LoginModalMode } from '../../../Registration/login-enum';

export interface ProjectAccessErrorProps {
    code: string;
    message: string;
}

interface ProjectAccessProps {
    error?: string;
    resetError?: () => void;
}

const ProjectAccess = ({ error, resetError }: ProjectAccessProps) => {
    const userLoggedIn = useSelector(selectLoggedIn);

    const [showErrorMessage, setShowErrorMessage] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [accessError, setAccessError] = useState<ProjectAccessErrorProps | undefined>();

    useEffect(() => {
        if (error) {
            const errorResponse = error.split(':');
            const errorCode = errorResponse[0];
            const errorMessage = errorResponse[1];
            setAccessError({
                code: errorCode,
                message: errorMessage,
            });

            if (errorCode === '403' && !userLoggedIn) {
                setShowLogin(true);
            }
        }
    }, [error, userLoggedIn]);

    const handleCloseModal = () => {
        resetError && resetError();
        setShowErrorMessage(false);
        setShowLogin(false);
        setAccessError(undefined);
        UriHelper.navigateToPath('/maps');
    };

    if (!accessError) {
        return <React.Fragment />;
    }

    if (showLogin) {
        return (
            <LoginRegisterDialog
                isOpen={showLogin}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => setShowLogin(false)}
                onLoginSuccess={() => setShowLogin(false)}
            />
        );
    }

    const handleErrorMessageDisplay = () => {
        if (accessError.code === '403') {
            return (
                <React.Fragment>
                    <ProjectAccessIcon src="/assets/floating-drawer-icons/icon-info.svg" alt="Info" />
                    {!isMobileVersion && <ProjectAccessTitle>Oops!</ProjectAccessTitle>}
                    <ProjectAccessMessage>
                        This is a private stoary. You will need access from the original creator to view.
                    </ProjectAccessMessage>
                </React.Fragment>
            );
        } else {
            return <ProjectAccessMessage className="unknown-error">{accessError.message}</ProjectAccessMessage>;
        }
    };

    return (
        <ProjectAccessModal isOpen={showErrorMessage} toggle={handleCloseModal} title="Stoaries">
            <ProjectAccessContainer>
                <ProjectAccessContent>{handleErrorMessageDisplay()}</ProjectAccessContent>
            </ProjectAccessContainer>
            <ProjectAccessFooter>
                <StyledButton onClick={handleCloseModal}>OK</StyledButton>
            </ProjectAccessFooter>
        </ProjectAccessModal>
    );
};

export default ProjectAccess;

const ProjectAccessModal = styled(SoarModal)`
    width: 420px;
`;

const ProjectAccessFooter = styled(StyledModalFooter)`
    @media only screen and (max-width: 600px) {
        justify-content: center;
    }
`;

const ProjectAccessContainer = styled(StyledModalBody)`
    display: flex;

    & + ${ProjectAccessFooter} {
        padding-top: 0;
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const ProjectAccessContent = styled.div`
    text-align: left;
    padding: 12px;

    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const ProjectAccessIcon = styled.img`
    float: left;
    width: 48px;
    height: 48px;
    margin: 12px 8px 12px 6px;

    @media only screen and (max-width: 600px) {
        width: 64px;
        height: 64px;
        margin: 0 auto;
    }
`;

const ProjectAccessTitle = styled.h3`
    text-align: left;
    color: #fff;
    margin: 0;
    font-size: 18px;
`;

const ProjectAccessMessage = styled.p`
    text-align: left;
    color: #fff;
    margin: 0;

    &.unknown-error {
        color: #f44336;
    }

    @media only screen and (max-width: 600px) {
        text-align: center;
        margin-top: 5px;
    }
`;
