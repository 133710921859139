import FreehandPolyline from '../../../components/MapView/Annotations/FreehandPolyline/freehand-polyline';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface FreehandState {
    freehandDraws: FreehandPolyline[];
    freehandDrawOptions?: L.PolylineOptions;
    projectChanged?: boolean;
}

const intitialState: FreehandState = {
    freehandDraws: [],
    freehandDrawOptions: undefined,
    projectChanged: false,
};

export default (state: FreehandState = intitialState, action: Action): FreehandState => {
    switch (action.type) {
        case at.SET_FREEHAND_DRAWS:
            return { ...state, freehandDraws: action.payload, projectChanged: true };

        case at.SET_FREEHAND_DRAW_OPTIONS:
            return { ...state, freehandDrawOptions: action.payload };

        case at.SET_FREEHAND_DRAW_ADD:
            return {
                ...state,
                freehandDraws: [...state.freehandDraws, action.payload],
                projectChanged: true,
            };

        case at.SET_FREEHAND_DRAW_UPDATE: {
            const newFreehandDraws = state.freehandDraws.map((freehandDraw) => {
                if (freehandDraw.id === action.payload.id) {
                    return { ...freehandDraw, ...action.payload };
                }
                return freehandDraw;
            });
            return { ...state, freehandDraws: newFreehandDraws, projectChanged: true };
        }

        case at.SET_FREEHAND_DRAW_DELETE: {
            const newFreehandDraws = state.freehandDraws.filter(
                (freehandDraw) => freehandDraw.id !== action.payload.id
            );
            return { ...state, freehandDraws: newFreehandDraws, projectChanged: true };
        }

        default:
            return state;
    }
};
