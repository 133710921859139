import styled from 'styled-components';

export const NotificationsRead = () => {
    return (
        <Container>
            <Row>
                <Icon src="/assets/notifications/no-notifications.png" />
                <Title>You don't have any new notifications</Title>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    color: rgb(255 255 255 / 67%);
    background-color: transparent;
    text-align: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0 -30px 0;
`;

const Icon = styled.img`
    margin: 4px;
    width: 39px;
    height: 37px;
`;

const Title = styled.p`
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    text-align: left;
    margin-bottom: 0;
    align-self: center;
`;
