import React from 'react';
import styled from 'styled-components';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../Shared/soar-modal';
import { StyledDarkButton, StyledDeleteButton } from '../../../Shared/styled-button';
import Analytics from '../../../../lib/user-analytics';

interface DeleteConfirmModalProps {
    isOpen: boolean;
    title: string;
    message: string;
    onToggle: () => void;
    onConfirm: () => void;
    isExistingProject: boolean;
    cancelText?: string;
    confirmText?: string;
}

const DeleteConfirmModal = (props: DeleteConfirmModalProps) => {
    const onDeleteAll = () => {
        Analytics.Event('Draw Tools - Project', `Clicked delete page`);
        props.onConfirm();
        props.onToggle();
    };

    return (
        <SoarModal title={props.title} isOpen={props.isOpen} toggle={props.onToggle} width="600px">
            <StyledModalBody>{props.message}</StyledModalBody>
            <DeleteAllModalFooter>
                <RightGroup>
                    <StyledDarkButton onClick={props.onToggle}>
                        {props.cancelText ? props.cancelText : 'CANCEL'}
                    </StyledDarkButton>
                    <StyledDeleteButton onClick={onDeleteAll}>
                        {props.confirmText ? props.confirmText : 'DELETE'}
                    </StyledDeleteButton>
                </RightGroup>
            </DeleteAllModalFooter>
        </SoarModal>
    );
};

export default DeleteConfirmModal;

const LeftGroup = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const RightGroup = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DeleteAllModalFooter = styled(StyledModalFooter)`
    display: flex;
    justify-content: space-between;

    & ${LeftGroup}, & ${RightGroup} {
        &:only-child {
            margin-left: auto;
        }

        button:not(:last-child) {
            margin-right: 0.25rem;
        }
        button:not(:first-child) {
            margin-left: 0.25rem;
        }
    }
`;
