import React from 'react';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { ListingCategories, ListingType } from '../../api/model';
import { inputMaxValues } from '../../lib/limitation-constants';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { MarkdownTextArea } from '../Shared/markdown-textarea';
import { Multiselect } from '../Shared/multi-select';
import { ScrollableContainer } from '../Shared/scrollable-container';
import { StyledButton, StyledTransButton, TransparentButton } from '../Shared/styled-button';
import TagInputField from '../Shared/tag-input-field';

interface UploadMapMetadataProps {
    onClickBack: () => void;
    submitted: (
        title: string,
        description: string,
        tags: string[],
        categories: string[],
        hasCheckedTerms: boolean,
        attachment?: File
    ) => void;
    fileName: string;
    title?: string;
    description?: string;
    tags?: string[];
    categories?: string[];
    listingType: ListingType;
}

const UploadMapMetadata = (props: UploadMapMetadataProps) => {
    const [tags, setTags] = useState<string[]>(props.tags ? props.tags : []);
    const [title, setTitle] = useState<string | undefined>(props.title ? props.title : '');
    const [titleLength, setTitleLength] = useState<number>(props.title ? props.title.length : 0);
    const [description, setDescription] = useState<string>(props.description ? props.description : '');
    const [descriptionLength, setDescriptionLength] = useState<number>(
        props.description ? UserHelper.getWordCount(description) : 0
    );
    const [categories, setCategories] = useState<string[]>(props.categories ?? []);
    const [hasCheckedTerms, setHasCheckedTerms] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [file, setFile] = useState<File | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);

    const checkForm = () => {
        if (!title || !description || categories.length === 0 || categories.length > 3 || !hasCheckedTerms) {
            return false;
        } else {
            props.submitted(title, description, tags, categories, hasCheckedTerms, file);
            return true;
        }
    };

    const setImageTitle = (title: string) => {
        if (title.length < inputMaxValues.MAX_TITLE_CHARACTERS) {
            setTitle(title);
            setTitleLength(title.length);
        }
    };

    const setImageDescription = (description: string) => {
        const descriptionCount = UserHelper.getWordCount(description);
        if (descriptionCount < inputMaxValues.MAX_DESCRIPTION_WORDS) {
            setDescription(description);
            setDescriptionLength(descriptionCount);
        }
    };

    const options = Object.keys(ListingCategories).map((c) => {
        return { value: c, label: ListingCategories[c].title };
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            e.target.value = '';
        }
    };

    return (
        <ScrollableContainer>
            <Title>Map Details</Title>
            <Content className="d-flex justify-content-between">
                {props.fileName && (
                    <Label className="filename overflow-auto text-break">
                        File name: <strong>{props.fileName}</strong>
                    </Label>
                )}
            </Content>
            <Content>
                <Label>
                    Title <span>*</span>
                </Label>
                <div className="d-flex" style={{ margin: '0px 1px' }}>
                    <TextField
                        type="text"
                        value={title}
                        onChange={(e) => setImageTitle(e.target.value)}
                        placeholder="Give your map a catchy title"
                        disabled={titleLength === inputMaxValues.MAX_TITLE_CHARACTERS ? true : false}
                    />
                </div>

                <CharacterCount characters={titleLength} maxCharacters={inputMaxValues.MAX_TITLE_CHARACTERS - 1}>
                    Characters: {titleLength}/{inputMaxValues.MAX_TITLE_CHARACTERS - 1}
                </CharacterCount>
                {!formValid && !title && <Error>Please enter a display name</Error>}

                <Label>
                    Description <span>*</span>
                </Label>

                <MarkdownTextArea value={description} setInputValue={setImageDescription} initialEditorHeight={120} />

                <CharacterCount characters={descriptionLength} maxCharacters={inputMaxValues.MAX_DESCRIPTION_WORDS - 1}>
                    Words: {descriptionLength}/{inputMaxValues.MAX_DESCRIPTION_WORDS - 1}
                </CharacterCount>
                {!formValid && !description && <Error>Please provide a description</Error>}

                <Label>
                    Categories <span>*</span>
                </Label>
                <Multiselect
                    dataTestId="upload-metadata-categories"
                    options={options}
                    onChange={(values) => setCategories(values.map((c) => c.value))}
                    defaultValue={options.filter((o) => categories.includes(o.value))}
                />
                {!formValid && categories.length === 0 ? (
                    <Error>Please choose at least one category</Error>
                ) : !formValid && categories.length > 3 ? (
                    <Error>Please choose no more than 3 categories</Error>
                ) : null}
                <div className="mt-3"></div>

                <Label>Tags (optional)</Label>
                <TagInputField onTagInput={(tags) => setTags(tags)} initTags={tags} />
                <CharacterCount characters={tags.length} maxCharacters={inputMaxValues.MAX_TAG_COUNT - 1}>
                    Tags: {tags.length}/{inputMaxValues.MAX_TAG_COUNT - 1}
                </CharacterCount>

                <input
                    style={{ display: 'none' }}
                    ref={inputRef}
                    type="file"
                    accept=".jpg,.png"
                    onChange={handleFileChange}
                />

                <Label className="mr-2 d-inline-block">Legend:</Label>
                {file === undefined ? (
                    <Button onClick={() => inputRef.current?.click()} title="Add">
                        <i className="fa fa-paperclip mr-1"></i>Attach image
                    </Button>
                ) : (
                    <React.Fragment>
                        <i className="fa fa-paperclip mr-2"></i>
                        {file.name}
                        <TrashButton className="ml-3" onClick={() => setFile(undefined)} title="Remove attachment">
                            <i className="fa fa-trash"></i>
                        </TrashButton>
                    </React.Fragment>
                )}

                <Agreement>
                    <label>
                        <Checkbox
                            type="checkbox"
                            defaultChecked={hasCheckedTerms}
                            onChange={() => setHasCheckedTerms(!hasCheckedTerms)}
                        />
                        By submitting this map/image you agree that the content is your own work and/or you have right
                        to publish this content.{' '}
                    </label>
                    <br />
                    For further information refer to our{' '}
                    <A href="https://about.soar.earth/terms.html" target="_blank">
                        Terms of Service
                    </A>
                </Agreement>
                {!formValid && !hasCheckedTerms && (
                    <Error>Please confirm you have the rights to publish this content</Error>
                )}
                <div className="d-flex mt-4 justify-content-between">
                    <StyledTransButton onClick={() => props.onClickBack()}>
                        <i className="fa fa-chevron-left mr-2"></i>BACK
                    </StyledTransButton>

                    <StyledButton
                        className="mr-0 pl-4 pr-4"
                        onClick={() => {
                            setFormValid(checkForm());
                            Analytics.Event('Upload', 'Clicked to upload');
                        }}
                    >
                        UPLOAD
                        <i className="fa fa-upload ml-2"></i>
                    </StyledButton>
                </div>
            </Content>
        </ScrollableContainer>
    );
};

export default UploadMapMetadata;

const Title = styled.h3`
    flex: 0 1 auto;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    text-align: left;
    font-weight: 400;
    color: rgb(179, 179, 179);
`;

const TextField = styled.input`
    display: block;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    padding: 4px;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 0.25rem;
    margin: 0px 1px;
`;

const Label = styled.p`
    display: block;
    color: white;
    font-size: 16px;
    margin: 4px 0px;
    strong {
        color: rgb(179, 179, 179);
    }
    span {
        color: red;
    }
    &.filename {
        font-size: 14px;
    }
`;

const Error = styled.p`
    color: red;
    top: -10px;
`;

interface CharacterCountProps {
    characters: number;
    maxCharacters: number;
}

const CharacterCount = styled.span<CharacterCountProps>`
    color: ${(props) =>
        props.characters >= props.maxCharacters ? 'rgba(255, 0, 0, 0.7);' : 'rgba(255, 255, 255, 0.5);'};
    font-size: 10px;
    text-align: right;
    display: block;
`;

const Agreement = styled.p`
    display: block;
    color: white;
    margin: 8px 0 4px 0;
`;

const Checkbox = styled.input`
    margin-right: 5px;
    display: inline-block;
`;

const A = styled.a`
    display: inline-block;
    color: #eed926;
    text-decoration: underline !important;
    font-style: italic;
`;

const Button = styled(TransparentButton)`
    display: inline-block;
    border: none !important;
    padding: 0px;
    text-transform: none;
`;

const TrashButton = styled(Button)`
    color: red !important;
    -webkit-text-fill-color: red !important;
`;
