import React from 'react';
import styled from 'styled-components';

interface ThinDrawerHeaderProps {
    title: string;
    subTitle: string;
    handleBack?: () => void;
}

/**
 * ThinDrawerHeader
 * Standardized header for thin drawer
 * @required title string
 * @required subTitle string
 * @optional handleBack () => void
 */

const ThinDrawerHeader = (props: ThinDrawerHeaderProps) => {
    return (
        <ThinDrawerHeaderContainer>
            {props.handleBack ? (
                <HeaderButton onClick={() => props.handleBack && props.handleBack()} data-testid="sentinel-back-button">
                    <BackArrow />
                </HeaderButton>
            ) : null}
            <Title>{props.title}</Title>
            <Subtitle>{props.subTitle}</Subtitle>
        </ThinDrawerHeaderContainer>
    );
};

export default ThinDrawerHeader;

const Title = styled.h1`
    font-size: 22px;
    color: white;
    padding: 10px;
    flex: 1;
    margin: 0;
`;

const Subtitle = styled.span`
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    display: block;
    margin-top: 4px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 1rem;
`;

const HeaderButton = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    cursor: pointer;
    position: absolute;

    & + ${Title} {
        padding: 10px 42px;
    }
`;

const ThinDrawerHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin: 8px;
    float: left;
`;
