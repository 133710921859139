import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListingDTO } from '../../../../api/model';
import Constants from '../../../../constants';
import { selectIsMapMoving, selectMapBounds } from '../../../../store/App/selectors';
import EmbeddedMapCode from './embedded-map-code';
import EmbeddedMapSettings from './embedded-map-settings';

interface EmbeddedMapDialogBodyProps {
    tileLayer: ListingDTO;
    embedKey: number;
}

const EmbeddedMapDialogBody = (props: EmbeddedMapDialogBodyProps) => {
    const selectedBounds = useSelector(selectMapBounds);
    const isMapMoving = useSelector(selectIsMapMoving);

    const isTest = process.env.REACT_APP_ENVIRONMENT === 'test';

    // TODO: Fix this data structure
    const [configuration, setConfiguration] = useState<Record<string, unknown>>({
        embedKey: props.embedKey.toString(),
    });

    useEffect(() => {
        if (selectedBounds && isTest) {
            setConfiguration({ ...configuration, ['bounds']: selectedBounds, ['isTest']: true });
        } else {
            setConfiguration({ ...configuration, ['bounds']: selectedBounds });
        }
        // I've disabled this lint rule because we are deriving state from redux and
        // combining it into an un-typed dictionary as local state.  This needs fixing in the above TODO.
        // Since a useSelector value isn't available until the component renders it cannot be used
        // as a default value in useState so you need to create an effect for it which has a dependency of itself...
    }, [selectedBounds]); // eslint-disable-line react-hooks/exhaustive-deps

    const [embeddedMapContainerWidthCSS, setEmbeddedMapContainerWidthCSS] = useState(
        Constants.EMBEDDED_MAP.DEFAULT_WIDTH
    );
    const [embeddedMapContainerHeightCSS, setEmbeddedMapContainerHeightCSS] = useState(
        Constants.EMBEDDED_MAP.DEFAULT_HEIGHT
    );

    return (
        <React.Fragment>
            <EmbeddedMapCode
                tileLayerId={props.tileLayer.id}
                configuration={JSON.stringify(configuration, null, 4)}
                containerWidthCSS={embeddedMapContainerWidthCSS}
                containerHeightCSS={embeddedMapContainerHeightCSS}
                enabled={!isMapMoving}
            />
            <EmbeddedMapSettings
                configuration={configuration}
                setConfiguration={setConfiguration}
                handleChangeContainerWidthCSS={(css) => setEmbeddedMapContainerWidthCSS(css)}
                handleChangeContainerHeightCSS={(css) => setEmbeddedMapContainerHeightCSS(css)}
            />
        </React.Fragment>
    );
};

export default EmbeddedMapDialogBody;
