import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSideDrawerCollapse } from '../../../../store/SideDrawer/selectors';
import { StyledDrawerContentContainer } from './drawer-styles';

interface DrawerContentProps {
    children: React.ReactNode;
    className?: string;
}

/**
 * DrawerContent
 * Standardized drawer content to control height and scroll behavior
 * @required children React.ReactNode
 * @optional className string
 */

const DrawerContent = (props: DrawerContentProps) => {
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    return (
        <DrawerContentContainer className={props.className} closeDraw={sideDrawerCollapse}>
            {props.children}
        </DrawerContentContainer>
    );
};

export default DrawerContent;

interface DrawerContentContainerProps {
    closeDraw?: boolean;
}

// TODO this is starting to become a little messy and needs reviewing
export const DrawerContentContainer = styled(StyledDrawerContentContainer)<DrawerContentContainerProps>`
    min-height: ${(props) => (props.closeDraw ? '0px' : '215px')};
    max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 262px)')};
    overflow: ${(props) => (props.closeDraw ? 'hidden' : '')};

    &.display-comment-input {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 442px)')};
    }

    &.hide-display-comment-input {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 337px)')};
    }

    &.satellite-container {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 296px)')};
    }

    &.draw-stoaries-container {
        min-height: auto;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 310px)')};
    }

    &.draw-stoaries-container-active-map {
        min-height: auto;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 425px)')};
    }
`;
