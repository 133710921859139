import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import ThinDrawer from '../../../../Drawer/Shared/Drawer/thin-drawer';
import { DrawProjectDrawerHeader, DrawProjectDrawerContent } from '../../../../Drawer/drawer-control';
import GeoJSONProject, { Project } from '../../Project/geojson-project';
import { DrawPage } from '../draw-story';
import StoryBuilderAddPage from './story-builder-add-page';
import StoryBuilderIntroduction from './story-builder-introduction';
import StoryBuilderPageItem from './story-builder-page-item';
import StoryBuilderSaveButton from './story-builder-save-button';

import { setArrowsAction } from '../../../../../store/Annotations/Arrow/actions';
import { selectArrows } from '../../../../../store/Annotations/Arrow/selectors';
import { setCirclesAction } from '../../../../../store/Annotations/Circle/actions';
import { selectCircles } from '../../../../../store/Annotations/Circle/selectors';
import { setCoordinatesAction } from '../../../../../store/Annotations/Coordinate/actions';
import { selectCoordinates } from '../../../../../store/Annotations/Coordinate/selectors';
import { setFreehandDrawsAction } from '../../../../../store/Annotations/Freehand/actions';
import { selectFreehandDraws } from '../../../../../store/Annotations/Freehand/selectors';
import { setImagesAction } from '../../../../../store/Annotations/Images/actions';
import { selectImages } from '../../../../../store/Annotations/Images/selectors';
import { setMarkersAction } from '../../../../../store/Annotations/Marker/actions';
import { selectMarkers } from '../../../../../store/Annotations/Marker/selectors';
import { setMilitaryMarkersAction } from '../../../../../store/Annotations/MilitaryMarker/actions';
import { selectMilitaryMarkers } from '../../../../../store/Annotations/MilitaryMarker/selectors';
import { setPolylinesAction } from '../../../../../store/Annotations/Path/actions';
import { selectPolylines } from '../../../../../store/Annotations/Path/selectors';
import { setPolygonsAction } from '../../../../../store/Annotations/Polygon/actions';
import { selectPolygons } from '../../../../../store/Annotations/Polygon/selectors';
import { setRectanglesAction } from '../../../../../store/Annotations/Rectangle/actions';
import { selectRectangles } from '../../../../../store/Annotations/Rectangle/selectors';
import { selectTextBoxes } from '../../../../../store/Annotations/TextBox/selectors';
import { setSelectedAnnotationAction } from '../../../../../store/Annotations/actions';
import { selectMapBounds } from '../../../../../store/App/selectors';

import Arrow from '../../Arrow/arrow';
import Circle from '../../Circle/circle';
import Coordinate from '../../Coordinate/coordinate';
import FreehandPolyline from '../../FreehandPolyline/freehand-polyline';
import Image from '../../ImageTool/image';
import Marker from '../../Marker/marker';
import MilitaryMarker from '../../MilitaryMarker/military-marker';
import PolygonPath from '../../Polygon/polygon';
import Polyline from '../../Polyline/polyline';
import Rectangle from '../../Rectangle/rectangle';
import TextBox from '../../Text/textbox';

import ApiDraw from '../../../../../api/api-draw';
import ApiListings from '../../../../../api/api-listings';
import { arraymove } from '../../../../../lib/array-util';
import { setTextBoxesAction } from '../../../../../store/Annotations/TextBox/actions';
import { Annotation } from '../../../../../store/Annotations/reducer';
import { actionFlyTo } from '../../../../../store/App/actions';
import { selectDrawStoryId } from '../../../../../store/Map/DrawStory/selectors';
import {
    actionActiveMapCleared,
    actionActiveMapFetchById,
    actionSetActiveMapById,
} from '../../../../../store/Map/SuperMap/actions';
import ProjectAccess from '../../Project/project-access';
import { handleCopyingProjectAnnotations } from '../../FillPattern/pattern-util';
import Analytics from '../../../../../lib/user-analytics';
import { useBasemap } from '../../../../../store/Map/Basemap/use-basemap';

const StoryBuilderSidedrawer = () => {
    const { basemap, setBasemap } = useBasemap();

    const dispatch = useDispatch();
    const activeMap = useSelector(selectActiveAllMap);

    const markers = useSelector(selectMarkers);
    const polylines = useSelector(selectPolylines);
    const polygons = useSelector(selectPolygons);
    const circles = useSelector(selectCircles);
    const rectangles = useSelector(selectRectangles);
    const freehandDraws = useSelector(selectFreehandDraws);
    const arrows = useSelector(selectArrows);
    const images = useSelector(selectImages);
    const textBoxes = useSelector(selectTextBoxes);
    const coordinates = useSelector(selectCoordinates);
    const militarySymbols = useSelector(selectMilitaryMarkers);
    const drawStoryId = useSelector(selectDrawStoryId);
    const mapBounds = useSelector(selectMapBounds);

    const setMarkers = (markers: Marker[]) => dispatch(setMarkersAction(markers));
    const setPolylines = (polylines: Polyline[]) => dispatch(setPolylinesAction(polylines));
    const setPolygons = (polygons: PolygonPath[]) => dispatch(setPolygonsAction(polygons));
    const setCircles = (circles: Circle[]) => dispatch(setCirclesAction(circles));
    const setRectangles = (rectangles: Rectangle[]) => dispatch(setRectanglesAction(rectangles));
    const setFreehandDraws = (freehandDraws: FreehandPolyline[]) => dispatch(setFreehandDrawsAction(freehandDraws));
    const setArrows = (arrows: Arrow[]) => dispatch(setArrowsAction(arrows));
    const setImages = (images: Image[]) => dispatch(setImagesAction(images));
    const setCoordinates = (coordinates: Coordinate[]) => dispatch(setCoordinatesAction(coordinates));
    const setTextBoxes = (textBoxes: TextBox[]) => dispatch(setTextBoxesAction(textBoxes));
    const setMilitaryMarkers = (militaryMarkers: MilitaryMarker[]) =>
        dispatch(setMilitaryMarkersAction(militaryMarkers));
    const setSelectedAnnotation = (annotation: Annotation | undefined) =>
        dispatch(setSelectedAnnotationAction(annotation));

    const emptyProject: Project = {
        markers: [],
        polylines: [],
        polygons: [],
        circles: [],
        rectangles: [],
        freehandDraws: [],
        arrows: [],
        images: [],
        textBoxes: [],
        coordinates: [],
        militaryMarkers: [],
    };

    const emptyDrawPage: DrawPage = {
        pageTitle: 'Page 1',
        index: 0,
        id: uuidv4(),
        description: '',
        project: emptyProject,
        map: activeMap ? { id: activeMap.id, title: activeMap.title, author: activeMap.userName } : undefined,
        viewportLocked: false,
        allViewportsLocked: false,
        activeBaseMap: basemap,
    };

    const [drawPages, setDrawPages] = useState<DrawPage[]>([emptyDrawPage]);
    const [selectedDrawPage, setSelectedDrawPage] = useState<DrawPage>(emptyDrawPage);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (basemap) {
            const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
            if (currentPage.activeBaseMap === basemap) return;
            const updatedPage = { ...currentPage, activeBaseMap: basemap };
            setDrawPages((prev) => prev.map((p) => (p.id === currentPage.id ? updatedPage : p)));
        }
    }, [basemap, drawPages, selectedDrawPage]);

    // Update the current page when the annotations change
    useEffect(() => {
        const project: Project = {
            ...selectedDrawPage.project,
            markers: markers,
            polylines: polylines,
            polygons: polygons,
            circles: circles,
            rectangles: rectangles,
            freehandDraws: freehandDraws,
            arrows: arrows,
            images: images,
            textBoxes: textBoxes,
            activeMap: activeMap?.id,
            coordinates: coordinates,
            militaryMarkers: militarySymbols,
        };
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
        const updatedPage = { ...currentPage, project: project };
        setDrawPages((prev) => prev.map((p) => (p.id === currentPage.id ? updatedPage : p)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        markers,
        polylines,
        polygons,
        circles,
        rectangles,
        freehandDraws,
        arrows,
        images,
        textBoxes,
        coordinates,
        militarySymbols,
    ]);

    // Reset the project when unmounted otherwise the previous project will be used when the component is mounted again
    useEffect(() => {
        return () => {
            setSelectedDrawPage(emptyDrawPage);
            setDrawPages([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update the map when the selected page changes
    useEffect(() => {
        if (selectedDrawPage.project) {
            setArrows(selectedDrawPage.project.arrows);
            setMarkers(selectedDrawPage.project.markers);
            setPolylines(selectedDrawPage.project.polylines);
            setPolygons(selectedDrawPage.project.polygons);
            setCircles(selectedDrawPage.project.circles);
            setRectangles(selectedDrawPage.project.rectangles);
            setFreehandDraws(selectedDrawPage.project.freehandDraws);
            setImages(selectedDrawPage.project.images);
            setTextBoxes(selectedDrawPage.project.textBoxes);
            setCoordinates(selectedDrawPage.project.coordinates);
            setMilitaryMarkers(selectedDrawPage.project.militaryMarkers);
            setSelectedAnnotation(undefined);
        }

        if (selectedDrawPage.map) {
            dispatch(actionSetActiveMapById(selectedDrawPage.map.id));
        } else if (selectedDrawPage.project && selectedDrawPage.project.activeMap) {
            dispatch(actionSetActiveMapById(selectedDrawPage.project.activeMap));
        } else {
            dispatch(actionActiveMapCleared());
        }

        if (selectedDrawPage.project && selectedDrawPage.project.viewportBounds) {
            dispatch(actionFlyTo(selectedDrawPage.project.viewportBounds as L.LatLngBounds, undefined, true));
        }

        if (selectedDrawPage.activeBaseMap) {
            setBasemap(selectedDrawPage.activeBaseMap);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDrawPage, selectedDrawPage.project?.viewportBounds]);

    // Load the selected map from the route /map/edit/{id}
    useEffect(() => {
        if (drawStoryId) {
            ApiDraw.getDrawProject(Number(drawStoryId))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((res: any) => {
                    Analytics.Event('Draw Tools - Project', `Fetched story project ${drawStoryId}`);
                    if (res.data && res.data.pages) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const pagesRaw: any[] = res.data.pages;
                        const pages: DrawPage[] = pagesRaw.map((page, index) => {
                            const project = GeoJSONProject.import(JSON.stringify(page));
                            const drawPage: DrawPage = {
                                projectTitle: res.title,
                                pageTitle: page.pageTitle || page.title,
                                index: index,
                                id: page.project.id,
                                description: page.description || res.description,
                                project: project,
                                map: page.map
                                    ? { id: page.map.id, title: page.map.title, author: page.map.author }
                                    : undefined,
                                viewportLocked: page.viewportLocked,
                                allViewportsLocked: page.allViewportsLocked || false,
                                activeBaseMap: page.activeBaseMap,
                            };
                            return drawPage;
                        });
                        setDrawPages(pages);
                        setSelectedDrawPage(pages[0]);
                    } else if (res.data) {
                        const drawPage: DrawPage = {
                            pageTitle: res.title,
                            index: 0,
                            id: res.id,
                            description: res.description,
                            project: GeoJSONProject.import(JSON.stringify(res.data)),
                            map: res.mapIds
                                ? { id: res.mapIds[0], title: res.title, author: res.ownerName }
                                : undefined,
                            viewportLocked: res.viewportLocked,
                            allViewportsLocked: res.allViewportsLocked || false,
                            activeBaseMap: res.activeBaseMap,
                        };
                        setDrawPages([drawPage]);
                    }
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    }, [drawStoryId]);

    const handleDeletePage = (page: DrawPage) => {
        Analytics.Event('Draw Tools - Project', 'Clicked delete page');
        const currentPagePos = drawPages.findIndex((p) => p.id === page.id);
        const nextPagePos = currentPagePos === 0 ? 1 : currentPagePos - 1;
        const nextPage = drawPages[nextPagePos];
        setDrawPages(drawPages.filter((p) => p.id !== page.id));
        setSelectedDrawPage(nextPage);
    };

    const handleAddNewPage = () => {
        Analytics.Event('Draw Tools - Project', 'Clicked add new page');
        const project = drawPages[drawPages.length - 1].allViewportsLocked
            ? {
                  ...drawPages[drawPages.length - 1].project,
              }
            : {
                  ...emptyProject,
                  viewportBounds: mapBounds as L.LatLngBounds,
              };

        const newPage: DrawPage = {
            pageTitle: `Page ${drawPages.length + 1}`,
            index: drawPages.length,
            id: uuidv4(),
            description: '',
            project: project,
            viewportLocked: false,
            allViewportsLocked: drawPages[drawPages.length - 1].allViewportsLocked || false,
            activeBaseMap: basemap,
        };
        setDrawPages([...drawPages, newPage]);
        setSelectedDrawPage(newPage);
    };

    const handleCopyPage = () => {
        Analytics.Event('Draw Tools - Project', 'Clicked copy page');
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
        const titleCopy = currentPage?.pageTitle?.includes('(copy)')
            ? currentPage.pageTitle
            : `${currentPage.pageTitle} (copy)`;
        const updateUniqueProjectAnnotations = handleCopyingProjectAnnotations(currentPage.project);

        const newPage: DrawPage = {
            pageTitle: titleCopy,
            index: drawPages.length,
            id: uuidv4(),
            description: currentPage.description,
            project: updateUniqueProjectAnnotations ? updateUniqueProjectAnnotations : currentPage.project,
            map: currentPage.map ? { ...currentPage.map } : undefined,
            activeMap: currentPage.activeMap,
            viewportLocked: currentPage.viewportLocked,
            allViewportsLocked: currentPage.allViewportsLocked,
            activeBaseMap: currentPage.activeBaseMap,
        };
        setDrawPages([...drawPages, newPage]);
        setSelectedDrawPage(newPage);
    };

    const handleDeleteMapFromPage = (page: DrawPage) => {
        Analytics.Event('Draw Tools - Project', 'Clicked delete map from page', page?.activeMap || '');
        const updatedPage = {
            ...page,
            map: undefined,
            activeMap: undefined,
            project: {
                ...page.project,
                activeMap: undefined,
            },
        };
        const updatedPages = drawPages.map((p) => (p.id === selectedDrawPage.id ? updatedPage : p));
        setDrawPages(updatedPages as DrawPage[]);
        dispatch(actionActiveMapCleared());
    };

    const handleAddMapToPage = (id: number) => {
        ApiListings.getListing(id).then((listing) => {
            const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
            const updatedPage = {
                ...currentPage,
                map: { id: listing.id, title: listing.title, author: listing.userName || 'Soar User' },
                activeMap: listing.id,
            };
            const updatedPages = drawPages.map((p) => (p.id === selectedDrawPage.id ? updatedPage : p));
            setDrawPages(updatedPages);
            if (updatedPage.map) {
                Analytics.Event('Draw Tools - Project', 'Clicked add map to page', id);
                dispatch(actionActiveMapFetchById(updatedPage.map.id));
            } else {
                Analytics.Event('Draw Tools - Project', 'Clicked remove map from page');
                dispatch(actionActiveMapCleared());
            }
        });
    };

    const handleSetViewport = (locked: boolean) => {
        Analytics.Event('Draw Tools - Project', `Clicked set viewport`);
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;

        const resetPages = drawPages.map((page) => ({
            ...page,
            allViewportsLocked: false,
        }));

        const updatedPage: DrawPage = {
            ...currentPage,
            project: {
                ...currentPage.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: locked,
            allViewportsLocked: false,
        };

        const updatedPages = resetPages.map((page) => (page.id === selectedDrawPage.id ? updatedPage : page));

        setDrawPages(updatedPages);
        setSelectedDrawPage(updatedPage);
    };

    const handleSetAllPagesViewport = (allLocked: boolean) => {
        Analytics.Event('Draw Tools - Project', `Clicked set all viewports`);
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
        const updatedPage: DrawPage = {
            ...currentPage,
            project: {
                ...currentPage.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: false,
            allViewportsLocked: allLocked,
        };

        const updatedPages = drawPages.map((page) => ({
            ...page,
            project: {
                ...page.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: false,
            allViewportsLocked: allLocked,
        }));

        setDrawPages(updatedPages);
        setSelectedDrawPage(updatedPage);
    };

    const handlePageOrder = (page: DrawPage, direction: 'up' | 'down') => {
        Analytics.Event('Draw Tools - Project', `Clicked to reorder pages ${direction}`);
        const index = page.index;
        const newIndex = direction === 'up' ? index - 1 : index + 1;

        if ((direction === 'up' && index > 0) || (direction === 'down' && index < drawPages.length - 1)) {
            const reorderedPages = arraymove(drawPages, index, newIndex).map((p, i) => ({
                ...p,
                index: i,
            }));

            setDrawPages(reorderedPages);
        }
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = Array.from(drawPages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedPages = items.map((item, index) => ({
            ...item,
            index,
        }));
        setDrawPages(updatedPages);
    };

    return (
        <React.Fragment>
            {error ? (
                <ProjectAccess
                    error={error}
                    resetError={() => {
                        Analytics.Event('Draw Tools - Project', `Clicked reset after project error`, error);
                        setError(undefined);
                    }}
                />
            ) : (
                <React.Fragment>
                    <EditBorder
                        isPageLocked={
                            selectedDrawPage.allViewportsLocked ? true : selectedDrawPage.viewportLocked || false
                        }
                    />

                    <ThinDrawer style={{ marginTop: '48px' }}>
                        <DrawProjectDrawerHeader collapsible title=" " />
                        <DrawProjectDrawerContent>
                            <StoryBuilderIntroduction />
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="drawPages">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {drawPages.map((page, index) => (
                                                <Draggable key={page.id} draggableId={page.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <StoryBuilderPageItem
                                                                key={page.id}
                                                                page={page}
                                                                pageNumber={drawPages.length}
                                                                isSelected={selectedDrawPage.id === page.id}
                                                                onSelect={(page: DrawPage) => {
                                                                    if (selectedDrawPage.id !== page.id) {
                                                                        setSelectedDrawPage(page);
                                                                    }
                                                                    setSelectedAnnotation(undefined);
                                                                }}
                                                                onDelete={(page: DrawPage) => {
                                                                    handleDeletePage(page);
                                                                }}
                                                                onDeleteMapFromPage={(page: DrawPage) => {
                                                                    handleDeleteMapFromPage(page);
                                                                }}
                                                                onSelectListing={(listingId: number) => {
                                                                    handleAddMapToPage(listingId);
                                                                }}
                                                                onUpdatePageDescription={(description: string) => {
                                                                    const updatedPage = {
                                                                        ...page,
                                                                        description: description,
                                                                    };
                                                                    setDrawPages(
                                                                        drawPages.map((p) =>
                                                                            p.id === page.id ? updatedPage : p
                                                                        )
                                                                    );
                                                                }}
                                                                onUpdatePageTitle={(title: string) => {
                                                                    const updatedPage = { ...page, pageTitle: title };
                                                                    setDrawPages(
                                                                        drawPages.map((p) =>
                                                                            p.id === page.id ? updatedPage : p
                                                                        )
                                                                    );
                                                                }}
                                                                onSetPageViewport={(locked: boolean) =>
                                                                    handleSetViewport(locked)
                                                                }
                                                                onSetAllPagesViewport={(allLocked: boolean) =>
                                                                    handleSetAllPagesViewport(allLocked)
                                                                }
                                                                onMovePageDown={(page: DrawPage) =>
                                                                    handlePageOrder(page, 'down')
                                                                }
                                                                onMovePageUp={(page: DrawPage) =>
                                                                    handlePageOrder(page, 'up')
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <StoryBuilderAddPage onAddPage={handleAddNewPage} onCopyPage={handleCopyPage} />
                            <Divider />

                            <StoryBuilderSaveButton drawPages={drawPages} currentDrawProjectId={drawStoryId} />
                        </DrawProjectDrawerContent>
                    </ThinDrawer>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default StoryBuilderSidedrawer;

const Divider = styled.div`
    height: 1px;
    border-top: 1px solid ${(props) => props.theme.color.lightGray};
`;

const EditBorder = styled.div<{ isPageLocked: boolean }>`
    position: fixed;
    top: 78px;
    bottom: 0;
    left: 0;
    right: 0;
    border: ${(props) => (props.isPageLocked ? '4px solid #e55801' : '4px solid #eed923')};
    z-index: 9997;
    user-select: none;
    pointer-events: none;
`;
