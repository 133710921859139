import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import ApiSocial from '../../../../api/api-social';
import Analytics from '../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { selectFavouriteMaps } from '../../../../store/Map/Favourites/selectors';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import { ListingDTO } from '../../../../api/model';
import { actionFetchLikedMaps } from '../../../../store/Map/Favourites/actions';

interface ShareLikeProps {
    selectedMap: ListingDTO;
}

const ShareLike = ({ selectedMap }: ShareLikeProps) => {
    const likedMaps = useSelector(selectFavouriteMaps);
    const isLoggedIn = useSelector(selectLoggedIn);
    const dispatch = useDispatch();

    const [likes, setLikes] = useState(selectedMap.totalLikes || 0);
    const [isLikedByUser, setIsLikedByUser] = useState(false);

    useEffect(() => {
        setIsLikedByUser(likedMaps.find((t) => t.id === selectedMap.id) ? true : false);
    }, [likedMaps, selectedMap.id]);

    const handleClickedLike = async () => {
        Analytics.Event('Sidedraw - Maps', 'Clicked Like');
        if (!isLoggedIn) {
            dispatch(actionShowLoginDialog(true));
            return;
        }

        try {
            let newLikes: number;
            if (isLikedByUser) {
                newLikes = (await ApiSocial.unlikeListing(selectedMap.id)).totalLikes;
            } else {
                newLikes = (await ApiSocial.likeListing(selectedMap.id)).totalLikes;
            }
            setLikes(newLikes);

            setIsLikedByUser(!isLikedByUser);
            dispatch(actionFetchLikedMaps());
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <React.Fragment>
            <HeartIcon
                onClick={handleClickedLike}
                isLiked={isLikedByUser}
                className={isLikedByUser ? 'fas fa-heart liked' : 'far fa-heart'}
                title="Like this map"
            />
            <HeartValue data-testid="map-likes" title="Like this map" onClick={handleClickedLike}>
                {likes}
            </HeartValue>
        </React.Fragment>
    );
};

export default ShareLike;

interface HeartIconProps {
    isLiked: boolean;
}

const HeartPulseAnimation = keyframes`
    0% {
        transform: scale( 1 );
    } 20% {
        transform: scale( 1.25 );
    } 40% {
        transform: scale( 1 );
    } 60% {
        transform: scale( 1.25 );
    } 80% {
        transform: scale( 1 );
    } 100% {
        transform: scale( 1.25 );
    }
`;

const HeartIcon = styled.i<HeartIconProps>`
    cursor: pointer;
    color: ${(props) => (props.isLiked ? '#FF5A5A' : 'white')};
    font-size: 18px;
    height: 20px;
    pointer-events: all;

    &.liked {
        animation: ${HeartPulseAnimation} 1s;
        animation-iteration-count: 1;
    }
`;

const HeartValue = styled.p`
    cursor: pointer;
    color: white;
    margin: -5px 0 0 6px;
    font-size: 18px;
`;
