import React, { useEffect } from 'react';
import styled from 'styled-components';
import ListingHelper from '../../../../../lib/listing-helper';
import MapViewHistoryHelper from '../../../../../lib/map-view-history-helper';
import PreviewTile from '../../../../Shared/preview-tile';
import { DrawPage } from '../draw-story';

interface StoryPageItemMapsProps {
    page: DrawPage;
    setShowSearchModal: () => void;
    onDeleteMapFromPage: (page: DrawPage) => void;
}

const StoryBuilderPageItemMaps = (props: StoryPageItemMapsProps) => {
    useEffect(() => {
        if (props.page.map) {
            MapViewHistoryHelper.setLastUsedDrawProjectMap(
                props.page.map.id,
                props.page.map.title,
                props.page.map.author
            );
        }
    }, [props.page]);

    if (!props.page.map) return <React.Fragment />;

    return (
        <Row>
            <PreviewContainer title="Swap this with another map" onClick={() => props.setShowSearchModal()}>
                <PreviewTile
                    previewUrl={ListingHelper.getPreviewUrlForListing(props.page.map.id, 'small')}
                    title={props.page.map.title}
                    subText={`by ${props.page.map.author || 'Soar User'}`}
                />
            </PreviewContainer>
            <DeleteIcon
                src="/assets/annotations/icon-trash-red.svg"
                title="Remove map from this page"
                onClick={() => {
                    props.onDeleteMapFromPage({ ...props.page, map: undefined });
                }}
            />
        </Row>
    );
};

export default StoryBuilderPageItemMaps;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    margin: 0 0 18px 0;
`;

const PreviewContainer = styled.div`
    display: block;
    cursor: pointer;
    height: 48px;
    width: calc(100% - 32px);
    border-radius: ${(props) => props.theme.borderRadius};
    overflow: hidden;
    position: relative;
    border: ${(props) => `1px solid ${props.theme.color.gray}`};
`;

const DeleteIcon = styled.img`
    width: 24px;
`;
