import { LatLng, LatLngBounds } from 'leaflet';
import { isMobileVersion } from '../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO, UserDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../store/App/actions';
import {
    actionSetHighlightedListingId,
    actionSetHighlightedListingGroup,
} from '../../../store/Map/MapSelection/actions';
import { selectHighlightedListingGroup } from '../../../store/Map/MapSelection/selectors';
import { StyledButton } from '../../Shared/styled-button';

interface ProfileFeaturedMapsProps {
    profile: UserDTO;
    listings: ListingDTO[];
    seeAllMaps: () => void;
}

const ProfileFeaturedMaps = (props: ProfileFeaturedMapsProps) => {
    const dispatch = useDispatch();
    const flyTo = (position: LatLng | LatLngBounds) => dispatch(actionFlyToOnMap(position));

    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const onMouseEnter = (mapId: number) => {
        dispatch(actionSetHighlightedListingId(mapId));
        dispatch(actionSetHighlightedListingGroup(undefined));
    };

    const onMouseLeave = () => {
        dispatch(actionSetHighlightedListingId(undefined));
        dispatch(actionSetHighlightedListingGroup(undefined));
    };

    return (
        <Container>
            <ProfileHeader>
                {props.listings?.length > 0 ? <Title>Recent Maps</Title> : null}

                {!isMobileVersion && (
                    <AllMapsButton
                        onClick={() => {
                            props.seeAllMaps();
                        }}
                        style={{ right: 0, float: 'right' }}
                    >
                        <AllMapsLabel>All Maps</AllMapsLabel>
                        <AllMapsArrow src="/assets/user-profile-logos/arrow.png" />
                    </AllMapsButton>
                )}
            </ProfileHeader>

            {props.listings?.length ? (
                <FeaturedMaps>
                    {props.listings.slice(0, isMobileVersion ? 8 : 5).map((l, key) => {
                        const isHighlighted = highlightedTileLayerGroup?.tileLayers[0].id === l.id;
                        return (
                            <MapsGrid
                                key={key}
                                onMouseEnter={() => onMouseEnter(l.id)}
                                onMouseLeave={() => onMouseLeave()}
                                onClick={() => {
                                    UriHelper.navigateToMap(l.id);
                                    flyTo(l.boundingBox);
                                    Analytics.Event('Profile sidedraw', 'Clicked to view', l.id);
                                    ApiAnalytics.postAnalyticsListing(
                                        AnalyticsAction.VIEW,
                                        AnalyticsNote.USER_PROFILE,
                                        l.id
                                    );
                                }}
                            >
                                <MapImage
                                    isHighlighted={isHighlighted}
                                    src={ListingHelper.getPreviewUrlForListing(l.id, 'small')}
                                    title={l.title || ''}
                                />
                            </MapsGrid>
                        );
                    })}
                </FeaturedMaps>
            ) : null}

            {isMobileVersion && (
                <ProfileFeaturedMapsButtonContainer>
                    <ProfileMobileAllMapsButton onClick={props.seeAllMaps}>
                        {`VIEW ALL CONTENTS (${props.listings.length === 0 ? '0' : props.listings.length})`}
                    </ProfileMobileAllMapsButton>
                </ProfileFeaturedMapsButtonContainer>
            )}
        </Container>
    );
};

export default ProfileFeaturedMaps;

const Container = styled.div`
    color: white;
    padding: 15px 15px 10px 15px;
`;

const ProfileHeader = styled.div`
    width: 100%;
`;

const Title = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;

    color: #ffffff;
    margin-left: 5px;
    margin-top: 5px;
`;

const AllMapsButton = styled.span`
    cursor: pointer;
    float: right;
`;

const AllMapsLabel = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;

    color: #ffffff;

    opacity: 0.3;
    margin-right: 5px;
`;

const AllMapsArrow = styled.img`
    margin-bottom: 4px;
    margin-left: 5px;
`;

const FeaturedMaps = styled.div`
    margin-top: 10px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-auto-rows: 1fr;
`;

const MapsGrid = styled.div`
    padding: 3px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
`;

export const MapImage = styled.img<{ isHighlighted: boolean }>`
    width: inherit;
    height: inherit;
    aspect-ratio: 1;

    border-radius: 6px;
    cursor: pointer;
    border: ${(props) => (props.isHighlighted ? '2px solid #eed926' : '')};
    -webkit-box-shadow: ${(props) => (props.isHighlighted ? '0px 0px 26px 10px rgba(238, 217, 38, 0.49)' : '')};
    -moz-box-shadow: ${(props) => (props.isHighlighted ? '0px 0px 26px 10px rgba(238, 217, 38, 0.49)' : '')};
    box-shadow: ${(props) => (props.isHighlighted ? '0px 0px 26px 10px rgba(238, 217, 38, 0.49)' : '')};

    &:hover {
        border: 2px solid #eed926;
        -webkit-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        -moz-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
    }

    @media only screen and (max-width: 600px) {
        width: calc((100vw - 60px) / 4);
        height: calc((100vw - 60px) / 4);
    }
`;

const ProfileFeaturedMapsButtonContainer = styled.div`
    height: 40px;
    width: 100%;
    margin-top: 10px;
`;

const ProfileMobileAllMapsButton = styled(StyledButton)`
    margin: 5px;
    width: 100%;
`;
