import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfig } from '../../App/selectors';
import { selectBasemap } from './selector';
import { Basemap, getBasemap } from './model';
import { actionSetBasemap } from './actions';
import UriHelper from '../../../lib/uri-helper';
import { useLocation } from 'react-router';

export const useBasemap = () => {
    const config = useSelector(selectConfig);
    const dispatch = useDispatch();
    const selectedBasemap = useSelector(selectBasemap);
    const defaultBasemap = config.COUNTRY_CODE === 'CN' ? Basemap.OPEN_ELEVATION : Basemap.GOOGLE_HYBRID;
    const url = useLocation();

    const setBasemap = useCallback(
        (basemap: Basemap | undefined) => {
            dispatch(actionSetBasemap(basemap));
        },
        [dispatch]
    );

    useEffect(() => {
        if (selectedBasemap && selectedBasemap !== defaultBasemap) {
            UriHelper.addParameterToUri(UriHelper.BASEMAP_URI_KEY, selectedBasemap);
        } else if (selectedBasemap === defaultBasemap) {
            UriHelper.removeParameterFromUri('basemap');
        }

        if (selectedBasemap) return;

        const params = new URLSearchParams(window.location.search);
        const urlSelectedBaseMap = params.get(UriHelper.BASEMAP_URI_KEY);

        if (urlSelectedBaseMap) {
            const bm = getBasemap(decodeURI(urlSelectedBaseMap));
            setBasemap(bm);
        }
    }, [url, selectedBasemap, defaultBasemap, setBasemap]);

    return { basemap: selectedBasemap ?? defaultBasemap, setBasemap };
};
