import { Category } from '../../../api/model';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { ListingDictionary, ListingGroup } from './model';

interface MapSelectionStore {
    listingDictionaryIsLoading: boolean;
    listingDictionary: ListingDictionary | undefined;
    globalListings: ListingGroup | undefined;
    continentListings: ListingDictionary | undefined;
    highlightedContinent: ListingGroup | undefined;
    visibleListingGroups: ListingGroup[];
    bestFittingListingGroups: ListingGroup[];
    highlightedListingGroup: ListingGroup | undefined;
    highlightedListingId: number | undefined;
    searchFocused: boolean;
    selectedCategory: Category | undefined;
    selectedContinent: ListingGroup | undefined;
    selectedSearchTerm: string | undefined;
    clearListing: boolean;
}

const initialState: MapSelectionStore = {
    listingDictionaryIsLoading: false,
    listingDictionary: undefined,
    visibleListingGroups: [],
    bestFittingListingGroups: [],
    globalListings: undefined,
    continentListings: undefined,
    highlightedContinent: undefined,
    highlightedListingGroup: undefined,
    highlightedListingId: undefined,
    searchFocused: false,
    selectedContinent: undefined,
    selectedCategory: undefined,
    selectedSearchTerm: undefined,
    clearListing: false,
};

export default (state: MapSelectionStore = initialState, action: Action): MapSelectionStore => {
    switch (action.type) {
        case at.FETCH_TILE_LAYER_DICTIONARY:
            return {
                ...state,
            };

        case at.FETCH_TILE_LAYER_DICTIONARY_LOADING:
            return {
                ...state,
                listingDictionaryIsLoading: action.payload,
            };

        case at.FETCH_TILE_LAYER_DICTIONARY_SUCCESS:
            return {
                ...state,
                listingDictionary: action.payload,
            };

        case at.SET_VISIBLE_TILE_LAYER_GROUPS:
            return {
                ...state,
                visibleListingGroups: action.payload,
            };

        case at.SET_BEST_FITTING_TILE_LAYER_GROUPS:
            return {
                ...state,
                bestFittingListingGroups: action.payload,
            };

        case at.SET_HIGHLIGHTED_TILE_LAYER_GROUP:
            return {
                ...state,
                highlightedListingGroup: action.payload,
            };

        case at.SET_HIGHLIGHTED_TILE_LAYER_ID:
            return {
                ...state,
                highlightedListingId: action.payload,
            };

        case at.FETCH_GLOBAL_TILELAYER_SUCCESS:
            return {
                ...state,
                globalListings: action.payload,
            };

        case at.FETCH_CONTINENT_TILELAYER_SUCCESS:
            return {
                ...state,
                continentListings: action.payload,
            };

        case at.SET_HIGHLIGHTED_CONTINENT:
            return {
                ...state,
                highlightedContinent: action.payload,
            };

        case at.SET_SEARCH_FOCUSED:
            return {
                ...state,
                searchFocused: action.payload,
            };

        case at.SET_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload,
            };

        case at.SET_SELECTED_CONTINENT:
            return {
                ...state,
                selectedContinent: action.payload,
            };

        case at.SET_SELECTED_SEARCH_TERM:
            return {
                ...state,
                selectedSearchTerm: action.payload,
            };
        case at.SET_CLEAR_SELECTED_LISTING:
            return {
                ...state,
                clearListing: action.payload,
            };
        default:
            return state;
    }
};
