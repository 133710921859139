import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import { actionSetTileLayerOpacity } from '../../../store/Map/TileLayer/actions';
import { selectTileLayersOpacity } from '../../../store/Map/TileLayer/selectors';

interface ActiveMapOpacitySliderProps {
    tileLayer: ListingDTO;
}

const ActiveMapOpacitySlider = (props: ActiveMapOpacitySliderProps) => {
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);

    const dispatch = useDispatch();
    const setTileLayerOpacity = useCallback(
        (tileLayerId: number, opacity: number): void => {
            dispatch(actionSetTileLayerOpacity(tileLayerId, opacity));
        },
        [dispatch]
    );
    const [opacity, setOpacity] = useState(tileLayersOpacity[props.tileLayer.id] || 1.0);

    // Handler for when the input value changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value || '0';

        const newOpacity = parseInt(inputValue) / 100;

        setOpacity(newOpacity);
        setTileLayerOpacity(props.tileLayer.id, newOpacity || 0.0001);

        e.target.value = inputValue;
    };

    return (
        <SliderContainer className="opacity-slider">
            <SliderText>Transparency:</SliderText>
            <SliderInput
                type="number"
                value={(opacity * 100).toFixed()}
                step={1}
                min={0}
                max={100}
                onChange={handleInputChange}
            />
            <PercentageSign>%</PercentageSign>

            <SliderInputContainer>
                <Slider
                    type="range"
                    value={opacity * 100}
                    step={0.01}
                    onChange={(e) => {
                        if (document.activeElement === document.getElementById('listing-comment-input')) {
                            document.getElementById('listing-comment-input')?.blur();
                        }
                        const newOpacity = parseInt(e.target.value) / 100;
                        setOpacity(newOpacity);
                        setTileLayerOpacity(props.tileLayer.id, newOpacity || 0.0001);
                    }}
                />
            </SliderInputContainer>
        </SliderContainer>
    );
};

export default ActiveMapOpacitySlider;

const SliderContainer = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    padding: 12px 4px;
    display: flex;

    @media only screen and (max-width: 600px) {
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        padding: 14px 10px;
    }
`;

const SliderText = styled.p`
    vertical-align: middle;
    color: white;
    font-size: 15px;
    margin: 0;
    padding: 0;
`;

const SliderInput = styled.input`
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    width: 32px;
    text-align: right;
    margin-right: 1px;
    padding: 0px 1px;

    -moz-appearance: textfield;

    &:focus {
        color: white;
        outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const PercentageSign = styled.span`
    display: inline;
    margin-right: 2px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    vertical-align: middle;
    padding-top: 1px;
`;

const SliderInputContainer = styled.div`
    width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 12px;

    &::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
