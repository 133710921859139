import styled from 'styled-components';
import { Basemap } from '../../../../../store/Map/Basemap/model';
import { useBasemap } from '../../../../../store/Map/Basemap/use-basemap';
import Analytics from '../../../../../lib/user-analytics';

interface StoryBuilderBaseMapControlProps {
    basemap: string;
}

const StoryBuilderBaseMapControl = ({ basemap }: StoryBuilderBaseMapControlProps) => {
    const { setBasemap } = useBasemap();

    return (
        <BaseMapSelect
            name="baseMap"
            id="story-builder-baseMap"
            value={basemap}
            onChange={(e) => {
                Analytics.Event(
                    'Draw Tools - Project',
                    `Clicked to change basemap when editing page ${e.target.value}`
                );
                setBasemap(e.target.value as Basemap);
            }}
        >
            {Object.values(Basemap).map((value) => {
                return (
                    <BaseMapSelectOption key={value} value={value}>
                        {value}
                    </BaseMapSelectOption>
                );
            })}
        </BaseMapSelect>
    );
};

export default StoryBuilderBaseMapControl;

const BaseMapSelect = styled.select`
    width: 100%;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: transparent !important;
    font-size: 1.2rem;
    outline: none;
    color: white;
    margin-bottom: 12px;

    :hover {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :-webkit-autofill {
        :hover {
            border: 1px solid ${(props) => props.theme.color.lightGray};
        }
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :focus {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }
`;

const BaseMapSelectOption = styled.option`
    background-color: #333;
    color: white;
`;
