import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { selectMyPoints } from '../../../store/Account/selectors';
import PointsUtil from './points-util';
import { lightDropShadow } from '../../../styles/style';

// // How long the points notification pulses
const NEW_POINTS_ANIMATION_PULSE_DURATION = 1000; //ms

// // How long the points notification stays around without animation
const NEW_POINTS_NOTIFICATION_DURATION = 2000; //ms

// // How long the points notification takes to fade out
const NEW_POINTS_NOTIFICATION_FADEOUT_TIME = 1000; //ms

// // Total duration of the animation
const NEW_POINTS_NOTIFICATION_TIME =
    NEW_POINTS_ANIMATION_PULSE_DURATION + NEW_POINTS_NOTIFICATION_DURATION + NEW_POINTS_NOTIFICATION_FADEOUT_TIME;

const NavbarPoints = () => {
    const userPoints = useSelector(selectMyPoints);
    const [previousUserPoints, setPreviousUserPoints] = useState<undefined | number>(undefined);
    const [newPointsNotification, setNewPointsNotification] = useState<number | undefined>(undefined);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (!userPoints) {
            // should be impossible
            return;
        }

        if (!previousUserPoints) {
            setPreviousUserPoints(userPoints);
            return;
        }

        setNewPointsNotification(userPoints - previousUserPoints);
        setPreviousUserPoints(userPoints);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPoints]);

    // Clear the new points notification after a certain amount of time
    useEffect(() => {
        const timeout = setTimeout(() => {
            setNewPointsNotification(undefined);
        }, NEW_POINTS_NOTIFICATION_TIME);

        return () => clearTimeout(timeout);
    }, [newPointsNotification]);

    return (
        <Container>
            <Points onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
                {PointsUtil.pointsFormatter(userPoints)} points
                <NewPointsContainer>
                    {newPointsNotification ? <PointsSpan>+{newPointsNotification}</PointsSpan> : null}
                </NewPointsContainer>
            </Points>
            {showTooltip ? (
                <TooltipContainer>
                    <TooltipTitle>
                        You have <span>{userPoints ? userPoints.toLocaleString() : 'no'}</span> points
                    </TooltipTitle>
                    <TooltipContent>Earn more points by:</TooltipContent>
                    <TooltipList>
                        <TooltipListItem>Viewing maps</TooltipListItem>
                        <TooltipListItem>Commenting on maps</TooltipListItem>
                        <TooltipListItem>Uploading maps</TooltipListItem>
                    </TooltipList>
                </TooltipContainer>
            ) : null}
        </Container>
    );
};

export default NavbarPoints;

const Container = styled.div`
    position: absolute;
    margin-top: -27px;
    user-select: none;
    pointer-events: none;
    right: 1px;
`;

const Points = styled.span`
    color: #eed923;
    font-weight: bold;
    margin-left: 50px;
    font-size: 0.9rem;
    cursor: help;
    user-select: auto;
    pointer-events: auto;
`;

const NewPointsContainer = styled.div`
    position: absolute;
    right: 0;
    top: 15px;
    font-weight: normal;
`;

const TooltipContainer = styled.div`
    position: absolute;
    width: 200px;
    max-height: 200px;
    top: 30px;
    left: -80px;
    background: ${(props) => props.theme.color.transparentDark};
    padding: 8px;
    z-index: 99999;
    border-radius: 6px;
    ${lightDropShadow}

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        right: 20px;
    }
    &:after {
        top: -10px;
        border-bottom: 10px solid ${(props) => props.theme.color.transparentDark};
        border-bottom: 10px solid ${(props) => props.theme.color.transparentDark};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
    &:before {
        right: 19px;
        top: -11px;
        border-bottom: 11px solid rgba(255, 255, 255, 0.3);
        border-bottom: 11px solid rgba(255, 255, 255, 0.3);
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
    }
`;

const TooltipTitle = styled.h5`
    text-align: center;
    color: white;
    padding: 0;
    margin: 0;

    span {
        color: #eed923;
    }
`;

const TooltipContent = styled.p`
    color: white;
    padding: 0;
    margin: 12px 0 0 0;
`;

const TooltipList = styled.ul`
    list-style: inside;
    padding: 0 0 0 6px;
    margin: 0;
`;

const TooltipListItem = styled.li`
    color: white;
`;

const PulseAnimation = keyframes`
    0% {
        font-size: 0.9rem;
    } 20% {
        font-size: 1.1rem;
    } 40% {
        font-size: 0.9rem;
    } 60% {
        font-size: 1.1rem;
    } 80% {
        font-size: 0.9rem;
    }
`;

const FadeOut = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

const PointsSpan = styled.span`
    margin-left: 7px;
    color: #eed923;
    animation-name: ${PulseAnimation}, ${FadeOut};
    animation-duration: ${NEW_POINTS_ANIMATION_PULSE_DURATION}ms;
    animation-delay: 0ms, ${NEW_POINTS_ANIMATION_PULSE_DURATION + NEW_POINTS_NOTIFICATION_DURATION}ms;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
`;
