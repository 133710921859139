import { toast } from 'react-toastify';

import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';

export enum ShareTypes {
    CURRENT = 'Current Map View',
    FULL = 'Full Map View',
}

export const ShareTypeOptions = {
    title: 'Share Type:',
    options: [
        {
            value: ShareTypes.CURRENT,
            label: 'Use current map view (default)',
        },
        {
            value: ShareTypes.FULL,
            label: 'Use stoary extents',
        },
    ],
};

export const DefaultShareType = ShareTypes.CURRENT;

export const generateShareLink = (drawProjectId: number, shareType?: ShareTypes) => {
    const selectedShareType = shareType ? shareType : DefaultShareType;

    const baseUrl = window.location.origin;

    let params = '';
    if (selectedShareType === ShareTypes.CURRENT) {
        const pos = UriHelper.tryGetParam('pos');
        if (pos) {
            params = `pos=${pos}`;
        }
    }

    return new URL(`${baseUrl}/draw/${drawProjectId}${params ? `?${params}` : ''}`);
};

export const shareProjectToClipboard = (drawProjectId: number) => {
    const baseUrl = window.location.origin;
    const shareUrl = `${baseUrl}/draw/${drawProjectId}`;
    navigator.clipboard.writeText(shareUrl.toString());
    toast.dark('Copied to clipboard');

    Analytics.Event('Draw Tools - Project', 'Shared', shareUrl);
};

export const shareProjectToSocials = (drawProjectId: number, socialUrl: string, shareType?: ShareTypes) => {
    const url = generateShareLink(drawProjectId, shareType);

    const shareUrl = new URL(`${socialUrl}${url}`);

    window.open(shareUrl.toString(), '_blank');

    Analytics.Event(`Social Media - Shared Project - ${drawProjectId}`, 'Link', drawProjectId);
};
