import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiComments from '../../../../api/api-comments';
import { selectActiveAnnotationString } from '../../../../store/SocialMapping/selectors';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import TextareaAutosize from 'react-textarea-autosize';
import { inputMaxValues } from '../../../../lib/limitation-constants';
import ActiveCommentAnnotationTags from './comment-annotation-tags';
import { CommentResultsDTO } from '../../../../api/model';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { useSelector, useDispatch } from 'react-redux';
import { StyledButton, StyledDarkButton } from '../../../Shared/styled-button';
import React from 'react';
import { CommentResponseHandler } from './types';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import Analytics from '../../../../lib/user-analytics';

interface ActiveListingCommentReply {
    comment: CommentResultsDTO;
    handleReplyAction: CommentResponseHandler;
}

const ActiveListingCommentReply = (props: ActiveListingCommentReply) => {
    const { comment } = props;

    const dispatch = useDispatch();
    const selectedAnnotationString = useSelector(selectActiveAnnotationString);
    const isLoggedIn = useSelector(selectLoggedIn);

    const [userComment, setUserComment] = useState<string>('');

    const handleSubmitComment = () => {
        if (!isLoggedIn) {
            dispatch(actionShowLoginDialog(true));
            return;
        }

        if (isMobileVersion && userComment.length < 1) {
            toast.error('Please add a reply');
            return;
        } else if (
            userComment.length < 1 &&
            (selectedAnnotationString === '' || selectedAnnotationString === comment.annotations)
        ) {
            toast.error('Add comment to reply');
            return;
        }

        if (userComment.length >= inputMaxValues.MAX_DESCRIPTION_WORDS - 1) {
            toast.error(`Comments can only be ${inputMaxValues.MAX_DESCRIPTION_WORDS - 1} characters`);
            return;
        }

        const commentReply = {
            text: userComment,
            annotations: selectedAnnotationString,
            listingId: comment.listingId,
            parentId: comment.id,
        };
        ApiComments.createCommentReply(commentReply)
            .then((res) => {
                props.handleReplyAction(res);
                toast.dark('You have added a reply');
                Analytics.Event('Comments', 'Created reply', comment.listingId);
            })
            .catch((err) => {
                toast.error('Something has gone wrong with adding the reply', err);
                props.handleReplyAction(undefined, err);
            });
    };

    const isCharLimitReached = userComment.length >= inputMaxValues.MAX_DESCRIPTION_WORDS - 1;

    return (
        <React.Fragment>
            <ListingCommentContainer>
                <CommentTagContainer isCharLimitReached={isCharLimitReached}>
                    <ListingCommentInput
                        id="listing-comment-input"
                        value={userComment}
                        onChange={(e) => {
                            setUserComment(e.target.value);
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        placeholder={`Reply to ${comment.userName}`}
                        maxRows={4}
                    />
                    {selectedAnnotationString && (
                        <ActiveCommentAnnotationTags annotationString={selectedAnnotationString} />
                    )}
                </CommentTagContainer>
                <ListingCommentButtonContainer>
                    <ListingCommentButton
                        disabled={isCharLimitReached}
                        onClick={(e) => {
                            handleSubmitComment();
                            e.stopPropagation();
                        }}
                    >
                        REPLY
                    </ListingCommentButton>
                    <ListingCommentCancelButton
                        onClick={(e) => {
                            props.handleReplyAction();
                            e.stopPropagation();
                        }}
                    >
                        CANCEL
                    </ListingCommentCancelButton>
                </ListingCommentButtonContainer>
            </ListingCommentContainer>
            <CharacterCount isCharLimitReached={isCharLimitReached}>
                {userComment.length}/{inputMaxValues.MAX_DESCRIPTION_WORDS - 1}
            </CharacterCount>
        </React.Fragment>
    );
};

export default ActiveListingCommentReply;

interface CharacterCount {
    isCharLimitReached: boolean;
}

const ListingCommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 24px 0px 2px;
`;

const CommentTagContainer = styled.div<CharacterCount>`
    color: white;
    background: transparent;
    border: ${(props) =>
        props.isCharLimitReached ? '2px solid rgba(255, 0, 0, 0.7);' : '1px solid rgba(255, 255, 255, 0.3);'};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0px 3px 0px 13px;
`;

const ListingCommentInput = styled(TextareaAutosize)`
    background: none;
    border: none;
    width: 100%;
    outline: none;
    color: rgba(255, 255, 255, 0.6);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    max-height: 200px;
    min-height: 30px;
    padding: 5px;

    &::placeholder {
        color: rgba(255, 255, 255, 0.3) !important;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const ListingCommentButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const ListingCommentButton = styled(StyledButton)`
    margin: 7px 0px -10px 13px;
    font-size: 12px !important;
    border-radius: 6px !important;
    min-width: 55px;
    padding: 1px 6px !important;

    :focus {
        outline: none !important;
    }
`;

const ListingCommentCancelButton = styled(StyledDarkButton)`
    margin: 7px 0px -10px 13px;
    font-size: 12px !important;
    border-radius: 6px !important;
    min-width: 55px;
    padding: 1px 6px !important;
    margin-left: 7px;

    :focus {
        outline: none !important;
    }
`;

const CharacterCount = styled.span<CharacterCount>`
    color: ${(props) => (props.isCharLimitReached ? 'rgba(255, 0, 0, 0.7);' : 'rgba(255, 255, 255, 0.5);')};
    font-size: 10px;
    text-align: right;
    display: block;
    height: 0px;
    margin-right: 12px;
`;
