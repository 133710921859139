import styled from 'styled-components';
import { MarkerIconName, getMarkerIconsByCategory, isMarkerSelected } from './marker-util';
import Marker from './marker';

interface MarkerSelectProps {
    marker: Marker;
    onUpdate: (marker: Marker) => void;
}

const MarkerSelect = (props: MarkerSelectProps) => {
    return (
        <MarkerSelectContainer>
            {getMarkerIconsByCategory('Preselect', props.marker.color)?.map((newIcon, index) => {
                return (
                    <MarkerItem
                        isSelected={isMarkerSelected(props.marker.iconName as MarkerIconName, newIcon)}
                        title={newIcon.options.title}
                        key={`marker-select-${index}`}
                        src={newIcon.options.iconUrl}
                        alt={newIcon.options.iconUrl}
                        data-testid="annotation-marker-select-icon"
                        onClick={() => {
                            const newMarker = {
                                ...props.marker,
                                iconName: newIcon.options.name as MarkerIconName,
                                options: { ...props.marker.options, ...newIcon.options },
                            };
                            props.onUpdate(newMarker);
                        }}
                    />
                );
            })}
        </MarkerSelectContainer>
    );
};

export default MarkerSelect;

const MarkerSelectContainer = styled.div`
    margin-left: 8px;
    padding-top: 8px;
`;

const MarkerItem = styled.img<{ isSelected: boolean }>`
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: ${(props) =>
        props.isSelected ? '1px solid rgba(255, 255, 255, 0.7)' : '1px solid rgba(255, 255, 255, 0.2)'};
    border-radius: 3px;
    margin-right: 5px;
    padding: 3px;

    &:hover {
        border: 1px solid rgba(255, 255, 255, 0.4);
        background-color: rgba(255, 255, 255, 0.3);
    }
`;
