import { DrawPage, DrawProject } from '../../../components/MapView/Annotations/StoryMaps/draw-story';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface StoreDrawStory {
    drawProject: DrawProject | undefined;
    drawPage: DrawPage | undefined;
    drawStoryBuilderActive: boolean;
    drawStoryId: string | undefined;
}

const initialState: StoreDrawStory = {
    drawProject: undefined,
    drawPage: undefined,
    drawStoryBuilderActive: false,
    drawStoryId: undefined,
};

export default (state: StoreDrawStory = initialState, action: Action): StoreDrawStory => {
    switch (action.type) {
        case at.DRAW_STORY_BUILDER_ACTIVE:
            return {
                ...state,
                drawStoryBuilderActive: action.payload,
            };

        case at.SET_DRAW_STORY:
            return {
                ...state,
                drawProject: action.payload,
            };

        case at.SET_DRAW_PAGE:
            return {
                ...state,
                drawPage: action.payload,
            };

        case at.SET_DRAW_STORY_ID:
            return {
                ...state,
                drawStoryId: action.payload,
            };
        default:
            return state;
    }
};
