import ReactGA from 'react-ga4';
import ApiListings from '../../api/api-listings';
import ApiSubdomain from '../../api/api-subdomain';
import ApiUser from '../../api/api-user';
import {
    DomainDTO,
    DomainUserDTO,
    FileInfo,
    ListingDTO,
    ListingMetadata,
    ListingType,
    UploadCredentialsDTO,
    SaleInfo,
} from '../../api/model';
import { OssUploader } from '../../api/oss-uploader';
import UriHelper from '../../lib/uri-helper';
import { actionTypes as at } from './constants';

import { actionLogoutUser } from '../Account/actions';

export const actionGetDomainUsers = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.API_GET_ADMIN_USER_DATA,
            });
            const result: DomainUserDTO[] = await ApiSubdomain.getSubdomainUsers();
            dispatch(actionGetAdminUsersDataSuccess(result));
        } catch (err) {
            dispatch(actionGetAdminUsersDataError());
        }
    };
};

const actionGetAdminUsersDataSuccess = (user: DomainUserDTO[]) => {
    return {
        type: at.API_GET_ADMIN_USER_DATA_SUCCESS,
        payload: user,
    };
};

const actionGetAdminUsersDataError = () => {
    return {
        type: at.API_GET_ADMIN_USER_DATA_ERROR,
        payload: {},
    };
};

export const actionGetSubdomainListings = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.API_GET_SUBDOMAIN_LISTINGS,
        });
        try {
            const isLoggedIn = getState().accountDomain.loggedIn;
            if (isLoggedIn) {
                const result = await ApiSubdomain.getSubdomainListings();
                dispatch(actionGetSubdomainListingSuccess(result));
            } else {
                dispatch(actionGetSubdomainListingsError());
            }
        } catch (err) {
            dispatch(actionGetSubdomainListingsError());
        }
    };
};

const actionGetSubdomainListingSuccess = (listings: ListingDTO[]) => {
    return {
        type: at.API_GET_SUBDOMAIN_LISTINGS_SUCCESS,
        payload: listings,
    };
};

const actionGetSubdomainListingsError = () => {
    return {
        type: at.API_GET_SUBDOMAIN_LISTINGS_ERROR,
        payload: {},
    };
};

export const actionGetSubdomain = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.API_GET_SUBDOMAIN,
        });
        try {
            const userLoggedIn = getState().accountDomain.loggedIn;
            if (userLoggedIn) {
                const result: DomainDTO = await ApiSubdomain.getSubdomainInfo();
                dispatch(actionGetSubdomainSuccess(result));
            }
        } catch (err) {
            console.log(err);
        }
    };
};

export const actionGetSubdomainSuccess = (subdomainInfo: DomainDTO) => {
    return {
        type: at.API_GET_SUBDOMAIN_SUCCESS,
        payload: subdomainInfo,
    };
};

export const actionGetSales = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.API_GET_SALES,
        });
        try {
            const userLoggedIn = getState().accountDomain.loggedIn;
            if (userLoggedIn) {
                const result: SaleInfo[] = await ApiUser.getMySales();
                dispatch({
                    type: at.API_GET_SALES_SUCCESS,
                    payload: result,
                });
            }
        } catch (err) {
            dispatch({
                type: at.API_GET_SALES_ERROR,
                payload: err.message ?? 'Something went wrong',
            });
        }
    };
};

export const actionUploadToSuperMap = (
    file: File,
    listingType: ListingType,
    listing: ListingMetadata,
    metadata: string,
    fileInfo?: FileInfo,
    attachment?: File
) => {
    return async (dispatch, getState) => {
        const key = new Date().getTime().toString();
        dispatch(actionUploadToSuperMapStatusReport(key, 0, file));

        try {
            const MESSAGE_BEFORE_LEAVE = 'Leave the page? Your map is still uploading.';
            window.onbeforeunload = () => MESSAGE_BEFORE_LEAVE;

            const credentials: UploadCredentialsDTO = await ApiListings.postListingAndGetUploadCredentials(
                listingType,
                file.name,
                listing,
                metadata,
                fileInfo
            );

            if (listingType === ListingType.TILE_LAYER) {
                const ossUploader = new OssUploader(credentials, () =>
                    ApiSubdomain.refreshUploadCredentials(credentials.listingId)
                );
                ossUploader
                    .multipartUpload(file, credentials.filename, (cpt) => {
                        dispatch(actionUploadToSuperMapStatusReport(key, cpt, file, credentials));
                    })
                    .then(async () => {
                        if (attachment && credentials.listingId) {
                            await ApiListings.addFile(credentials.listingId, attachment);
                        }

                        ApiUser.updateMyPoints();
                        window.onbeforeunload = null;
                    });
            } else if (listingType === ListingType.IMAGE) {
                const ossUploader = new OssUploader(credentials);
                await ossUploader.uploadFileToStorage(file, credentials.filename);
                dispatch(actionUploadToSuperMapStatusReport(key, 100, file, credentials));
                if (attachment && credentials.listingId) {
                    await ApiListings.addFile(credentials.listingId, attachment);
                }
                ApiUser.updateMyPoints();
                window.onbeforeunload = null;
            }
        } catch (err) {
            ReactGA.event({ category: 'Error', action: 'Upload Map', label: err.message });

            let errorMessage = err.message;
            if (/^401/.test(errorMessage)) {
                dispatch(actionLogoutUser());
                errorMessage = 'Not logged in';
            }

            dispatch(actionUploadToSuperMapError(errorMessage));
            dispatch(actionUploadToSuperMapStatusReportError(key, errorMessage));

            window.onbeforeunload = null;

            UriHelper.exitToMap();
        }
    };
};

export const actionUploadToSuperMapStatusReport = (
    key: string,
    status: number,
    file: File,
    credentials?: UploadCredentialsDTO
) => {
    return {
        type: at.API_UPLOAD_TO_SUPERMAP_STATUS_REPORT,
        payload: { key, status, file, credentials },
    };
};

export const actionUploadToSuperMapStatusReportError = (key: string, error: string) => {
    return {
        type: at.API_UPLOAD_TO_SUPERMAP_STATUS_REPORT,
        payload: { key, error, status: 0 },
    };
};

const actionUploadToSuperMapError = (err) => {
    return {
        type: at.API_UPLOAD_TO_SUPERMAP_ERROR,
        payload: err,
    };
};

export const actionReuploadMapId = (id: string | undefined) => {
    return {
        type: at.API_REUPLOAD_MAP_ID,
        payload: id,
    };
};
