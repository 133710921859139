export const actionTypes = {
    FETCH_TILE_LAYER_DICTIONARY: 'MAP/SELECTION/FETCH_TILE_LAYER_DICTIONARY',
    FETCH_TILE_LAYER_DICTIONARY_LOADING: 'MAP/SELECTION/FETCH_TILE_LAYER_DICTIONARY_LOADING',
    FETCH_TILE_LAYER_DICTIONARY_SUCCESS: 'MAP/SELECTION/FETCH_TILE_LAYER_DICTIONARY_SUCCESS',
    FETCH_GLOBAL_TILELAYER_SUCCESS: 'MAP/SELECTION/FETCH_GLOBAL_TILE_LAYER_SUCCESS',
    FETCH_CONTINENT_TILELAYER_SUCCESS: 'MAP/SELECTION/FETCH_CONTINENT_TILE_LAYER_SUCCESS',
    SET_HIGHLIGHTED_CONTINENT: 'MAP/SELECTION/SET_HIGHLIGHTED_CONTINENT',
    SET_VISIBLE_TILE_LAYER_GROUPS: 'MAP/SELECTION/SET_VISIBLE_TILE_LAYER_DICTIONARIES',
    SET_BEST_FITTING_TILE_LAYER_GROUPS: 'MAP/SELECTION/SET_BEST_FITTING_TILE_LAYER_DICTIONARIES',
    SET_HIGHLIGHTED_TILE_LAYER_GROUP: 'MAP/SELECTION/SET_HIGHLIGHTED_TILE_LAYER_DICTIONARY',
    SET_HIGHLIGHTED_TILE_LAYER_ID: 'MAP/SELECTION/SET_HIGHLIGHTED_TILE_LAYER_ID',

    SET_SEARCH_FOCUSED: 'MAP/SELECTION/SET_SEARCH_FOCUSED',
    SET_SELECTED_CONTINENT: 'MAP/SELECTION/SET_SELECTED_CONTINENT',
    SET_SELECTED_CATEGORY: 'MAP/SELECTION/SET_SELECTED_CATEGORY',
    SET_SELECTED_SEARCH_TERM: 'MAP/SELECTION/SET_SELECTED_SEARCH_TERM',
    SET_CLEAR_SELECTED_LISTING: 'MAP/SELECTION/SET_CLEAR_SELECTED_LISTING',
};
