import { LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { SatelliteArchiveCostDTO, SatelliteArchiveImageryDTO } from '../../../../api/api-supplier';
import { CountryInfo } from '../../../../api/model';
import Constants from '../../../../constants';
import GeoUtil from '../../../../lib/geo-util';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import UserHelper from '../../../../lib/user-helper';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import {
    actionSatelliteEndBoxSelect,
    actionSatelliteResetAOI,
    actionSatelliteResetActiveFeatures,
} from '../../../../store/Map/SatelliteArchive/actions';
import {
    selectIsSatelliteBoxSelectActive,
    selectSatelliteAOI,
    selectSatelliteSelectedFeatures,
} from '../../../../store/Map/SatelliteArchive/selectors';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { selectMyProfile } from '../../../../store/Account/selectors';
import DrawerContainerHeader from '../../drawer-container-header';
import DrawerHint from '../../drawer-hint';
import { Container, Logo, LogoContainer, TeaserIcon, TeaserTitle, Button } from '../satellite-drawer-styles';
import SatelliteLoading from '../satellite-loading';
import SatelliteArchiveResults from './satellite-archive-results';
import SatelliteArchiveSelectAOI from './satellite-archive-select-aoi';
import SatelliteOrderConditions from './satellite-order-conditions';
import SatelliteOrderCreditCardDetails from './satellite-order-credit-card-details';
import SatelliteOrderPurchaseComplete from './satellite-order-purchase-complete';
import SatelliteOrderPurchaseDetails from './satellite-order-purchase-details';
import SatelliteOrderUserDetails, { Industry } from './satellite-order-user-details';

enum ArchiveWorkflow {
    NoPermission,
    SelectAOI,
    SearchLoading,
    SearchError,
    SearchResults,
    PurchaseDetails,
    UserDetails,
    Conditions,
    EnterCreditCard,
    OrderComplete,
}

export interface ValidArchiveAOIParameters {
    maxArea: number;
    minArea: number;
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
}

type CreateOrderPromise = (
    selectedAOI: LatLngBounds,
    sceneIds: string[],
    stripeToken: any, //eslint-disable-line @typescript-eslint/no-explicit-any
    userCompanyName: string,
    userIndustry: Industry,
    userCountry: CountryInfo,
    voucher: string | undefined
) => Promise<any>; //eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * This interface is the heart of the Satellite Archive re-usability.
 * It factors out all the workflow behaviours, network calls and parameters
 * leaving the underlying components generic.
 */
interface SatelliteArchiveProps {
    title: string;
    validAOIParameters: ValidArchiveAOIParameters;
    rootSideDrawerMode: SideDrawerMode;
    analyticsCategory: string;
    termsAndConditionsChecklist: string[];
    termsAndConditionsHref: string;
    logoUrl: string;
    isVoucherVisible?: boolean;

    fetchSearchResults: (aoi: LatLngBounds) => Promise<SatelliteArchiveImageryDTO[]>;
    fetchPrice: (
        aoi: LatLngBounds,
        sceneIds: string[],
        voucher?: string,
        countryCode?: string
    ) => Promise<SatelliteArchiveCostDTO>;
    createOrder: CreateOrderPromise;
}

const SatelliteArchive = (props: SatelliteArchiveProps) => {
    const { fetchPrice, fetchSearchResults, validAOIParameters } = props;
    const [workflow, setWorkflow] = useState<ArchiveWorkflow>(ArchiveWorkflow.SelectAOI);
    const [cost, setCost] = useState<SatelliteArchiveCostDTO | undefined>(undefined);
    const [costError, setCostError] = useState<Error | undefined>(undefined);
    const [validVoucher, setValidVoucher] = useState<string | undefined>(undefined);
    const [validAOI, setValidAOI] = useState<LatLngBounds | undefined>(undefined);

    const [userCompanyName, setUserCompanyName] = useState<string | undefined>(undefined);
    const [userIndustry, setUserIndustry] = useState<Industry | undefined>(undefined);
    const [userCountryInfo, setUserCountryInfo] = useState<CountryInfo | undefined>(undefined);

    const [createOrderError, setCreateOrderError] = useState<Error | undefined>(undefined);
    const myProfile = useSelector(selectMyProfile);

    const selectedAOI = useSelector(selectSatelliteAOI);
    const isArchiveBoxSelectActive = useSelector(selectIsSatelliteBoxSelectActive);
    const selectedFeatures = useSelector(selectSatelliteSelectedFeatures);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SatelliteArchiveImageryDTO[]>([]);
    const [searchError, setSearchError] = useState<Error | undefined>(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!UserHelper.hasWritePermissionsForSubdomain(myProfile)) {
            setWorkflow(ArchiveWorkflow.NoPermission);
        }
    }, [myProfile]);

    const handleCreateOrder = (stripeToken: string) => {
        if (!selectedAOI) return;
        if (!selectedFeatures || selectedFeatures.length == 0) return;
        if (!userCompanyName) return;
        if (!userIndustry) return;
        if (!userCountryInfo) return;

        const sceneIds = selectedFeatures.map((t) => t.id);
        props
            .createOrder(
                selectedAOI,
                sceneIds,
                stripeToken,
                userCompanyName,
                userIndustry,
                userCountryInfo,
                validVoucher
            )
            .then(() => {
                // TODO: Use the response DTO to populate OrderComplete UI
                //       This may not needed since the endpoint should throw err on failure
                //       so it should be safe to assume that a 200 means a successful purchase
                setWorkflow(ArchiveWorkflow.OrderComplete);
                setValidVoucher(undefined);
                Analytics.Event('Satellite', 'Completed order');
            })
            .catch((err) => {
                setCreateOrderError(err);
            });
    };

    useEffect(() => {
        // TODO: Remove duplicate AOI logic from here and `satellite-archive-select-aoi-error.tsx`
        if (selectedAOI) {
            const params = validAOIParameters;
            const selectedArea = GeoUtil.area(selectedAOI) / 1000000;
            const height = GeoUtil.heightKilometers(selectedAOI);
            const width = GeoUtil.widthKilometers(selectedAOI);

            if (
                selectedArea > params.minArea &&
                selectedArea < params.maxArea &&
                width > params.minWidth &&
                width < params.maxWidth &&
                height > params.minHeight &&
                height < params.maxHeight
            ) {
                setWorkflow(ArchiveWorkflow.SearchLoading);
                setValidAOI(selectedAOI);
                setValidVoucher(undefined);
                setSearchResults([]);
                setSearchError(undefined);
                fetchSearchResults(selectedAOI)
                    .then((res) => {
                        if (res.length === 0) {
                            throw new Error('no results');
                        } else {
                            setSearchResults(res);
                            setWorkflow(ArchiveWorkflow.SearchResults);
                        }
                    })
                    .catch((err) => {
                        let message = 'An error occurred while accessing the satellite. Please try again later.';
                        if (err.message.toLowerCase().includes('unknown error')) {
                            // TODO: Improve our backend error message when external API is not responding
                            message = 'Search is temporarily unavailable. Please try again later.';
                        } else if (err.message.toLowerCase().includes('restricted_area')) {
                            message =
                                'Sorry due to data access restrictions satellite imagery is not available in this area. Please check back soon.';
                        } else if (err.message.toLowerCase().includes('no results')) {
                            message = 'There are no results in that area. Try a different AOI.';
                        }
                        err.message = message;
                        setSearchError(err);
                        setWorkflow(ArchiveWorkflow.SearchError);
                    });
            } else {
                setWorkflow(ArchiveWorkflow.SelectAOI);
            }
        }
    }, [selectedAOI, validAOIParameters, fetchSearchResults]);

    useEffect(() => {
        setValidVoucher(undefined);
    }, [isArchiveBoxSelectActive]);

    const costAdjustments = (res: SatelliteArchiveCostDTO) => {
        const adjustedCost: SatelliteArchiveCostDTO = {
            ...res,
            totalPrice: res.totalPrice / 100,
            pricePerKm: res.pricePerKm / 100,
            voucherValueUsed: res.voucherValueUsed / 100,
            totalTax: res.totalTax / 100,
        };
        return adjustedCost;
    };

    const updatePrice = useCallback(
        (voucher, isVoucherReset = false) => {
            if (!validAOI) return;
            if (!selectedFeatures || selectedFeatures.length == 0) return;

            const sceneIds = selectedFeatures.map((t) => t.id);
            setIsLoading(true);
            setCostError(undefined);
            fetchPrice(validAOI, sceneIds, voucher, userCountryInfo?.countryCode)
                .then((res) => {
                    setCost(costAdjustments(res));
                    setValidVoucher(voucher);
                })
                .catch((error: Error) => {
                    setCostError(error);
                    setValidVoucher(undefined);
                    if (isVoucherReset) {
                        fetchPrice(validAOI, sceneIds, undefined, userCountryInfo?.countryCode).then((res) => {
                            setCost(costAdjustments(res));
                        });
                    }
                })
                .finally(() => setIsLoading(false));
        },
        [fetchPrice, validAOI, selectedFeatures, userCountryInfo?.countryCode]
    );

    useEffect(() => {
        updatePrice(validVoucher);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePrice, validAOI, selectedFeatures, userCountryInfo?.countryCode]);

    const handleClickBack = () => {
        switch (workflow) {
            case ArchiveWorkflow.NoPermission:
                UriHelper.navigateToDrawer(props.rootSideDrawerMode);
                break;

            case ArchiveWorkflow.SelectAOI:
                setCost(undefined);
                dispatch(actionSatelliteResetActiveFeatures());
                dispatch(actionSatelliteResetAOI());
                dispatch(actionSatelliteEndBoxSelect());
                UriHelper.navigateToDrawer(props.rootSideDrawerMode);
                break;

            case ArchiveWorkflow.SearchLoading:
                setWorkflow(ArchiveWorkflow.SelectAOI);
                break;

            case ArchiveWorkflow.SearchError:
                setWorkflow(ArchiveWorkflow.SelectAOI);
                break;

            case ArchiveWorkflow.SearchResults:
                setCost(undefined);
                dispatch(actionSatelliteResetActiveFeatures());
                dispatch(actionSatelliteResetAOI());
                dispatch(actionSatelliteEndBoxSelect());

                setWorkflow(ArchiveWorkflow.SelectAOI);
                break;

            case ArchiveWorkflow.PurchaseDetails:
                setWorkflow(ArchiveWorkflow.SearchResults);
                break;

            case ArchiveWorkflow.UserDetails:
                setWorkflow(ArchiveWorkflow.PurchaseDetails);
                break;

            case ArchiveWorkflow.Conditions:
                setWorkflow(ArchiveWorkflow.UserDetails);
                break;

            case ArchiveWorkflow.EnterCreditCard:
                setWorkflow(ArchiveWorkflow.UserDetails);
                break;

            case ArchiveWorkflow.OrderComplete:
                dispatch(actionSatelliteResetAOI());
                dispatch(actionSatelliteEndBoxSelect());
                setCost(undefined);
                setUserCompanyName(undefined);
                setUserIndustry(undefined);
                setUserCountryInfo(undefined);
                setCreateOrderError(undefined);
                setValidVoucher(undefined);
                setWorkflow(ArchiveWorkflow.SelectAOI);
                break;

            default:
        }
    };

    const renderContent = (): React.ReactNode => {
        switch (workflow) {
            case ArchiveWorkflow.NoPermission:
                return (
                    <React.Fragment>
                        <TeaserTitle>{props.title}</TeaserTitle>
                        <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                        <DrawerHint isActive={true}>You do not have permission to order satellite imagery!</DrawerHint>
                    </React.Fragment>
                );

            case ArchiveWorkflow.SelectAOI:
                return (
                    <SatelliteArchiveSelectAOI
                        title={props.title}
                        analyticsCategory={props.analyticsCategory}
                        validAOIParameters={props.validAOIParameters}
                    />
                );

            case ArchiveWorkflow.SearchLoading:
                return <SatelliteLoading />;

            case ArchiveWorkflow.SearchError:
                return (
                    <React.Fragment>
                        <TeaserTitle>Archival</TeaserTitle>
                        <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                        <Button onClick={() => setWorkflow(ArchiveWorkflow.SelectAOI)}>Reset area of interest</Button>
                        <DrawerHint error>{searchError?.message}</DrawerHint>
                    </React.Fragment>
                );

            case ArchiveWorkflow.SearchResults:
                return (
                    <SatelliteArchiveResults
                        satelliteArchiveResults={searchResults}
                        selectedScenes={selectedFeatures}
                        satelliteArchiveCost={cost}
                        satelliteArchiveCostError={costError}
                        onClickCheckout={() => setWorkflow(ArchiveWorkflow.PurchaseDetails)}
                    />
                );

            case ArchiveWorkflow.PurchaseDetails:
                return (
                    <SatelliteOrderPurchaseDetails
                        isLoading={isLoading}
                        isVoucherVisible={!!props.isVoucherVisible}
                        selectedFeatures={selectedFeatures}
                        cost={cost}
                        error={costError}
                        onConfirm={() => {
                            if (!myProfile) {
                                dispatch(actionShowLoginDialog(true));
                                return;
                            }
                            setWorkflow(ArchiveWorkflow.UserDetails);
                        }}
                        onEnterVoucher={(voucher) => {
                            updatePrice(voucher, true);
                        }}
                    />
                );

            case ArchiveWorkflow.UserDetails:
                return (
                    <SatelliteOrderUserDetails
                        handleSubmitUserDetails={(companyName: string, industry: Industry, country: CountryInfo) => {
                            setUserCompanyName(companyName);
                            setUserIndustry(industry);
                            setUserCountryInfo(country);
                            setWorkflow(ArchiveWorkflow.Conditions);
                        }}
                    />
                );

            case ArchiveWorkflow.Conditions:
                return (
                    <SatelliteOrderConditions
                        termsAndConditions={props.termsAndConditionsChecklist}
                        termsAndConditionsHref={props.termsAndConditionsHref}
                        handleSubmitConditions={() => setWorkflow(ArchiveWorkflow.EnterCreditCard)}
                        isLoading={isLoading}
                    />
                );

            case ArchiveWorkflow.EnterCreditCard:
                if (!cost) throw new Error('Precondition error: No cost object before credit card input');
                return (
                    <StripeProvider apiKey={Constants.STRIPE_API_KEY}>
                        <Elements>
                            <SatelliteOrderCreditCardDetails
                                data-sentry-block
                                termsAndConditionsHref={props.termsAndConditionsHref}
                                totalPrice={cost.totalPrice}
                                totalTax={cost.totalTax}
                                currency={cost.currency}
                                handleStripeTokenSubmitted={(stripeToken: string) => {
                                    handleCreateOrder(stripeToken);
                                }}
                                error={createOrderError}
                            />
                        </Elements>
                    </StripeProvider>
                );

            case ArchiveWorkflow.OrderComplete:
                return <SatelliteOrderPurchaseComplete handleClickFinished={() => handleClickBack()} />;

            default:
                return <React.Fragment />;
        }
    };

    return (
        <Container>
            <DrawerContainerHeader handleBack={() => handleClickBack()} data-testid="sentinel-back-button">
                <LogoContainer>
                    <Logo src={props.logoUrl} />
                </LogoContainer>
            </DrawerContainerHeader>

            {renderContent()}
        </Container>
    );
};

export default SatelliteArchive;
