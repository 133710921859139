import React from 'react';
import styled from 'styled-components';

import PrivacyAgreement from '../PrivacyAgreement/privacy-agreement';
import TermsAndConditions from '../PrivacyAgreement/terms-and-conditions';
import NavigationPanel from './Shared/navigation-panel';
import { isMobileVersion } from '../../lib/soar-helper';

const DashboardTermsAndCondition = () => {
    return (
        <DashboardTermsAndConditions>
            {isMobileVersion && <NavigationPanel label="Terms & Conditions" />}
            <TermsAndConditions />
            <PrivacyAgreement />
        </DashboardTermsAndConditions>
    );
};

export default DashboardTermsAndCondition;

const DashboardTermsAndConditions = styled.div`
    & > div {
        padding-top: 0 !important;
    }
`;
