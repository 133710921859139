import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SatelliteArchiveCostDTO, SatelliteArchiveImageryDTO } from '../../../../api/api-supplier';
import {
    actionSatelliteMouseOut,
    actionSatelliteMouseOver,
    actionSatelliteReorderZIndex,
    actionSatelliteSetFeatureActive,
    actionSatelliteSetFeatureInactive,
} from '../../../../store/Map/SatelliteArchive/actions';
import PriceWithCurrency from '../../../Shared/price-with-currency';
import { StyledButton } from '../../../Shared/styled-button';
import SatelliteArchiveSelectAndReorder from './satellite-archive-select-and-reorder';

interface SatelliteArchiveResultsProps {
    satelliteArchiveResults: SatelliteArchiveImageryDTO[];
    selectedScenes: SatelliteArchiveImageryDTO[];
    satelliteArchiveCost?: SatelliteArchiveCostDTO;
    satelliteArchiveCostError?: Error;
    onClickCheckout: () => void;
}

const SatelliteArchiveResults = (props: SatelliteArchiveResultsProps) => {
    const { satelliteArchiveResults, satelliteArchiveCost, satelliteArchiveCostError, selectedScenes } = props;
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <SatelliteArchiveSelectAndReorder
                results={satelliteArchiveResults}
                reorderScene={(newOrder) => dispatch(actionSatelliteReorderZIndex(newOrder))}
                selectedResults={selectedScenes}
                setResultActive={(result: SatelliteArchiveImageryDTO) => {
                    dispatch(actionSatelliteSetFeatureActive(result));
                }}
                setResultInactive={(result: SatelliteArchiveImageryDTO) => {
                    dispatch(actionSatelliteSetFeatureInactive(result));
                }}
                setResultMouseOver={(result: SatelliteArchiveImageryDTO) => {
                    dispatch(actionSatelliteMouseOver(result));
                }}
                setResultMouseOut={() => {
                    dispatch(actionSatelliteMouseOut());
                }}
            >
                <ResultsText>
                    {selectedScenes.length} of {satelliteArchiveResults.length} results selected
                </ResultsText>

                {selectedScenes.length > 0 && !satelliteArchiveCost && !satelliteArchiveCostError ? (
                    <PurchaseButton disabled>
                        <i className="fa fa-spinner fa-spin" />
                    </PurchaseButton>
                ) : null}

                {selectedScenes.length > 0 && satelliteArchiveCostError ? (
                    <ErrorText>Not enough scenes.</ErrorText>
                ) : null}

                {selectedScenes.length > 0 && satelliteArchiveCost && !satelliteArchiveCostError ? (
                    <PurchaseButton onClick={props.onClickCheckout}>
                        Purchase for{' '}
                        <PriceWithCurrency
                            value={satelliteArchiveCost.totalPrice}
                            currency={satelliteArchiveCost.currency}
                        />
                    </PurchaseButton>
                ) : null}
            </SatelliteArchiveSelectAndReorder>
        </React.Fragment>
    );
};

export default SatelliteArchiveResults;

const ResultsText = styled.p`
    color: white;
    margin: 7px 0 0 10px;
    min-width: 160px;
`;

const ErrorText = styled.p`
    color: red;
    margin: 7px 10px 0 0;
`;

const PurchaseButton = styled(StyledButton)`
    min-width: 217px;
`;
