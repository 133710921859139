import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '../../../../UI/Button/button';
import { DrawPage } from '../draw-story';

interface StoryBuilderPageItemTitleProps {
    page: DrawPage;
    isSelected: boolean;
    handleShowDetails: () => void;
    handleHideDetails: () => void;
}

const StoryBuilderPageItemTitle = (props: StoryBuilderPageItemTitleProps) => {
    return (
        <React.Fragment>
            <Row>
                <TitleContainer>
                    <Title>{props.page.pageTitle || <span>Untitled page</span>}</Title>
                </TitleContainer>

                <ShowDetailButtonContainer>
                    {props.isSelected ? (
                        <ShowDetailsButton
                            onClick={() => {
                                props.handleShowDetails();
                            }}
                        >
                            Edit details
                        </ShowDetailsButton>
                    ) : null}
                </ShowDetailButtonContainer>
            </Row>
        </React.Fragment>
    );
};

export default StoryBuilderPageItemTitle;

const Row = styled.div`
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 90px);
`;

const Title = styled.h3`
    color: white;
    font-weight: bold;
    margin: 10px 3px 10px 6px;
    font-size: ${(props) => props.theme.fontSize.large};
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;

    span {
        color: red;
    }
`;

const ShowDetailButtonContainer = styled.div`
    width: 90px;
    height: auto;
    justify-content: center;
    vertical-align: middle;
`;

const ShowDetailsButton = styled(LinkButton)`
    width: 90px;
`;
