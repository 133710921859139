import { Children, ReactElement, ReactNode, cloneElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyledDarkButton } from '../../../Shared/styled-button';
import useOnClickOutside from '../../../../lib/use-on-click-outside';

interface AnnotationToolbarResizableProps {
    children?: React.ReactNode;
    deleteButton?: React.ReactNode;
}

const DynamicToolbar = (props: AnnotationToolbarResizableProps) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [visibleTabIndices, setVisibleTabIndices] = useState<number[]>([]);
    const [measuringRender, setMeasuringRender] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const deleteRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const overflowRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setIsMounted(true);
        setMeasuringRender(true);
    }, []);

    useLayoutEffect(() => {
        if (measuringRender && containerRef.current && buttonRef.current && deleteRef.current) {
            const tabElements = Array.from(containerRef.current.children);

            let stopWidth = 0;
            const visible: number[] = [];
            tabElements.forEach((tab, index) => {
                if (visible.length === tabElements.length - 1) {
                    const buttonWidth = buttonRef.current?.offsetWidth ?? 0;
                    const deleteWidth = deleteRef.current?.offsetWidth ?? 0;
                    stopWidth -= buttonWidth + deleteWidth;
                }

                stopWidth += (tab as HTMLElement).offsetWidth;
                const containerWidth = containerRef.current?.offsetWidth ?? 0;
                if (containerWidth >= stopWidth) {
                    visible.push(index);
                }
            });
            setVisibleTabIndices(visible);
            setMeasuringRender(false);
        }
    }, [measuringRender]);

    useOnClickOutside(overflowRef, () => {
        setMenuIsOpen(false);
    });

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                setMenuIsOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setMeasuringRender(true);
            setMenuIsOpen(false);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setMeasuringRender(true);
    }, [props.children]);

    const allTabs = Children.map(props.children, (tab, index) => {
        return cloneElement(tab as ReactElement, {
            key: index,
        });
    });

    let visibleTabs: ReactNode[] = [];
    const overflowTabs: ReactNode[] = [];

    if (allTabs) {
        if (!isMounted || measuringRender) {
            visibleTabs = allTabs;
        } else {
            allTabs.forEach((tab, index) => {
                if (visibleTabIndices.includes(index)) {
                    visibleTabs.push(tab);
                } else {
                    overflowTabs.push(tab);
                }
            });
        }
    }

    return (
        <ToolbarContainer>
            <ToolbarMenuItems ref={containerRef}>{visibleTabs}</ToolbarMenuItems>
            <ToolBarDeleteButton ref={deleteRef}>{props.deleteButton}</ToolBarDeleteButton>
            {(measuringRender || overflowTabs.length > 0) && (
                <ToolBarMenu>
                    <ToolBarMoreItems ref={buttonRef} onClick={() => setMenuIsOpen(!menuIsOpen)}>
                        More
                    </ToolBarMoreItems>
                    {menuIsOpen && <ToolBarMoreDropdown>{overflowTabs}</ToolBarMoreDropdown>}
                </ToolBarMenu>
            )}
        </ToolbarContainer>
    );
};

export default DynamicToolbar;

export const ToolbarMenuItem = styled.div`
    display: flex;
    flex-direction: row;
`;

const ToolbarContainer = styled.div`
    user-select: none;
    position: fixed;
    top: 82px;
    left: 4px;
    right: 4px;
    height: 48px;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const ToolbarMenuItems = styled.div`
    display: flex;
    align-items: stretch;
    flex: 1;
    min-width: 400px;
    justify-content: center;

    & > * {
        flex-shrink: 0; // prevents child elements from shrinking when there's not enough available space, can be softened by using 0.1 - 0.9...
    }
`;

const ToolBarMenu = styled.div`
    display: flex;
    align-items: stretch;
    position: relative;
`;

const ToolBarDeleteButton = styled.div`
    width: 40px;
    align-self: center;
`;

const ToolBarMoreItems = styled(StyledDarkButton)`
    border-color: transparent !important;
    margin: 4px 0px;
    width: 95px;
    transition: width 0.3s;
`;

const ToolBarMoreDropdown = styled.div`
    padding: 0px 10px;
    margin-top: 50px;
    position: absolute;
    right: 0;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    width: 300px;
    user-select: none;
    border-radius: 4px;
    animation: expandDropdown 0.3s ease-in-out forwards;

    & > * {
        // Comment these out the flex if you want the label to stay on the same row in dropdown
        display: flex;
        flex-direction: column;
        animation: none;
    }

    @keyframes expandDropdown {
        0% {
            opacity: 0;
            transform: rotateY(70deg);
            transform-origin: right;
        }

        100% {
            opacity: 1;
            transform: rotateY(0);
            transform-origin: right;
        }
    }
`;
