import { ListingCategories } from '../../api/model';

export const regionOptions = {
    title: 'Region',
    options: [
        { label: 'North America', value: 'northAmerica' },
        { label: 'South America', value: 'southAmerica' },
        { label: 'Europe', value: 'europe' },
        { label: 'Africa', value: 'africa' },
        { label: 'Asia', value: 'asia' },
        { label: 'Oceania', value: 'oceania' },
        { label: 'Antarctica', value: 'antarctica' },
    ],
    icons: {
        light: '/assets/explore-dropdown/marker-icon-black.svg',
        dark: '/assets/explore-dropdown/marker-icon-white.svg',
    },
};

export const regionPolygons = {
    northAmerica: '((-179 85,-5 85,-21 68,-25 7,-179 7,-179 85))',
    southAmerica: '((-95 14,-20 14,-20 -65,-95 -65,-95 14))',
    europe: '((-30 85,55 85,55 35,-30 35,-30 85))',
    africa: '((-20 40,55 40,55 -40,-20 -40,-20 40))',
    oceania: '((90 10,180 10,180 -50,90 -50,90 10))',
    antarctica: '((-180 -60, -180 -85,180 -85,180 -60,-180 -60))',
    asia: '((50 85,180 85, 180 0,50 0,50 85),(-180 85,-170 85,-170 0,-180 0,-180 85))',
};

export const orderByOptions = {
    title: 'Sort by',
    options: [
        { value: 'CREATED', label: 'Most Recent', key: 'most-recent' },
        { value: 'LIKES', label: 'Most Popular', key: 'most-popular' },
        { value: 'COMMENTS', label: 'Most Commented', key: 'most-commented' },
        { value: 'VIEWS', label: 'Most Viewed', key: 'most-viewed' },
        { value: 'AREA', label: 'Most Area', key: 'most-area' },
        { value: 'RELEVANCE', label: 'Most Relevant ', key: 'most-relevant', onlyV2: true },
    ],
    icons: {
        //encoded SVG  not applicable
        light: '/assets/explore-dropdown/sort-by-filter_light.svg',
        dark: '/assets/explore-dropdown/sort-by-filter_dark.svg',
    },
};

const getCategoryFilterFromListingCategories = () => {
    return Object.keys(ListingCategories).map((c) => {
        return { value: c, label: ListingCategories[c].title };
    });
};

export const categoryOptions = {
    title: 'Category',
    options: getCategoryFilterFromListingCategories(),
    icons: {
        light: '/assets/explore-dropdown/category-icon-black.svg',
        dark: '/assets/explore-dropdown/category-icon-white.svg',
    },
};
