import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { DashboardMode } from '../../../store/Dashboard/model';
import { selectSalesInfo } from '../../../store/Dashboard/selectors';
import { actionLogoutUser } from '../../../store/Account/actions';
import { selectMyListings, selectMyProfile } from '../../../store/Account/selectors';
import profileIconUrl from '../profile-icon-url';
import MenuLink from './menu-link';
import MenuUserDetails from './menu-user-details';
import { selectPurchasedListings } from '../../../store/Purchases/selectors';
import SignOutModal from '../../Shared/Modal/sign-out';

interface MenuSoarProps {
    dashboardMode: DashboardMode;
}

const MenuSoar = ({ dashboardMode }: MenuSoarProps) => {
    const myProfile = useSelector(selectMyProfile);
    const myListings = useSelector(selectMyListings);
    const myPurchases = useSelector(selectPurchasedListings);
    const salesInfo = useSelector(selectSalesInfo);

    const dispatch = useDispatch();

    const [isUserLogout, setIsUserLogout] = useState(false);

    const logout = () => {
        Analytics.Event('My Dashboard', 'Clicked to sign out');
        dispatch(actionLogoutUser());
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    const handleModeSelected = (mode: DashboardMode) => {
        Analytics.Event('My Dashboard', 'Clicked Dashboard Menu', mode);
        UriHelper.navigateToDashboard(mode);
    };

    return (
        <UserDashboardContainer>
            <MenuContainer>
                <MenuUserDetails myProfile={myProfile} myListings={myListings} />
                <MenuList>
                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.MY_IMAGERY)}
                        iconLight={profileIconUrl.imageryLightUrl}
                        iconDark={profileIconUrl.imageryDarkUrl}
                        selected={dashboardMode === DashboardMode.MY_IMAGERY}
                        label="My Maps"
                    />

                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.MY_DRAW_PROJECTS)}
                        iconLight={profileIconUrl.drawProjectLightUrl}
                        iconDark={profileIconUrl.drawProjectDarkUrl}
                        selected={dashboardMode === DashboardMode.MY_DRAW_PROJECTS}
                        label="My Stoaries"
                    />

                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.MY_BOOKMARKS)}
                        iconLight={profileIconUrl.bookmarkLightUrl}
                        iconDark={profileIconUrl.bookmarkDarkUrl}
                        selected={dashboardMode === DashboardMode.MY_BOOKMARKS}
                        label="My Bookmarks"
                    />

                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.SATELLITE_QUEUE)}
                        iconLight={profileIconUrl.satelliteQueueLightUrl}
                        iconDark={profileIconUrl.satelliteQueueDarkUrl}
                        selected={dashboardMode === DashboardMode.SATELLITE_QUEUE}
                        label="My Satellite Orders"
                    />

                    {myPurchases.length > 0 ? (
                        <MenuLink
                            handleMenuItemClick={() => handleModeSelected(DashboardMode.PURCHASE_HISTORY)}
                            iconLight={profileIconUrl.purchaseHistoryLightUrl}
                            iconDark={profileIconUrl.purchaseHistoryDarkUrl}
                            selected={dashboardMode === DashboardMode.PURCHASE_HISTORY}
                            label="My Purchase History"
                        />
                    ) : null}
                    {salesInfo.length > 0 ? (
                        <MenuLink
                            handleMenuItemClick={() => handleModeSelected(DashboardMode.SALES_HISTORY)}
                            iconLight={profileIconUrl.salesHistoryLightUrl}
                            iconDark={profileIconUrl.salesHistoryDarkUrl}
                            selected={dashboardMode === DashboardMode.SALES_HISTORY}
                            label="My Sales History"
                        />
                    ) : null}

                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.PROFILE)}
                        iconLight={profileIconUrl.profileLightUrl}
                        iconDark={profileIconUrl.profileDarkUrl}
                        selected={dashboardMode === DashboardMode.PROFILE}
                        label="Edit My Profile"
                    />

                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.ACCOUNT_SETTINGS)}
                        iconLight={profileIconUrl.accountSettingsLightUrl}
                        iconDark={profileIconUrl.accountSettingsDarkUrl}
                        selected={dashboardMode === DashboardMode.ACCOUNT_SETTINGS}
                        label="My Account Settings"
                    />
                </MenuList>
            </MenuContainer>

            <UserDashboardTermsLink onClick={() => handleModeSelected(DashboardMode.TERMS_AND_CONDITION)}>
                Terms & Conditions
            </UserDashboardTermsLink>

            <SignOutModal
                isOpen={isUserLogout}
                onToggle={() => setIsUserLogout(false)}
                onSignOut={() => {
                    setIsUserLogout(false);
                    logout();
                }}
            />
        </UserDashboardContainer>
    );
};

export default MenuSoar;

const UserDashboardContainer = styled.div`
    color: white;
    width: 330px;
    margin: 0px 15px;
`;

const MenuList = styled.div`
    // Makes only the menu item section scrollable
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    width: inherit;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }
    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const MenuContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
`;

const UserDashboardTermsLink = styled.div`
    text-decoration-line: underline;
    padding-top: 10px;
    text-align: center;
    color: #fff;
    cursor: pointer;
`;
