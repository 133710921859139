import { isMobileVersion } from '../../lib/soar-helper';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';

const PrivacyAgreement = () => {
    const handleClose = () => {
        UriHelper.navigateToPath(`/`);
    };

    return (
        <PrivacyAgreementContainer>
            {isMobileVersion ? <CloseIcon src="/assets/close.png" onClick={() => handleClose()} /> : null}
            <PrivacyAgreementHeader>
                <h3>Soar.Earth Privacy Policy</h3>
                <p>Last updated: 20 June 2023</p>
            </PrivacyAgreementHeader>
            <PrivacyAgreementBody>
                <p>
                    This privacy policy ("Policy") describes how Soar.Earth Ltd and any related entity (together
                    "Soar.Earth", "We", "Us", "Our") collects, uses, shares, and stores personal information of users of
                    this website,&nbsp;
                    <a href="https://soar.earth" target="_blank">
                        https://soar.earth
                    </a>
                    &nbsp; ("the Site"). This Policy applies to the Site, applications, products and services
                    (collectively, "the Services") on or in which it is posted, linked, or referenced and includes any
                    sub-domains of the site.
                </p>
                <p>
                    By using the Services, you accept the terms of this Privacy Policy and our&nbsp;
                    <a href="https://soar.earth/terms" target="_blank">
                        Terms & Conditions
                    </a>
                    , and consent to our collection, use, disclosure, and retention of your information as described in
                    this Policy.
                </p>
                <h5>
                    IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE, THEN PLEASE DO NOT
                    USE ANY OF THE SERVICES.
                </h5>
                <p>
                    Please note that this Privacy Policy does not apply to information collected through third-party
                    websites or services that you may access through the Services or that you submit to us through
                    email, text message or other electronic message or offline.
                </p>
                <h5>1. Information We Collect</h5>
                <p>Information we collect from you may include:</p>
                <ul>
                    <li>Network information regarding transactions, both paid and unpaid;</li>
                    <li>Information about You that you consent to provide such as Your:</li>
                    <li style={{ marginLeft: '40px' }}>name</li>
                    <li style={{ marginLeft: '40px' }}>email address</li>
                    <li style={{ marginLeft: '40px' }}>social media profiles</li>
                    <li style={{ marginLeft: '40px' }}>other information within your user profile</li>
                    <li style={{ marginLeft: '40px' }}>information related to content that You upload to the site</li>
                    <li style={{ marginLeft: '40px' }}>Content ordered, purchased or downloaded from the Site</li>
                    <li>
                        Feedback and correspondence, such as information You provide in Your responses to surveys when
                        you participate in market research activities, report a problem with Service, receive customer
                        support or otherwise correspond with us;
                    </li>
                    <li>
                        Usage information, such as information about how You use the Site or the Service and interact
                        with Us; and
                    </li>
                    <li>
                        Marketing information, such your preferences for receiving marketing communications and details
                        about how you engage with them.
                    </li>
                </ul>

                <h5>1.1 Information we get from others</h5>
                <p>
                    We may get information about You from other third party sources and We may add this to information
                    We get from your use of the Services. Such information may include:
                </p>
                <ul>
                    <li>
                        <b>Social Media Widgets:</b> Our Site includes social media features, such as the "Share"
                        button. These features may collect your personal information and track your use of the Site.
                        Your interactions with these features are governed by the Privacy Policy of the company
                        providing such functionality.
                    </li>
                    <li>
                        <b>Payment processors</b>: Payment amounts and certain details regarding your transactions on
                        the site to process payments and prevent fraud.
                    </li>
                    <li>
                        <b>Analytics providers</b>: to understand your usage of the site and manage site performance.
                    </li>
                </ul>
                <p>Our Third Party providers who may collect data from You are:</p>
                <table border={1}>
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th style={{ width: '60%' }}>Purpose and basis of use</th>
                            <th>Privacy Policy</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Twitter</td>
                            <td>Sharing content to social media</td>
                            <td>
                                <a href="https://twitter.com/en/privacy" target="_blank">
                                    Twitter Privacy Policy
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                            <td>Sharing content to social media</td>
                            <td>
                                <a href="https://www.facebook.com/about/privacy/" target="_blank">
                                    Facebook Privacy Policy
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>LinkedIn</td>
                            <td>Sharing content to social media</td>
                            <td>
                                <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">
                                    LinkedIn Privacy Policy
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Mailchimp</td>
                            <td>
                                To provide users with newsletters, marketing materials and communications regarding the
                                Services and related matters.
                            </td>
                            <td>
                                <a href="https://www.intuit.com/privacy/statement/" target="_blank">
                                    MailChimp Privacy Statement
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Sendgrid</td>
                            <td>
                                To provide users with system generated communications regarding support, account status,
                                status of orders, any moderation of Content and similar communications
                            </td>
                            <td>
                                <a href="https://www.twilio.com/legal/privacy" target="_blank">
                                    Sendgrid Privacy Policy
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Stripe</td>
                            <td>
                                Secure processing of payments made on the platform and to communicate with internal
                                accounting systems.
                            </td>
                            <td>
                                <a href="https://stripe.com/en-au/privacy" target="_blank">
                                    Stripe Privacy Policy
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>CloudFlare</td>
                            <td>
                                To monitor site performance and performance analytics and collect log data and service
                                requests
                            </td>
                            <td>
                                <a href="https://www.cloudflare.com/privacypolicy/" target="_blank">
                                    CloudFlare
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Google Analaytics</td>
                            <td>Site performance and marketing</td>
                            <td>
                                <a href="https://policies.google.com/privacy?hl=en" target="_blank">
                                    Google Privacy Policy
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Microsoft Clarity</td>
                            <td>
                                To capture how you use and interact with our website through behavioral metrics,
                                heatmaps, and session replay to improve and market our products/services. Website usage
                                data is captured using first and third-party cookies and other tracking technologies to
                                determine the popularity of products/services and online activity. Additionally, we use
                                this information for site optimization, fraud/security purposes, and advertising.
                            </td>
                            <td>
                                <a href="https://privacy.microsoft.com/privacystatement" target="_blank">
                                    Microsoft Policy Statement
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Sentry</td>
                            <td>
                                For error monitoring, session replays where an error occurs within the Site and
                                performance profiling to improve design and functionality of the site and correct errors
                                in performance.
                            </td>
                            <td>
                                <a href="https://sentry.io/privacy/" target="_blank">
                                    Sentry Privacy Statement
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5>1.2 Information automatically collected</h5>
                <p>
                    We may automatically record certain information about how You use the Site (we refer to this
                    information as "Log Data"). Log Data may include information such as:
                </p>
                <ul>
                    <li>the Internet Protocol (IP) address you access the site from</li>
                    <li>device and browser type</li>
                    <li>operating system </li>
                    <li>the pages or features of our Site to which You browsed </li>
                    <li>the time spent on those pages or features</li>
                    <li>the frequency with which the Site is used</li>
                    <li>search terms or locations </li>
                    <li>the links on our Site that You clicked on or used, and</li>
                    <li>other statistics.</li>
                </ul>
                <p>
                    We may use cookies or similar technologies to analyse trends, administer the website, track user
                    movements around the website, and to gather demographic information about our user base. Users can
                    control the use of cookies at the individual browser level. <br />
                </p>
                <p>
                    For more information, please see the section entitled "Cookies Policy" below. <br />
                </p>
                <p>
                    We also use Google Analytics to help us offer you optimized user experience. You can find more
                    information about Google Analytics use of Your personal data here at&nbsp;
                    <a href="https://policies.google.com/privacy?hl=en" target="_blank">
                        https://policies.google.com/privacy?hl=en
                    </a>
                </p>

                <h5>2. Use of personal information</h5>
                <b>2.1 To provide our service</b>
                <br />
                <p>We will use your personal information in the following ways:</p>
                <ul>
                    <li>To enable You to access and use the Services;</li>
                    <li>To provide and deliver products and services that You may request;</li>
                    <li>To identify You as the owner, creator or authorised licensee of content; and</li>
                    <li>
                        To send information, including confirmations, technical notices, updates, security alerts, and
                        support and administrative messages.
                    </li>
                </ul>
                <h5>2.2 To comply with the law</h5>
                <p>
                    We use Your personal information as We believe necessary or appropriate to comply with applicable
                    laws, lawful requests and legal process, such as to respond to subpoenas or requests from government
                    authorities.
                </p>
                <h5>2.3 To communicate with You </h5>
                <p>
                    We use Your personal information to communicate about promotions, upcoming events, and other news
                    about products and services offered by Us and Our selected partners.
                </p>
                <h5>2.4 To optimize Our platform </h5>
                <p>
                    In order to optimize Your user experience, We may use your personal information to operate,
                    maintain, and improve our Services. We may also use Your information to respond to your comments and
                    questions regarding the Services and to provide You and other users with general customer service.
                </p>

                <h5>2.5 With Your consent</h5>
                <p>
                    We may use or share Your personal information with your consent, such as when you instruct us to
                    take a specific action with respect to Your personal information, or You opt into any third party
                    marketing communications.
                </p>

                <h5>2.6 For compliance, fraud prevention, and safety</h5>
                <p>
                    We may use Your personal information to protect, investigate, and deter against fraudulent,
                    unauthorized, or illegal activity.
                </p>

                <h5>3. Sharing of Personal Information</h5>
                <p>
                    We do not share or sell the personal information that you provide us with other organizations
                    without your express consent, except as explicitly described in this Privacy Policy.
                </p>
                <p>We disclose personal information to third parties under the following circumstances: </p>
                <ul>
                    <li>
                        to Our subsidiaries and corporate affiliates for purposes consistent with this Privacy Policy.
                    </li>

                    <li>
                        when we do a business deal, or negotiate a business deal, involving the sale or transfer of all
                        or a part of our business or assets. These deals can include any merger, financing or
                        acquisition.
                    </li>

                    <li>for legal, protection, and safety purposes.</li>

                    <li>to comply with laws and to respond to lawful requests and legal processes.</li>

                    <li>
                        to protect the rights and Our property, agents, customers, and others. This includes enforcing
                        our agreements, policies, and Terms & Conditions.
                    </li>

                    <li>
                        in an emergency including protecting the safety of Our employees and agents, customers, or any
                        person.
                    </li>

                    <li>
                        with those who need it to do work for Us. These recipients may include third party companies and
                        individuals to administer and provide the Service on Our behalf (such as customer support,
                        hosting, email delivery and database management services), as well as lawyers, bankers,
                        auditors, and insurers.
                    </li>

                    <li>
                        Other. You may permit us to share Your personal information with other companies or entities of
                        your choosing. Those uses will be subject to the privacy policies of the recipient entity or
                        entities.
                    </li>
                </ul>

                <h5>4. International Transfer</h5>
                <p>
                    We have offices outside of the EU and affiliates and service providers in the United States and in
                    other countries. Your personal information may be transferred to or from the United States or other
                    locations outside of your state, province, country or other governmental jurisdiction where privacy
                    laws may not be as protective as those in your jurisdiction. <br />
                </p>
                <p>
                    EU users should read the important information provided below about transfer of personal information
                    outside of the European Economic Area (EEA).
                </p>

                <h5>5. How Information is secured</h5>
                <p>
                    To determine the appropriate retention period for personal information, We consider the amount,
                    nature, and sensitivity of the personal information, the potential risk of harm from unauthorized
                    use or disclosure of Your personal information, the purposes for which we process Your personal
                    information and whether we can achieve those purposes through other means, and the applicable legal
                    requirements. <br />
                </p>
                <p>
                    In some circumstances, We may anonymize your personal information (so that it can no longer be
                    associated with you) in which case We may use this information indefinitely without further notice
                    to You. <br />
                </p>
                <p>
                    We employ industry standard security measures designed to protect the security of all information
                    submitted through the Services. However, the security of information transmitted through the
                    internet can never be guaranteed. We are not responsible for any interception or interruption of any
                    communications through the internet or for changes to or losses of data. Users of the Services are
                    responsible for maintaining the security of any:
                </p>
                <ul>
                    <li>User ID</li>
                    <li>Password</li>
                    <li>Multi-factor authentication devices</li>
                    <li>Biometrics or</li>
                    <li>
                        Other forms of authentication involved in obtaining access to secure areas of any of our digital
                        services.
                    </li>
                </ul>
                <p>
                    To protect You and Your data, We may suspend Your use of any of the Services, without notice,
                    pending an investigation, if any breach of security is suspected.
                </p>

                <h5>6. Information Choices & Changes</h5>
                <h5>6.1 Accessing, updating, correcting, and deleting your information</h5>
                <p>
                    You may access information that You have voluntarily provided through Your Account on the Site, and
                    to review, correct, or delete it. If you are unable to access or change this information You may do
                    so by sending a request to&nbsp;
                    <a href="mailto:hello@soar.earth?subject=Privacy%20information">hello@soar.earth</a>&nbsp; and
                    specifying what you require in the body of the email. <br />
                </p>
                <p>
                    You can request to change contact choices, opt-out of our sharing with others, and update your
                    personal information and preferences.
                </p>
                <h5>6.2 Tracking technologies generally</h5>
                <p>
                    Regular cookies may generally be disabled or removed by tools available as part of most commercial
                    browsers, and in some instances blocked in the future by selecting certain settings. For more
                    information, please see the section entitled "Cookies Policy" below.
                </p>

                <h5>6.3 Google Analytics</h5>
                <p>
                    You may exercise choices regarding the use of cookies from Google Analytics by going to &nbsp;
                    <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                        https://tools.google.com/dlpage/gaoptout
                    </a>
                    &nbsp; and downloading the Google Analytics Opt-out Browser Add-on.
                </p>

                <h5>7. Changes to this Privacy Policy</h5>
                <p>
                    We may change this Privacy Policy at any time. We encourage You to periodically review this page for
                    the latest information on our privacy practices. <br />
                </p>
                <p>
                    If We make any changes, We will change the Last Updated date above. Any modifications to this
                    Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the
                    changes to the Site (or as otherwise indicated at the time of posting). <br />
                </p>
                <p>
                    In all cases, your continued use of the Site or Services after the posting of any modified Privacy
                    Policy indicates your acceptance of the terms of the modified Privacy Policy.
                </p>

                <h5>7. Eligibility</h5>
                <p>
                    If you are under the age of majority in your jurisdiction of residence, you may use the Services
                    only with the consent of or under the supervision of your parent or legal guardian. Consistent with
                    the requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that we have
                    received any information directly from a child under age 13 without first receiving his or her
                    parent's verified consent, we will use that information only to respond directly to that child (or
                    his or her parent or legal guardian) to inform the child that he or she cannot use the Site and
                    subsequently we will delete that information.
                </p>

                <h5>8. Notice to EU Data Subjects</h5>

                <h5>8.1 Personal information</h5>
                <p>
                    With respect to EU data subjects, "personal information" as used in this Privacy Policy, is
                    equivalent to "personal data" as defined in the&nbsp;
                    <a href="https://gdpr-info.eu/art-4-gdpr/" target="_blank">
                        European Union General Data Protection Regulation
                    </a>
                    &nbsp; (GDPR).
                </p>

                <h5>8.2 Sensitive data</h5>
                <p>
                    Some of the information you provide us may constitute sensitive data as defined in the GDPR (also
                    referred to as special categories of personal data), including identification of your race or
                    ethnicity on government-issued
                </p>
                <h5>8.3 Use for new purposes</h5>
                <ul>
                    <li>
                        We may use your personal information for reasons not described in this Privacy Policy, where we
                        are permitted by law to do so and where the reason is compatible with the purpose for which we
                        collected it.
                    </li>
                    <li>
                        If we need to use your personal information for an unrelated purpose, we will notify you and
                        explain the applicable legal basis for that use.
                    </li>

                    <li>
                        If we have relied upon your consent for a particular use of your personal information, we will
                        seek your consent for any unrelated purpose.
                    </li>
                </ul>

                <h5>8.4 Your rights</h5>
                <p>
                    Under the GDPR, you have certain rights regarding your personal information. You may ask us to take
                    the following actions in relation to your personal information that we hold to:
                </p>
                <ul>
                    <li>
                        Opt-out of direct marketing communications which you have previously consented to receive. We
                        may continue to send you Service-related and other non-marketing communications;
                    </li>
                    <li>
                        To provide you with information about our processing of your personal information and give you
                        access to your personal information
                    </li>
                    <li>Update or correct inaccuracies in your personal information;</li>
                    <li>Delete your personal information;</li>
                    <li>
                        Transfer a machine-readable copy of your personal information to you or a third party of your
                        choice;
                    </li>
                    <li>Restrict the processing of your personal information;</li>
                    <li>
                        Object to our reliance on our legitimate interests as the basis of our processing of your
                        personal information that impacts your rights.
                    </li>
                </ul>

                <p>
                    You can submit these requests by email to&nbsp;
                    <a href="mailto:hello@soar.earth?subject=Privacy%20request">hello@soar.earth</a>&nbsp; and describe
                    your requirements in the body of the text. <br />
                </p>

                <p>
                    We may request specific information from you to help us confirm your identity and process your
                    request. Applicable law may require or permit us to decline your request. If we decline your
                    request, we will tell you why subject to legal restrictions. <br />
                </p>

                <p>
                    If you would like to submit a complaint about our use of your personal information or response to
                    your requests regarding your personal information, you may contact us at&nbsp;
                    <a href="mailto:hello@soar.earth?subject=Privacy%20complaint">hello@soar.earth</a>&nbsp; or submit a
                    complaint to the data protection regulator in your jurisdiction.
                </p>

                <h5>8.5 Cross-border data transfer </h5>
                <p>
                    Please be aware that your personal data may be transferred to, processed, and stored in a different
                    jurisdiction to that in which you reside or access the service. Data protection laws where your data
                    is stored may be different from those in your country of residence. <br />
                    <br />
                    You consent to the transfer of Your information, including personal information, as set forth in
                    this Privacy Policy by visiting&nbsp; the Site or using the Service.
                </p>
                <br />

                <h5>9. Cookies Policy</h5>
                <p>
                    We understand that your privacy is important to you and are committed to being transparent about the
                    technologies we use. In the spirit of transparency, this policy provides detailed information about
                    how and when we use cookies on the Site.
                </p>
                <ul>
                    <b>Do we use cookies?</b>
                    <br />
                    Yes, native and third-party cookies may be present on the Site and through the use of the Services.
                </ul>
                <ul>
                    <b> What is a cookie?</b>
                    <br />A cookie ("Cookie") is a small text file that is placed on your hard drive by a web page
                    server. Cookies contain information that can later be read by a web server in the domain that issued
                    the cookie to you. Some of the cookies will only be used if you use certain features or select
                    certain preferences, and some cookies will always be used. You can find out more about each cookie
                    by viewing our current cookie list below. We update this list periodically, so there may be
                    additional cookies that are not yet listed. Web beacons, tags and scripts may be used in the Site or
                    in emails to help us to deliver cookies, count visits, understand usage and campaign effectiveness
                    and determine whether an email has been opened and acted upon. We may receive reports based on the
                    use of these technologies by our service / analytics providers on an individual and aggregated
                    basis.
                </ul>
                <ul>
                    <b>How to disable cookies</b>
                    <br />
                    You can generally activate or later deactivate the use of cookies through functionality built into
                    your web browser. Please note that disabling cookies may impact the performance of the site.
                </ul>
                <p>
                    If you want to learn more about cookies, or how to control, disable or delete them, please
                    visit&nbsp;
                    <a href="http://www.aboutcookies.org" target="_blank">
                        http://www.aboutcookies.org
                    </a>
                    &nbsp; for detailed guidance. In addition, certain third-party advertising networks, including
                    Google, permit users to opt out of or customize preferences associated with your internet browsing.
                    To learn more about this feature from Google, click&nbsp;
                    <a href="https://adssettings.google.com/u/0/authenticated?hl=en" target="_blank">
                        here
                    </a>
                    .
                </p>

                <p>
                    <u>Please note</u> that disabling cookies may impact the performance of the site.
                </p>

                <h5>9.1 Third Party cookies</h5>
                <p>The following cookies are used on the Site.</p>
                <table border={1}>
                    <thead>
                        <tr>
                            <th>Cookie Name</th>
                            <th>Generated by</th>
                            <th>Purpose / Basis of use</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>_ga</td>
                            <td>Google Analytics</td>
                            <td>
                                The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign
                                data and also keeps track of site usage for the site's analytics report. The cookie
                                stores information anonymously and assigns a randomly generated number to recognize
                                unique visitors.
                            </td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            <td>Visited</td>
                            <td>Soar.Earth</td>
                            <td>Fo managing the progress of the tutorial by the users</td>
                            <td> Other</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>Google Analytics</td>
                            <td>
                                _gid cookie stores information on how visitors use a website, while also creating an
                                analytics report of the website's performance. Some of the data that are collected
                                include the number of visitors, their source, and the pages they visit anonymously.
                            </td>
                            <td> Analytics</td>
                        </tr>
                        <tr>
                            <td>_gcl_au</td>
                            <td>Google Tag Manager</td>
                            <td>
                                Provided by Google Tag Manager to experiment advertisement efficiency of websites using
                                their services.
                            </td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            <td>_gat</td>
                            <td>Google Analytics</td>
                            <td>
                                This cookie is installed by Google Universal Analytics to restrain request rate and thus
                                limit the collection of data on high traffic sites.
                            </td>
                            <td>Performance</td>
                        </tr>
                        <tr>
                            <td>__stripe_mid</td>
                            <td>Stripe</td>
                            <td>Stripe sets this cookie cookie to process payments.</td>
                            <td>Necessary</td>
                        </tr>
                        <tr>
                            <td>__stripe_sid</td>
                            <td>Stripe</td>
                            <td>Stripe sets this cookie cookie to process payments.</td>
                            <td>Necessary</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5>10. Prior use of blockchain technologies</h5>
                <p>
                    Until August 2020, some activities undertaken through the Service are recorded on the Ethereum (ETH)
                    blockchain. The blockchain is an immutable record of certain transactions that occurred on the Site
                    up to its discontinuation and may not be modified by Us in any manner. <br />
                </p>
                <p>
                    Information recorded on the blockchain by Us relates to the uploading or downloading of certain
                    files or content from the site and includes:
                </p>
                <ul>
                    <li>The files hash, a unique mathematical identifier of image content;</li>
                    <li>Location, date, type of platform used to collect the content and other image data;</li>
                    <li>Title, description, keywords and other information associated with the content;</li>
                    <li>Content storage address;</li>
                    <li>Wallet addresses; and</li>
                    <li>Sales transactions related to certain content.</li>
                </ul>
                <p>
                    These transactions included a wallet address which is pseudonymous within the Site. However, it may
                    be possible that the wallet owner may be identified by content or username details on the Site when
                    combined with blockchain information. <br />
                </p>
                <p>
                    Removal of personal data from the Site will not remove the transaction from the blockchain but
                    potentially will remove the ability to relate a wallet address with the owner's online identity on
                    the Site.
                </p>
                <h5>11. Contact Information</h5>
                <p>
                    We welcome your comments or questions about this Policy, and you may contact us at
                    <br />
                    <br />
                    The Data Controller <br />
                    Soar.Earth Ltd <br />
                    Level 32 Central Park
                    <br />
                    152 St Georges Terrace
                    <br />
                    Perth, WA 6000
                    <br />
                    Australia
                    <br />
                    <br />
                    <a href="mailto:hello@soar.earth?subject=Privacy%20policy%20questions">hello@soar.earth</a>
                </p>
            </PrivacyAgreementBody>
        </PrivacyAgreementContainer>
    );
};

export default PrivacyAgreement;

const PrivacyAgreementContainer = styled.div`
    padding-right: 50px;
    padding-bottom: 20px;
    padding-top: 60px;
    overflow: auto;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        height: 100vh;
        margin-left: 4vw;
        overflow: auto;
    }

    @media all and (max-width: 1180px) and (max-height: 820px) and (orientation: landscape) {
        margin: 20px;
        height: 100vh;
        overflow: auto;
    }

    @media only screen and (max-width: 600px) {
        height: 100vh;
        overflow: auto;
    }
`;

const PrivacyAgreementHeader = styled.div`
    -webkit-text-fill-color: white;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    height: 60px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    color: white;
    margin: auto;
    text-align: center;
    flex-direction: none;
    padding-top: 0px;

    h3 {
        color: inherit;
        justify-content: center;
        align-self: center;
        display: flex;
        padding-left: 20px;
    }

    p {
        color: inherit;
        opacity: 0.8;
        margin: 18px;
    }

    @media only screen and (max-width: 600px) {
        justify-content: none;
        background-color: none;
        border-radius: 0px;
        flex-direction: column;
        border-top: none;
        text-align: left;

        h3 {
            justify-content: start;
            align-self: flex-start;
            padding-left: 10px;
        }

        p {
            margin: 0px 10px;
        }
    }
`;

const PrivacyAgreementBody = styled.div`
    width: 100%;
    padding-left: 22px;
    padding-top: 20px;
    color: white;
    -webkit-text-fill-color: white;

    @media only screen and (max-width: 900px) {
        padding-left: 10px;
        padding-top: 5px;
        padding-right: 5px;
    }

    h5 {
        font-weight: bold;
        color: #eed926;
        -webkit-text-fill-color: #eed926;
        text-align: left;
        font-size: 1.5rem;
        font-weight: 200;
    }

    a {
        color: #eed926;
        -webkit-text-fill-color: #eed926;
        text-decoration: underline;
    }

    ul {
        margin-left: 20px;
        list-style: none;
        padding-inline-start: 0px;
    }

    li {
        color: inherit;
        -webkit-text-fill-color: inherit;
    }
`;

const CloseIcon = styled.img`
    margin: 15px;
    width: 22px;
    height: 22px;
    pointer-events: all;
    float: right;
`;
