import React from 'react';
import styled from 'styled-components';

interface StoryBuilderPageItemMapsAddButtonProps {
    setShowSearchModal: () => void;
}

const StoryBuilderPageItemMapsAddButton = (props: StoryBuilderPageItemMapsAddButtonProps) => {
    return (
        <React.Fragment>
            <Container onClick={() => props.setShowSearchModal()}>
                <AddIcon className="fas fa-plus" />
                <AddText>Add a map from the Soar atlas to this page</AddText>
            </Container>
        </React.Fragment>
    );
};

export default StoryBuilderPageItemMapsAddButton;

const Container = styled.div`
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: ${(props) => props.theme.borderRadius};
    margin-bottom: 12px;
    color: white;

    :hover {
        border: 1px solid ${(props) => props.theme.color.lightGray};
        opacity: 1;
    }
`;

const AddIcon = styled.i`
    color: ${(props) => props.theme.color.yellow};
    margin-right: 12px;
    display: block;
    font-size: 22px;
    width: 22px;
    padding: 12px 6px;
    cursor: pointer;
`;

const AddText = styled.span`
    color: white !important;
    margin-top: 12px;
`;
