import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { CommentResultsDTO, ListingDTO } from '../../../../api/model';
import ActiveCommentActions from './comment-actions';
import CommentVote from './comment-vote';
import UriHelper from '../../../../lib/uri-helper';
import { useDispatch } from 'react-redux';
import { actionSetResetCommentSection, actionSetSelectedCommentId } from '../../../../store/SocialMapping/actions';
import Analytics from '../../../../lib/user-analytics';
import { deleteAllAnnotationsAction } from '../../../../store/Annotations/actions';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { actionActiveMapCleared } from '../../../../store/Map/SuperMap/actions';

import ProfileName from '../../Profile/profile-name';
import StyledComment from '../../../Shared/styled-comment';
import UserAvatar from '../../../Shared/Avatar/user-avatar';

interface CommentDetailsProps {
    comment: CommentResultsDTO;
    isSelected: boolean;
    handleOpenDeleteComment: () => void;
    handleEditComment: () => void;
    handleReportComment: () => void;
    handleModerateComment: () => void;
    listing: ListingDTO | number | string;
}

export const CommentDetails = (props: CommentDetailsProps) => {
    const dispatch = useDispatch();

    const handleSelectProfile = () => {
        dispatch(deleteAllAnnotationsAction());

        if (props.comment.userId) {
            const userId = props.comment.userId;
            if (userId) {
                UriHelper.navigateToPath(`/profile/${userId}`);
                Analytics.Event('Comments', 'Clicked comment profile', userId);
                dispatch(actionSetResetCommentSection(true));
                dispatch(actionSetSelectedCommentId(''));
            }
        }
        dispatch(actionActiveMapCleared());
    };

    return (
        <CommentContainer>
            {isMobileVersion ? null : <CommentVote comment={props.comment} />}
            <CommentCard>
                <TopRow>
                    <TopRowCommentUser>
                        <UserAvatar user={props.comment} margin="0" diameter={40} onClick={handleSelectProfile} />
                        <TopRowContent>
                            <Username
                                userId={props.comment.userId}
                                isCommunityModerator={props.comment.communityModerator}
                                isReputable={props.comment.userReputable}
                                onClick={handleSelectProfile}
                            >
                                {props.comment.userName || 'Soar User'}
                            </Username>

                            <CommentDate>{moment(new Date(props.comment.createdAt * 1000)).fromNow()}</CommentDate>
                        </TopRowContent>
                    </TopRowCommentUser>
                    {props.isSelected && !isMobileVersion ? (
                        <ActiveCommentActions
                            handleCommentDelete={props.handleOpenDeleteComment}
                            handleCommentEdit={props.handleEditComment}
                            handleReportComment={props.handleReportComment}
                            handleModerateComment={props.handleModerateComment}
                            commentUserId={props.comment.userId}
                        />
                    ) : null}
                </TopRow>

                <SecondRow>
                    <StyledComment text={props.comment.text || ''} isSelected={props.isSelected} />
                </SecondRow>
            </CommentCard>
        </CommentContainer>
    );
};

const CommentContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const CommentCard = styled.div`
    width: 100%;
`;

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 7px 0px 0px 30px;

    @media only screen and (max-width: 600px) {
        margin-left: 2vw;
    }
`;

const TopRowCommentUser = styled.div`
    display: flex;
    flex-direction: row;
    width: 93%;
`;

const TopRowContent = styled.div`
    width: 88%;
    margin-left: 7px;

    @media only screen and (max-width: 600px) {
        max-width: calc(100vw - 130px);
    }
`;

const Username = styled(ProfileName)`
    margin: 0 !important;
    font-size: 16px;
    display: flex;

    a {
        color: #eed926;
        max-width: 370px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: normal;
        display: inline-block;

        & ~ sup {
            font-size: 16px;
        }
    }
`;

const CommentDate = styled.span`
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    width: 120px;

    @media only screen and (max-width: 600px) {
        margin-top: 1px;
        font-size: 9px;
    }
`;

const SecondRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;
